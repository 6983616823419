import { Component, Input, OnInit } from '@angular/core';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { environment } from 'src/environments/environment.prod';
import states from 'src/app/data/states.json';
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import {getAddressLine } from 'src/app/tools/address-utils';

@Component({
  selector: 'app-manage-one-address',
  templateUrl: './manage-one-address.component.html',
  styleUrls: ['./manage-one-address.component.css']
})
export class ManageOneAddressComponent implements OnInit {

  @Input() public address;
  @Input() private src;
  public addressDelete = null as any;
  modalOptions: NgbModalOptions;
  closeResult: string;
  public infoAddress = {
    id: '',
    country: environment.countryCode,
    street: '',
    outdoor_number: '',
    interior_number: '',
    neighborhood: '',
    postalcode: '',
    city: '',
    state: {
      code: "",
      name: ""
    },
    reference: '',
    indications: ''
  };
  public colorTheme = 'white';
  public titleAction = 'Agregar una dirección';
  public estados = [];
  public estadoSeleccionado;
  private infoService = null;

  constructor(private api: RestApiServiceService, private auth: ShopsAuthAngularService, private cart: CartServiceService, private styling: StyleManagerService, private service: CustomStoreService, private modalService: NgbModal, private toastrService: ToastrService, public activeModal: NgbActiveModal) {
    this.modalOptions = {
      size: 'xl',
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
        }
      }
    });
    setTimeout(() => {
      const infoService = this.service.infoService.getValue();
      if(infoService){
        this.infoService = infoService;
      }
    }, 1000);
    setTimeout(()=> {
      this.styling.setColor('state', 'color', '#495057');
      this.styling.setColor('state', 'backgroundColor', 'transparent');
      this.styling.setColor('state', 'borderColor', '#ced4da');
    }, 100);
   }

  ngOnInit() {
    if(localStorage.getItem('SU5GTy1FU1RBRE9T') !== null){
      this.estados = JSON.parse(atob(localStorage.getItem('SU5GTy1FU1RBRE9T')));
    } else {
      this.estados = states.states;
    }
    if(this.address){
      this.titleAction = 'Editar una dirección';
      this.infoAddress = this.address;
      const findState = states.states.find((state) => state.code === this.infoAddress.state.code);
      if(findState){
        this.estadoSeleccionado = findState;
      }
      // si la direccion es diferente de null, llenar campos
    }
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'reference') {
      this.infoAddress.reference = valor;
    } else if (idInput === 'street') {
      this.infoAddress.street = valor;
    } else if (idInput === 'outdoor_number') {
      this.infoAddress.outdoor_number = valor;
    } else if (idInput === 'interior_number') {
      this.infoAddress.interior_number = valor;
    } else if (idInput === 'neighborhood') {
      this.infoAddress.neighborhood = valor;
    } else if (idInput === 'city') {
      this.infoAddress.city = valor;
    } else if (idInput === 'postalcode') {
      this.infoAddress.postalcode = valor;
    } else if (idInput === 'indications') {
      this.infoAddress.indications = valor;
    }
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id, 'color', '#ffffff');
      this.styling.setColor(id, 'background', this.colorTheme);
      this.styling.setColor(id, 'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id, 'color', '#495057');
      this.styling.setColor(id, 'backgroundColor', 'transparent');
      this.styling.setColor(id, 'borderColor', '#ced4da');
    }
  }

  selectDropdown(value, type){
    if(type === 'state'){
      this.estadoSeleccionado = value;
      this.infoAddress.state = {
        name: this.estadoSeleccionado.nombre,
        code: this.estadoSeleccionado.code
      };
      setTimeout(()=> {
        this.styling.setColor('state', 'color', '#495057');
        this.styling.setColor('state', 'backgroundColor', 'transparent');
        this.styling.setColor('state', 'borderColor', '#ced4da');
      }, 100);
    }
  }

  generateQuote(token){
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.cart.generateQuote(this.infoAddress, decodedToken);
  }

  checkAddress = async() =>{
    if(this.isValidAddress()){
      const token = this.auth.getToken();
      if(token !== null){
        if(this.infoAddress.id === ''){
          // 1. AGREGAR DIRECCION API
          const infoAddress = await this.api.addNewAddress(token, this.infoAddress, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
          if(infoAddress && infoAddress.code === 200){
            this.toastrService.success('Dirección agregada exitosamente');
            // 2. Pushear la nueva direccion en listado
            this.cart.pushNewAddress(infoAddress.info);
            // 3. Si viene del checkout, generar cotizacion...
            if(this.src === 'checkout'){
              this.generateQuote(token);
            }
            this.activeModal.close();
          } else {
            this.toastrService.error('Ocurrió un error al agregar la dirección');
          }
        } else {
          const infoUpdated = await this.api.editAddress(token, this.infoAddress, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
          if(infoUpdated && infoUpdated.code === 200){
            this.toastrService.info('Dirección actualizada exitosamente');
            // 2. editar la direccion en listado
            this.cart.editAddress(this.infoAddress);
            this.activeModal.close();
          } else {
            this.toastrService.error('Ocurrió un error al agregar la dirección');
          }
        }
      } else {
        this.activeModal.close();
        window.location.reload();
      }
    }
  }

  isValidAddress(){
    let errors = [];
    this.toastrService.clear();
    if(this.infoAddress.reference.trim() === ''){
      errors.unshift('Ingresa un nombre para tu dirección');
    }
    if(this.infoAddress.street.trim() === ''){
      errors.unshift('Ingresa la calle');
    }
    if(this.infoAddress.outdoor_number.trim() === ''){
      errors.unshift('Ingresa el número exterior');
    }
    if(this.infoAddress.neighborhood.trim() === ''){
      errors.unshift('Ingresa la colonia');
    }
    if(this.infoAddress.city.trim() === ''){
      errors.unshift('Ingresa el municipio');
    }
    if(this.infoAddress.state.code.trim() === ''){
      errors.unshift('Selecciona un estado');
    }
    if(this.infoAddress.postalcode.trim() === ''){
      errors.unshift('Ingresa un código postal');
    } else if(this.infoAddress.postalcode.trim().length !== 5){
      errors.unshift('El código postal debe tener 5 dígitos');
    } else {
      var regex = /^\d*$/;
      const validacion = regex.test(this.infoAddress.postalcode.trim());
      if(!validacion){
        errors.unshift('El código postal debe tener 5 números');
      }
    }
    if(this.infoAddress.street.trim() === ''){
      errors.unshift('Ingresa la calle');
    }
    if(this.infoAddress.street.trim() === ''){
      errors.unshift('Ingresa la calle');
    }
    if(errors.length === 0){
      return true;
    } else {
      for(const error of errors){
        this.toastrService.warning(error);
      }
    }
    return false;
  }

  deleteAddress(address){
    this.addressDelete = address;
    this.titleAction = '¿Estas seguro de que deseas eliminar la dirección?'
  }

  addressLine(address){
    return getAddressLine(address);
  }


  async confirmDelete(){
    const token = this.auth.getToken();
    const infoDelete = await this.api.deleteAddress(token, this.addressDelete.id, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(infoDelete.code === 200){
      this.cart.deleteAddress(this.addressDelete);
      this.toastrService.info('Se eliminó la dirección');
      this.activeModal.close();
    } else {
      this.toastrService.error(infoDelete.message, `Error ${infoDelete.code}`);
    }
  }

}
