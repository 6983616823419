// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://shops.loclogapis.com/shops/v1.0.0',
  public_key: 'lwxMWJToREgsyvqF',
  private_key: 'yvZYcUlOEjrsUfkljrM5yVTBYfLAMA41sWDiA8BK2F52Nif10WEoPjd6TPeQ097IuVXPUgtpPcOQEmKJq05FPqwhB2YrbcEI719mIm181DzHxt3YXV4Jjn9Yd7zdsSlo',
  publicKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGF2ZV9wdWJsaWNhIjoibHd4TVdKVG9SRWdzeXZxRiIsImlhdCI6MTYwNjQzNzIyMCwiYXVkIjoid2VuZHllc3RyYWRhLmNvbSJ9.amhHf2bhbohRh4G8ePskcW0F_YrPlNTjjuJAp7ShmW4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
