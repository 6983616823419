import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { MainStoreComponent } from './components/store/main-store/main-store.component';
import { OffersComponent } from './components/pages/offers/offers.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { MyaccountComponent } from './components/account/myaccount/myaccount.component';
import { IsAuthenticatedServiceService } from './guards/is-authenticated/is-authenticated-service.service';
import { SigninComponent } from './components/account/signin/signin.component';
import { SignupComponent } from './components/account/signup/signup.component';
import { RecoverPasswordComponent } from './components/account/recover-password/recover-password.component';
import { OrderSuccessComponent } from './components/store/order-success/order-success.component';
import { ProductDetailComponent } from './components/store/product-detail/product-detail.component';
import { CartComponent } from './components/store/cart/cart.component';
import { CheckoutComponent } from './components/store/checkout/checkout.component';
import { IsAuthenticatedToPayServiceService } from './guards/is-authenticated-to-pay/is-authenticated-to-pay-service.service';
import { Error402Component } from './components/pages/error402/error402.component';
import { Error403Component } from './components/pages/error403/error403.component';
import { HomeComponent } from './components/pages/home/home.component';


let routesb2c: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    component: HomeComponent
  },
  {
    path: 'tienda',
    component: MainStoreComponent
  },
  {
    path: 'ofertas',
    component: OffersComponent
  },
  {
    path: 'contacto',
    component: ContactUsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'perfil',
    component: MyaccountComponent,
    canActivate: [IsAuthenticatedServiceService],
  },
  {
    path: 'ingresar',
    component: SigninComponent
  },
  {
    path: 'registrarme',
    component: SignupComponent
  },
  {
    path: 'recuperar',
    component: RecoverPasswordComponent
  },
  {
    path: 'pedido/:order',
    component: OrderSuccessComponent,
    // canActivate: [IsAuthenticatedServiceService],
  },

  {
    path: 'p/:slug',
    component: ProductDetailComponent
  },
  {
    path: 'carrito',
    component: CartComponent
  },
  {
    path: 'pagar',
    component: CheckoutComponent,
    // canActivate: [IsAuthenticatedToPayServiceService],
  },
  {
    path: '402',
    component: Error402Component
  },
  {
    path: '403',
    component: Error403Component
  },
  {
    path: '**',
    component: Error404Component
  }
];
let routesb2b: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    component: HomeComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'tienda',
    component: MainStoreComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'ofertas',
    component: OffersComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'contacto',
    component: ContactUsComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'perfil',
    component: MyaccountComponent,
    canActivate: [IsAuthenticatedServiceService],
  },
  {
    path: 'ingresar',
    component: SigninComponent
  },
  {
    path: 'registrarme',
    component: SignupComponent
  },
  {
    path: 'recuperar',
    component: RecoverPasswordComponent
  },
  {
    path: 'pedido/:order',
    component: OrderSuccessComponent,
    canActivate: [IsAuthenticatedServiceService]
  },

  {
    path: 'p/:slug',
    component: ProductDetailComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'carrito',
    component: CartComponent,
    canActivate: [IsAuthenticatedServiceService]
  },
  {
    path: 'pagar',
    component: CheckoutComponent,
    canActivate: [IsAuthenticatedToPayServiceService],
    // canActivate: [IsAuthenticatedToPayServiceService],
  },
  {
    path: '402',
    component: Error402Component
  },
  {
    path: '403',
    component: Error403Component
  },
  {
    path: '**',
    component: Error404Component
  }
];

let routesbasic: Routes = [
  {
    path: '',
    redirectTo: 'tienda',
    pathMatch: 'full'
  },
  {
    path: 'tienda',
    component: MainStoreComponent
  },
  {
    path: 'perfil',
    component: MyaccountComponent,
    canActivate: [IsAuthenticatedServiceService],
  },
  {
    path: 'ingresar',
    component: SigninComponent
  },
  {
    path: 'registrarme',
    component: SignupComponent
  },
  {
    path: 'recuperar',
    component: RecoverPasswordComponent
  },
  {
    path: 'pedido/:order',
    component: OrderSuccessComponent,
    // canActivate: [IsAuthenticatedServiceService],
  },

  {
    path: 'p/:slug',
    component: ProductDetailComponent
  },
  {
    path: 'carrito',
    component: CartComponent
  },
  {
    path: 'pagar',
    component: CheckoutComponent,
    // canActivate: [IsAuthenticatedToPayServiceService],
  },
  {
    path: '402',
    component: Error402Component
  },
  {
    path: '403',
    component: Error403Component
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routesb2b, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
