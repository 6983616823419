<li class="dropdown cart_dropdown" *ngIf="showCartIcon">
    <a id="icon_add_to_cart" class="nav-link cart_trigger" (click)="goTo(['', 'carrito'])" data-toggle="dropdown" style="cursor: pointer;">
        <i class="linearicons-cart" id="cart" [ngStyle]="{'color': colorIcon}"></i>
        <span class="cart_count" *ngIf="products.length > 0" id="cart_count" [ngStyle]="{'background': colorIcon}">{{products.length}}</span>
    </a>
    <div class="cart_box dropdown-menu dropdown-menu-right" *ngIf="products.length > 0">
        <ul class="cart_list">
            <li *ngFor="let product of products">
                <a (click)="removeItem(product)" class="item_remove" style="cursor: pointer;"><i class="ti-close" [ngStyle]="{'color': colorIcon}"></i></a>
                <a [ngStyle]="{'color': colorIcon}" *ngIf="product.images[0] !== undefined" (click)="showDetail(product)" style="cursor: pointer;color: #333;"><img [src]="ImageSrc ? ImageSrc : product.images[0].url" width="100%" height="auto" max-width="300px">{{product.title}}</a>
                <a [ngStyle]="{'color': colorIcon}" *ngIf="product.images[0] === undefined" (click)="showDetail(product)" style="cursor: pointer;color: #333;"><img [src]="'assets/images/product_img1.jpg'" width="100%" height="auto" max-width="300px">{{product.title}}</a>
                <div class="pr_switch_wrap" *ngIf="product.attribute_types === 'variations'" style="font-size: 12px;">
                    <span class="switch_lable" *ngIf="product.variation.variant1.id !== undefined"><b>{{product.variation.variant1.name}}:</b> {{product.variation.variant1.value_name}}</span><br/>
                    <span class="switch_lable" *ngIf="product.variation.variant2.id !== undefined"><b>{{product.variation.variant2.name}}:</b> {{product.variation.variant2.value_name}}</span><br *ngIf="product.variation.variant2.id !== undefined"/>
                </div>
                <span class="cart_quantity" *ngIf="!product.has_wholesale_price && product.minimum_purchase >=0 && !product.has_discount"> {{product.quantity}} x {{product.normal_price | currency}} {{product.currency}}</span>
                <span class="cart_quantity" *ngIf="!product.has_wholesale_price && product.minimum_purchase >=0 && product.has_discount"> {{product.quantity}} x {{product.normal_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
                <span class="cart_quantity" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && !product.has_discount"> {{product.quantity}} x {{product.wholesale_price | currency}} {{product.currency}}</span>
                <span class="cart_quantity" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && product.has_discount"> {{product.quantity}} x {{product.wholesale_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
                <span class="cart_quantity" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && !product.has_discount"> {{product.quantity}} x {{product.normal_price | currency}} {{product.currency}}</span>
                <span class="cart_quantity" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && product.has_discount"> {{product.quantity}} x {{product.normal_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
            </li>
        </ul>
        <div class="cart_footer">
            <p class="cart_total" *ngIf="subtotalCurrency !== ''"><strong>Subtotal:</strong> <span class="cart_price">{{ subtotal | currency}} {{subtotalCurrency}}</span></p>
            <p class="cart_buttons"><a (click)="goTo(['', 'carrito'])" class="btn btn-fill-line rounded-0 view-cart" style="text-transform: uppercase;">Ver carrito</a><a (click)="goTo(['', 'pagar'])" class="btn btn-go-pay rounded-0 checkout" style="text-transform: uppercase;" id="go_pay" [ngStyle]="{'background': colorTheme}">Confirmar</a></p>
        </div>
    </div>
    <div class="cart_box dropdown-menu dropdown-menu-right" *ngIf="products.length === 0">
        <p class="mt-4 text-center"><strong>Tu carrito está vacío</strong></p>
    </div>
</li>