<!-- START SECTION BANNER -->
<div class="banner_section slide_medium shop_banner_slider staggered-animation-wrap">
    <div id="carouselExampleControls" class="carousel slide carousel-fade light_arrow" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item background_bg" *ngFor="let banner of banners; let position = index;" [ngClass]="{'active': position === 0}" id="banner_{{position}}" (click)="visualizarProductos(banner)" style="cursor: pointer;">
                <!-- <div class="banner_slide_img">
                    <img [src]="banner.img" width="auto" height="100%" />
                </div> -->
                <div class="banner_slide_content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="banner_content overflow-hidden">
                                    <h5 class="mb-3 font-weight-light text-white">{{banner.title | uppercase}}</h5>
                                    <h2 class="text-white" [ngStyle]="{'font-size': fontSize}">{{banner.title2 | uppercase}}</h2>
                                    <a *ngIf="banner.show_btn" class="btn rounded-0 text-uppercase text-white" (click)="visualizarProductos(banner)" id="btn_link_{{position}}" (mouseover)="isHover(true, position)" (mouseleave)="isHover(false, position)" [ngStyle]="{'background': colorTheme, 'border': '1px solid'+colorTheme, 'color': 'white'}"><b>{{banner.text_btn | uppercase}}</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" id="prev" (mouseover)="isHover2(true, 'prev')" (mouseleave)="isHover2(false, 'prev')"><i class="ion-chevron-left"></i></a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" id="next" (mouseover)="isHover2(true, 'next')" (mouseleave)="isHover2(false, 'next')"><i class="ion-chevron-right"></i></a>
    </div>
</div>
<!-- END SECTION BANNER -->