import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CartModalComponent } from 'src/app/components/widgets/cart-modal/cart-modal.component';
import { PreorderModalComponent } from 'src/app/components/widgets/preorder-modal/preorder-modal.component';
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.css']
})
export class AddCartComponent implements OnInit {

  @Input() public product;
  @Input() public src;
  @Input() public colorTheme;
  @Input() events: Observable<void>;
  private infoService = null as any;

  constructor(private service: CustomStoreService,private router: Router, private auth: ShopsAuthAngularService, private cart: CartServiceService, private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    if(this.events){
      this.events.subscribe((event) => {
        if(event !== undefined && event !== null){
          this.product = event;
          if(!this.product.stock){
            this.product.stock = 0;
          }
          this.product.counter = 1;
        }
      });
    }

  }

  listenQuantity(event, producto){
    const value = event.target.value;
    const reg = /^\d+$/;
    if(reg.test(value)){
      const quantity = Number(value);
      let stock = producto.stock;
      if(quantity > 0 && quantity <= stock){
        producto.counter = quantity;
      } else if(quantity > stock){
        producto.counter = stock;
        event.target.value = `${stock}`;
      } else {
        producto.counter = 1;
        event.target.value = '';
      }
    } else {
      producto.counter = 1;
      event.target.value = '';
    }
  }

  actionInCart(quantity, type, producto){
    if(type === 'less' && (producto.counter - quantity) >= 1){
      producto.counter = producto.counter - quantity;
    }
    if(type === 'more' && (producto.counter + quantity) <= producto.stock){
      producto.counter = producto.counter + quantity;
    }
  }

  async addToCart(product){
    let tieneVariantes = false;
    let infoVariation;
    product.quantity = product.counter || 1;
    const token = this.auth.getToken();
    const searchId = sessionStorage.getItem('c2VhcmNoX2lk');
    const status = await this.cart.addItem(product, product.quantity, token, this.infoService ? this.infoService.pickerpro_id: '', searchId ? searchId : '');
    if(status){
      sessionStorage.setItem('aXNBbmltYXRlQ2FydA==', 'true');
      this.cart.updateCartAnimation(true);
      this.product.counter = 1;
      if(environment.show_confirm_cart_modal){
        const modalRef = this.modalService.open(CartModalComponent, {size: 'lg', centered: true});
        if(!tieneVariantes){
          modalRef.componentInstance.product = product;
        } else {
          let producto = product;
          producto.variation = infoVariation;
          modalRef.componentInstance.product = producto;
        }
        this.activeModal.close('Modal Closed');
      } else {
        this.activeModal.close('Modal Closed');
      }
      const source = sessionStorage.getItem('c291cmNlQmFjaw==');
      if(this.src === 'productdetail' && source){
        sessionStorage.removeItem('c291cmNlQmFjaw==')
        if(source !== 'cart'){
          window.history.back();
        } else {
          this.router.navigate(['', 'carrito']);
        }
      }
    }
  }

  showPreorderModal(product){
    this.activeModal.close('Modal Closed');
    const modalRef = this.modalService.open(PreorderModalComponent, {size: 'lg', centered: true});
    // modalRef.componentInstance.product = product;
    modalRef.componentInstance.product = {
      ...product,
      ...{
        source: 'main'
      }
    };

  }
}
