import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestApiServiceService } from '../api/rest-api-service.service';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CustomStoreService } from '../store/custom-store.service';

@Injectable({
  providedIn: 'root'
})
export class CartServiceService {

  public Currency = { name: 'Peso mexicano', currency: 'MXN', price: 1 } // Default Currency
  private cartItem = new BehaviorSubject([]);
  currentCart = this.cartItem.asObservable();
  private cartAnimation = new BehaviorSubject(false);
  currentCartAnimation = this.cartAnimation.asObservable();
  private addresses = new BehaviorSubject([]);
  currentAddresses = this.addresses.asObservable();
  private quotation = new BehaviorSubject(null);
  currentQuotation = this.quotation.asObservable();

  constructor(private service: CustomStoreService, private api: RestApiServiceService, private auth: ShopsAuthAngularService, private toaster: ToastrService) { }

  async getCartItems(){
    const token = this.auth.getToken();
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    if(token && !isExpired){
      let info = this.service.infoService.getValue();
      if(info){
        const data = await this.api.getCartItems(token, info ? info.pickerpro_id : '') as any;
        if(data && data?.items){
          sessionStorage.setItem('Y2FydEluZm8=', data?.cartId);
          this.cartItem.next(data.items);
        }
      } else {
        setTimeout(async()=> {
          info = this.service.infoService.getValue();
          const data = await this.api.getCartItems(token, info ? info.pickerpro_id : '') as any;
          if(data && data?.items){
            sessionStorage.setItem('Y2FydEluZm8=', data?.cartId);
            this.cartItem.next(data.items);
          }
        }, 2000);
      }
    }
  }

  cleanCart(){
    this.cartItem.next([]);
    this.getCartItems();
  }

  updateCartAnimation(value){
    this.cartAnimation.next(value);
  }

  async addItem(item, qty, token, pickerpro_id, search_id) {
    try {
      const quantity = Math.min(qty, item.stock);
      const items = this.cartItem.getValue();
      const findItem = items.findIndex((itemf) => itemf.item_id === item.id);
      let weightItem = 0.01;
      if(item.weight){
        weightItem = item.weight;
      } else {
        const foundWeight = item.attributes.find((attr) => attr.id === "PACKAGE_WEIGHT");
        weightItem = foundWeight ? Number(foundWeight.value_name) : 0.01;
      }

      let qtyOrder = quantity;

      if(findItem !== -1){
        const newQty = Math.min(items[findItem].quantity+= quantity, item.stock);
        items[findItem].quantity = Math.min(newQty, item.stock);
        // items[findItem].quantity = Math.min(items[findItem].quantity + quantity, item.stock);
      } else {
        items.push({wid:item.wid,item_id:item.id,slug: item.slug, attribute_types:item.attribute_types,title:item.title,warning:item.warning,sku:item.gtin,quantity:item.quantity,stock:item.stock,weight:weightItem,price:item.retail_price,discount:item.discount,images: item.images,normal_price:item.retail_price,minimum_purchase:item.minimum_purchase?item.minimum_purchase:1,has_wholesale_price:item.has_wholesale_price,has_discount:item.has_discount,currency:item.currency});
      }
      this.cartItem.next(items);
      const cartId = sessionStorage.getItem('Y2FydEluZm8=') ? sessionStorage.getItem('Y2FydEluZm8=') : '';
      const product = {
        search_id,
        item_id: item.id,
        attribute_types: item.attribute_types,
        title: item.title,
        wid: item.wid,
        warning: item.warning,
        sku: item.gtin,
        quantity: qtyOrder,
        price: item.retail_price,
        discount: item.discount,
        stock: item.stock,
        weight: weightItem,
        images: item.images
      }
      if(product.quantity !==0){
        const infoAdd = await this.api.addCartItem(product,cartId, token, pickerpro_id);
        if(infoAdd){
          return true;
        } else {
          this.toaster.error('Ocurrió un error al agregar el producto a carrito');
          return false;
        }
      }
      return true;
      // await this.api.addCartItem()
      // return true;
    } catch (error) {
      return false;
    }
  }

  async removeItem(item, token, pickerpro_id){
    try {
      const items = this.cartItem.getValue();
      const findItem = items.findIndex((itemf) => itemf.item_id === item.item_id);
      if(findItem !== -1){
        const cartId = sessionStorage.getItem('Y2FydEluZm8=') ? sessionStorage.getItem('Y2FydEluZm8=') : '';
        const infoRemove = await this.api.removeCartItem(item.item_id, cartId, token, pickerpro_id) as any;
        if(infoRemove){
          if(infoRemove?.code !== 200){
            this.toaster.error(infoRemove.message);
          } else {
            items.splice(findItem,1);
            this.cartItem.next(items);
          }
        } else {
          throw {message: 'Ocurrió un error al eliminar el producto'};
        }
      } else {
        throw {message: 'Producto no encontrado'};
      }
    } catch (error) {
      this.toaster.clear();
      this.toaster.error(error.message, 'Ocurrió un error');
    }
  }

  updateAddress(addresses){
    this.addresses.next(addresses);
    sessionStorage.setItem('Z2V0TXlBZGRyZXNzZXM=', JSON.stringify(addresses));
  }

  pushNewAddress(addressInfo){
    let addresses = this.addresses.getValue();
    addresses.unshift(addressInfo);
    this.addresses.next(addresses);
    sessionStorage.setItem('Z2V0TXlBZGRyZXNzZXM=', JSON.stringify(addresses));
  }

  editAddress(addressInfo){
    let addresses = this.addresses.getValue();
    const findAddress = addresses.findIndex((address) => address.id === addressInfo.id);
    if(findAddress !== -1){
      addresses[findAddress] = addressInfo;
    }
    this.addresses.next(addresses);
    sessionStorage.setItem('Z2V0TXlBZGRyZXNzZXM=', JSON.stringify(addresses));
  }

  deleteAddress(addressInfo){
    let addresses = this.addresses.getValue();
    const findAddress = addresses.findIndex((address) => address.id === addressInfo.id);
    if(findAddress !== -1){
      addresses.splice(findAddress, 1);
    }
    this.addresses.next(addresses);
    sessionStorage.setItem('Z2V0TXlBZGRyZXNzZXM=', JSON.stringify(addresses));
  }

  public cartTotalAmount(): Observable<number> {
    return this.currentCart.pipe(map((product) => {
      return product.reduce((prev, curr) => {
        let price = curr.price;
        if(curr.has_discount) {
          price = curr.price - (curr.price * (curr.discount / 100));
        }
        return (prev + (price * curr.quantity)) * this.Currency.price;
      }, 0);
    }));
  }

  updateQuotation(value = null){
    this.quotation.next(value);
  }

  async generateQuote(address, customer){
    try {
      let peso = 0;
      const items = this.cartItem.getValue();
      const subtotal = items.reduce((prev, curr) => {
        let price = curr.price;
        if(curr.has_discount) {
          price = curr.price - (curr.price * (curr.discount / 100));
        }
        return (prev + (price * curr.quantity)) * this.Currency.price;
      }, 0);
      items.forEach((product) => {
        peso+= (product.quantity * product.weight);
      });
      const quoteObject = {
        service: customer.clave_publica,
        destination: {
          name: address.reference,
          phone: customer.telefono,
          street: address.address,
          number: address.outdoor_number,
          city: address.city,
          state: address.state.code,
          postal_code: address.postalcode,
          country_code: address.country
        },
        parcel: {
          weight: peso,
          weight_unit: 'kg'
        },
        amount: subtotal
      }
      const infoQuote = await this.api.generateShippingQuote(quoteObject) as any;
      if(infoQuote.code === 200){
        this.quotation.next({
          quotation_id: infoQuote.quotation_id,
          quote: infoQuote.quote
          // quote: 500
        });
      } else {
        this.toaster.warning('Ocurrió un error al cotizar su envío', 'Sin cobertura');
      }
    } catch (error) {
    }
  }


}
