import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { RestApiServiceService } from '../api/rest-api-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ConsumeWorkersService {

  private configWorker: any;
  public waitingRequests = new BehaviorSubject([]);
  currentRequests = this.waitingRequests.asObservable();

  private itemsResult = new BehaviorSubject(null);
  currentItems = this.itemsResult.asObservable();

  public terminoDeBuscar = new BehaviorSubject(false);
  currentBuscando = this.terminoDeBuscar.asObservable();

  constructor(private router: Router, private api: RestApiServiceService, private titleService: Title, private toastrService: ToastrService) {
  }

  initWorkers(){
    try {
      if(typeof Worker !== undefined){
        // CREAR WORKER
        if(!this.configWorker){
          this.configWorker = new Worker(new URL('../../workers/config-worker.worker', import.meta.url), { type: 'module'});
        }
        setTimeout(()=> {
          this.listenWorkerResponse();
        }, 1000);
      } else {
        // WEBWORKER NOT SUPORT
      }
    } catch (error) {
    }
  }

  getWorkerInfo(querySearch: any, remove = false){
    try {
      let request = this.waitingRequests.getValue();
      if(remove){
        const findRequest = request.findIndex((reque) => reque.request === querySearch.request);
        if(findRequest !== -1){
          request.splice(findRequest, 1);
        }
      } else {
        request.push(querySearch);
      }
      this.waitingRequests.next(request);
      if(!remove){
        this.configWorker.postMessage(querySearch);
      }
    } catch (error) {
    }
  }

  listenWorkerResponse(){
    try {
      this.configWorker.onmessage = ({data}) => {
        const estado = data.code;
        const result = data.result;
        const items = data.items;
        const general = data.general;
        if(estado === 200){
          let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
          let titleHeader = '';
          if(titulo !== undefined){
            titleHeader = `${titulo} | Tienda`;
          } else {
            titleHeader = `LocLog Shops | Tienda`;
          }
          this.titleService.setTitle(titleHeader);
          this.api.updateTags([
            {name: 'og:title', content: titleHeader}
          ]);
          if(result !== undefined){

            let producto = {...{
              query: 'getItemsV2'
            }, ...result};
            if(producto.category === undefined){
              producto.category = {
                uid: '',
                name: '',
                attribute_types: '',
                variations_type: '',
                path_from_root: [],
                children_categories: [],
              };
            }
            if(producto.collection === undefined){
              producto.collection = {
                uid: '',
                slug: '',
                name: ''
              };
            }

            if(producto.sort === undefined){
              producto.sort = {
                sort_by: 'updated_at',
                text: 'Mas recientes',
                order_by: 'desc'
              };
            }
            this.itemsResult.next(producto);
            localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
            this.finalizarBusqueda();
          } else if(items !== undefined){
            // this.itemsResult.next({...{
            //   query: 'searchItems'
            // }, ...{items}});

            let producto = {...{
              query: 'searchItems'
            }, ...{items}, ...{
              category: {
                uid: '',
                name: '',
                attribute_types: '',
                variations_type: '',
                path_from_root: [],
                children_categories: [],
              },
              collection: {
                uid: '',
                slug: '',
                name: ''
              },
              sort: {
                sort_by: 'updated_at',
                text: 'Mas recientes',
                order_by: 'desc'
              }
            }};
            this.itemsResult.next(producto);
            this.finalizarBusqueda();
          }
        } else {
          if(estado === 402){
            sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
            this.toastrService.warning('Servicio suspendido');
            this.router.navigate(['', '402']);
          } else if(estado === 403){
            sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
            this.toastrService.warning('Servicio cancelado');
            this.router.navigate(['', '403']);
          } else if(estado === 429){
            sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
            this.toastrService.info('Demasiadas solicitudes');
          } else {
            sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
            this.toastrService.error('Error inesperado :(');
          }
        }
      };
    } catch (error) {
    }
  }

  finalizarBusqueda(finaliza = true){
    this.terminoDeBuscar.next(finaliza);
  }





}
