import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { ProductServiceService } from '../../../services/product/product-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import {Location} from '@angular/common';
// import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
// import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { PreorderModalComponent } from '../../widgets/preorder-modal/preorder-modal.component';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
// import { AddCartComponent } from '../../shared/cart/add-cart/add-cart.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css'],
  styles: [
    `.price {
      color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `.product_sort_info li i {
      color: var(--colorThemePrimary);
    }`,
    `.tab-style3 .nav-tabs .nav-item a.active {
      border-bottom-color: var(--colorThemePrimary);
      color: var(--colorThemePrimary);
    }`,
    `.product_size_switch span.active {
      border-color: var(--colorThemePrimary);
      background-color: var(--colorThemePrimary);
      color: #fff;
    }`,
    `.dash-btn-outline-1:hover {
      background: var(--colorThemePrimary);
      border: 1px solid var(--colorThemePrimary);
      color: white;
    }`
  ]
})
export class ProductDetailComponent implements OnInit {

  @Input() public product;
  eventsSubject: Subject<void> = new Subject<void>();
  // @ViewChild(AddCartComponent) addCart: any;
  public imgSelected = 0;
  public imgPrincipal = 'assets/images/product_img1.jpg';
  public allImg = [];
  public counter = 1;

  // NUEVAS VARIABLES
  private product_id = '';
  public required_attributes = [];
  public optional_attributes = [];
  public variantsLevel1 = [];
  public variantsLevel2 = [];
  public variantSelected;
  private queryVariant1 = '';
  private queryVariant2 = '';
  public colorTheme = 'white';
  public colorTheme2 = '#333';
  private position_variant2 = 0;
  private configWorker: any;
  public showStock = environment.showStock;
  private infoService = null as any;

  constructor(private service: CustomStoreService, private auth: ShopsAuthAngularService, private styling: StyleManagerService, private router: Router, private route: ActivatedRoute, private notify: ToastrService, private toastrService: ToastrService, private api: RestApiServiceService, private _location: Location) {
    this.initWorkers();
    this.configWorker.onmessage = ({data}) => {
      let estado = data.code;
      let color = data.color;
      if(estado === 200){
        if(color !== undefined){
          setTimeout(() => {
            this.styling.setColor(`variant2_${this.position_variant2}`, 'backgroundColor', color);
            this.styling.setColor(`variant2_${this.position_variant2}`, 'borderColor', color);
            this.styling.setColor(`variant2_${this.position_variant2}`, 'color', 'white');
          }, 1);
        }
      } else {
        if(estado === 402){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === 403){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === 429){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.error('Error inesperado :(');
        }
      }
    };
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id, 'color', '#ffffff');
      this.styling.setColor(id, 'background', this.colorTheme);
      this.styling.setColor(id, 'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id, 'backgroundColor', 'transparent');
      this.styling.setColor(id, 'color', '#6c757d');
      this.styling.setColor(id, 'borderColor', '#6c757d');
    }
  }

  ngOnInit() {
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    // if(localStorage.getItem('c2hvd1N0b2Nr') !== null){
    //   try {
    //     const info = JSON.parse(atob(localStorage.getItem('c2hvd1N0b2Nr')));
    //     this.showStock = info.show;
    //   } catch (error) {

    //   }
    // }
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
    if(this.product === undefined){
      this.product = {}
    }
    window.scroll(0,0);
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(queryParams => {
        if(queryParams.uid !== undefined){
          if(queryParams.uid !== ''){
            this.product_id = queryParams.uid;
          } else {
            this.notify.warning('El producto que intentas buscar no está disponible', 'Producto no disponible');
            this.goBack();
          }
        } else {
          this.notify.warning('El producto que intentas buscar no está disponible', 'Producto no disponible');
          this.goBack();
        }
        if(queryParams.variant1 !== undefined){
          if(queryParams.variant1 !== ''){
            this.queryVariant1 = queryParams.variant1;
          }
        }
        if(queryParams.variant2 !== undefined){
          if(queryParams.variant2 !== ''){
            this.queryVariant2 = queryParams.variant2;
          }
        }
        if(localStorage.getItem('ZGV0YWlsT2ZJdGVt') !== null){
          const productoLocal = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('ZGV0YWlsT2ZJdGVt')))));;
          if(productoLocal.id === queryParams.uid){
            this.product = productoLocal;
            this.required_attributes = this.product.attributes.filter((attribute) => {
              if(attribute.tags !== undefined){
                return attribute.tags.catalog_required !== undefined || attribute.tags.required !== undefined
              }
            });
            this.optional_attributes = this.product.attributes.filter((attribute) => {
              if(attribute.tags !== undefined){
                return attribute.tags.catalog_required === undefined && attribute.tags.required === undefined
              }
            });
            const variations_length = this.product.variations_type.split('&').length;
            if(this.product.attribute_types === "variations" && variations_length === 2){
              this.variantsLevel2 = [...this.product.variations];
              this.product.variations.forEach((variation) => {
                const filterVariation = this.variantsLevel1.filter((variant) => {
                  return variant.id.trim().toUpperCase() === variation.combinations[0].id.trim().toUpperCase() && variant.value_name.trim().toUpperCase() === variation.combinations[0].value_name.trim().toUpperCase();
                });

                if(filterVariation.length === 0){
                  this.variantsLevel1.push(variation.combinations[0]);
                }
              });
              const filterVariante2 = this.variantsLevel2.filter((variant2, index) => {
                variant2.index = index;
                return variant2.combinations[0].id.trim().toUpperCase() === this.variantsLevel1[0].id.trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === this.variantsLevel1[0].value_name.trim().toUpperCase();
              });
              if(filterVariante2.length > 0){
                this.variantSelected = {
                  id: filterVariante2[0].id,
                  variant1: [...this.variantsLevel1],
                  images: [...filterVariante2[0].images],
                  variant2: filterVariante2,
                  variant1_selected: this.variantsLevel1[0],
                  variant2_selected: filterVariante2[0].combinations[1],
                  stock: filterVariante2[0].stock,
                  selected: 1
                }
              }
            } else if(this.product.attribute_types === "variations" && variations_length === 1){
              this.variantsLevel1 = [...this.product.variations];
              this.variantSelected = {
                id: this.variantsLevel1[0].id,
                variant1: [...this.variantsLevel1],
                images: [...this.variantsLevel1[0].images],
                variant2: [],
                variant1_selected: this.variantsLevel1[0],
                variant2_selected: '',
                stock: this.variantsLevel1[0].stock,
                selected: 1
              };
            } else if(this.product.attribute_types === "attributes"){
              this.variantSelected = {
                variant1: [],
                images: [...this.product.images],
                variant2: [],
                variant1_selected: '',
                variant2_selected: '',
                stock: this.product.stock,
                selected: 1
              };
            }
            this.parsingImages();
          } else {
            // HACER PETICIÓN
            this.getInfoOfItem();
          }
        } else {
          // HACER PETICIÓN
          this.getInfoOfItem();
        }
      });
    });
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTheme2 = colorTheme;
    //   this.configWorker.postMessage({request: 'updateColor', body: {code: 200, color: colorTheme}});
    // }

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorTheme2 = response.color;
          this.configWorker.postMessage({request: 'updateColor', body: {code: 200, color: response.color}});
        }
        this.showStock = response.show_stock;
      }
    });
  }

  getInfoOfItem(){
    let item;
    let estado2;
    if(this.infoService){
      this.api.getItemV2(this.product_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        item = JSON.parse(JSON.stringify(respuesta)).item;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400');
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 404) {
          this.toastrService.warning('El producto que estas buscando no está disponible');
          setTimeout(()=> {
            this.router.navigate(['', 'tienda']);
          }, 2000);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500');
        }
      }, () => {
        if(estado2 === 200){
          this.product = item;

          if(item.gtin){
            this.product.attributes.push({
              id: "GTIN",
              name: "Código de barras",
              tags: {
                catalog_required: true,
                required: true
              },
              value_name: item.gtin
            });
          }

          if(item.substance && item.substance !== ''){
            this.product.attributes.push({
              id: "SUBSTANCE",
              name: "Sustancia activa",
              tags: {},
              value_name: item.substance
            });
          }
          // this.validateAttributes();
          this.getAttributes([this.product.id]);
          if(this.product.warning !== ''){
            // external
            const wid = this.product.wid;
            this.getExternalWMS([this.product.id], wid);
          } else {
            this.getPrices([this.product.id]);
            this.getStocks([this.product.id]);
          }

          this.eventsSubject.next(this.product);



          const variations_length = this.product.variations_type.split('&').length;
          if(this.product.attribute_types === "variations" && variations_length === 2){
            this.variantsLevel2 = [...this.product.variations];
            this.product.variations.forEach((variation) => {
              const filterVariation = this.variantsLevel1.filter((variant) => {
                return variant.id.trim().toUpperCase() === variation.combinations[0].id.trim().toUpperCase() && variant.value_name.trim().toUpperCase() === variation.combinations[0].value_name.trim().toUpperCase();
              });

              if(filterVariation.length === 0){
                this.variantsLevel1.push(variation.combinations[0]);
              }
            });
            const filterVariante2 = this.variantsLevel2.filter((variant2, index) => {
              variant2.index = index;
              return variant2.combinations[0].id.trim().toUpperCase() === this.variantsLevel1[0].id.trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === this.variantsLevel1[0].value_name.trim().toUpperCase();
            });
            if(filterVariante2.length > 0){
              this.variantSelected = {
                id: filterVariante2[0].id,
                variant1: [...this.variantsLevel1],
                images: [...filterVariante2[0].images],
                variant2: filterVariante2,
                variant1_selected: this.variantsLevel1[0],
                variant2_selected: filterVariante2[0].combinations[1],
                stock: filterVariante2[0].stock,
                selected: 1
              }
            }
          } else if(this.product.attribute_types === "variations" && variations_length === 1){
            this.variantsLevel1 = [...this.product.variations];
            this.variantSelected = {
              id: this.variantsLevel1[0].id,
              variant1: [...this.variantsLevel1],
              images: [...this.variantsLevel1[0].images],
              variant2: [],
              variant1_selected: this.variantsLevel1[0],
              variant2_selected: '',
              stock: this.variantsLevel1[0].stock,
              selected: 1
            };
          } else if(this.product.attribute_types === "attributes"){
            this.variantSelected = {
              variant1: [],
              images: [...this.product.images],
              variant2: [],
              variant1_selected: '',
              variant2_selected: '',
              stock: this.product.stock,
              selected: 1
            };
          }
          this.parsingImages();
          // localStorage.setItem('ZGV0YWlsT2ZJdGVt', btoa(unescape(encodeURIComponent(JSON.stringify(item)))));
          let titulo = 'LocLog Shops';
          if(localStorage.getItem('SU5GTy1USVRMRQ==') !== null){
            try {
              titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
            } catch(err){

            }
          }
          this.api.setTitle(`${titulo} | ${item.title}`);
          this.api.updateTags([
            {name: 'description', content: item.description},
            {name: 'twitter:image', content: this.imgPrincipal},
            {name: 'twitter:card', content: 'summary'},
            {name: 'twitter:description', content: item.description},
            {name: 'og:locale', content: 'es_MX'},
            {name: 'og:type', content: 'article'},
            {name: 'og:image', content: this.imgPrincipal},
            {name: 'og:title', content: `${titulo} | ${item.title}`},
            {name: 'og:description', content: item.description},
            {name: 'og:site_name', content: 'LocLog Shops'}
          ]);
        }
      });
    } else {
      setTimeout(()=> {
        this.api.getItemV2(this.product_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
          item = JSON.parse(JSON.stringify(respuesta)).item;
          estado2 = JSON.parse(JSON.stringify(respuesta)).code;
        },  (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
          } else {
          }
          if (err.status === 400) {
            this.toastrService.error('Error 400');
          }
          if (err.status === 401) {
            this.toastrService.warning('No estás autorizado para acceder');
            this.router.navigate(['', 'ingresar']);
          }
          if (err.status === 404) {
            this.toastrService.warning('El producto que estas buscando no está disponible');
            setTimeout(()=> {
              this.router.navigate(['', 'tienda']);
            }, 2000);
          }
          if (err.status === 429) {
            this.toastrService.warning(err.error.message);
          }
          if (err.status === 500) {
            this.toastrService.error('Error 500');
          }
        }, () => {
          if(estado2 === 200){
            this.product = item;

            if(item.gtin){
              this.product.attributes.push({
                id: "GTIN",
                name: "Código de barras",
                tags: {
                  catalog_required: true,
                  required: true
                },
                value_name: item.gtin
              });
            }

            if(item.substance && item.substance !== ''){
              this.product.attributes.push({
                id: "SUBSTANCE",
                name: "Sustancia activa",
                tags: {},
                value_name: item.substance
              });
            }
            // this.validateAttributes();
            this.getAttributes([this.product.id]);
            if(this.product.warning !== ''){
              // external
              const wid = this.product.wid;
              this.getExternalWMS([this.product.id], wid);
            } else {
              this.getPrices([this.product.id]);
              this.getStocks([this.product.id]);
            }

            this.eventsSubject.next(this.product);



            const variations_length = this.product.variations_type.split('&').length;
            if(this.product.attribute_types === "variations" && variations_length === 2){
              this.variantsLevel2 = [...this.product.variations];
              this.product.variations.forEach((variation) => {
                const filterVariation = this.variantsLevel1.filter((variant) => {
                  return variant.id.trim().toUpperCase() === variation.combinations[0].id.trim().toUpperCase() && variant.value_name.trim().toUpperCase() === variation.combinations[0].value_name.trim().toUpperCase();
                });

                if(filterVariation.length === 0){
                  this.variantsLevel1.push(variation.combinations[0]);
                }
              });
              const filterVariante2 = this.variantsLevel2.filter((variant2, index) => {
                variant2.index = index;
                return variant2.combinations[0].id.trim().toUpperCase() === this.variantsLevel1[0].id.trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === this.variantsLevel1[0].value_name.trim().toUpperCase();
              });
              if(filterVariante2.length > 0){
                this.variantSelected = {
                  id: filterVariante2[0].id,
                  variant1: [...this.variantsLevel1],
                  images: [...filterVariante2[0].images],
                  variant2: filterVariante2,
                  variant1_selected: this.variantsLevel1[0],
                  variant2_selected: filterVariante2[0].combinations[1],
                  stock: filterVariante2[0].stock,
                  selected: 1
                }
              }
            } else if(this.product.attribute_types === "variations" && variations_length === 1){
              this.variantsLevel1 = [...this.product.variations];
              this.variantSelected = {
                id: this.variantsLevel1[0].id,
                variant1: [...this.variantsLevel1],
                images: [...this.variantsLevel1[0].images],
                variant2: [],
                variant1_selected: this.variantsLevel1[0],
                variant2_selected: '',
                stock: this.variantsLevel1[0].stock,
                selected: 1
              };
            } else if(this.product.attribute_types === "attributes"){
              this.variantSelected = {
                variant1: [],
                images: [...this.product.images],
                variant2: [],
                variant1_selected: '',
                variant2_selected: '',
                stock: this.product.stock,
                selected: 1
              };
            }
            this.parsingImages();
            // localStorage.setItem('ZGV0YWlsT2ZJdGVt', btoa(unescape(encodeURIComponent(JSON.stringify(item)))));
            let titulo = 'LocLog Shops';
            if(localStorage.getItem('SU5GTy1USVRMRQ==') !== null){
              try {
                titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
              } catch(err){

              }
            }
            this.api.setTitle(`${titulo} | ${item.title}`);
            this.api.updateTags([
              {name: 'description', content: item.description},
              {name: 'twitter:image', content: this.imgPrincipal},
              {name: 'twitter:card', content: 'summary'},
              {name: 'twitter:description', content: item.description},
              {name: 'og:locale', content: 'es_MX'},
              {name: 'og:type', content: 'article'},
              {name: 'og:image', content: this.imgPrincipal},
              {name: 'og:title', content: `${titulo} | ${item.title}`},
              {name: 'og:description', content: item.description},
              {name: 'og:site_name', content: 'LocLog Shops'}
            ]);
          }
        });
      }, 550);
    }
  }

  validateAttributes(){
    this.required_attributes = this.product.attributes.filter((attribute) => {
      if(attribute.tags !== undefined){
        return attribute.tags.catalog_required !== undefined || attribute.tags.required !== undefined
      }
    });
    this.optional_attributes = this.product.attributes.filter((attribute) => {
      if(attribute.tags !== undefined){
        return attribute.tags.catalog_required === undefined && attribute.tags.required === undefined
      }
    });
  }

  // showPreorderModal(product){
  //   const modalRef = this.modalService.open(PreorderModalComponent, {size: 'lg', centered: true});
  //   modalRef.componentInstance.product = {
  //     ...product,
  //     ...{
  //       source: 'detail'
  //     }
  //   };
  // }

  getAttributes(items_id){
    let estado2;
      let attributes;
      this.api.getAttributesItem(items_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        attributes = JSON.parse(JSON.stringify(respuesta)).attributes;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            attributes.forEach((attribute) => {
              if(attribute.item_id === this.product.id){
                if(attribute.attributes.length > 0){
                  attribute.attributes.filter((attr) => attr.visible).forEach((attr) => {
                    this.product.attributes.push(attr);
                  });
                }
              }
            });
            this.validateAttributes();
          } catch (error) {

          }
        }
      });
  }

  getPrices(items_id){
    let estado2;
      let prices;
      this.api.getPricesItems(items_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        prices = JSON.parse(JSON.stringify(respuesta)).prices;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            prices.forEach((price) => {
              if(price.item_id === this.product.id){
                this.product.discount = price.discount;
                this.product.retail_price = price.retail_price;
                this.product.currency = 'MXN';
                if(price.discount > 0){
                  this.product.has_discount = true;
                } else {
                  this.product.has_discount = false;
                  this.product.minimum_purchase = 0;
                  this.product.wholesale_price = price.retail_price;
                }
                if(price.has_wholesale_price){
                  this.product.has_wholesale_price = true;
                } else {
                  this.product.has_wholesale_price = false;
                }
              }
            });
          } catch (error) {

          }
        }
      });
  }

  getStocks(items_id){
    let estado2;
      let stocks;
      this.api.getStockItems(items_id, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        stocks = JSON.parse(JSON.stringify(respuesta)).stocks;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            stocks.forEach((stock) => {
              if(stock.item_id === this.product.id){
                this.product.stock+= stock.qty;
              }
            });
          } catch (error) {

          }
        }
      });
  }

  getExternalWMS(items_id, wmsId){
    let estado2;
      let stocks;
      this.api.getExternalWMS(items_id, wmsId, this.auth.getToken(), false, this.infoService ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        stocks = JSON.parse(JSON.stringify(respuesta)).stocks;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          stocks.forEach((price) => {
            if(price.item_id === this.product.id){
              this.product.discount = price.discount;
              this.product.retail_price = price.retail_price;
              this.product.currency = 'MXN';
              this.product.stock = price.qty;
              if(price.discount > 0){
                this.product.has_discount = true;
              } else {
                this.product.has_discount = false;
                this.product.minimum_purchase = 0;
                this.product.wholesale_price = price.retail_price;
              }
              if(price.has_wholesale_price){
                this.product.has_wholesale_price = true;
              } else {
                this.product.has_wholesale_price = false;
              }
            }
          });
          // try {
          //   stocks.forEach((stock) => {
          //     const indexItem = this.products.findIndex((item) => item.id === stock.item_id);
          //     if(indexItem !== -1){
          //       this.product.stock = stock.qty;
          //     }
          //   });
          // } catch (error) {

          // }
        }
      });
  }

  parsingImages(){
    const variations_length = this.product.variations_type.split('&').length;
    if(this.product.attribute_types === "variations"){
      this.allImg = this.product.variations[0].images;
      let variant1Split;
      let variant2Split;
      if(this.queryVariant1 !== null){
        variant1Split = this.queryVariant1.split('_')
      } else {
        variant1Split = ['', '']
      }
      if(this.queryVariant2 !== null){
        variant2Split = this.queryVariant2.split('_')
      } else {
        variant2Split = ['', '']
      }
      if(variations_length === 1){
        if(this.queryVariant1 !== ''){
          const valueFiltered = this.variantsLevel1.filter((variant, index) => {
            variant.index = index;
            return variant.combinations[0].id === variant1Split[0] && variant.combinations[0].value_name.trim().toUpperCase() === variant1Split[1].trim().toUpperCase();
          });
          if(valueFiltered.length > 0){
            this.variantSelected.images = valueFiltered[0].images;
            this.variantSelected.stock = valueFiltered[0].stock;
            this.variantSelected.id = valueFiltered[0].id;
            this.allImg = valueFiltered[0].images;
            this.variantSelected.variant1_selected = this.product.variations[valueFiltered[0].index];
            if(this.product.variations[valueFiltered[0].index].images[0] !== undefined){
              this.imgPrincipal = '' + this.product.variations[valueFiltered[0].index].images[0].url;
            } else {
              this.imgPrincipal = 'assets/images/product_img1.jpg';
            }
            this.position_variant2 = 0;
            this.checkVariantLevel2();
          } else {
            const valueFiltered2 = this.variantsLevel1.filter((variant, index) => {
              variant.index = index;
              if(index === 0){
                return variant
              }
            });
            if(valueFiltered2.length > 0){
              this.variantSelected.images = valueFiltered2[0].images;
              this.variantSelected.stock = valueFiltered2[0].stock;
              this.variantSelected.id = valueFiltered2[0].id;
              this.allImg = valueFiltered2[0].images;
              this.variantSelected.variant1_selected = this.product.variations[valueFiltered2[0].index];
              if(this.product.variations[valueFiltered2[0].index].images[0] !== undefined){
                this.imgPrincipal = '' + this.product.variations[valueFiltered2[0].index].images[0].url;
              } else {
                this.imgPrincipal = 'assets/images/product_img1.jpg';
              }
              this.position_variant2 = 0;
              this.queryVariant1 = `${this.variantSelected.variant1_selected.combinations[0].id}_${this.variantSelected.variant1_selected.combinations[0].value_name}`;
              this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant1: this.queryVariant1} });
              this.showError404(this.variantSelected.variant1_selected.combinations[0].id, this.variantSelected.variant1_selected.combinations[0].name, '');
            }
          }
        } else {
          if(this.product.variations[0].images[0] !== undefined){
            this.imgPrincipal = '' + this.product.variations[0].images[0].url;
          } else {
            this.imgPrincipal = 'assets/images/product_img1.jpg';
          }
          this.position_variant2 = 0;
        }
      } else if(variations_length === 2){
        if(this.queryVariant1 !== '' && this.queryVariant2 !== ''){
          const valueFiltered = this.product.variations.filter((variant, index) => {
            variant.index = index;
            return variant.combinations[0].id === variant1Split[0] && variant.combinations[0].value_name.trim().toUpperCase() === variant1Split[1].trim().toUpperCase() && variant.combinations[1].id === variant2Split[0] && variant.combinations[1].value_name.trim().toUpperCase() === variant2Split[1].trim().toUpperCase();
          });
          const filterVariante2 = this.variantsLevel2.filter((variant2) => {
            return variant2.combinations[0].id.trim().toUpperCase() === variant1Split[0].trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === variant1Split[1].trim().toUpperCase();
          });
          if(valueFiltered.length > 0){
            this.variantSelected.variant1_selected = valueFiltered[0].combinations[0];
            this.variantSelected.images = valueFiltered[0].images;
            this.variantSelected.stock = valueFiltered[0].stock;
            this.variantSelected.id = valueFiltered[0].id;
            this.allImg = valueFiltered[0].images;
            if(this.product.variations[valueFiltered[0].index].images[0] !== undefined){
              this.imgPrincipal = '' + this.product.variations[valueFiltered[0].index].images[0].url;
            } else {
              this.imgPrincipal = 'assets/images/product_img1.jpg';
            }
            if(filterVariante2.length > 0){
              this.variantSelected.variant2 = [];
              this.variantSelected.variant2 = [...filterVariante2];
              const filterVariante2Selected = filterVariante2.filter((variant2, index) => {
                variant2.index = index;
                return variant2.combinations[1].id === variant2Split[0] && variant2.combinations[1].value_name.trim().toUpperCase() === variant2Split[1];
              })
              if(filterVariante2Selected.length > 0){
                this.variantSelected.variant2_selected = filterVariante2[filterVariante2Selected[0].index].combinations[1];
                this.position_variant2 = filterVariante2Selected[0].index;
              } else {
                this.variantSelected.variant2_selected = filterVariante2[0].combinations[1];
                this.position_variant2 = 0;
              }
              this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant1: this.queryVariant1, variant2: this.queryVariant2} });
              this.checkVariantLevel2();
            }
          } else {
            const valueFiltered2 = this.product.variations.filter((variant, index) => {
              variant.index = index;
              if(index === 0){
                return variant
              }
            });
            if(valueFiltered2.length > 0){
              this.variantSelected.variant1_selected = valueFiltered2[0].combinations[0];
              this.variantSelected.variant2_selected = valueFiltered2[0].combinations[1];
              this.variantSelected.images = valueFiltered2[0].images;
              this.variantSelected.stock = valueFiltered2[0].stock;
              this.variantSelected.id = valueFiltered2[0].id;
              this.allImg = valueFiltered2[0].images;
              if(this.product.variations[valueFiltered2[0].index].images[0] !== undefined){
                this.imgPrincipal = '' + this.product.variations[valueFiltered2[0].index].images[0].url;
              } else {
                this.imgPrincipal = 'assets/images/product_img1.jpg';
              }
              if(this.queryVariant1 !== null){
                this.showError404(this.variantSelected.variant1_selected.id, this.variantSelected.variant1_selected.name, this.variantSelected.variant2_selected.name);
              }
              this.position_variant2 = 0;
              this.queryVariant2 = `${this.variantSelected.variant2_selected.id}_${this.variantSelected.variant2_selected.value_name}`;
              this.queryVariant1 = null;
              this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant2: this.queryVariant2} });
              this.checkVariantLevel2();
            }
          }
        } else if(this.queryVariant1 !== ''){
          const valueFiltered = this.product.variations.filter((variant, index) => {
            variant.index = index;
            return variant.combinations[0].id === variant1Split[0] && variant.combinations[0].value_name.trim().toUpperCase() === variant1Split[1].trim().toUpperCase();
          });
          const filterVariante2 = this.variantsLevel2.filter((variant2) => {
            return variant2.combinations[0].id.trim().toUpperCase() === variant1Split[0].trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === variant1Split[1].trim().toUpperCase();
          });
          if(valueFiltered.length > 0){
            this.variantSelected.variant1_selected = valueFiltered[0].combinations[0];
            this.variantSelected.images = valueFiltered[0].images;
            this.variantSelected.stock = valueFiltered[0].stock;
            this.variantSelected.id = valueFiltered[0].id;
            this.allImg = valueFiltered[0].images;
            if(this.product.variations[valueFiltered[0].index].images[0] !== undefined){
              this.imgPrincipal = '' + this.product.variations[valueFiltered[0].index].images[0].url;
            } else {
              this.imgPrincipal = 'assets/images/product_img1.jpg';
            }
            if(filterVariante2.length > 0){
              this.variantSelected.variant2_selected = filterVariante2[0].combinations[1];
              this.variantSelected.variant2 = [];
              this.variantSelected.variant2 = [...filterVariante2]
              this.position_variant2 = 0;
              this.checkVariantLevel2();
            }
          } else {
            const valueFiltered2 = this.product.variations.filter((variant, index) => {
              variant.index = index;
              if(index === 0){
                return variant
              }
            });
            if(valueFiltered2.length > 0){
              this.variantSelected.variant1_selected = valueFiltered2[0].combinations[0];
              this.variantSelected.variant2_selected = valueFiltered2[0].combinations[1];
              this.variantSelected.images = valueFiltered2[0].images;
              this.variantSelected.stock = valueFiltered2[0].stock;
              this.variantSelected.id = valueFiltered2[0].id;
              this.allImg = valueFiltered2[0].images;
              if(this.product.variations[valueFiltered2[0].index].images[0] !== undefined){
                this.imgPrincipal = '' + this.product.variations[valueFiltered2[0].index].images[0].url;
              } else {
                this.imgPrincipal = 'assets/images/product_img1.jpg';
              }
              this.showError404(this.variantSelected.variant1_selected.id, this.variantSelected.variant1_selected.name, '');
              this.position_variant2 = 0;
              this.queryVariant2 = `${this.variantSelected.variant2_selected.id}_${this.variantSelected.variant2_selected.value_name}`;
              this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant2: this.queryVariant2} });
              this.checkVariantLevel2();
            }
          }
        } else if(this.queryVariant2 !== ''){
          const valueFiltered = this.product.variations.filter((variant, index) => {
            variant.index = index;
            return variant.combinations[0].id === this.variantSelected.variant1_selected.id && variant.combinations[0].value_name.trim().toUpperCase() === this.variantSelected.variant1_selected.value_name.trim().toUpperCase() && variant.combinations[1].id === variant2Split[0] && variant.combinations[1].value_name == variant2Split[1];
          });
          if(valueFiltered.length > 0){
            this.variantSelected.variant2_selected = valueFiltered[0].combinations[1];
            this.variantSelected.images = valueFiltered[0].images;
            this.variantSelected.stock = valueFiltered[0].stock;
            this.variantSelected.id = valueFiltered[0].id;
            this.allImg = valueFiltered[0].images;
            if(this.product.variations[valueFiltered[0].index].images[0] !== undefined){
              this.imgPrincipal = '' + this.product.variations[valueFiltered[0].index].images[0].url;
            } else {
              this.imgPrincipal = 'assets/images/product_img1.jpg';
            }
            this.position_variant2 = valueFiltered[0].index;
            this.queryVariant2 = `${this.variantSelected.variant2_selected.id}_${this.variantSelected.variant2_selected.value_name}`;
            this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant2: this.queryVariant2} });
            this.checkVariantLevel2();
          } else {
            const valueFiltered2 = this.product.variations.filter((variant, index) => {
              variant.index = index;
              return variant.combinations[0].id === this.variantSelected.variant1_selected.id && variant.combinations[0].value_name.trim().toUpperCase() === this.variantSelected.variant1_selected.value_name.trim().toUpperCase();
            });
            if(valueFiltered2.length > 0){
              this.variantSelected.variant2_selected = valueFiltered2[0].combinations[1];
              this.variantSelected.images = valueFiltered2[0].images;
              this.variantSelected.stock = valueFiltered2[0].stock;
              this.variantSelected.id = valueFiltered2[0].id;
              this.allImg = valueFiltered2[0].images;
              if(this.product.variations[valueFiltered2[0].index].images[0] !== undefined){
                this.imgPrincipal = '' + this.product.variations[valueFiltered2[0].index].images[0].url;
              } else {
                this.imgPrincipal = 'assets/images/product_img1.jpg';
              }
              this.showError404(this.variantSelected.variant2_selected.id, this.variantSelected.variant2_selected.name, '');
              this.position_variant2 = 0;
              this.queryVariant2 = `${this.variantSelected.variant2_selected.id}_${this.variantSelected.variant2_selected.value_name}`;
              this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant2: this.queryVariant2} });
              this.checkVariantLevel2();
            }
          }
        } else {
          if(this.product.variations[0].images[0] !== undefined){
            this.imgPrincipal = '' + this.product.variations[0].images[0].url;
          } else {
            this.imgPrincipal = 'assets/images/product_img1.jpg';
          }
          this.position_variant2 = 0;
          this.checkVariantLevel2();
        }
      }
    } else {
      this.allImg = this.product.images;
      if(this.product.images[0] !== undefined){
        this.imgPrincipal = '' + this.product.images[0].url;
      } else {
        this.imgPrincipal = 'assets/images/product_img1.jpg';
      }
    }
  }

  updateUrl() {
    this.imgPrincipal = 'assets/images/product_img1.jpg';
  }

  updateUrl2(position) {
    this.allImg[position].url = 'assets/images/product_img1.jpg';
  }


  goBack() {
    this._location.back();
  }

  setImg(url, position){
    this.imgPrincipal = url;
    this.imgSelected = position;
  }

  // actionInCart(quantity, type, variation){
  //   if(variation === undefined){
  //     if(type === 'less' && (this.counter - quantity) >= 1){
  //       this.counter = this.counter - quantity;
  //     }
  //     if(type === 'more' && (this.counter + quantity) <= this.product.stock){
  //       this.counter = this.counter + quantity;
  //     }
  //   }
  //   if(variation !== undefined){
  //     if(this.product.attribute_types === 'variations'){
  //       if(type === 'less' && (this.counter - quantity) >= 1){
  //         this.counter = this.counter - quantity;
  //         this.variantSelected.selected = this.counter;
  //       }
  //       if(type === 'more' && (this.counter + quantity) <= this.variantSelected.stock){
  //         this.counter = this.counter + quantity;
  //         this.variantSelected.selected = this.counter;
  //       }
  //     }
  //   }
  // }

  // listenQuantity(event, variation){
  //   const value = event.target.value;
  //   const reg = /^\d+$/;
  //   if(reg.test(value)){
  //     const quantity = Number(value);
  //     let stock = this.product.stock;
  //     if(variation !== undefined){
  //       stock = variation.stock;
  //     }

  //     if(quantity > 0 && quantity <= stock){
  //       this.counter = quantity;
  //     } else if(quantity > stock){
  //       this.counter = stock;
  //       event.target.value = `${stock}`;
  //     } else {
  //       this.counter = 1;
  //       event.target.value = '';
  //     }
  //     if(variation !== undefined){
  //       this.variantSelected.selected = this.counter;
  //     }
  //   } else {
  //     this.counter = 1;
  //     event.target.value = '';
  //   }
  // }

  selectVariationLevel1(variation){
    const variation_length = this.product.variations_type.split('&').length;
    if(this.product.attribute_types === "variations" && variation_length === 2){
      const filterVariante2 = this.variantsLevel2.filter((variant2) => {
        return variant2.combinations[0].id.trim().toUpperCase() === variation.id.trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === variation.value_name.trim().toUpperCase();
      });
      if(filterVariante2.length > 0){
        this.queryVariant1 = `${variation.id}_${variation.value_name}`;
        this.queryVariant2 = `${filterVariante2[0].combinations[1].id}_${filterVariante2[0].combinations[1].value_name}`;
        this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant1: this.queryVariant1, variant2: this.queryVariant2} });
        this.variantSelected.images = [];
        this.variantSelected.variant2 = [];
        this.variantSelected.images = [...filterVariante2[0].images];
        this.variantSelected.variant2 = [...filterVariante2];
        this.variantSelected.variant1_selected = variation;
        this.variantSelected.variant2_selected = filterVariante2[0].combinations[1];
        this.variantSelected.id = filterVariante2[0].id;
        this.variantSelected.stock = filterVariante2[0].stock;
        this.variantSelected.selected = 1;
        this.imgPrincipal = filterVariante2[0].images[0].url;
        this.allImg = [];
        this.allImg = filterVariante2[0].images;
        setTimeout(() => {
          this.styling.setColor(`variant2_0`, 'backgroundColor', this.colorTheme);
          this.styling.setColor(`variant2_0`, 'borderColor', this.colorTheme);
          this.styling.setColor(`variant2_0`, 'color', 'white');
        }, 1);
      }
    } else if(this.product.attribute_types === "variations" && variation_length === 1){
        this.queryVariant1 = `${variation.combinations[0].id}_${variation.combinations[0].value_name}`;
        this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant1: this.queryVariant1} });
        this.variantSelected.images = [];
        this.variantSelected.variant2 = [];
        this.variantSelected.images = [...variation.images];
        this.variantSelected.variant1_selected = variation;
        this.variantSelected.variant2_selected = '';
        this.variantSelected.id = variation.id;
        this.variantSelected.stock = variation.stock;
        this.variantSelected.selected = 1;
        this.imgPrincipal = variation.images[0].url;
        this.allImg = [];
        this.allImg = variation.images;
    }
    this.counter = 1;
  }

  selectVariationLevel2(variation, position){
    this.variantSelected.variant2_selected = variation.combinations[1];
    this.variantSelected.id = variation.id;
    this.variantSelected.stock = variation.stock;
    this.variantSelected.selected = 1;
    this.counter = 1;
    this.queryVariant2 = `${this.variantSelected.variant2_selected.id}_${this.variantSelected.variant2_selected.value_name}`;
    this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product_id , variant1: this.queryVariant1, variant2: this.queryVariant2} });
  }

  checkVariantLevel2(){
    setTimeout(() => {
      this.variantSelected.variant2.forEach((variant, index) => {
        if(index !== this.position_variant2){
          this.styling.setColor(`variant2_${index}`, 'backgroundColor', 'transparent');
          this.styling.setColor(`variant2_${index}`, 'borderColor', '#ddd');
          this.styling.setColor(`variant2_${index}`, 'color', '#687188');
        } else {
          this.styling.setColor(`variant2_${index}`, 'backgroundColor', this.colorTheme);
          this.styling.setColor(`variant2_${index}`, 'borderColor', this.colorTheme);
          this.styling.setColor(`variant2_${index}`, 'color', 'white');
        }
      });
    }, 1);
  }

  showError404(id, name, name2){
    if(id === 'COLOR' || id === 'FINISH' || id === 'EFFECT'){
      if(name2 === ''){
        this.toastrService.warning(`No se encontró el ${name.toLowerCase()} que estás buscando`);
      } else {
        this.toastrService.warning(`No se encontró el ${name.toLowerCase()} o ${name2.toLowerCase()} que estás buscando`);
      }
    } else {
      if(name2 === ''){
        this.toastrService.warning(`No se encontró la ${name.toLowerCase()} que estás buscando`);
      } else {
        this.toastrService.warning(`No se encontró la ${name.toLowerCase()} o ${name2.toLowerCase()} que estás buscando`);
      }
    }
  }

}
