import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { HttpErrorResponse } from '@angular/common/http';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { EncryptManagerService } from '@ignacioruben7/shops-encrypt-angular';
import { Title } from '@angular/platform-browser';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  styles: [
    `.contact_style3 .contact_icon {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.contact_style3 .contact_icon i {
      color: var(--colorThemePrimary);
    }`,
    `.contact_style3 .contact_icon::before {
      background-color: var(--colorThemePrimary);
    }`,
    `.contact_style3:hover .contact_icon i {
      color: #fff;
    }`,
    `.contact_text a:hover {
      color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`
  ]
})
export class ContactUsComponent implements OnInit {

  themeColor:string = '';
  public email = '';
  public address = '';
  public phone = '';
  public urlPhone = '';
  public urlEmail = '';
  public nombreProspecto = '';
  public telefonoProspecto = '';
  public correoProspecto = '';
  public asuntoCorreo = '';
  public colorTheme = 'white';

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private toastrService: ToastrService, private encryption: EncryptManagerService, private api: RestApiServiceService, private titleService: Title) {
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'correo'){
      this.correoProspecto = valor;
    } else if (idInput === 'telefono'){
      this.telefonoProspecto = valor;
    } else if (idInput === 'nombre'){
      this.nombreProspecto = valor;
    } else if (idInput === 'asunto'){
      this.asuntoCorreo = valor;
    }
  }

  ngOnInit() {
    window.scroll(0,0);
    this.checkLocalStorage();
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        this.colorTheme = response.color;
        const titleHeader = response.title ? `${response.title} | Contacto`:'LocLog Shops | Contacto';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.email = response.email;
        if(response.social && response.social?.length >0) {
          if(this.filtrarRedes(response.social, "Email").length > 0){
            this.urlEmail = this.filtrarRedes(response.social, "Email")[0].url;
          }
        }
        if(response.phone !== ''){
          this.phone = response.phone;
          this.urlPhone = 'tel:' + response.phone.replace(/\s/g, '');
        }
        if(response.address !== ''){
          this.address = response.address;
        }
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // }

    // const email = atob(localStorage.getItem('SU5GTy1FTUFJTA=='));
    // if(email !== ''){
    //   this.email = email;
    // }

    // const social = JSON.parse(atob(localStorage.getItem('SU5GTy1TT0NJQUw=')));
    // if(social.length >0) {
    //   if(this.filtrarRedes(social, "Email").length > 0){
    //     this.urlEmail = this.filtrarRedes(social, "Email")[0].url;
    //   }
    // }
    // const phone = atob(localStorage.getItem('SU5GTy1QSE9ORQ=='));;
    // if(phone !== ''){
    //   this.phone = phone;
    //   this.urlPhone = 'tel:' + phone.replace(/\s/g, '');
    // }
    // const address = atob(localStorage.getItem('SU5GTy1BRERSRVNT'));
    // if(address !== ''){
    //   this.address = address;
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Contacto`;
    // } else {
    //   titleHeader = `LocLog Shops | Contacto`;
    // }
    // this.titleService.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);
  }

  enviarDatos(){
    if (this.correoProspecto === undefined || this.correoProspecto.trim() === ''){
      this.toastrService.warning('El correo es requerido');
    }
    if (this.telefonoProspecto === undefined || this.telefonoProspecto.trim() === ''){
      this.toastrService.warning('El teléfono es requerido');
    }
    if (this.nombreProspecto === undefined || this.nombreProspecto.trim() === ''){
      this.toastrService.warning('El nombre es requerido');
    }
    if (this.asuntoCorreo === undefined || this.asuntoCorreo.trim() === ''){
      this.toastrService.warning('El asunto es requerido');
    }

    if(this.correoProspecto !== undefined && this.correoProspecto.trim() !== '' &&
    this.telefonoProspecto !== undefined && this.telefonoProspecto.trim() !== '' &&
    this.nombreProspecto !== undefined && this.nombreProspecto.trim() !== '' &&
    this.asuntoCorreo !== undefined && this.asuntoCorreo.trim() !== '') {
      if(this.validateEmail(this.correoProspecto)) {
        const info = {
          nombre: this.nombreProspecto,
          correo: this.correoProspecto,
          telefono: this.telefonoProspecto,
          asunto: this.asuntoCorreo
        }

        // let privateKey = '';
        // const key = sessionStorage.getItem('a2V5');
        // if(key !== null){
        //   privateKey = key;
        // }
        const info_encriptada = this.encryption.encryptBase64(JSON.stringify(info));
        //enviar info encriptada en petición
        let estado;
        this.api.enviarDatosContacto(info_encriptada).subscribe(
          respuesta => {
            estado = JSON.parse(JSON.stringify(respuesta)).code;
          }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
            } else {
            }
            if (err.status === 400) {
              this.toastrService.error('Error 400');
            }
            if (err.status === 429) {
              this.toastrService.warning(err.error.message);
            }
            if (err.status === 500) {
              this.toastrService.error('Error 500');
            }
          }, () => {
            if (estado === 201){
              this.nombreProspecto = '';
              this.telefonoProspecto = '';
              this.correoProspecto = '';
              this.asuntoCorreo = '';
              this.toastrService.success('Mensaje enviado');
            } else {
              this.toastrService.error('Ocurrió un error inesperado');
            }
          });
      } else {
        this.toastrService.warning('El correo no es válido');
      }
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  filtrarRedes(redes, nombre){
    return redes.filter((product) => {
      return product.name === nombre;
    });
  }

  isHover(hover, id){
    if(id === 'direccion'){
      this.styling.setColor('direccion','color', '#ffffff');
      this.styling.setColor('direccion2','backgroundColor', this.colorTheme);
    } else if(id === 'email'){
      this.styling.setColor('email','color', '#ffffff');
      this.styling.setColor('email2','backgroundColor', this.colorTheme);
    } else if(id === 'phone'){
      this.styling.setColor('phone','color', '#ffffff');
      this.styling.setColor('phone2','backgroundColor', this.colorTheme);
    }
    // if(hover){
    //   if(id === 'direccion'){
    //     this.styling.setColor('direccion','color', '#ffffff');
    //     this.styling.setColor('direccion2','backgroundColor', this.colorTheme);
    //   } else if(id === 'email'){
    //     this.styling.setColor('email','color', '#ffffff');
    //     this.styling.setColor('email2','backgroundColor', this.colorTheme);
    //   } else if(id === 'phone'){
    //     this.styling.setColor('phone','color', '#ffffff');
    //     this.styling.setColor('phone2','backgroundColor', this.colorTheme);
    //   }
    // } else {
    //   if(id === 'direccion'){
    //     // this.styling.setColor('direccion','backgroundColor', '#ffffff');
    //     this.styling.setColor('direccion2','color', this.colorTheme);
    //   } else if(id === 'email'){
    //     // this.styling.setColor('email','backgroundColor', '#ffffff');
    //     this.styling.setColor('email2','color', this.colorTheme);
    //   } else if(id === 'phone'){
    //     // this.styling.setColor('phone','backgroundColor', '#ffffff');
    //     this.styling.setColor('phone2','color', this.colorTheme);
    //   }
    // }
  }

  isHover2(hover, id){
    if(hover){
      this.styling.setColor(id,'color', this.colorTheme);
      this.styling.setColor(id,'background', 'transparent');
      this.styling.setColor(id,'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#ffffff');
      this.styling.setColor(id,'background', this.colorTheme);
      this.styling.setColor(id,'borderColor', this.colorTheme);
    }
  }

  isHover3(hover, id){
    if(hover){
      this.styling.setColor(id,'color', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#687188');
    }
  }

}
