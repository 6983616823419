import { Component, OnInit } from '@angular/core';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private api: RestApiServiceService) { }

  ngOnInit() {
    window.scroll(0,0);
    let titulo = '';
    if(localStorage.getItem('SU5GTy1USVRMRQ==') !== null){
      titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
    }

    let titleHeader = '';
    if(titulo !== undefined){
      titleHeader = `${titulo} | 404`;
    } else {
      titleHeader = `LocLog Shops | 404`;
    }
    this.api.setTitle(titleHeader);
    this.api.updateTags([
      {name: 'og:title', content: titleHeader}
    ]);
  }

}
