<app-header></app-header>
<app-navbar></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content" style="margin-top: -40px;">
    <!-- START SECTION SHOP -->
    <div class="section">
        <div class="container" *ngIf="products.length === 0">
            <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!in_process && !products.length">
                <i class="fa fa-cart-arrow-down price mb-4" style="font-size: 300%;"></i>
                <h3><strong>Tu carrito está vacío</strong></h3>
                <a [routerLink]="['/tienda']" routerLinkActive="router-link-active" class="btn go_back mt-3 text-white" id="go_back" [ngStyle]="{'background': colorTheme}">IR A TIENDA</a><br/>
                <h5 class="mt-3">ó</h5>
                <h1><b>Orden rápida</b></h1>
                <h6>Haz tus pedidos de forma rápida y sencilla</h6>
                <p><a (click)="downloadFormat()" [ngStyle]="{'color': colorTheme}" style="cursor: pointer;"><b>Descarga aquí el formato en Excel</b></a>,  llénalo como se indica y súbelo a continuación. Validaremos existencias y se agregarán a tu carrito de compra</p>
                <button class="btn btn-excel" (click)="openFileInput('fileInputExcel')"><i class="fa fa-file-excel" style="margin-right: 10px;"></i>SUBIR EXCEL</button>
                <input id="fileInputExcel" type="file" #fileInputExcel (change)="onFileSelected($event)" accept=".xml,.xlsx,.xls" style="display: none;">
            </div>
            <div class="col-sm-12 empty-cart-cls text-center" *ngIf="in_process && !products.length">
              <i class="fa fa-cart-plus price mb-4" style="font-size: 300%;"></i>
              <h3><strong>Validando existencias</strong></h3>
              <h6>Por favor espere...</h6>
          </div>
        </div>
        <div class="container" *ngIf="products.length > 0">
          <div class="col-sm-12 empty-cart-cls text-center mb-4" *ngIf="!in_process" style="margin-top: -30px;">
            <h1><b>Orden rápida</b></h1>
            <h6>Haz tus pedidos de forma rápida y sencilla</h6>
            <p><a (click)="downloadFormat()" [ngStyle]="{'color': colorTheme}" style="cursor: pointer;"><b>Descarga aquí el formato en Excel</b></a>,  llénalo como se indica y súbelo a continuación. Validaremos existencias y se agregarán a tu carrito de compra</p>
            <button class="btn btn-excel" (click)="openFileInput('fileInputExcel')"><i class="fa fa-file-excel" style="margin-right: 10px;"></i>SUBIR EXCEL</button>
            <input id="fileInputExcel" type="file" #fileInputExcel (change)="onFileSelected($event)" accept=".xml,.xlsx,.xls" style="display: none;">
          </div>
          <div class="col-sm-12 empty-cart-cls text-center" *ngIf="in_process">
            <i class="fa fa-cart-plus price mb-4" style="font-size: 300%;"></i>
            <h3><strong>Validando existencias</strong></h3>
            <h6>Por favor espere...</h6>
        </div>
            <div *ngIf="!in_process" class="row pb-2 pb-md-4 pb-lg-5" style="margin-top: -10px;">
                <div class="col-12">
                    <div class="table-responsive shop_cart_table">
                        <table class="table shop_cart_table">
                            <thead>
                                <tr>
                                    <th class="product-thumbnail">&nbsp;</th>
                                    <th class="product-name">Producto</th>
                                    <th class="product-price">Precio</th>
                                    <th class="product-quantity">Cantidad</th>
                                    <th class="product-subtotal">Subtotal</th>
                                    <th class="product-remove"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of products">
                                    <td class="product-thumbnail">
                                        <!-- <a href="#"><img src="assets/images/product_img1.jpg" alt="product1"></a> -->
                                        <a (click)="showDetail(product)" style="cursor: pointer;">
                                            <img *ngIf="product.images[0] !== undefined" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].url"  width="100%" height="auto" max-width="300px" />
                                            <img *ngIf="product.images[0] === undefined" [lazyLoad]="'assets/images/product_img1.jpg'" width="100%" height="auto" max-width="300px" />
                                            <!-- <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"> -->
                                        </a>
                                    </td>
                                    <td class="product-name" data-title="Producto"><a (click)="showDetail(product)" style="cursor: pointer;"><span *ngIf="product.warning !== undefined" class="badge badge-warning text-white" style="text-transform: none;">{{product.warning}}</span><br *ngIf="product.warning !== undefined"/>{{product.title}}</a> <br/><b style="text-transform: none;font-size: 80%;" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase">Mayoreo a partir de {{product.minimum_purchase}}</b></td>
                                    <td class="product-price" data-title="Precio" *ngIf="!product.has_wholesale_price && product.minimum_purchase >= 0 && !product.has_discount">{{product.normal_price | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="!product.has_wholesale_price && product.minimum_purchase >= 0 && product.has_discount">{{product.normal_price * ((100 - product.discount)/100) | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && !product.has_discount">{{product.wholesale_price | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && product.has_discount">{{product.wholesale_price * ((100 - product.discount)/100) | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && !product.has_discount">{{product.normal_price | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && product.has_discount">{{product.normal_price * ((100 - product.discount)/100) | currency}}</td>
                                    <td class="product-quantity" data-title="CANTIDAD">
                                        <div class="quantity" *ngIf="product.attribute_types === 'attributes'">
                                            <input type="button" value="-" class="minus" (click)="decrement(product)" [disabled]="product.quantity <= 1" disabled="product.quantity <= 1">
                                            <input (keyup)="listenQuantity($event, product)" type="text" name="quantity" value="{{product.quantity}}" title="Qty" class="qty" size="4" [max]="product.stock">
                                            <input type="button" value="+" class="plus" (click)="increment(product)" [disabled]="product.quantity >= product.stock" disabled="product.quantity >= product.stock">
                                        </div>
                                        <div class="quantity" *ngIf="product.attribute_types === 'variations'">
                                            <input type="button" value="-" class="minus" (click)="decrement(product)" [disabled]="product.quantity <= 1">
                                            <input (keyup)="listenQuantity($event, product)" type="text" name="quantity" value="{{product.quantity}}" title="Qty" class="qty" size="4" [max]="product.stock">
                                            <input type="button" value="+" class="plus" (click)="increment(product)" [disabled]="product.quantity >= product.variation.stock">
                                        </div>
                                        <div *ngIf="product.attribute_types === 'attributes'">
                                            <!-- <span class="price" *ngIf="product.stock > 0 && !showStock" style="font-size: 90%;">Disponible</span> -->
                                            <span class="price" *ngIf="product.stock >= 1 && product.stock !== 1 && showStock" style="font-size: 90%;">{{ product.stock}} disponibles</span>
                                            <span class="price" *ngIf="product.stock >= 1 && product.stock === 1 && showStock" style="font-size: 90%;">{{ product.stock}} disponible</span>
                                            <span class="price text-danger" *ngIf="product.stock === 0" style="font-size: 90%;">No disponible</span>
                                        </div>
                                        <div *ngIf="product.attribute_types === 'variations'">
                                            <!-- <span class="price" *ngIf="product.variation.stock > 0 && !showStock" style="font-size: 90%;">Disponible</span> -->
                                            <span class="price" *ngIf="product.variation.stock >= 1 && product.variation.stock !== 1 && showStock" style="font-size: 90%;">{{ product.variation.stock}} disponibles</span>
                                            <span class="price" *ngIf="product.variation.stock >= 1 && product.variation.stock === 1 && showStock" style="font-size: 90%;">{{ product.variation.stock}} disponible</span>
                                            <span class="price text-danger" *ngIf="product.variation.stock === 0" style="font-size: 90%;">No disponible</span>
                                        </div>
                                    </td>
                                    <td class="product-price" data-title="Precio" *ngIf="!product.has_wholesale_price && product.minimum_purchase >= 0 && !product.has_discount">{{product.normal_price * product.quantity | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="!product.has_wholesale_price && product.minimum_purchase >= 0 && product.has_discount">{{product.normal_price * ((100 - product.discount)/100) * product.quantity | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && !product.has_discount">{{product.wholesale_price * product.quantity | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity >= product.minimum_purchase && product.has_discount">{{product.wholesale_price * ((100 - product.discount)/100) * product.quantity | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && !product.has_discount">{{product.normal_price * product.quantity | currency}}</td>
                                    <td class="product-price" data-title="Precio" *ngIf="product.has_wholesale_price && product.quantity < product.minimum_purchase && product.has_discount">{{product.normal_price * ((100 - product.discount)/100) * product.quantity | currency}}</td>
                                    <td class="product-remove d-none d-md-block mt-3" style="border: none;"><a (click)="removeItem(product)" style="cursor: pointer;text-transform: none;"><i class="ti-close mt-md-3"></i></a></td>
                                    <td class="btn col-12 mb-5 d-md-none" [ngStyle]="{'background': colorTheme, 'color': 'white'}"  (click)="removeItem(product)"><a style="cursor: pointer;text-transform: none;text-align: center;font-size: 18px;"><b>Eliminar del carrito <i class="ti-close mt-1" style="float: right;margin-right: 10px;font-size: 18px;"></i></b></a></td>
                                </tr>
                                <tr *ngIf="subtotalCurrency !== ''">
                                    <td style="border-color: white;"></td>
                                    <td style="border-color: white;"></td>
                                    <td style="border-color: white;"></td>
                                    <td class="cart_total_label subtotal" style="border-color: white;" id="subtotal" [ngStyle]="{'background': colorTheme, 'color': 'white'}">Subtotal</td>
                                    <!-- <td class="subtotal-price:hover" style="border-color: white;" id="subtotal_price" [ngStyle]="{'color': colorTheme}"><strong>{{ (getTotal) | async | currency }} {{subtotalCurrency}}</strong></td> -->
                                    <td class="subtotal-price:hover" style="border-color: white;" id="subtotal_price" [ngStyle]="{'color': colorTheme}"><strong>{{ (getTotal) | async | currency}} {{subtotalCurrency}}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="!in_process" class="row mt-5 mt-md-5">
                <div class="col-12 col-lg-4 mb-3">
                    <a [routerLink]="['', 'tienda']" class="btn go_back col-12" id="go_shop" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="fa fa-shopping-basket mr-2"></i>SEGUIR COMPRANDO</a>
                </div>
                <div class="col-12 col-lg-3 mb-3 offset-0 offset-lg-5">
                    <a (click)="pagarPedido()" class="btn go_back col-12"  id="go_checkout" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="fa fa-shopping-cart mr-2"></i>CONFIRMAR</a>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->

</div>
<!-- END MAIN CONTENT -->

<app-footer></app-footer>

<ng-template #confirmar_pedido let-modal>
  <div class="modal-header text-center" style="margin-bottom: -20px;vertical-align: middle;">
      <h4 class="modal-title" id="modal-basic-title" style="font-size: 130%;"><b>CONFIRMAR COMPRA</b></h4>
      <a (click)="modal.dismiss('Cross click')"><i class="fa fa-times" style="cursor: pointer;"></i></a>
  </div>
  <div class="modal-body" class="col-12 pt-4">
      <div class="col-12 text-center mt-2">
          <b style="text-transform: none;font-weight: normal;">Los productos con la etiqueta amarilla <span class="badge badge-warning text-white" style="text-transform: none;">Entrega en X dias</span> no se encuentran disponibles en el CEDI y pueden demorar en entregarse el lapso de tiempo especificado.</b>
          <br/>
          <b>¿Acepta que los productos se surtan de otros CEDIs en los tiempos de entrega establecidos?</b>
      </div>
      <br/>
  </div>
  <div class="modal-footer">
    <div class="col-12 row align-center">
      <button type="button" class="btn btn-danger col-5 offset-1 align-center" (click)="aceptarPedido(false)"><b>NO</b></button>
      <button type="button" class="btn btn-success col-5 align-center" (click)="aceptarPedido(true)"><b>SI</b></button>
    </div>
  </div>
</ng-template>
