import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { QuickViewComponent } from '../../widgets/quick-view/quick-view.component';
import { CartModalComponent } from '../../widgets/cart-modal/cart-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import { Title } from '@angular/platform-browser';
import { ItemsRequest } from 'src/app/classes/items_request';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { environment } from 'src/environments/environment.prod';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { PreorderModalComponent } from '../../widgets/preorder-modal/preorder-modal.component';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css'],
  styles: [
    `.price {
      color: var(--colorThemePrimary);
    }`,
    `.pr_action_btn li a:hover {
      background-color: var(--colorThemePrimary);
    }`,
    `.pagination_style1 .page-item.active .page-link,
    .pagination_style1 .page-item .page-link:hover {
        background-color: var(--colorThemePrimary);
        border-color: var(--colorThemePrimary);
    }`,
    `.dash-btn-outline-1:hover {
      background: var(--colorThemePrimary);
      border: 1px solid var(--colorThemePrimary);
      color: white;
    }`,
    `.bold {
      font-weight: bold;
      color: var(--colorThemePrimary);
    }`,
    `.icon-prev-next {
      color: var(--colorThemePrimary);
    }`,
    `.badge-category-icon {
      color: var(--colorThemePrimary);
    }`
  ]
})
export class OffersComponent implements OnInit, OnDestroy {

  public terminoDeBuscar = false;
  public products = [];
  // public categories = [];
  // public categories2 = [];
  public productos = [];
  public allProducts = [];
  public numPerPage = 9;
  public totalPages = 1;
  public pages = [];
  public brands = [];
  public paginaSeleccionada = 1;
  public mostrarFiltros = false;
  public category: string;

  // NUEVAS VARIABLES
  public searchQuery = {type: 'onsale'};
  public sortOption = {
    sort_by: 'discount',
    text: 'Mayor descuento',
    order_by: 'desc'
  }
  public sortOptions = [
    {
      sort_by: 'discount',
      text: 'Mayor descuento',
      order_by: 'desc'
    },
    {
      sort_by: 'discount',
      text: 'Menor descuento',
      order_by: 'asc'
    },
    {
      sort_by: 'updated_at',
      text: 'Mas recientes',
      order_by: 'desc'
    },
  ];
  public searching = false;
  public isSearchResult = false;
  public current_page = 1;
  public per_page = 12;
  public total_pages = 0;
  public prev_page = null;
  public next_page = null;
  public total_products = 0;
  public category_selected = {
    uid: '',
    name: '',
    attribute_types: '',
    variations_type: '',
    path_from_root: [],
    children_categories: [],
  }
  public department_selected = {
    uid: '',
    slug: '',
    name: ''
  };
  public departments = [];
  modalOptions: NgbModalOptions;
  closeResult: string;
  public ImageSrc : string = '';
  public colorTheme = '';
  public colorTheme2 = '#333';
  private colorDefault = '#0097db';
  private configWorker: any;
  private infoService = null;

  constructor(private service: CustomStoreService, private cart:ShopsCartAngularService, public activeModal: NgbActiveModal, private styling: StyleManagerService, private product: ProductServiceService, private route: ActivatedRoute, private modalService: NgbModal, private toastrService: ToastrService, private productService: ProductServiceService, private router: Router, private api: RestApiServiceService, private notify: ToastrService, private titleService: Title, private auth: ShopsAuthAngularService) {
    this.modalOptions = {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit() {
    window.scroll(0,0);
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    const currentPage = sessionStorage.getItem('b2ZmZXJzQ3VycmVudFBhZ2U=');
    if(currentPage){
      if(!isNaN(Number(currentPage))){
        this.current_page = Number(currentPage);
        sessionStorage.removeItem('b2ZmZXJzQ3VycmVudFBhZ2U=');
      }
    }
    this.initWorkers();

    this.configWorker.onmessage = ({data}) => {
      let estado = data.code;
      let result = data.result;
      let general = data.general;
      if(estado === 200){
        let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
        let titleHeader = '';
        if(titulo !== undefined){
          titleHeader = `${titulo} | Ofertas`;
        } else {
          titleHeader = `LocLog Shops | Ofertas`;
        }
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        if(result !== undefined){
          let producto;
          producto = {
            docs: result.docs,
            total: result.total,
            total_pages: result.total_pages,
            actual_page: result.actual_page,
            prev: result.prev,
            next: result.next,
          }
          if(result.info_category !== undefined){
            this.category_selected = result.info_category;
          }
          producto.sort = this.sortOption;
          producto.collection = this.department_selected;
          producto.category = this.category_selected;
          localStorage.setItem('bGlzdE9mSXRlbXNPblNhbGU=', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
          this.products = result.docs;
          this.terminoDeBuscar = true;
          this.current_page = result.actual_page;
          this.total_pages = result.total_pages;
          this.prev_page = result.prev;
          this.next_page = result.next;
          this.total_products = result.total;
          this.notify.clear();
        } else if(general.catalog_version !== undefined){
          // const catalog = {
          //   categories: general.catalog,
          //   version: general.catalog_version
          // }
          // localStorage.setItem('aW5mb0NhdGFsb2dTZXJ2aWNl', btoa(JSON.stringify(catalog)));
        } else if(general.version !== undefined){
          // const catalog = {
          //   version: general.version,
          //   categories: general.catalog
          // }
          // localStorage.setItem('bGlzdE9mQ2F0ZWdvcmllcw==', btoa(JSON.stringify(catalog)));
          // this.categories = catalog.categories;
          // this.categories2 = catalog.categories.filter((category) => {
          //   return category.path_from_root.length === 1;
          // });
        }
      } else {
        if(estado === 402){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === 403){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === 429){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.error('Error inesperado :(');
        }
      }
    };
    this.checkLocalStorage();

    // if(localStorage.getItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  ngOnDestroy() {
    // LIMPIAR HISTORIAL BUSQUEDA OFERTAS
    localStorage.removeItem('bGlzdE9mSXRlbXNPblNhbGU=');
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }

  isHover(hover, id, type){
    if(hover){
      this.styling.setColor(id,'color', '#ffffff');
      this.styling.setColor(id,'background', this.colorTheme);
      this.styling.setColor(id,'borderColor', this.colorTheme);
    } else {
      if(type === '1'){
        this.styling.setColor(id,'backgroundColor', 'transparent');
      } else {
        this.styling.setColor(id,'backgroundColor', '#ffffff');
      }
      this.styling.setColor(id,'color', '#6c757d');
      this.styling.setColor(id,'borderColor', '#6c757d');
    }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorTheme2 = response.color;
        }
        const departments = response.collections;
        if(departments.length > 0){
          this.departments = departments;
        }

        const titleHeader = response.title ? `${response.title} | Ofertas`:'LocLog Shops | Ofertas';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTheme2 = colorTheme;
    // }

    // const departments = JSON.parse(localStorage.getItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ=='));
    // if(departments.length > 0){
    //   this.departments = departments;
    // }

    // if(localStorage.getItem('SU5GTy1DT0xPUg==') === null){
    //   this.configWorker.postMessage({request: 'getInfoService'});
    //   // const catalog = JSON.parse(atob(localStorage.getItem('aW5mb0NhdGFsb2dTZXJ2aWNl')));
    //   // CATEGORIAS COMPLETAS
    //   // if(localStorage.getItem('bGlzdE9mQ2F0ZWdvcmllcw==') !== null){
    //   //   try {
    //   //     const catalogComplete = JSON.parse(atob(localStorage.getItem('bGlzdE9mQ2F0ZWdvcmllcw==')));
    //   //     if(catalog.version === catalogComplete.version){
    //   //       this.categories = catalogComplete.categories;
    //   //       this.categories2 = catalogComplete.categories.filter((category) => {
    //   //         return category.path_from_root.length === 1;
    //   //       });
    //   //     } else {
    //   //       this.configWorker.postMessage({request: 'getCategoriesFromService'});
    //   //     }
    //   //   } catch(err){
    //   //     this.configWorker.postMessage({request: 'getCategoriesFromService'});
    //   //   }
    //   // } else {
    //   //   this.configWorker.postMessage({request: 'getCategoriesFromService'});
    //   // }
    // } else {
    //   // this.configWorker.postMessage({request: 'getInfoService'});
    // }

    this.route.queryParams.subscribe(queryParams => {
      if(queryParams.collection !== undefined){
        if(queryParams.collection !== ''){
          const collectionFiltered = this.departments.filter((collectionD, index) => {
            collectionD.index = index;
            return collectionD.slug.trim() === queryParams.collection.trim();
          });
          if(collectionFiltered.length > 0){
            this.department_selected = collectionFiltered[0];
          }
        }
      }
      // if(queryParams.category_id !== undefined){
      //   if(queryParams.category_id !== ''){
      //     const categorias = this.categories.filter((category) => {
      //       return category.uid === queryParams.category_id;
      //     });

      //     if(categorias.length > 0){
      //       this.category_selected = categorias[0];
      //       if(localStorage.getItem('bGlzdE9mSXRlbXNPblNhbGU=') !== null){
      //         let productosLocal = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('bGlzdE9mSXRlbXNPblNhbGU=')))));
      //         if(productosLocal.category.uid !== this.category_selected.uid){
      //           productosLocal.category = this.category_selected;
      //           localStorage.setItem('bGlzdE9mSXRlbXNPblNhbGU=', btoa(unescape(encodeURIComponent(JSON.stringify(productosLocal)))));
      //         }
      //       }
      //     }
      //   }
      // }
    });

    if(localStorage.getItem('bGlzdE9mSXRlbXNPblNhbGU=') !== null){
      const productosLocal = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('bGlzdE9mSXRlbXNPblNhbGU=')))));
      this.products = productosLocal.docs;
      this.sortOption = productosLocal.sort;
      this.department_selected = productosLocal.collection;
      this.category_selected = productosLocal.category;
      if(this.category_selected.uid !== '' && this.department_selected.slug !== ''){
        this.router.navigate(['', 'ofertas'], { queryParams: { category_id: this.category_selected.uid, collection: this.department_selected.slug }, queryParamsHandling: 'merge' });
      } else if(this.category_selected.uid !== '' && this.department_selected.slug === ''){
        this.router.navigate(['', 'ofertas'], { queryParams: { category_id: this.category_selected.uid }, queryParamsHandling: 'merge' });
      } else if(this.category_selected.uid === '' && this.department_selected.slug !== ''){
        this.router.navigate(['', 'ofertas'], { queryParams: { collection: this.department_selected.slug }, queryParamsHandling: 'merge' });
      }
      this.terminoDeBuscar = true;
      this.current_page = productosLocal.actual_page;
      this.total_pages = productosLocal.total_pages;
      this.prev_page = productosLocal.prev;
      this.next_page = productosLocal.next;
      this.total_products = productosLocal.total;
    }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));

    // let titleHeader = '';
    // if(titulo !== undefined){
    //   titleHeader = `${titulo} | Ofertas`;
    // } else {
    //   titleHeader = `LocLog Shops | Ofertas`;
    // }
    // this.titleService.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);

    this.getProducts('', this.department_selected.slug, {type: 'onsale'}, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page, this.per_page);
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getProducts(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage){
    let estado2;
      let result;
      this.api.getItemsV2(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage, this.auth.getToken(), this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        result = JSON.parse(JSON.stringify(respuesta)).result;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400');
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500');
        }
      }, () => {
        if(estado2 === 200){
          let producto = result;
          localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
          // this.products = result.docs;
          this.products = result.docs.map((item) => {
            return {
              ...item,
              ...{
                counter: 1
              }
            }
          });
          this.current_page = result.actual_page;
          this.total_pages = result.total_pages;
          this.prev_page = result.prev;
          this.next_page = result.next;
          this.total_products = result.total;
          this.terminoDeBuscar = true;
          const CediItemsId = this.products.filter((product) => product.warning === '').map((product) => product.id);
          if(CediItemsId.length > 0){
            this.getPrices(CediItemsId);
            this.getStocks(CediItemsId);
          }
        }
      });
  }

  getPrices(items_id){
    let estado2;
      let prices;
      this.api.getPricesItems(items_id, this.auth.getToken(), this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        prices = JSON.parse(JSON.stringify(respuesta)).prices;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            prices.forEach((price) => {
              const indexItem = this.products.findIndex((item) => item.id === price.item_id);
              if(indexItem !== -1){
                this.products[indexItem].discount = price.discount;
                this.products[indexItem].retail_price = price.retail_price;
                this.products[indexItem].currency = 'MXN';
                if(price.discount > 0){
                  this.products[indexItem].has_discount = true;
                } else {
                  this.products[indexItem].has_discount = false;
                  this.products[indexItem].minimum_purchase = 0;
                  this.products[indexItem].wholesale_price = price.retail_price;
                }
                if(price.has_wholesale_price){
                  this.products[indexItem].has_wholesale_price = true;
                } else {
                  this.products[indexItem].has_wholesale_price = false;
                }
              }
            });
          } catch (error) {

          }
        }
      });
  }

  getStocks(items_id){
    let estado2;
      let stocks;
      this.api.getStockItems(items_id, this.auth.getToken(), this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        stocks = JSON.parse(JSON.stringify(respuesta)).stocks;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            stocks.forEach((stock) => {
              const indexItem = this.products.findIndex((item) => item.id === stock.item_id);
              if(indexItem !== -1){
                this.products[indexItem].stock = stock.qty;
              }
            });
          } catch (error) {

          }
        }
      });
  }

  listenQuantity(event, producto){
    const value = event.target.value;
    const reg = /^\d+$/;
    if(reg.test(value)){
      const quantity = Number(value);
      let stock = producto.stock;
      if(quantity > 0 && quantity <= stock){
        producto.counter = quantity;
      } else if(quantity > stock){
        producto.counter = stock;
        event.target.value = `${stock}`;
      } else {
        producto.counter = 1;
        event.target.value = '';
      }
    } else {
      producto.counter = 1;
      event.target.value = '';
    }
  }

  actionInCart(quantity, type, producto){
    if(type === 'less' && (producto.counter - quantity) >= 1){
      producto.counter = producto.counter - quantity;
    }
    if(type === 'more' && (producto.counter + quantity) <= producto.stock){
      producto.counter = producto.counter + quantity;
    }
  }

  async addToCart(product){
    let tieneVariantes = false;
    let infoVariation;
    product.quantity = product.counter || 1;
    const status = await this.productService.addToCart(product, tieneVariantes, infoVariation);
    if(status){
      sessionStorage.setItem('aXNBbmltYXRlQ2FydA==', 'true');
      this.cart.updateCartAnimation(true);
      if(environment.show_confirm_cart_modal){
        const modalRef = this.modalService.open(CartModalComponent, {size: 'lg', centered: true});
        if(!tieneVariantes){
          modalRef.componentInstance.product = product;
        } else {
          let producto = product;
          producto.variation = infoVariation;
          modalRef.componentInstance.product = producto;
        }
        this.activeModal.close('Modal Closed');
      } else {
        this.activeModal.close('Modal Closed');
      }
      this.toastrService.clear();
      this.toastrService.success('Producto agregado a carrito');
    }
  }

  showPreorderModal(product){
    this.activeModal.close('Modal Closed');
    const modalRef = this.modalService.open(PreorderModalComponent, {size: 'lg', centered: true});
    // modalRef.componentInstance.product = product;
    modalRef.componentInstance.product = {
      ...product,
      ...{
        source: 'main'
      }
    };

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  navOnPagination(type){
    if(this.terminoDeBuscar){
      this.terminoDeBuscar = false;
      if(type === 'next'){
        this.products = [];
        window.scroll(0,0);
        if(this.category_selected.children_categories.length === 0){
          this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page + 1, this.per_page);
        } else {
          this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page + 1, this.per_page);
        }
      } else if(type === 'back'){
        this.products = [];
        window.scroll(0,0);
        if(this.category_selected.children_categories.length === 0){
          this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page - 1, this.per_page);
        } else {
          this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page - 1, this.per_page);
        }
      }
    } else {
      this.notify.warning('Por favor espere...');
    }
  }

  updateUrl(position, product) {
    if(product.attribute_types === 'attributes'){
      this.products[position].images[0].url = 'assets/images/product_img1.jpg';
    } else if(product.attribute_types === 'variations'){
      this.products[position].variations[0].images[0].url = 'assets/images/product_img1.jpg';
    }
  }

  selectSort(sort){
    if(sort.text !== this.sortOption.text){
      this.sortOption = sort;
      this.notify.info('Un momento...');
      this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
    }
  }

  selectCollection(collection, position){
    this.terminoDeBuscar = false;
    this.products = [];
    if(this.department_selected.slug !== collection.slug){
      this.department_selected = collection;
      this.router.navigate(['', 'ofertas'], { queryParams: { collection: collection.slug }, queryParamsHandling: 'merge' });
      if(this.category_selected.children_categories.length === 0){
        this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      } else {
        this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      }
      this.styling.setColor(`${position}`,'backgroundColor', this.colorTheme);
    } else {
      this.department_selected = {
        uid: '',
        name: '',
        slug: ''
      }
      this.router.navigate(['', 'ofertas'], { queryParams: { collection: undefined }, queryParamsHandling: 'merge' });
      if(this.category_selected.children_categories.length === 0){
        this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      } else {
        this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, 1, this.per_page);
      }
      this.styling.setColor(`${position}`,'backgroundColor', 'transparent');
    }
  }

  // selectCategory(category2){
  //   const subCategorias = this.categories.filter((category) => {
  //     return category.path_from_root.length === (this.category_selected.path_from_root.length + 1) && category.path_from_root[this.category_selected.path_from_root.length].uid === category2.uid;
  //   });
  //   if(subCategorias.length > 0){
  //     this.category_selected = subCategorias[0];
  //     this.router.navigate(['', 'ofertas'], { queryParams: { category_id: subCategorias[0].uid }, queryParamsHandling: 'merge' });
  //     let productosLocal = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('bGlzdE9mSXRlbXNPblNhbGU=')))));
  //     productosLocal.category = this.category_selected;
  //     localStorage.setItem('bGlzdE9mSXRlbXNPblNhbGU=', btoa(unescape(encodeURIComponent(JSON.stringify(productosLocal)))));
  //     if(subCategorias[0].children_categories.length === 0){
  //       this.terminoDeBuscar = false;
  //       this.products = [];
  //       this.getProducts(this.category_selected.uid, this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page, this.per_page);
  //     }
  //   }
  // }

  // regresarDeNivel(position){
  //   if(this.category_selected.children_categories.length === 0 && this.category_selected.uid !== ''){
  //     this.getProducts('', this.department_selected.slug, this.searchQuery, {sort_by: this.sortOption.sort_by, order_by: this.sortOption.order_by}, this.current_page, this.per_page);
  //   }
  //   if(position > 1){
  //     const nuevasCategorias = this.categories.filter((category) => {
  //       return category.path_from_root.length === (this.category_selected.path_from_root.length - 1) && category.uid === this.category_selected.path_from_root[position - 1].uid;
  //     });
  //     this.category_selected = nuevasCategorias[0];
  //     this.router.navigate(['', 'ofertas'], { queryParams: { category_id: nuevasCategorias[0].uid }, queryParamsHandling: 'merge' });
  //   } else if(position > 0){
  //     const nuevasCategorias = this.categories.filter((category) => {
  //       return category.path_from_root.length === 1 && category.uid === this.category_selected.path_from_root[position - 1].uid;
  //     });
  //     this.category_selected = nuevasCategorias[0];
  //     this.router.navigate(['', 'ofertas'], { queryParams: { category_id: nuevasCategorias[0].uid }, queryParamsHandling: 'merge' });
  //   } else {
  //     this.router.navigate(['', 'ofertas'], { queryParams: { category_id: undefined }, queryParamsHandling: 'merge' });
  //     this.categories2 = this.categories.filter((category) => {
  //       return category.path_from_root.length === 1;
  //     });
  //     this.category_selected = {
  //       uid: '',
  //       name: '',
  //       attribute_types: '',
  //       variations_type: '',
  //       path_from_root: [],
  //       children_categories: [],
  //     };
  //   }
  //   let productosLocal = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('bGlzdE9mSXRlbXNPblNhbGU=')))));
  //   productosLocal.category = this.category_selected;
  //   localStorage.setItem('bGlzdE9mSXRlbXNPblNhbGU=', btoa(unescape(encodeURIComponent(JSON.stringify(productosLocal)))));
  // }

  // getPrincipalCategories(){
  //   let estado;
  //   let info;
  //   this.api.getInfoService().subscribe(respuesta => {
  //       info = JSON.parse(JSON.stringify(respuesta)).general;
  //       estado = JSON.parse(JSON.stringify(respuesta)).code;
  //     }, (err: HttpErrorResponse) => {
  //       if (err.error instanceof Error) {
  //       } else {
  //       }
  //       if (err.status === 400) {
  //         this.toastrService.error('Error 400');
  //       }
  //       if (err.status === 401) {
  //         this.toastrService.warning('No estás autorizado para acceder');
  //         this.router.navigate(['', 'ingresar']);
  //       }
  //       if (err.status === 402) {
  //         this.toastrService.warning('Servicio suspendido');
  //         this.router.navigate(['', '402']);
  //       }
  //       if (err.status === 403) {
  //         this.toastrService.warning('Servicio cancelado');
  //         this.router.navigate(['', '403']);
  //       }
  //       if (err.status === 429) {
  //         this.toastrService.warning(err.error.message);
  //       }
  //       if (err.status === 500) {
  //         this.toastrService.error('Error 500');
  //       }
  //     }, () => {
  //       if(estado === 200){
  //         // SAVE
  //         if(info.catalog !== undefined){
  //           const catalog = {
  //             categories: info.catalog,
  //             version: info.catalog_version
  //           }
  //           localStorage.setItem('aW5mb0NhdGFsb2dTZXJ2aWNl', btoa(JSON.stringify(catalog)));
  //         }
  //       }
  //     }
  //   );
  // }

  // getAllCategories(){
  //   let estado;
  //   let info;
  //   this.api.getCategoriesFromService().subscribe(respuesta => {
  //       info = JSON.parse(JSON.stringify(respuesta)).general;
  //       estado = JSON.parse(JSON.stringify(respuesta)).code;
  //     }, (err: HttpErrorResponse) => {
  //       if (err.error instanceof Error) {
  //       } else {
  //       }
  //       if (err.status === 400) {
  //         this.toastrService.error('Error 400');
  //       }
  //       if (err.status === 401) {
  //         this.toastrService.warning('No estás autorizado para acceder');
  //         this.router.navigate(['', 'ingresar']);
  //       }
  //       if (err.status === 402) {
  //         this.toastrService.warning('Servicio suspendido');
  //         this.router.navigate(['', '402']);
  //       }
  //       if (err.status === 403) {
  //         this.toastrService.warning('Servicio cancelado');
  //         this.router.navigate(['', '403']);
  //       }
  //       if (err.status === 429) {
  //         this.toastrService.warning(err.error.message);
  //       }
  //       if (err.status === 500) {
  //         this.toastrService.error('Error 500');
  //       }
  //     }, () => {
  //       if(estado === 200){
  //         const catalog = {
  //           version: info.version,
  //           categories: info.catalog
  //         }
  //         localStorage.setItem('bGlzdE9mQ2F0ZWdvcmllcw==', btoa(JSON.stringify(catalog)));
  //         this.categories = catalog.categories;
  //         this.categories2 = catalog.categories.filter((category) => {
  //           return category.path_from_root.length === 1;
  //         });
  //       }
  //     }
  //   );
  // }

  // getProducts(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage){
  //   let estado2;
  //     let result;
  //     this.api.getItemsV2(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage, this.auth.getToken()).subscribe(respuesta => {
  //       result = JSON.parse(JSON.stringify(respuesta)).result;
  //       estado2 = JSON.parse(JSON.stringify(respuesta)).code;
  //     },  (err: HttpErrorResponse) => {
  //       if (err.error instanceof Error) {
  //       } else {
  //       }
  //       if (err.status === 400) {
  //         this.toastrService.error('Error 400');
  //       }
  //       if (err.status === 401) {
  //         this.toastrService.warning('No estás autorizado para acceder');
  //         this.router.navigate(['', 'ingresar']);
  //       }
  //       if (err.status === 402) {
  //         this.toastrService.warning('Servicio suspendido');
  //         this.router.navigate(['', '402']);
  //       }
  //       if (err.status === 403) {
  //         this.toastrService.warning('Servicio cancelado');
  //         this.router.navigate(['', '403']);
  //       }
  //       if (err.status === 429) {
  //         this.toastrService.warning(err.error.message);
  //       }
  //       if (err.status === 500) {
  //         this.toastrService.error('Error 500');
  //       }
  //     }, () => {
  //       if(estado2 === 200){
  //         let producto = result;
  //         producto.sort = this.sortOption;
  //         producto.collection = this.department_selected;
  //         producto.category = this.category_selected;
  //         localStorage.setItem('bGlzdE9mSXRlbXNPblNhbGU=', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
  //         this.products = result.docs;
  //         this.terminoDeBuscar = true;
  //         this.current_page = result.actual_page;
  //         this.total_pages = result.total_pages;
  //         this.prev_page = result.prev;
  //         this.next_page = result.next;
  //         this.total_products = result.total;
  //         this.notify.clear();
  //       }
  //     });
  // }

  scroll(id){
    setTimeout(() => {
      try {
        this.styling.setEffect(id, 'focus');
        const options = {block: "start", behavior: "smooth"};
        this.styling.setEffect(id, 'scrollIntoView', options);
      } catch (error) {

      }
    }, 100);
  }

  showProduct(producto){
    sessionStorage.setItem('b2ZmZXJzQ3VycmVudFBhZ2U=', `${this.current_page}`);
    sessionStorage.setItem('c291cmNlQmFjaw==', 'offers-store');
    this.productService.actualizarDetalleProducto(producto);
    this.router.navigate(['', 'p', producto.slug], { queryParams: { uid: producto.id } });
  }

  showQuickView(product){
    const modalRef = this.modalService.open(QuickViewComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

  showCartModal(product){
    const modalRef = this.modalService.open(CartModalComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

}
