import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomStoreService {

  public infoService = new BehaviorSubject(null);
  currentService = this.infoService.asObservable();

  private infoStores = new BehaviorSubject([]);
  currentStores = this.infoStores.asObservable();
  private infoPayments = new BehaviorSubject([]);
  currentPayments = this.infoPayments.asObservable();

  constructor() { }

  updateService(info){
    this.infoService.next(info);
  }

  updateStores(info){
    this.infoStores.next(info);
  }

  updatePayments(info){
    this.infoPayments.next(info);
  }
}
