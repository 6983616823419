import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { QuickViewComponent } from '../quick-view/quick-view.component';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';

@Component({
  selector: 'app-preorder-modal',
  templateUrl: './preorder-modal.component.html',
  styleUrls: ['./preorder-modal.component.css']
})
export class PreorderModalComponent implements OnInit {

  @Input() public product;
  modalOptions: NgbModalOptions;
  closeResult: string;
  public counter = 1;
  public colorTheme = 'white';
  public colorTheme2 = '#333';

  constructor(private auth: ShopsAuthAngularService, private api: RestApiServiceService, private service: CustomStoreService, private modalService: NgbModal, private toastrService: ToastrService, public activeModal: NgbActiveModal) {
    this.modalOptions = {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
   }

  ngOnInit() {
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTheme2 = colorTheme;
    // }
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorTheme2 = response.color;
        }
      }
    });
  }

  showQuickView(product){
    const modalRef = this.modalService.open(QuickViewComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

  listenQuantity(event){
    const value = event.target.value;
    const reg = /^\d+$/;
    if(reg.test(value)){
      const quantity = Number(value);
      this.counter = quantity;
    } else {
      this.counter = 1;
      event.target.value = '';
    }
  }

  async sendPreorder(){
    const token = this.auth.getToken();
    const infoNotify = await this.api.notifyStock(token,this.product.id, this.counter) as any;
    if(infoNotify){
      if(infoNotify.code !== 200){
        this.toastrService.error(infoNotify.message, `Error ${infoNotify.code}`);
      } else {
        this.toastrService.success('Se ha colocado su solicitud de producto','Preorden enviada');
        this.activeModal.close('Modal Closed');
        if(!this.product.source){
          this.showQuickView(this.product);
        }
      }
    } else {
      this.toastrService.error('Ocurrió un error inesperado');
    }
  }

}
