import { Injectable } from '@angular/core';
import { map, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
// import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})

export class RestApiServiceService {

  private headers: HttpHeaders;
  private headersAccess: HttpHeaders;

  constructor(private http: HttpClient, private titleService: Title, private metaService: Meta) {
    /* HEADER PUBLICO*/
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `LocLog ${environment.appKey}`
    });
  }

  funcionHeader(accessToken) {
    /* HEADER AUTH*/
    return this.headersAccess = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'LocLog ' + accessToken
    });
    // if(environment.smartbCompatible){
    //   return this.headersAccess = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     Authorization: `LocLog ${environment.appKey}`,
    //     'Authentication': 'LocLog ' + accessToken
    //   });
    // } else {
    //   return this.headersAccess = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     Authorization: 'LocLog ' + accessToken
    //   });
    // }
  }

  // funcionSmartHeader(accessToken) {
  //   /* HEADER AUTH*/
  //   return this.headersAccess = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     Authorization: `LocLog ${environment.appKey}`,
  //     'x-smartb-store-id': `${environment.smartb_store_id}`,
  //     'Authentication': 'LocLog ' + accessToken
  //   });
  // }

  funcionWMSHeader(accessToken, wms_key2 = '') {
    /* HEADER AUTH*/
    let key = environment.wms_key;
    if(wms_key2 !== ''){
      key = wms_key2;
    }
    return this.headersAccess = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${key}`,
      'Authentication': accessToken,
      'x-wms-id': ``
    });
  }

  funcionOrdersHeader(accessToken) {
    // const helper = new JwtHelperService();
    // const decodedToken = helper.decodeToken(accessToken);
    /* HEADER AUTH*/
    return this.headersAccess = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${environment.checkout_key}`,
      'Authentication': accessToken,
      // 'Origin': `${decodedToken.aud}`
    });
  }

  funcionShipmentsHeader() {
    /* HEADER AUTH*/
    return this.headersAccess = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'LocLog ' + environment.shipmentKey,
    });

  }

  getPublicIp(){
    const headers = new HttpHeaders({
      'Content-Type': 'text/plain; charset=utf-8'
    });

    return this.http
      .get('https://www.cloudflare.com/cdn-cgi/trace', {headers, responseType: 'text'})
      .pipe(map(data => data));
  }

  /* FORMAS DE PAGO */
  getPaymentSources(token, pickerpro_id = '') {
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/payment/sources?pickerpro_id=${pickerpro_id}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
              // resolve(null);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  getPaymentSourceInfo(token, source_id, pickerpro_id = '') {
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/payment/source?source_id=${source_id}&pickerpro_id=${pickerpro_id}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
              // resolve(null);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  /* SERVICE */

  getItemsV2(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage, token, pickerpro_id = '') {
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/items?pickerpro_id=${pickerpro_id}`;
    return this.http
      .post(urlApi, { category: categoryId, gender: genderType, query: queryInfo, sort: sortInfo, page: currentPage, per_page: perPage}, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  getAttributesItem(items_id, token, pickerpro_id = '') {
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/attributes?pickerpro_id=${pickerpro_id}`;
    return this.http
      .post(urlApi, { ids: items_id}, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  getItemV2(productId, token, pickerpro_id = '') {
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/item?pickerpro_id=${pickerpro_id}`;
    return this.http
      .post(urlApi, {id: productId}, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  getPricesItems(items_id, token, pickerpro_id = '') {
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/prices?pickerpro_id=${pickerpro_id}`;
    return this.http
      .post(urlApi, { ids: items_id}, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  getStockItems(items_id, token, pickerpro_id = '', search_id = '') {
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/stocks?pickerpro_id=${pickerpro_id}&search_id=${search_id}`;
    return this.http
      .post(urlApi, { ids: items_id}, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  getExternalWMS(items_id, wmsId, token, isBarcode = false, pickerpro_id = '', search_id = '') {
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/external?pickerpro_id=${pickerpro_id}&search_id=${search_id}`;
    return this.http
      .post(urlApi, { ids: items_id, wms_id: wmsId, is_barcode: isBarcode}, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  searchWmsItems(query, token, pickerpro_id = ''){
    let headersAccessLocal = this.funcionWMSHeader(token);
    let urlApi = `${environment.wms_v1}/catalog/search?q=${query}&t=${environment.searchType}&pickerpro_id=${pickerpro_id}`;
    return this.http
      .get(urlApi, { headers: headersAccessLocal })
      .pipe(timeout(90000), map(data => data));
  }

  notifyStock(token, item_uid, quantity, pickerpro_id = ''){
    return new Promise((resolve) => {
        try {
          const headersAccessLocal = this.funcionWMSHeader(token);
          const urlApi = `${environment.wms_v1}/catalog/notify?item_uid=${item_uid}&quantity=${quantity}`;

          this.http
            .get(urlApi, { headers: headersAccessLocal })
            .pipe(timeout(90000))
            .subscribe(
              (data) => {
                resolve(data);
              },
              (error) => {
                resolve(error.error);
                // resolve(null);
              }
            );
        } catch (error) {
          resolve(null)
        }
      });
  }

  // getSmartbCollections(accessToken){
  //   let headersAccessLocal: HttpHeaders = this.funcionSmartHeader(accessToken);
  //   const urlApi = `${environment.smartb_v1}/shops/departments`;

  //   return this.http
  //     .get(urlApi, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  // CART
  // getCartItems(token){
  //   let headersAccessLocal = this.funcionHeader(token);
  //   let urlApi = `${environment.checkout_v2}/cart/items`;
  //   return this.http
  //     .get(urlApi, { headers: headersAccessLocal })
  //     .pipe(timeout(90000), map(data => data));
  // }

  validateCartItem(token, cart) {
    return new Promise((resolve) => {
        try {
          const headersAccessLocal = this.funcionWMSHeader(token);
          const urlApi = `${environment.wms_v1}/catalog/cart`;

          this.http
            .post(urlApi,{cart}, { headers: headersAccessLocal })
            .pipe(timeout(1800000))
            .subscribe(
              (data) => {
                resolve(data);
              },
              (error) => {
                resolve(error.error);
              }
            );
        } catch (error) {
          resolve(null)
        }
      });
  }

  getCartItems(token, pickerpro_id = '') {
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/cart/items?pickerpro_id=${pickerpro_id}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
              // resolve(null);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  addCartItem(item, cartId, token, pickerpro_id = '') {
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/cart/item?pickerpro_id=${pickerpro_id}`;

        this.http
          .post(urlApi,{cartId, item}, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(null);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  removeCartItem(item_id, cartId, token, pickerpro_id = '') {
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/cart/item?cartId=${cartId}&item_id=${item_id}&pickerpro_id=${pickerpro_id}`;

        this.http
          .delete(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  processOrder(info, token, pickerpro_id = '') {
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/orders?pickerpro_id=${pickerpro_id}`;

        this.http
          .post(urlApi,info, { headers: headersAccessLocal })
          .pipe(timeout(1800000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  // DIRECCIONES
  getMyAddresses(token, pickerpro_id, addressId = ''){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/shipping/address?pickerpro_id=${pickerpro_id}&addressId=${addressId}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  addNewAddress(token, infoAddress, pickerpro_id){
    let address = infoAddress;
    delete address.id;
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/shipping/address?pickerpro_id=${pickerpro_id}`;

        this.http
          .post(urlApi,address, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  editAddress(token, infoAddress, pickerpro_id){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/shipping/address?pickerpro_id=${pickerpro_id}`;

        this.http
          .put(urlApi,infoAddress, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  deleteAddress(token, addressId, pickerpro_id){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/shipping/address?addressId=${addressId}&pickerpro_id=${pickerpro_id}`;

        this.http
          .delete(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  // PUNTOS DE RECOLECCION
  getPickupPoints(token,pickerpro_id, pickupId = ''){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionOrdersHeader(token);
        const urlApi = `${environment.checkout_v2}/shipping/pickuppoints?pickerpro_id=${pickerpro_id}&pickupId=${pickupId}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  // PEDIDOS
  getMyOrders(token, currentPage = 1, pickerpro_id = ''){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionWMSHeader(token, environment.wms_key);
        const urlApi = `${environment.wms_v1}/catalog/orders?currentPage=${currentPage}&pickerpro_id=${pickerpro_id}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  getOrderItems(token, orderId, pickerpro_id = ''){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionWMSHeader(token, environment.wms_key);
        const urlApi = `${environment.wms_v1}/catalog/order/items?orderId=${orderId}&pickerpro_id=${pickerpro_id}`;

        this.http
          .get(urlApi, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  getInfoItems(items_id, token) {
    return new Promise((resolve) => {
        try {
          const headersAccessLocal = this.funcionWMSHeader(token, environment.wms_key);
          const urlApi = `${environment.wms_v1}/catalog/info/items`;

          this.http
            .post(urlApi, { ids: items_id}, { headers: headersAccessLocal })
            .pipe(timeout(90000))
            .subscribe(
              (data) => {
                resolve(data);
              },
              (error) => {
                resolve(error.error);
              }
            );
        } catch (error) {
          resolve(null)
        }
      });
  }

  generateShippingQuote(infoQuote){
    return new Promise((resolve) => {
      try {
        const headersAccessLocal = this.funcionShipmentsHeader();
        const urlApi = `${environment.shipments_v1}/quotations/new`;
        this.http
          .post(urlApi,infoQuote, { headers: headersAccessLocal })
          .pipe(timeout(90000))
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              resolve(error.error);
            }
          );
      } catch (error) {
        resolve(null)
      }
    });
  }

  /* AUTH */
  // authAPI(datosIngreso) {
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/autenticarme`;
  //   return this.http
  //     .post(urlApi, {user_info: datosIngreso}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  authAPIv2(datosIngreso, infoDevice, serviceId) {
    // const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    // const urlApi = `${environment.shops_v3}/cuenta/autenticarme`;
    const urlApi = `${environment.session_manager_v1}/account/signin`;
    return this.http
      .post(urlApi, {user_info: datosIngreso, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // generateFbLink() {
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/auth/fb/link`;
  //   return this.http
  //     .get(urlApi, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  // fbAuthAPI(fbCode) {
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/auth/fb/token`;
  //   return this.http
  //     .post(urlApi, {code: fbCode}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  // registroAPI(datosRegistro){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/registrarme`;
  //   return this.http
  //     .post(urlApi, {user_info: datosRegistro}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  registroAPIv2(datosRegistro, infoDevice, serviceId, pickerpro_id = ''){
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/account/signup?pickerpro_id=${pickerpro_id}`;
    return this.http
      .post(urlApi, {user_info: datosRegistro, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // verificarCodigoConfirmacion(codigo){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/confirmar`;
  //   return this.http
  //     .post(urlApi, {verification_code: codigo}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  verificarCodigoConfirmacionV2(codigo, infoDevice, serviceId){
    // const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
    // const urlApi = `${environment.shops_v3}/cuenta/confirmar`;
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/account/confirm`;
    return this.http
      .post(urlApi, {verification_code: codigo, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // recuperarContrasenia(correoUsuario){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/recuperar`;
  //   return this.http
  //     .post(urlApi, {correo: correoUsuario}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  recuperarContraseniaV2(correoUsuario, infoDevice, serviceId){
    // const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/account/recovery/send`;
    return this.http
      .post(urlApi, {email: correoUsuario, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // actualizarContrasenia(infoUsuario){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/restablecer`;
  //   return this.http
  //     .put(urlApi, {user_info: infoUsuario}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  actualizarContraseniaV2(infoUsuario, infoDevice, serviceId){
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/account/recovery/confirm`;
    return this.http
      .post(urlApi, {user_info: infoUsuario, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // actualizarUnaDireccion(datosDireccion, accessToken){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/direcciones`;
  //   return this.http
  //     .put(urlApi, {direccion: datosDireccion}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  actualizarUnaDireccionV2(datosDireccion, infoDevice, serviceId){
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/customer/address`;
    return this.http
      .put(urlApi, {address: datosDireccion, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // actualizarDatosFacturacion(datosFacturacion){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/facturacion`;
  //   return this.http
  //     .put(urlApi, {facturacion: datosFacturacion}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  actualizarDatosFacturacionV2(datosFacturacion, infoDevice, serviceId){
    return new Promise((resolve) => {
        try {
          const headersAccessLocal = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'X-LOCLOG-API-APPKEY': environment.session_manager_key,
            'X-LOCLOG-API-SERVICE': serviceId
          });
          const urlApi = `${environment.session_manager_v1}/customer/billing`;

          this.http
            .put(urlApi,{billing: datosFacturacion, fingerprint: infoDevice}, { headers: headersAccessLocal })
            .pipe(timeout(90000))
            .subscribe(
              (data) => {
                resolve(data);
              },
              (error) => {
                resolve(error.error);
                // resolve(null);
              }
            );
        } catch (error) {
          resolve(null)
        }
      });
  }

  // actualizarInformacionPersonal(datosPersonales, accessToken){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/personal`;
  //   return this.http
  //     .put(urlApi, {user_info: datosPersonales}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  actualizarInformacionPersonalV2(datosPersonales, infoDevice, serviceId){
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/customer/info`;
    return this.http
      .put(urlApi, {user_info: datosPersonales, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // cambiarContrasenia(infoUsuario, accessToken){
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   const urlApi = `${environment.shops_v3}/cuenta/contrasenia`;
  //   return this.http
  //     .put(urlApi, {user_info: infoUsuario}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  cambiarContraseniaV2(infoUsuario, infoDevice, serviceId){
    const headersAccessLocal = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-LOCLOG-API-APPKEY': environment.session_manager_key,
      'X-LOCLOG-API-SERVICE': serviceId
    });
    const urlApi = `${environment.session_manager_v1}/customer/pass`;
    return this.http
      .put(urlApi, {user_info: infoUsuario, fingerprint: infoDevice}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  /* CONTACTO */
  enviarDatosContacto(datosContacto){
    const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
    const urlApi = `${environment.shops_v3}/tienda/contacto`;
    return this.http
      .post(urlApi, {customer_info: datosContacto}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  /* PEDIDOS */
  // generateOrderV2(datosPedido, accessToken, sidUsuario){
  //   let urlApi = '';
  //   const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
  //   if(sidUsuario !== ''){
  //     urlApi = `${environment.shops_v3}/tienda/orderv3/customer`;
  //   } else {
  //     urlApi = `${environment.shops_v3}/tienda/orderv3/guest`;
  //   }
  //   return this.http
  //     .post(urlApi, {order: datosPedido}, { headers: headersAccessLocal })
  //     .pipe(map(data => data));
  // }

  validarCupon(sidUsuario, accessToken, couponInfo, itemsInfo, quotationInfo, storeInfo){
    let urlApi = '';
    const headersAccessLocal: HttpHeaders = this.funcionHeader(environment.appKey);
    if(sidUsuario !== ''){
      urlApi = `${environment.shops_v3}/tienda/coupons/customer`;
    } else {
      urlApi = `${environment.shops_v3}/tienda/coupons/guest`;
    }
    return this.http
      .post(urlApi, {store: storeInfo, quotation: quotationInfo, items: itemsInfo, coupon: couponInfo, sid: sidUsuario}, { headers: headersAccessLocal })
      .pipe(map(data => data));
  }

  // SEO
  setTitle(title){
    this.titleService.setTitle(title);
  }

  getTitle(){
    return this.titleService.getTitle();
  }

  updateTags(tags){
    tags.forEach((tag) => {
      try {
        this.metaService.updateTag({name: tag.name, content: tag.content});
      } catch(err){
      }
    });
  }

}
