

export const getDate = (fecha, includeTime = true): string => {
  const payDayLimit = new Date(fecha);
  let dia = payDayLimit.getDate() as any;
  let mes = (payDayLimit.getMonth() + 1) as any;
  if (dia < 10) {
    dia = `0${dia}`;
  }
  if (mes < 10) {
    mes = `0${mes}`;
  }
  if (includeTime) {
    let horas = payDayLimit.getHours() as any;
    let minutos = payDayLimit.getMinutes() as any;
    let segundos = payDayLimit.getSeconds() as any;

    if (horas < 10) {
      horas = `0${horas}`;
    }
    if (minutos < 10) {
      minutos = `0${minutos}`;
    }
    if (segundos < 10) {
      segundos = `0${segundos}`;
    }
    return `${dia}/${mes}/${payDayLimit.getFullYear()} ${horas}:${minutos}:${segundos}`;
  } else {
    return `${dia}/${mes}/${payDayLimit.getFullYear()}`;
  }
};
