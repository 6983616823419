interface Address {
  street: string;
  outdoor_number: string;
  interior_number: string;
  neighborhood: string;
  postalcode: string;
  city: string;
  state: { name: string };
}

export const getAddressLine = (address: Address): string => {
  let addressLine = '';

  if (address.interior_number !== '') {
    addressLine = `${address.street} #${address.outdoor_number} Int. ${address.interior_number}, Col. ${address.neighborhood}, C.P. ${address.postalcode}, ${address.city}, ${address.state.name}`;
  } else {
    addressLine = `${address.street} #${address.outdoor_number}, Col. ${address.neighborhood}, C.P. ${address.postalcode}, ${address.city}, ${address.state.name}`;
  }

  return addressLine;
};
