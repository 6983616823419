import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import states from 'src/app/data/states.json';
import countries from 'src/app/data/countries.json';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EncryptManagerService } from '@ignacioruben7/shops-encrypt-angular';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { environment } from '../../../../environments/environment.prod';
import { Title } from '@angular/platform-browser';
import { AnimationSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import regimenes from 'src/app/data/regimenes.json';
import usosFacturas from 'src/app/data/usosCFDI.json';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageOneAddressComponent } from '../manage-one-address/manage-one-address.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `.btn-click {
      color: var(--colorThemePrimary)
    }`,
    `.btn-click2 {
      background: var(--colorThemePrimary);
      color: white;
      text-transform: none
    }`,
  ]
})
export class CheckoutComponent implements OnInit {

  public estadosForm: FormGroup;
  public paisesForm: FormGroup;
  private direccionLocal;
  public calle = '';
  public no_exterior = '';
  public no_interior = '';
  public postal_code = '';
  public ciudad = '';
  public municipio = '';
  public colonia = '';
  public recibe = '';
  public telefono_recibe = '';
  public entre_calle_1 = '';
  public entre_calle_2 = '';
  public indicaciones_extra = '';
  public estados = [];
  public paises = countries.countries;
  public estadoSeleccionado;
  public paisSeleccionado = '+52';
  public countryCode = 'MX';
  public flagPais = '🇲🇽';
  public shippingPrice = 0;
  private quotation_id = '';
  public quotation = null;
  public calculandoEnvio = false;
  public products = [];
  public total = 0;
  public subtotal = 0;
  public showDeliveryAddress = false;
  public showDeliveryShop = false;
  public showOrderReview = false;
  public showAccount = true;
  public actual_step = 1;
  public purchaseOption = '';
  public showIsHomeDelivery = false;
  public deliveryOption = '';
  public listOfStores = [];
  private storesInTest = false;
  public storeSelected = '';
  public showPaymentMethods = false;
  private listOfPayments = [];
  public listOfPayments2 = [];
  public paymentMethodSelected;
  public colorTheme = 'white';
  public colorRgb = '';
  private configWorker: any;
  private tokenCard = '';
  private chargeId = '';

  public hasAccount = false;

  // BUTTON PAY
  public textButton = 'CONFIRMAR COMPRA';
  public bgButtonPay = 'white';
  public disabledButtonPay = false;
  private btnPayValid = false;
  private requestCorrect = false;
  public durationAnimation = 10000;
  public animationSettings : AnimationSettingsModel = { duration: this.durationAnimation, easing: 'linear' };

  // COUPON
  public coupon = {
    code: '',
    amount: 0,
    validation_id: '',
    type: ''
  }
  public showOptionCoupon = true;
  public showCoupon = false;
  public validatingCoupon = false;
  public textCoupon = 'APLICAR';
  public bgButtonCoupon = 'white';
  public disabledButtonCoupon = false;
  private requestCorrect2 = false;
  public durationAnimation2 = 10000;
  public animationSettings2 : AnimationSettingsModel = { duration: this.durationAnimation, easing: 'linear' };


  // COMENTARIOS EXTRA
  public extraComments = '';

  // PRODUCTS
  public showProductsOnOrder = false;

  private isProcessOrder = false;

  public subtotalCurrency = '';

  // FACTURACION
  public mostrarFacturacion = false;
  public razon_social = '';
  public zip_code = '';
  public rfc = '';
  public regimenesFiscales = regimenes;
  public regimenSeleccionado = '';
  public usosCFDI = [];
  public usoCFDISeleccionado = '';
  public regimenForm: FormGroup;
  public usoForm: FormGroup;

  public myAddresses = [] as any;
  private addressSelected = '';
  private serviceId = '';
  public change_customer_address = true;
  private infoService = null;
  private token = null;


  constructor(private modalService: NgbModal, private spinner: NgxSpinnerService, private service: CustomStoreService, private styling: StyleManagerService, private cart: CartServiceService, private fb: FormBuilder, public productService: ProductServiceService, private auth: ShopsAuthAngularService, private toastrService: ToastrService, private router: Router, private api: RestApiServiceService, private encryption: EncryptManagerService, private titleService: Title) {
    this.initWorkers();
    const token = this.auth.getToken();
    this.token = token;
    // this.configWorker.postMessage({request: 'getListOfPayments'});
    // this.configWorker.postMessage({request: 'getListOfStores'});
    this.configWorker.onmessage = ({data}) => {
      let estado = data.code;
      let quote = data.quote;
      let quotationId = data.quotation_id;
      let stores = data.stores;
      let payments = data.payments;
      if(estado === 200){
        if(quote !== undefined){
          this.toastrService.clear();
          this.quotation = quote;
          if(quote === null){
            if(this.listOfStores.length > 0){
              this.toastrService.warning('Prueba con otro código postal o recoger en sucursal', 'Sin cubertura');
            } else {
              this.toastrService.warning('Prueba con otro código postal o ponte en contacto con nosotros', 'Sin cubertura');
            }
            this.postal_code = '';
          } else {
            this.quotation_id = quotationId;
          }
          if(this.deliveryOption === 'home'){
            this.shippingPrice = quote;
            this.total = this.subtotal + this.shippingPrice - this.coupon.amount;
          }
        } else if(stores !== undefined){
          if(!this.storesInTest){
            this.listOfStores = stores;
            sessionStorage.setItem('bGlzdE9mU3RvcmVz', btoa(JSON.stringify(this.listOfStores)));
          }
          if(token !== null){
            this.purchaseOption = 'account';
            this.hasAccount = true;
            this.verifyStep();
          }
        } else if(payments !== undefined){
          // sessionStorage.setItem('bGlzdE9mUGF5bWVudHM=', btoa(JSON.stringify(payments)));
          // this.listOfPayments = [];
          // this.listOfPayments = [...payments];
          // if(this.deliveryOption !== ''){
          //   this.selectOption(this.deliveryOption, 'Delivery', true);
          // }
        }
      } else {
        if(estado === 402){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === 403){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === 404){
          this.toastrService.warning(data.message);
          if(quote !== undefined){
            this.shippingPrice = quote;
            this.total = this.subtotal + this.shippingPrice - this.coupon.amount;
          }
          if(quote === null){
            this.postal_code = '';
          }
        } else if(estado === 405){
          this.toastrService.warning(data.message);
          if(quote !== undefined){
            this.shippingPrice = quote;
            this.total = this.subtotal + this.shippingPrice - this.coupon.amount;
          }
          if(quote === null){
            this.postal_code = '';
          }
        } else if(estado === 500){
          this.toastrService.error(data.message);
        } else if(estado === 429){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.error('Error inesperado :(');
        }
      }
    };
    // this.getPickupPoints(token);
    // if(token !== null){
    //   this.purchaseOption = 'account';
    //   this.hasAccount = true;
    //   this.verifyStep();
    // }
    // this.getAddress(token);
    // const direcciones = sessionStorage.getItem('Z2V0TXlBZGRyZXNzZXM=');
    // if(direcciones){
    //   this.cart.updateAddress(JSON.parse(direcciones));
    // } else {
    //   this.getAddress(token);
    // }

    this.cart.currentQuotation.subscribe((response) => {
      if(response !== this.quotation){
        if(response){
          this.quotation_id = response.quotation_id;
          this.quotation = response.quote;
        } else {
          this.quotation = null;
          this.quotation_id = '';
          // if(this.listOfStores.length > 0){
          //   this.toastrService.warning('Prueba con otro código postal o recoger en sucursal', 'Sin cubertura');
          // } else {
          //   this.toastrService.warning('Prueba con otro código postal o ponte en contacto con nosotros', 'Sin cubertura');
          // }
        }
        if(this.deliveryOption === 'home'){
          if(this.quotation){
            this.shippingPrice = this.quotation;
            this.total = this.subtotal + this.shippingPrice - this.coupon.amount;
          }
        }
      }
    })

  //   this.estadosForm = this.fb.group({
  //     estado: [null]
  //   });
  //   this.regimenForm = this.fb.group({
  //     regimen: [null]
  //   });

  //   this.usoForm = this.fb.group({
  //     uso: [null]
  //   });
  //   this.paisesForm = this.fb.group({
  //     pais: [null]
  //   });
  //   const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
  //   this.colorTheme = colorTheme;
  //   this.bgButtonPay = colorTheme;
  //   this.bgButtonCoupon = colorTheme;
  }

  async getPickupPoints(token){
    try {
      const pickups = await this.api.getPickupPoints(token, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
      if(pickups.code !== 200){
        this.toastrService.warning(pickups.message, `Error ${pickups.code}`);
      } else {
        this.listOfStores = pickups.pickups;
        this.purchaseOption = 'account';
        this.hasAccount = true;
        this.verifyStep();
      }
    } catch (error) {

    }
  }

  async getAddress(token){
    try {
      const address = await this.api.getMyAddresses(token, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
      if(address.code !== 200){
          this.toastrService.warning(address.message, `Error ${address.code}`);
        } else {
          this.cart.updateAddress(address.addresses);
          this.selectAddress(0);
        }
    } catch (error) {

    }
  }

  addNewAddress(){
    const modalRef = this.modalService.open(ManageOneAddressComponent, {size: 'xl'});
    modalRef.componentInstance.product = null;
    modalRef.componentInstance.src = 'checkout';
  }

  ngOnInit() {
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    window.scroll(0,0);
    this.getAddress(this.token);
    this.getPickupPoints(this.token);
    const infoSources = sessionStorage.getItem('cGF5bWVudFNvdXJjZXM=');
    if(infoSources){
      this.listOfPayments = JSON.parse(infoSources);
      this.listOfPayments = this.listOfPayments.map((source) => {
        return {
          ...source,
          ...{
            id: source.method_type
          }
        }
      })
    }

    const infoBilling = sessionStorage.getItem('ZGF0b3NGYWN0dXJhY2lvbg==');
    if(infoBilling !== null){
      this.mostrarFacturacion = true;
    }
    if(localStorage.getItem('SU5GTy1FU1RBRE9T') !== null){
      this.estados = JSON.parse(atob(localStorage.getItem('SU5GTy1FU1RBRE9T')));
    } else {
      this.estados = states.states;
    }
    this.paises.sort((a, b) => {
      if (a.dial_code < b.dial_code) {
        return -1;
      } else if (a.dial_code > b.dial_code) {
        return 1;
      }
      return 0;
    });

    this.cart.currentCart.subscribe((response)=> {
      if(response){
        let counter = 0;
        this.products = response;
        for(let i = 0; i < this.products.length; i++){
          if(counter === 0){
            this.subtotalCurrency = this.products[i].currency;
          }
          if(this.products[i].currency !== this.subtotalCurrency){
            this.subtotalCurrency = '';
          }
        }
      } else {
        this.products = [];
        this.router.navigate(['/carrito']);
      }
    });

    this.cart.currentAddresses.subscribe((response) => {
      this.myAddresses = response.map((address) => {
        return {
          ...address,
          ...{
            selected: false
          }
        }
      });
      if(this.myAddresses.length > 0){
        this.myAddresses[0].selected = true;
        this.addressSelected = this.myAddresses[0].id;
        // cotizar
        // this.cart.updateQuotation();
        this.cart.updateQuotation();
        if(this.deliveryOption === 'home'){
          setTimeout(()=> {
            // this.generateQuote();
            this.generateQuotev2(this.myAddresses[0]);
          }, 300);
        }
      }
    });

    if(this.products.length === 0){
      const infoOrder = sessionStorage.getItem('b3JkZXJTdWNjZXNz');
      if(infoOrder === null){
        this.router.navigate(['/carrito']);
      }
    }

    for(let i = 0; i < this.products.length; i++){
      let product = this.products[i];
      if(!product.has_wholesale_price && product.minimum_purchase >=0 && !product.has_discount){
        this.subtotal = this.subtotal + (product.normal_price * product.quantity);
      } else if(!product.has_wholesale_price && product.minimum_purchase >=0 && product.has_discount){
        this.subtotal = this.subtotal + ((product.normal_price * ((100 - product.discount)/100)) * product.quantity);
      } else if(product.has_wholesale_price && product.quantity >= product.minimum_purchase && !product.has_discount){
        this.subtotal = this.subtotal + (product.wholesale_price * product.quantity);
      } else if(product.has_wholesale_price && product.quantity >= product.minimum_purchase && product.has_discount){
        this.subtotal = this.subtotal + ((product.wholesale_price * ((100 - product.discount)/100)) * product.quantity);
      } else if(product.has_wholesale_price && product.quantity < product.minimum_purchase && !product.has_discount){
        this.subtotal = this.subtotal + (product.normal_price * product.quantity);
      } else if(product.has_wholesale_price && product.quantity < product.minimum_purchase && product.has_discount){
        this.subtotal = this.subtotal + ((product.normal_price * ((100 - product.discount)/100)) * product.quantity);
      }
    }
    this.total = this.shippingPrice + this.subtotal - this.coupon.amount;

    let nombres = '';
    let apellidos = '';
    if(sessionStorage.getItem('Tm9tYnJlIGNsaWVudGU=') !== null){
      nombres = sessionStorage.getItem('Tm9tYnJlIGNsaWVudGU=');
    }
    if(sessionStorage.getItem('QXBlbGxpZG9zIGNsaWVudGU=') !== null){
      apellidos = sessionStorage.getItem('QXBlbGxpZG9zIGNsaWVudGU=');
    }
    this.recibe = `${nombres} ${apellidos}`.trim();
    if (sessionStorage.getItem('VGVsw6lmb25vIGNsaWVudGU=') !== null) {
      const telefono = sessionStorage.getItem('VGVsw6lmb25vIGNsaWVudGU=');
      if(telefono.length > 10){
        this.telefono_recibe = `${telefono.substr(telefono.length - 10)}`.trim();
        this.paisSeleccionado = telefono.substr(0, telefono.length - 10);
        const filtrarFlag = this.paises.filter((pais) => {
          pais.dial_code === this.paisSeleccionado;
        });
        if(filtrarFlag.length > 0){
          this.flagPais = filtrarFlag[0].flag;
          this.countryCode = filtrarFlag[0].code;
        }
      } else if(telefono.length === 10){
        this.telefono_recibe = `${telefono.substr(telefono.length - 10)}`.trim();
      }
    }

    if(sessionStorage.getItem('Q3VzdG9tZXIgZGlyZWN0aW9u') !== null && sessionStorage.getItem('Q3VzdG9tZXIgZGlyZWN0aW9u') !== undefined){
      this.direccionLocal = JSON.parse(sessionStorage.getItem('Q3VzdG9tZXIgZGlyZWN0aW9u'));
      this.calle = this.direccionLocal.street;
      this.no_exterior = this.direccionLocal.outdoor_number;
      this.no_interior = this.direccionLocal.interior_number;
      this.postal_code = this.direccionLocal.postal_code;
      const stateKey = this.direccionLocal.state_key;
      const estadosFiltrados = this.estados.filter((estado) => {
        return estado.clave === stateKey;
      });
      if(estadosFiltrados.length === 0){
        this.estadoSeleccionado = undefined;
      } else {
        this.estadoSeleccionado = estadosFiltrados[0];
      }
      this.ciudad = this.direccionLocal.city;
      this.municipio = this.direccionLocal.town;
      this.colonia = this.direccionLocal.suburb;
      this.entre_calle_1 = this.direccionLocal.between_streets_1;
      this.entre_calle_2 = this.direccionLocal.between_streets_2;
      this.indicaciones_extra = this.direccionLocal.indications;
    }

    this.checkLocalStorage();
    setTimeout(() => {
      const infoService = this.service.infoService.getValue();
      if(infoService){
        if(typeof infoService.customer_edit_address === 'boolean'){
          this.change_customer_address = infoService.customer_edit_address;
        }
      }
    }, 1000);
    // this.checkPaymentSources();

    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  selectAddress(pos){
    const findBefore = this.myAddresses.findIndex((add) => add.selected);
    this.myAddresses[findBefore].selected = false;
    this.myAddresses[pos].selected = true;
    this.addressSelected = this.myAddresses[pos].id;
    // cotizar
    this.cart.updateQuotation();
    this.generateQuotev2(this.myAddresses[pos]);
  }

  generateQuotev2(infoAddress){
    const token = this.auth.getToken();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    this.cart.generateQuote(infoAddress, decodedToken);
  }

  requireInvoice(){
    this.toastrService.clear();
    const infoBilling = sessionStorage.getItem('ZGF0b3NGYWN0dXJhY2lvbg==');
    if(infoBilling !== null){
      this.mostrarFacturacion = !this.mostrarFacturacion;
    } else {
      this.toastrService.warning('Debes de ingresar primero tus datos de facturación');
      setTimeout(()=> {
        this.router.navigate(['', 'perfil'], { queryParams: { menu: 'facturacion' }, queryParamsHandling: 'merge' });
      }, 100);
    }
  }

  checkLocalStorage(){
    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Confirmar compra`;
    // } else {
    //   titleHeader = `LocLog Shops | Confirmar compra`;
    // }
    // this.titleService.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.bgButtonPay = response.color;
          this.bgButtonCoupon = response.color;
          const rgb = this.hexToRgb(this.colorTheme);
          this.colorRgb = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
        }
        const titleHeader = response.title ? `${response.title} | Confirmar compra`:'LocLog Shops | Confirmar compra';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.serviceId = response.public;
        this.estadosForm = this.fb.group({
          estado: [null]
        });
        this.regimenForm = this.fb.group({
          regimen: [null]
        });

        this.usoForm = this.fb.group({
          uso: [null]
        });
        this.paisesForm = this.fb.group({
          pais: [null]
        });
        this.regimenesFiscales = this.regimenesFiscales.sort((a,b)=> a.nombre.localeCompare(b.nombre));
        this.regimenForm.valueChanges.subscribe(val => {
          if(val.regimen !== undefined){
            if(val.regimen !== ''){
              const findRegimen = this.regimenesFiscales.findIndex((regimen) => regimen.clave === val.regimen);
              if(findRegimen !== -1){
                const persona = this.regimenesFiscales[findRegimen].persona;
                this.usosCFDI = [];
                if(persona === 'fisica'){
                  this.usosCFDI = usosFacturas.filter((uso) => uso.fisica).sort((a,b)=> a.nombre.localeCompare(b.nombre));
                } else {
                  this.usosCFDI = usosFacturas.filter((uso) => uso.moral).sort((a,b)=> a.nombre.localeCompare(b.nombre));
                }
                this.usoCFDISeleccionado = '';
              } else {
                this.toastrService.warning('No se encontraron usos CFDI para el regimen seleccionado', 'Usos CFDI');
              }
            }
          }
        });
      }
    });
    // this.configWorker.postMessage({request: 'getListOfPayments'});
    // this.service.currentPayments.subscribe((payments) => {
    //   if(payments){
    //     this.listOfPayments = [];
    //     this.listOfPayments = payments;
    //     if(this.deliveryOption !== ''){
    //       this.selectOption(this.deliveryOption, 'Delivery', true);
    //     }
    //   }
    // });
    // const token = this.auth.getToken();
    // this.service.currentStores.subscribe((stores) => {
    //   if(stores){
    //     if(!this.storesInTest){
    //       this.listOfStores = stores;
    //       sessionStorage.setItem('bGlzdE9mU3RvcmVz', btoa(JSON.stringify(this.listOfStores)));
    //     }
    //     // if(token !== null){
    //     //   this.purchaseOption = 'account';
    //     //   this.hasAccount = true;
    //     //   // this.verifyStep();
    //     // }
    //   }
    // })
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'calle') {
      this.calle = valor;
    } else if (idInput === 'no_exterior') {
      this.no_exterior = valor;
    } else if (idInput === 'no_interior') {
      this.no_interior = valor;
    } else if (idInput === 'postal_code') {
      var regex = /^\d*$/;
      const validacion = regex.test(valor.trim());
      this.quotation = null;
      if(validacion){
        this.postal_code = valor.trim();
        if(this.postal_code.length === 5 ){
          // this.generateQuote();
      } else {
        this.shippingPrice = 0;
        this.total = this.subtotal + this.shippingPrice  - this.coupon.amount;
      }
      } else {
        event.target.value = this.postal_code;
      }
    } else if (idInput === 'ciudad') {
      this.ciudad = valor;
    } else if (idInput === 'municipio') {
      this.municipio = valor;
    } else if (idInput === 'colonia') {
      this.colonia = valor;
    } else if (idInput === 'entre_calle_1') {
      this.entre_calle_1 = valor;
    } else if (idInput === 'entre_calle_2') {
      this.entre_calle_2 = valor;
    } else if (idInput === 'indicaciones_extra') {
      this.indicaciones_extra = valor;
    } else if (idInput === 'recibe') {
      this.recibe = valor;
    } else if (idInput === 'telefono_recibe') {
      var regex = /^\d*$/;
      const validacion = regex.test(valor.trim());
      if(validacion){
        if(valor.trim().length <= 10){
          this.telefono_recibe = valor.trim();
          if(this.telefono_recibe.length === 10){
            if(this.postal_code !== undefined){
              if(this.postal_code.length === 5 && this.shippingPrice === 0){
                // this.generateQuote();
              }
            }
          }
        } else {
          event.target.value = this.telefono_recibe;
        }
      } else {
        event.target.value = this.telefono_recibe;
      }
    } else if (idInput === 'extra_comments') {
      this.extraComments = valor;
    } else if (idInput === 'coupon') {
      this.coupon.code = valor.trim();
      this.coupon.validation_id = '';
      this.coupon.amount = 0;
    } else if (idInput === 'razon_social') {
      this.razon_social = `${valor}`.toUpperCase();
    } else if (idInput === 'zip_code') {
      this.zip_code = valor;
    } else if (idInput === 'rfc') {
      this.rfc =  `${valor}`.toUpperCase();
    }
  }

  // generateQuote(){
  //   let peso = 0;
  //   this.products.forEach((product) => {
  //     peso+=product.weight;
  //   });
  //   if(this.recibe.trim() === ''){
  //     this.toastrService.warning('Ingrese nombre de quién recibirá su pedido');
  //   }
  //   if(this.telefono_recibe.trim() === ''){
  //     this.toastrService.warning('Ingrese teléfono de contacto');
  //   }
  //   if(this.calle.trim() === ''){
  //     this.toastrService.warning('Ingrese nombre de la calle');
  //   }
  //   if(this.no_exterior.trim() === ''){
  //     this.toastrService.warning('Ingrese número exterior');
  //   }
  //   if(this.postal_code.trim().length !== 5){
  //     this.toastrService.warning('Código postal no válido');
  //   }
  //   // if(this.ciudad.trim() === ''){
  //   //   this.toastrService.warning('Ingrese ciudad');
  //   // }
  //   if(this.estadoSeleccionado === undefined){
  //     this.toastrService.warning('Seleccione un estado');
  //   }

  //   if(this.recibe.trim() !== '' && this.telefono_recibe.trim() !== '' && this.calle.trim() !== '' &&
  //   this.no_exterior.trim() !== '' && this.postal_code.trim().length === 5 &&
  //   this.estadoSeleccionado !== undefined){
  //     let publicKey = this.serviceId;
  //     // const key = sessionStorage.getItem('cHVibGlj');
  //     // if(key !== null){
  //     //   publicKey = this.serviceId;
  //     // }
  //     const quoteObject = {
  //       service: publicKey,
  //       destination: {
  //         name: this.recibe,
  //         phone: `${this.paisSeleccionado}${this.telefono_recibe}`,
  //         street: `${this.calle}`,
  //         number: `${this.no_exterior}`,
  //         city: this.ciudad,
  //         state: this.estadoSeleccionado.code,
  //         postal_code: this.postal_code,
  //         country_code: this.countryCode
  //       },
  //       parcel: {
  //         weight: peso,
  //         weight_unit: 'kg'
  //       },
  //       amount: this.subtotal
  //     }
  //     this.configWorker.postMessage({request: 'getQuoteShipment', body: quoteObject});
  //   }
  // }

  selectDropdown(country, type){
    if(type === 'country'){
      this.paisSeleccionado = country.dial_code;
      this.flagPais = country.flag;
      this.countryCode = country.code;
    } else if(type === 'state'){
      this.estadoSeleccionado = country;
    }
  }

  goTo(route){
    this.router.navigate(route);
  }

  filtrarEstado(key: string ) {
    if(this.estadoSeleccionado !== ''){
      return this.estados.filter(object => {
        return object.clave === key;
      });
    } else {
      return undefined;
    }
  }

  selectOption(option, type, local){
    if(type === 'Purchase'){
      this.purchaseOption = option;
    } else if(type === 'Delivery'){
      this.deliveryOption = option;
      this.storeSelected = '';
      this.shippingPrice = 0;
      this.coupon.amount = 0;
      this.coupon.validation_id = '';
      this.quotation_id = '';
      this.coupon.type = '';
      this.total = this.subtotal + this.shippingPrice  - this.coupon.amount;
      if(option === 'home'){
        this.listOfPayments2 = []
        this.listOfPayments2 = [...this.listOfPayments.filter((payment) => {
          return payment.delivery_type !== 'store' && payment.delivery_type !== 'pickup';
        })];
      } else {
        this.listOfPayments2 = []
        this.listOfPayments2 = [...this.listOfPayments.filter((payment) => {
          return payment.delivery_type !== 'store' && payment.delivery_type !== 'home';
        })]
      }
    }
    if(!local){
      this.verifyStep();
    }
  }

  selectCoupon(){
    this.showCoupon = !this.showCoupon;
  }

  showProducts(){
    this.showProductsOnOrder = !this.showProductsOnOrder;
  }

  validateCoupon(){
    let date = new Date();
    this.toastrService.clear();
    this.animationSettings2.duration = 10000;
    this.durationAnimation2 = 10000;
    this.validatingCoupon = true;
    const token = this.auth.getToken();
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    const decodedToken = helper.decodeToken(token);
    let sidUser = '';
    if (token !== '' && token !== null && isExpired !== true) {
      if (decodedToken.sid !== null && decodedToken.sid !== undefined) {
        sidUser = decodedToken.sid;
      }
    }

    let estado;
    let infoCupon;
    let date2;
    this.api.validarCupon(sidUser, token, this.coupon, this.products, this.quotation_id, this.storeSelected).subscribe((respuesta) => {
      infoCupon = JSON.parse(JSON.stringify(respuesta)).coupon;
      estado = JSON.parse(JSON.stringify(respuesta)).code;
      date2 = new Date();
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
      } else {
      }
      if (err.status === 400) {
        this.toastrService.error('Error 400');
      }
      if (err.status === 401) {
        this.toastrService.warning('No estás autorizado');
      }
      if (err.status === 403) {
        this.toastrService.error(err.error.message);
        date2 = new Date();
        const dif = (date2.getTime() - date.getTime());
        this.animationSettings2.duration = (dif + 500);
        this.durationAnimation2 = (dif + 500);
        this.textCoupon = '<i class="fa fa-exclamation-circle" style="font-weight: 300%;"></i>';
        this.toastrService.clear();
        this.bgButtonCoupon = '#BD362F';
        this.coupon = {
          code: '',
          amount: 0,
          validation_id: '',
          type: ''
        };
        this.showCoupon = true;
        setTimeout(() => {
          this.textCoupon = 'APLICAR';
          this.disabledButtonCoupon = false;
          this.bgButtonCoupon = this.colorTheme;
          this.validatingCoupon = false;
        }, 1500);
      }
      if (err.status === 429) {
        this.toastrService.warning(err.error.message);
      }
      if (err.status === 500) {
        this.toastrService.error('Error 500');
      }
    }, () => {
      if(estado === 200){
        const dif = (date2.getTime() - date.getTime());
        this.animationSettings2.duration = (dif + 500);
        this.durationAnimation2 = (dif + 500);
        this.coupon = infoCupon;
        this.textCoupon = 'APLICAR';
        this.toastrService.clear();
        this.toastrService.success('Tu cupón se aplicó correctamente');
        if(this.coupon.type === 'all'){
          if(this.coupon.amount > this.total){
            this.coupon.amount = this.total;
          }
        } else if(this.coupon.type === 'cart'){
          if(this.coupon.amount > this.subtotal){
            this.coupon.amount = this.subtotal;
          }
        } if(this.coupon.type === 'shipment'){
          if(this.coupon.amount > this.shippingPrice){
            this.coupon.amount = this.shippingPrice;
          }
        }
        this.total = this.subtotal + this.shippingPrice  - this.coupon.amount;
        this.disabledButtonCoupon = false;
        this.bgButtonCoupon = this.colorTheme;
        this.validatingCoupon = false;
        this.coupon.validation_id = '1';
        this.showCoupon = false;
      } else {
        this.toastrService.error('Ocurrió un error inesperado');
      }
    });
  }

  confirmarCompra() {
    const status = this.revisarOrden();
    if(status){
      if(this.shippingPrice >= 0){
        this.verifyStep();
        this.toastrService.clear();
      } else {
        this.toastrService.info('Por favor espere...', 'Cotizando envío')
      }
    } else {
      if(this.deliveryOption === 'home'){
        this.showDeliveryAddress = true;
      } else {
        this.showDeliveryShop = true;
        this.shippingPrice = 0;
        this.total = this.subtotal + this.shippingPrice - this.coupon.amount;
      }
    }
  }

  beginApply(){
    this.textCoupon = '¡Ya casi!';
    this.disabledButtonCoupon = true;
  }

  beginPay(){
    if(this.btnPayValid){
      this.textButton = '¡Ya casi!';
      this.disabledButtonPay = true;
    }
  }

  confirmarOrden(){
    if(this.paymentMethodSelected){
      const isAutomatic = this.paymentMethodSelected.sources && this.paymentMethodSelected.sources.length && this.paymentMethodSelected.sources[0].automatic ? this.paymentMethodSelected.sources[0].automatic : false;
      if(isAutomatic){
        this.toastrService.info('Realice su pago antes de continuar');
        this.animationSettings.duration = 0;
        this.durationAnimation = 0;
      } else {
        this.paymentMethodSelected.source_id = this.paymentMethodSelected.sources[0].id;
        if(this.isProcessOrder){
          this.toastrService.clear();
          this.toastrService.warning('Por favor espere...', '¡Sea paciente!');
        } else {
          this.animationSettings.duration = 15000;
          this.durationAnimation = 15000;
          this.btnPayValid = true;
          this.showOptionCoupon = false;
          this.showCoupon = false;
          this.toastrService.clear();
          this.procesarOrden(this.mostrarFacturacion);
        }
      }
      // if(this.tokenCard === '' && (this.paymentMethodSelected.method === 'debitcard' || this.paymentMethodSelected.method === 'creditcard')){
      //   this.toastrService.warning('Ingresa una tarjeta');
      //   this.animationSettings.duration = 0;
      //   this.durationAnimation = 0;
      // } else {
      //   if(this.isProcessOrder){
      //     this.toastrService.clear();
      //     this.toastrService.warning('Por favor espere...', '¡Sea paciente!');
      //   } else {
      //     this.animationSettings.duration = 15000;
      //     this.durationAnimation = 15000;
      //     this.btnPayValid = true;
      //     this.showOptionCoupon = false;
      //     this.showCoupon = false;
      //     this.toastrService.clear();
      //     this.procesarOrden(this.mostrarFacturacion);
      //   }
      // }
    } else {
      this.toastrService.warning('Seleccione una forma de pago');
      this.animationSettings.duration = 0;
      this.durationAnimation = 0;
      this.paymentMethodSelected = undefined;
    }
    // if(this.paymentMethodSelected === undefined){
    //   this.toastrService.warning('Seleccione una forma de pago');
    //   this.animationSettings.duration = 0;
    //   this.durationAnimation = 0;
    //   this.paymentMethodSelected = undefined;
    // } else if(this.paymentMethodSelected.id === ''){
    //   this.toastrService.warning('Seleccione una forma de pago');
    //   this.animationSettings.duration = 0;
    //   this.durationAnimation = 0;
    //   this.paymentMethodSelected = undefined;
    // } else {
    //   if(this.tokenCard === '' && (this.paymentMethodSelected.method === 'debitcard' || this.paymentMethodSelected.method === 'creditcard')){
    //     this.toastrService.warning('Ingresa una tarjeta');
    //     this.animationSettings.duration = 0;
    //     this.durationAnimation = 0;
    //   } else {
    //     if(this.isProcessOrder){
    //       this.toastrService.clear();
    //       this.toastrService.warning('Por favor espere...', '¡Sea paciente!');
    //     } else {
    //       this.animationSettings.duration = 15000;
    //       this.durationAnimation = 15000;
    //       this.btnPayValid = true;
    //       this.showOptionCoupon = false;
    //       this.showCoupon = false;
    //       this.toastrService.clear();
    //       this.procesarOrden(this.mostrarFacturacion);
    //     }
    //   }
    // }
  }

  validarFacturacion(){
    if(this.mostrarFacturacion){
      const infoBilling = sessionStorage.getItem('ZGF0b3NGYWN0dXJhY2lvbg==');
      if(infoBilling){
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  // procesarOrden(billingInfo){
  //   let userId = '';
  //   let userName = '';
  //   let emailUser = '';
  //   const token = this.auth.getToken();
  //   if(token !== null){
  //     const helper = new JwtHelperService();
  //     const decodedToken = helper.decodeToken(token);
  //     if(decodedToken.sid !== undefined){
  //       userId = decodedToken.sid;
  //     }
  //     if(decodedToken.correo !== undefined){
  //       emailUser = decodedToken.correo;
  //     }
  //     if(decodedToken.correo !== null){
  //       emailUser = decodedToken.correo;
  //     }
  //     if(decodedToken.nombres !== undefined && decodedToken.apellidos !== undefined){
  //       userName = `${decodedToken.nombres} ${decodedToken.apellidos}`;
  //     }
  //   }

  //   let state = null;
  //   if(this.estadoSeleccionado !== undefined){
  //     state = this.estadoSeleccionado;
  //   }

  //   let indicaciones = '';
  //   if(this.entre_calle_1 !== '' && this.entre_calle_2 !== ''){
  //     indicaciones = `Entre ${this.entre_calle_1} y ${this.entre_calle_2}. ${this.indicaciones_extra}`;
  //   } else if(this.entre_calle_1 === '' && this.entre_calle_2 !== ''){
  //     indicaciones = `Cerca de ${this.entre_calle_2}. ${this.indicaciones_extra}`;
  //   } else if(this.entre_calle_1 !== '' && this.entre_calle_2 === ''){
  //     indicaciones = `Cerca de ${this.entre_calle_1}. ${this.indicaciones_extra}`;
  //   }
  //   let destination = null;
  //   let interior = '';
  //   if(this.no_interior !== ''){
  //     interior = `Int. ${this.no_interior}`;
  //   }
  //   if(this.deliveryOption === 'home'){
  //     destination = {
  //       street: this.calle,
  //       outdoor_number: this.no_exterior,
  //       interior_number: this.no_interior,
  //       address_line: `${this.calle} ${this.no_exterior}`,
  //       address_line2: interior,
  //       zipcode: this.postal_code,
  //       neighborhood: this.colonia,
  //       town: this.municipio,
  //       city: this.ciudad,
  //       state: {
  //         key: state.clave,
  //         name: state.nombre,
  //         code: state.code
  //       },
  //       country: this.countryCode,
  //       reference: indicaciones,
  //       between_streets_1: this.entre_calle_1,
  //       between_streets_2: this.entre_calle_2,
  //       indications: this.indicaciones_extra
  //     }
  //   }


  //   let usuario;
  //   if(userId !== ''){
  //     usuario = {
  //       id: userId,
  //       name: userName,
  //       email: emailUser,
  //     }
  //   } else {
  //     usuario = {
  //       id: null
  //     }
  //   }
  //   // const order = {
  //   //   user: usuario,
  //   //   receiver: {
  //   //     name: this.recibe,
  //   //     phone: {
  //   //       lada: this.paisSeleccionado,
  //   //       number: this.telefono_recibe
  //   //     }
  //   //   },
  //   //   destination: destination,
  //   //   info: {
  //   //     quotation_id: this.quotation_id,
  //   //     store_id: this.storeSelected,
  //   //     delivery_type: this.deliveryOption,
  //   //     purchase_type: this.purchaseOption,
  //   //     payment_method: {
  //   //       id: this.paymentMethodSelected.id,
  //   //       method: this.paymentMethodSelected.method,
  //   //       token: this.tokenCard
  //   //     },
  //   //     coupon: {
  //   //       code: this.coupon.code,
  //   //       validation_id: this.coupon.validation_id
  //   //     },
  //   //     extra_comments: this.extraComments
  //   //   },
  //   //   billing: billingInfo,
  //   //   cart_id: cartId
  //   // }
  //   const cartId = sessionStorage.getItem('Y2FydEluZm8=') ? sessionStorage.getItem('Y2FydEluZm8=') : '';

  //   let coupon = this.coupon.validation_id !== '' ? this.coupon : null;
  //   const infoBackorders = sessionStorage.getItem('cHJlb3JkZXJz');
  //   const order = {
  //     cartId: cartId,
  //     shipping_quote:this.quotation_id,
  //     delivery_type:this.deliveryOption,
  //     store_id:this.storeSelected,
  //     paymentMethod: this.paymentMethodSelected.method,
  //     paymentToken: this.tokenCard,
  //     shippingAddress: {
  //       country:this.countryCode,
  //       street:this.calle,
  //       outdoor_number:this.no_exterior,
  //       interior_number:this.no_interior,
  //       neighborhood:this.colonia,
  //       postalcode:this.postal_code,
  //       city:this.ciudad,
  //       state: {
  //           code: state.code,
  //           name: state.nombre
  //         },
  //       reference:indicaciones? indicaciones : "",
  //       indications: this.indicaciones_extra ? this.indicaciones_extra : ""
  //     },
  //     billing: billingInfo,
  //     coupon
  // }

  //   const infoOrder = await this.api.processOrder(order, token);

  //   let estado;
  //   let order_info;
  //   setTimeout(() => {
  //     this.animationSettings.duration = 10000;
  //     this.durationAnimation = 10000;
  //   }, 2000);
  //   // LIMPIAR HISTORIAL BUSQUEDA TIENDA
  //   localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
  //   // LIMPIAR HISTORIAL BUSQUEDA OFERTAS
  //   localStorage.removeItem('bGlzdE9mSXRlbXNPblNhbGU=');
  //   this.isProcessOrder = true;
  //   // this.api.generateOrderV2(JSON.stringify(order), token, userId).subscribe(respuesta => {
  //   //   estado = JSON.parse(JSON.stringify(respuesta)).code;
  //   //   order_info = JSON.parse(JSON.stringify(respuesta)).info;
  //   // },(err: HttpErrorResponse) => {
  //   //   this.isProcessOrder = false;
  //   //   if (err.error instanceof Error) {
  //   //   } else {
  //   //   }
  //   //   if (err.status === 400) {
  //   //     this.toastrService.error('Error 400');
  //   //   }
  //   //   if (err.status === 401) {
  //   //     this.toastrService.error('No estás autorizado para acceder');
  //   //     this.auth.removeToken();
  //   //     this.router.navigate(['/ingresar'], { queryParams: { pendiente_pago: true } });
  //   //   }
  //   //   if (err.status === 403) {
  //   //     this.processOrderAnimation(false, err.error.message, new Date(), err.error.title);
  //   //   }
  //   //   if (err.status === 405) {
  //   //     const itemsWithoutStock = err.error.items;
  //   //     const itemsWithInsufficientStock = err.error.insufficient;
  //   //     if(itemsWithoutStock.length > 0 || itemsWithInsufficientStock.length > 0){
  //   //       let items = [];
  //   //       if(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=') !== null){
  //   //         try {
  //   //           items = JSON.parse(atob(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=')));
  //   //           for(let i = 0; i < itemsWithoutStock.length; i++){
  //   //             const index = items.findIndex((item) => {
  //   //               return item.id === itemsWithoutStock[i];
  //   //             });
  //   //             if(index !== -1){
  //   //               const infoItem = items[index];
  //   //               items.splice(index, 1);
  //   //               localStorage.setItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=', btoa(JSON.stringify(items)));
  //   //               this.productService.shareCarts(items, infoItem, 'carts_remove');
  //   //             }
  //   //           }
  //   //           for(let i = 0; i < itemsWithInsufficientStock.length; i++){
  //   //             const index2 = items.findIndex((item) => {
  //   //               return item.id === itemsWithInsufficientStock[i].id;
  //   //             });
  //   //             if(index2 !== -1){
  //   //               items[index2].quantity = itemsWithInsufficientStock[i].available;
  //   //               if(items[index2].attribute_types === 'attributes'){
  //   //                 items[index2].stock = itemsWithInsufficientStock[i].available;
  //   //               } else {
  //   //                 items[index2].variation.stock = itemsWithInsufficientStock[i].available;
  //   //               }
  //   //               localStorage.setItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=', btoa(JSON.stringify(items)));
  //   //               this.productService.shareCarts(items, items[index2], 'carts_update');
  //   //             }
  //   //           }
  //   //           window.location.pathname = '/carrito';
  //   //         } catch (error) {
  //   //           this.productService.cleanCart();
  //   //         }
  //   //       } else {
  //   //         this.productService.cleanCart();
  //   //       }
  //   //     } else {
  //   //       this.productService.cleanCart();
  //   //     }
  //   //     this.processOrderAnimation(false, err.error.message, new Date(), err.error.title);
  //   //   }
  //   //   if (err.status === 406) {
  //   //     this.processOrderAnimation(false, err.error.message, new Date(), err.error.title);
  //   //     this.coupon = {
  //   //       code: '',
  //   //       amount: 0,
  //   //       validation_id: '',
  //   //       type: ''
  //   //     };
  //   //     this.showCoupon = false;
  //   //   }
  //   //   if (err.status === 429) {
  //   //     this.toastrService.warning(err.error.message);
  //   //   }
  //   //   if (err.status === 500) {
  //   //     this.toastrService.error('Error 500');
  //   //   }
  //   // }, () => {
  //   //   if(estado === 201){
  //   //     this.guardarOrden(destination, this.deliveryOption, order_info, billingInfo, order);
  //   //     this.processOrderAnimation(true, 'Tu pedido se realizó correctamente', new Date(), 'Pedido realizado');
  //   //   } else {
  //   //     this.toastrService.error('Ocurrió un error inesperado');
  //   //     this.isProcessOrder = false;
  //   //   }
  //   // });
  // }
  async procesarOrden(billingInfo){
    const token = this.auth.getToken();

    let state = null;
    if(this.estadoSeleccionado !== undefined){
      state = this.estadoSeleccionado;
    }

    let indicaciones = '';
    if(this.entre_calle_1 !== '' && this.entre_calle_2 !== ''){
      indicaciones = `Entre ${this.entre_calle_1} y ${this.entre_calle_2}. ${this.indicaciones_extra}`;
    } else if(this.entre_calle_1 === '' && this.entre_calle_2 !== ''){
      indicaciones = `Cerca de ${this.entre_calle_2}. ${this.indicaciones_extra}`;
    } else if(this.entre_calle_1 !== '' && this.entre_calle_2 === ''){
      indicaciones = `Cerca de ${this.entre_calle_1}. ${this.indicaciones_extra}`;
    }
    const cartId = sessionStorage.getItem('Y2FydEluZm8=') ? sessionStorage.getItem('Y2FydEluZm8=') : '';

    let coupon = this.coupon.validation_id !== '' ? this.coupon : null;
    const order = {
      cartId: cartId,
      shipping_quote:this.quotation_id,
      delivery_type:this.deliveryOption,
      store_id: this.deliveryOption !== 'home' ? this.storeSelected : "",
      sourceId: this.paymentMethodSelected.source_id ? this.paymentMethodSelected.source_id : '',
      paymentMethod: this.paymentMethodSelected.method_type,
      paymentToken: this.chargeId,
      shippingAddress: this.deliveryOption === 'home' ? this.addressSelected : '',
      need_invoice: billingInfo,
      extra_comments: this.extraComments,
      coupon
    }
    /** spinner starts on init */
    this.spinner.show();

    const infoOrder = await this.api.processOrder(order, token, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    this.spinner.hide();
    if(infoOrder){
      if(infoOrder.code !== 200){
        this.toastrService.warning(infoOrder.message, `Error ${infoOrder.code}`);
        if(infoOrder.message === 'El carrito no existe o ya fue colocado' && infoOrder.code === 404){
          this.cart.cleanCart();
          this.router.navigate(['', 'carrito']);
        } else if(infoOrder.code === 401){
          this.cart.cleanCart();
          this.router.navigate(['', 'ingresar']);
        }
      } else {
        this.toastrService.clear();
        this.toastrService.success('Tu pedido se realizó correctamente');
        sessionStorage.setItem('b3JkZXJTdWNjZXNz', 'true');
        this.cart.getCartItems();
        // this.guardarOrden(destination, this.deliveryOption, order_info, billingInfo, order);
        // this.processOrderAnimation(true, 'Tu pedido se realizó correctamente', new Date(), 'Pedido realizado');
        this.router.navigate(['', 'perfil'], { queryParams: { menu: 'pedidos' }, queryParamsHandling: 'merge' });
      }
    } else {
      this.toastrService.error('Ocurrió un error inesperado');
    }

    // let estado;
    // let order_info;

    // LIMPIAR HISTORIAL BUSQUEDA TIENDA
    // localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
    // // LIMPIAR HISTORIAL BUSQUEDA OFERTAS
    // localStorage.removeItem('bGlzdE9mSXRlbXNPblNhbGU=');
    // this.isProcessOrder = true;
    // this.api.generateOrderV2(JSON.stringify(order), token, userId).subscribe(respuesta => {
    //   estado = JSON.parse(JSON.stringify(respuesta)).code;
    //   order_info = JSON.parse(JSON.stringify(respuesta)).info;
    // },(err: HttpErrorResponse) => {
    //   this.isProcessOrder = false;
    //   if (err.error instanceof Error) {
    //   } else {
    //   }
    //   if (err.status === 400) {
    //     this.toastrService.error('Error 400');
    //   }
    //   if (err.status === 401) {
    //     this.toastrService.error('No estás autorizado para acceder');
    //     this.auth.removeToken();
    //     this.router.navigate(['/ingresar'], { queryParams: { pendiente_pago: true } });
    //   }
    //   if (err.status === 403) {
    //     this.processOrderAnimation(false, err.error.message, new Date(), err.error.title);
    //   }
    //   if (err.status === 405) {
    //     const itemsWithoutStock = err.error.items;
    //     const itemsWithInsufficientStock = err.error.insufficient;
    //     if(itemsWithoutStock.length > 0 || itemsWithInsufficientStock.length > 0){
    //       let items = [];
    //       if(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=') !== null){
    //         try {
    //           items = JSON.parse(atob(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=')));
    //           for(let i = 0; i < itemsWithoutStock.length; i++){
    //             const index = items.findIndex((item) => {
    //               return item.id === itemsWithoutStock[i];
    //             });
    //             if(index !== -1){
    //               const infoItem = items[index];
    //               items.splice(index, 1);
    //               localStorage.setItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=', btoa(JSON.stringify(items)));
    //               this.productService.shareCarts(items, infoItem, 'carts_remove');
    //             }
    //           }
    //           for(let i = 0; i < itemsWithInsufficientStock.length; i++){
    //             const index2 = items.findIndex((item) => {
    //               return item.id === itemsWithInsufficientStock[i].id;
    //             });
    //             if(index2 !== -1){
    //               items[index2].quantity = itemsWithInsufficientStock[i].available;
    //               if(items[index2].attribute_types === 'attributes'){
    //                 items[index2].stock = itemsWithInsufficientStock[i].available;
    //               } else {
    //                 items[index2].variation.stock = itemsWithInsufficientStock[i].available;
    //               }
    //               localStorage.setItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=', btoa(JSON.stringify(items)));
    //               this.productService.shareCarts(items, items[index2], 'carts_update');
    //             }
    //           }
    //           window.location.pathname = '/carrito';
    //         } catch (error) {
    //           this.productService.cleanCart();
    //         }
    //       } else {
    //         this.productService.cleanCart();
    //       }
    //     } else {
    //       this.productService.cleanCart();
    //     }
    //     this.processOrderAnimation(false, err.error.message, new Date(), err.error.title);
    //   }
    //   if (err.status === 406) {
    //     this.processOrderAnimation(false, err.error.message, new Date(), err.error.title);
    //     this.coupon = {
    //       code: '',
    //       amount: 0,
    //       validation_id: '',
    //       type: ''
    //     };
    //     this.showCoupon = false;
    //   }
    //   if (err.status === 429) {
    //     this.toastrService.warning(err.error.message);
    //   }
    //   if (err.status === 500) {
    //     this.toastrService.error('Error 500');
    //   }
    // }, () => {
    //   if(estado === 201){
    //     this.guardarOrden(destination, this.deliveryOption, order_info, billingInfo, order);
    //     this.processOrderAnimation(true, 'Tu pedido se realizó correctamente', new Date(), 'Pedido realizado');
    //   } else {
    //     this.toastrService.error('Ocurrió un error inesperado');
    //     this.isProcessOrder = false;
    //   }
    // });
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  processOrderAnimation(requestCorrect, message, date, titulo){
    this.toastrService.clear();
    if(!requestCorrect){
      if(titulo !== undefined){
        this.toastrService.error(message, titulo);
      } else {
        this.toastrService.error(message);
      }
    }
    const date2 = new Date();
    const dif = (date2.getTime() - date.getTime());
    this.animationSettings.duration = (dif + 500);
    this.durationAnimation = (dif + 500);
    if(requestCorrect){
      this.textButton = '<i class="fa fa-check-circle" style="font-weight: 300%;"></i>';
      this.toastrService.success(message);
      this.btnPayValid = false;
    } else {
      this.textButton = '<i class="fa fa-exclamation-circle" style="font-weight: 300%;"></i>';
      this.bgButtonPay = '#BD362F';
      this.paymentMethodSelected = undefined;
      this.showOptionCoupon = true;
      this.btnPayValid = false;
    }
    setTimeout(() => {
      this.textButton = 'CONFIRMAR COMPRA';
      this.disabledButtonPay = false;
      this.bgButtonPay = this.colorTheme;
    }, 2000);
  }

  guardarOrden(direccion_local, delivery, order_info, billing_info, originalOrder = null){
     const pedidoParseado = {
      order_info: order_info,
      destination: direccion_local,
      delivery_type: delivery
    };
    const telefono = `${originalOrder.receiver.phone.lada}${originalOrder.receiver.phone.number}`;
    sessionStorage.setItem('VGVsw6lmb25vIGNsaWVudGU=', telefono);
    sessionStorage.setItem('UGVkaWRvIGNsaWVudGU=', JSON.stringify(pedidoParseado));
    if(billing_info !== null){
      sessionStorage.setItem('ZGF0b3NGYWN0dXJhY2lvbg==', JSON.stringify(billing_info));
    }
    if(direccion_local !== null){
      let destination = direccion_local;
      if(direccion_local.postal_code !== undefined && direccion_local.postal_code !== null){
        destination.postal_code = direccion_local.postal_code;
      } else if(direccion_local.zipcode !== undefined && direccion_local.zipcode !== null){
        destination.postal_code = direccion_local.zipcode;
      }
      // delete destination.postal_code;
      destination.state_key = direccion_local.state.key;
      delete destination.state;
      destination.suburb = direccion_local.neighborhood;
      sessionStorage.setItem('Q3VzdG9tZXIgZGlyZWN0aW9u', JSON.stringify(destination));
    }
    this.isProcessOrder = true;
    this.productService.cleanCart();
    if(environment.isB2C){
      setTimeout(()=> {
        this.router.navigate(['','pedido', order_info.id]);
      }, 100);
    } else {
      this.router.navigate(['', 'perfil'], { queryParams: { menu: 'pedidos' }, queryParamsHandling: 'merge' });
    }
  }

  revisarOrden(showToast = true){
    if(this.deliveryOption === 'home'){
      if(this.addressSelected !== ''){
        return true;
      } else {
        if(showToast){
          this.toastrService.warning('Seleccione una direccón');
        }
      }

      return false;
    } else if(this.deliveryOption !== 'home'){
      if(this.storeSelected !== ''){
        return true;
      } else {
        if(showToast){
          this.toastrService.warning('Seleccione una sucursal');
        }
      }
      return false;
    }
  }

  selectStore(store){
    this.coupon.amount = 0;
    this.coupon.validation_id = '';
    this.coupon.type = '';
    if(store.id !== this.storeSelected){
      this.storeSelected = store.id;
      // this.shippingPrice = store.cost_of_shipping;
      this.shippingPrice = store.shipping_amount;
      this.total = this.subtotal + this.shippingPrice  - this.coupon.amount;
    }
    if(this.listOfStores.length === 1){
      // this.verifyStep();
      this.goToConfirmPayment();
    }
  }

  selectPayment(payment){
    if(this.paymentMethodSelected !== undefined){
      if(payment.id !== this.paymentMethodSelected.id){
        this.paymentMethodSelected = payment;
      }
    } else {
      this.paymentMethodSelected = payment;
    }
  }

  verifyStep(){
    if(this.actual_step === 1){
      if(this.purchaseOption === 'account'){
        const token = this.auth.getToken();
        if(token !== null){
          this.actual_step = 2;
          this.showAccount = false;
          this.hasAccount = true;
          if(this.listOfStores.length > 0){
            this.deliveryOption = 'store';
            this.showIsHomeDelivery = true;
          } else {
            this.deliveryOption = 'home';
            this.showIsHomeDelivery = false;
            this.shippingPrice = 0;
            this.verifyStep();
            setTimeout(()=> {
              this.selectOption('home', 'Delivery', true);
            }, 1000);
          }
        } else {
          this.router.navigate(['', 'ingresar'], { queryParams: { pendiente_pago: true } });
        }
      } else {
        if(this.listOfStores.length > 0){
          this.actual_step = 2;
          this.showAccount = false;
          this.showIsHomeDelivery = true;
        } else {
          this.deliveryOption = 'home';
          this.showAccount = false;
          this.showIsHomeDelivery = false;
          this.actual_step = 2;
          // this.selectOption('home', 'Delivery', true);
          setTimeout(()=> {
            this.selectOption('home', 'Delivery', true);
          }, 1000);
        }
      }
    } else if(this.actual_step === 2){
      if(this.deliveryOption === 'home'){
        this.showDeliveryAddress = true;
      } else {
        this.showDeliveryShop = true;
        this.selectStore(this.listOfStores[0]);
      }
      this.showIsHomeDelivery = false;
      this.showOrderReview = true;
      this.actual_step = 3;
      if(this.showDeliveryAddress){
        if(this.myAddresses.length === 1){
          this.verifyStep();
        }
      }
    } else if(this.actual_step === 3){
      if(!this.validarFacturacion()){
        this.toastrService.clear();
        this.toastrService.warning('Llena tu informacion de facturacion');
      } else {
        this.goToConfirmPayment();
      }
    }
  }

  goToConfirmPayment(){
    this.actual_step = 4;
    setTimeout(()=> {
      this.textButton = 'CONFIRMAR COMPRA';
    }, 100);
    this.showPaymentMethods = true;
    if(this.listOfPayments2.length > 0){
      this.paymentMethodSelected = this.listOfPayments2[0];
    }
    this.showDeliveryAddress = false;
    this.showDeliveryShop = false;
    // if(this.deliveryOption === 'home'){
    //   this.showDeliveryAddress = false;
    // } else {
    //   this.showDeliveryShop = false;
    // }
  }

  backStep(actual_step){
    if(actual_step === 1){
      const token = this.auth.getToken();
      if(token === null){
        this.showAccount = true;
        this.actual_step = actual_step;
        this.showIsHomeDelivery = false;
      }
    } else if(actual_step === 2){
      this.showPaymentMethods = false;
      if(this.listOfStores.length > 0){
        this.actual_step = actual_step;
        this.showIsHomeDelivery = true;
        this.showOrderReview = false;
        if(this.deliveryOption === 'home'){
          this.showDeliveryAddress = false;
        } else {
          this.showDeliveryShop = false;
        }
        const token = this.auth.getToken();
      } else {
        this.showIsHomeDelivery = false;
        this.showOrderReview = false;
        if(this.deliveryOption === 'home'){
          this.showDeliveryAddress = false;
        } else {
          this.showDeliveryShop = false;
        }
        this.backStep(1);
      }
    } else if(actual_step === 3){
      this.actual_step = actual_step;
      this.showPaymentMethods = false;
      if(this.deliveryOption === 'home'){
        this.showDeliveryAddress = true;
      } else {
        this.showDeliveryShop = true;
      }
    }
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id, 'color', '#ffffff');
      this.styling.setColor(id, 'background', this.colorTheme);
      this.styling.setColor(id, 'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id, 'color', '#495057');
      this.styling.setColor(id, 'backgroundColor', 'transparent');
      this.styling.setColor(id, 'borderColor', '#ced4da');
    }
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }

  testWithStores() {
    if(confirm("¿Te gustaría hacer la prueba SIN SUCURSALES?")) {
      this.listOfStores = [];
      this.storesInTest = true;
      sessionStorage.removeItem('bGlzdE9mU3RvcmVz');
    }
  }

}
