<app-header></app-header>
<app-navbar></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content" style="margin-top: -60px;">
    <!-- START SECTION SHOP -->
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
                    <div class="product-image">
                        <div class="product_img_box text-center">
                            <img *ngIf="product.images !== undefined" [src]="imgPrincipal" alt="product_img1" (error)="updateUrl()">
                            <img *ngIf="product.images === undefined" src="{{imgPrincipal}}" alt="product_img1">
                        </div>
                        <div *ngIf="product.images !== undefined && allImg.length > 1" id="pr_item_gallery" class="product_gallery_item row">
                            <div class="item col-3" *ngFor="let img of allImg; let position = index;" style="cursor: pointer;">
                                <a (click)="setImg(img.url, position)" class="product_gallery_item" [ngClass]="{'active': position === imgSelected}">
                                    <img [src]="img.url" alt="product_small_img1" (error)="updateUrl2(position)" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="pr_detail">
                        <div class="product_description">
                            <h4 class="product_title">{{product.title}}</h4>
                            <div *ngIf="product.has_discount" class="product_price">
                                <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
                                <del>{{product.retail_price | currency}} {{product.currency}}</del>
                                <div class="on_sale">
                                    <span>{{product.discount}}% descuento</span>
                                </div>
                            </div>
                            <div *ngIf="!product.has_discount" class="product_price">
                                <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price | currency}} {{product.currency}}</span>
                            </div>
                            <br/>
                            <br/>
                            <div class="product_sort_info">
                                <ul>
                                    <li><i class="linearicons-shield-check" [ngStyle]="{'color': colorTheme2}"></i>Pago 100% seguro</li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div class="col-12 row">
                            <div class="cart-product-quantity col-12">
                                <!-- <div *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 2">
                                    <div class="pr_switch_wrap">
                                        <span class="switch_lable" *ngIf="variantSelected.variant1.length === 1">{{variantSelected.variant1_selected.name}}: <b style="text-transform: uppercase;">{{variantSelected.variant1_selected.value_name}}</b></span><br/>
                                        <div ngbDropdown class="product_color_switch" *ngIf="variantSelected.variant1.length > 1" style="margin-top: -20px;">
                                            <button class="btn dash-btn-outline-1 col-12 text-left" id="drop1" (mouseover)="isHover(true, 'drop1')" (mouseleave)="isHover(false, 'drop1')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;">{{variantSelected.variant1_selected.name}}: <b style="text-transform: uppercase;">{{variantSelected.variant1_selected.value_name}}</b><i class="fa fa-chevron-down" style="float: right; margin-top: 2%;"></i></button>
                                            <div ngbDropdownMenu aria-labelledby="drop1" style="max-height: 150px;overflow-y: scroll;">
                                                <button class="text-left" ngbDropdownItem *ngFor="let variation of variantSelected.variant1; let position = index;" [hidden]="variation.value_name === variantSelected.variant1_selected.value_name" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;" (click)="selectVariationLevel1(variation)">{{variation.value_name}}</button>
                                            </div>
                                        </div><br/>
                                        <span class="switch_lable" style="margin-top: -15px;">{{variantSelected.variant2_selected.name}}: <b style="text-transform: uppercase;">{{variantSelected.variant2_selected.value_name}}</b></span><br/>
                                        <div class="product_size_switch" *ngIf="variantSelected.variant2.length > 1" style="margin-top: -5px;">
                                            <span class="ml-1 mr-1 noselect" *ngFor="let variation of variantSelected.variant2; let position = index;" id="variant2_{{position}}" (click)="selectVariationLevel2(variation, position)" style="text-transform: uppercase;">{{variation.combinations[1].value_name}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 1">
                                    <div class="pr_switch_wrap">
                                        <span class="switch_lable" *ngIf="variantSelected.variant1.length === 1">{{variantSelected.variant1_selected.combinations[0].name}}: <b style="text-transform: uppercase;">{{variantSelected.variant1_selected.combinations[0].value_name}}</b></span><br/>
                                        <div ngbDropdown class="product_color_switch" *ngIf="variantSelected.variant1.length > 1" style="margin-top: -20px;">
                                            <button class="btn dash-btn-outline-1 col-12 text-left" id="drop2" (mouseover)="isHover(true, 'drop2')" (mouseleave)="isHover(false, 'drop2')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{variantSelected.variant1_selected.combinations[0].name}}: <b>{{variantSelected.variant1_selected.combinations[0].value_name}}</b><i class="fa fa-chevron-down" style="float: right; margin-top: 2%;"></i></button>
                                            <div ngbDropdownMenu aria-labelledby="drop2" style="max-height: 150px;overflow-y: scroll;">
                                                <button class="text-left" ngbDropdownItem *ngFor="let variation of variantSelected.variant1; let position = index;" [hidden]="variation.combinations[0].value_name === variantSelected.variant1_selected.combinations[0].value_name" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;" (click)="selectVariationLevel1(variation)">{{variation.combinations[0].value_name}}</button>
                                            </div>
                                        </div><br/>
                                    </div>
                                </div>
                                <br *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 2 && variantSelected.variant2.length > 1"/>
                                <div class="quantity" *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 2" style="margin-top: -10px;">
                                    <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', variantSelected)" [disabled]="counter <= 1">
                                    <input (keyup)="listenQuantity($event, variantSelected)" type="text" name="quantity" value="{{counter}}" title="Qty" class="qty" max="{{variantSelected.stock}}" min="1">
                                    <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', variantSelected)" [disabled]="counter >= variantSelected.stock">
                                </div>
                                <div class="quantity" *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 1" style="margin-top: -20px;">
                                    <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', variantSelected)" [disabled]="counter <= 1">
                                    <input (keyup)="listenQuantity($event, variantSelected)" type="text" name="quantity" value="{{counter}}" title="Qty" class="qty" max="{{variantSelected.stock}}" min="1">
                                    <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', variantSelected)" [disabled]="counter >= variantSelected.stock">
                                </div> -->
                                <div *ngIf="product.attribute_types === 'variations'">
                                    <span class="price" [ngStyle]="{'color': colorTheme2}" *ngIf="variantSelected.stock > 0 && !showStock" style="font-size: 90%;">Disponible</span>
                                    <span class="price" [ngStyle]="{'color': colorTheme2}" *ngIf="variantSelected.stock >= 1 && variantSelected.stock !== 1 && showStock" style="font-size: 90%;">{{ variantSelected.stock}} disponibles</span>
                                    <span class="price" [ngStyle]="{'color': colorTheme2}" *ngIf="variantSelected.stock >= 1 && variantSelected.stock === 1 && showStock" style="font-size: 90%;">{{ variantSelected.stock}} disponible</span>
                                    <span class="price text-danger" [ngStyle]="{'color': colorTheme2}" *ngIf="variantSelected.stock === 0" style="font-size: 90%;">No disponible</span>
                                </div>
                                <div *ngIf="product.attribute_types === 'attributes' && (product.stock === null || product.stock === 0)">
                                  <span class="price badge badge-danger text-white" style="font-size: 90%;">No disponible</span>
                                </div>
                                <app-add-cart [events]="eventsSubject.asObservable()" [product]="product" [src]="'productdetail'" [colorTheme]="colorTheme"></app-add-cart>
                                <!-- <div class="quantity" *ngIf="product.attribute_types === 'attributes' && product.stock >0">
                                    <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', undefined)" [disabled]="counter <= 1">
                                    <input (keyup)="listenQuantity($event, undefined)" type="text" name="quantity" value="{{counter}}" title="Qty" class="qty" max="{{product.stock}}" min="1">
                                    <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', undefined)" [disabled]="counter >= product.stock">
                                </div> -->
                                <!-- <div *ngIf="product.attribute_types === 'attributes'">
                                    <span class="price" [ngStyle]="{'color': colorTheme2}" *ngIf="product.stock > 0 && !showStock" style="font-size: 90%;">Disponible</span>
                                    <span class="price" [ngStyle]="{'color': colorTheme2}" *ngIf="product.stock >= 1 && product.stock !== 1 && showStock" style="font-size: 90%;">{{ product.stock}} disponibles</span>
                                    <span class="price" [ngStyle]="{'color': colorTheme2}" *ngIf="product.stock >= 1 && product.stock === 1 && showStock" style="font-size: 90%;">{{ product.stock}} disponible</span>
                                    <span class="price text-danger" [ngStyle]="{'color': colorTheme2}" *ngIf="product.stock === 0" style="font-size: 90%;">No disponible</span>
                                </div> -->
                            </div>
                        </div>
                        <!-- <br *ngIf="(product.attribute_types === 'attributes' && product.stock >0) || (product.attribute_types === 'variations' && variantSelected.stock > 0)"/> -->
                        <!-- <button [disabled]="(product.attribute_types === 'attributes' && product.stock <=0) || (product.attribute_types === 'variations' && variantSelected.stock <= 0)" style="text-transform: uppercase;" class="btn col-12 mb-1 mt-3" type="button" (click)="addToCart(product)" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-basket-loaded"></i> Añadir a carrito</button><br/> -->
                        <!-- <button *ngIf="(product.attribute_types === 'attributes' && product.stock >0) || (product.attribute_types === 'variations' && variantSelected.stock > 0)" style="text-transform: uppercase;" class="btn col-12 mb-1 mt-2 rounded-pill" type="button" (click)="addToCart(product)" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-basket-loaded"></i> Añadir a carrito</button><br/> -->
                        <!-- <button *ngIf="(product.stock !== null && product.attribute_types === 'attributes' && product.stock <=0) || (product.attribute_types === 'variations' && variantSelected.stock <= 0)" style="text-transform: uppercase;" class="btn col-12 mb-1 mt-0 rounded-pill" type="button" (click)="showPreorderModal(product)" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-bell"></i> ¡Avísame cuando llegue!</button><br/> -->
                        <br/>
                        <hr />
                        <br/>
                    </div>
                </div>
                <div class="col-12 col-lg-6 pr_detail mt-3 mt-md-4 mt-lg-5" *ngIf="required_attributes.length > 0">
                    <h4 class="attribute_name">Características principales</h4>
                    <table class="ui-table">
                        <tbody>
                            <tr *ngFor="let attribute of required_attributes; let position = index;">
                                <td class="ui-table-td ui-table-td-name" [ngClass]="{'ui-table-top-left': position === 0, 'uid-table-border-bottom': (position + 1) !== required_attributes.length, 'ui-table-bottom-left': (position + 1) === required_attributes.length}"><b class="attribute_name">{{attribute.name}}</b></td>
                                <td class="ui-table-td ui-table-td-value" [ngClass]="{'ui-table-top-right': position === 0, 'uid-table-border-bottom': (position + 1) !== required_attributes.length, 'ui-table-bottom-right': (position + 1) === required_attributes.length}">{{attribute.value_name}} <b *ngIf="attribute.value_type === 'number_unit'" style="font-weight: normal;">{{attribute.value_struct.unit}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 col-lg-6 pr_detail mt-5" *ngIf="optional_attributes.length > 0">
                    <h4 class="attribute_name">Otras características</h4>
                    <table class="ui-table">
                        <tbody>
                            <tr *ngFor="let attribute of optional_attributes; let position = index;" [ngClass]="{'ui-table-top-left': position === 0, 'ui-table-top-right': position === 0}">
                                <td class="ui-table-td ui-table-td-name" [ngClass]="{'ui-table-top-left': position === 0, 'uid-table-border-bottom': (position + 1) !== required_attributes.length, 'ui-table-bottom-left': (position + 1) === required_attributes.length}"><b class="attribute_name">{{attribute.name}}</b></td>
                                <td class="ui-table-td ui-table-td-value" [ngClass]="{'ui-table-top-right': position === 0, 'uid-table-border-bottom': (position + 1) !== required_attributes.length, 'ui-table-bottom-right': (position + 1) === required_attributes.length}">{{attribute.value_name}} <b *ngIf="attribute.value_type === 'number_unit'" style="font-weight: normal;">{{attribute.value_struct.unit}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="large_divider clearfix"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="tab-style3">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="Description-tab" data-toggle="tab" href="#Description" role="tab" aria-controls="Description" aria-selected="true" [ngStyle]="{'color': colorTheme, 'border-bottom-color': colorTheme2}">Descripción</a>
                            </li>
                        </ul>
                        <div class="tab-content shop_info_tab">
                            <div class="tab-pane fade show active" id="Description" role="tabpanel" aria-labelledby="Description-tab" [innerHTML]="product.description">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="small_divider"></div>
                    <div class="divider"></div>
                    <div class="medium_divider"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->

</div>
<!-- END MAIN CONTENT -->
<app-footer></app-footer>
