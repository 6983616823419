import { Component, OnInit } from '@angular/core';
import { ProductServiceService } from 'src/app/services/product/product-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import { environment } from '../../../../environments/environment.prod';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  styles: [
    `a:hover {
      color: var(--colorThemePrimary)
    }`,
    `.bottom_footer {
      background: var(--colorThemePrimary)
    }`,
    `.footer_dark a:hover,
    .footer_dark .widget_links li a:hover,
    .widget_links li a:hover,
    .footer_dark .footer_link li a:hover,
    .footer_link li a:hover {
        color: var(--colorThemePrimary);
    }`,
    `.social_icons li a:hover,
    .header_wrap .social_icons li a:hover {
        color: var(--colorThemePrimary);
    }`,
    `.line_footer {
        background: var(--colorThemePrimary);
    }`
  ]
})
export class FooterComponent implements OnInit {

  public urlLogo = '';
  public urlEmail = '';
  public urlDribbble = '';
  public urlFacebook = '';
  public urlGooglePlus = '';
  public urlInstagram = '';
  public urlLinkedIn = '';
  public urlPinterest = '';
  public urlSnapchat = '';
  public urlTumblr = '';
  public urlTwitch = '';
  public urlTwitter = '';
  public urlWhatsApp = '';
  public urlYoutube = '';
  public email = '';
  public description = '';
  public address = '';
  public phone = '';
  public urlPhone = '';
  public categories = [];
  public actualYear = 2020;
  public colorTheme = '';
  private colorDefault = '#0097db';
  public logoColor = '#ffffff';
  public ImageSrc : string = '';
  public banner_principal;
  public colorLine = '#0097db';
  public isBasic = environment.isBasic;

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private product: ProductServiceService, private toastrService: ToastrService, private router: Router, private api: RestApiServiceService,private auth: ShopsAuthAngularService) {
  }

  ngOnInit() {
    this.actualYear = new Date().getFullYear();
    this.checkLocalStorage();
    this.styling.setColor(`footer_bg`,'background', this.colorDefault);
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
    // if(sessionStorage.getItem('U2VydmljZVN0YXR1cw==') === null){
    //   const interval = setInterval(() => {
    //     if(sessionStorage.getItem('U2VydmljZVN0YXR1cw==') !== null){
    //       this.checkStatus();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkStatus();
    // }

    // if(environment.smartbCompatible){
    //   const departments = JSON.parse(localStorage.getItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ=='));
    //   if(departments !== null){
    //     if(departments.length > 0){
    //       this.categories = departments;
    //     }
    //   } else {
    //     let estado;
    //     let departamentos;
    //     this.api.getSmartbCollections(this.auth.getToken()).subscribe(
    //       respuesta => {
    //         estado = JSON.parse(JSON.stringify(respuesta)).code;
    //         departamentos = JSON.parse(JSON.stringify(respuesta)).departments;
    //       }, (err: HttpErrorResponse) => {
    //         this.toastrService.clear();
    //         if (err.error instanceof Error) {
    //         } else {
    //         }
    //         if (err.status === 400) {
    //           this.toastrService.error('Error 400');
    //         }
    //         if (err.status === 401) {
    //           this.toastrService.error('No estás autorizado para acceder');
    //           // this.auth.removeToken();
    //           // this.router.navigate(['/ingresar']);
    //         }
    //         if (err.status === 429) {
    //           this.toastrService.warning(err.error.message);
    //         }
    //         if (err.status === 500) {
    //           this.toastrService.error('Error 500');
    //         }
    //       }, () => {
    //         this.toastrService.clear();
    //         if(estado === 200){
    //           this.categories = departamentos;
    //           localStorage.removeItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==');
    //           localStorage.setItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==', JSON.stringify(departamentos));
    //         } else {
    //           this.toastrService.error('Ocurrió un error al obtener los departamentos');
    //         }
    //         // if (estado === 200){
    //         //   const direccion_base64 = JSON.stringify(direccion_local);
    //         //   sessionStorage.setItem('Q3VzdG9tZXIgZGlyZWN0aW9u', direccion_base64);
    //         //   this.toastrService.success('Dirección actualizada');
    //         // } else {
    //         //   this.toastrService.error('Ocurrió un error inesperado');
    //         // }
    //       });
    //   }
    // }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        this.colorTheme = response.color;
        setTimeout(()=>{
          this.styling.setColor(`footer_bg`,'background', this.colorTheme);
          this.styling.setColor(`line_footer`,'background', this.colorTheme);
          this.logoColor = this.styling.invertColor(this.colorTheme, true);
        }, 100);
        if(response.collections && response.collections.length > 0){
          this.categories = response.collections;
        }
        this.urlLogo = response.logo;
        if(response.phone !== ''){
          this.phone = response.phone;
          this.urlPhone = 'tel:' + response.phone.replace(/\s/g, '');
        }
        if(response.description !== ''){
          this.description = response.description;
        }
        if(response.email !== ''){
          this.email = response.email;
        }
        const social = response.social;
        if(social.length > 0){
          if(this.filtrarRedes(social, "Email").length > 0){
            this.urlEmail = this.filtrarRedes(social, "Email")[0].url;
          }
          if(this.filtrarRedes(social, "Dribbble").length > 0){
            this.urlDribbble  = this.filtrarRedes(social, "Dribbble")[0].url;
          }
          if(this.filtrarRedes(social, "Facebook").length > 0){
            this.urlFacebook  = this.filtrarRedes(social, "Facebook")[0].url;
          }
          if(this.filtrarRedes(social, "Google Plus").length > 0){
            this.urlGooglePlus  = this.filtrarRedes(social, "Google Plus")[0].url;
          }
          if(this.filtrarRedes(social, "Instagram").length > 0){
            this.urlInstagram  = this.filtrarRedes(social, "Instagram")[0].url;
          }
          if(this.filtrarRedes(social, "LinkedIn").length > 0){
            this.urlLinkedIn  = this.filtrarRedes(social, "LinkedIn")[0].url;
          }
          if(this.filtrarRedes(social, "Pinterest").length > 0){
            this.urlPinterest  = this.filtrarRedes(social, "Pinterest")[0].url;
          }
          if(this.filtrarRedes(social, "Snapchat").length > 0){
            this.urlSnapchat  = this.filtrarRedes(social, "Snapchat")[0].url;
          }
          if(this.filtrarRedes(social, "Tumblr").length > 0){
            this.urlTumblr  = this.filtrarRedes(social, "Tumblr")[0].url;
          }
          if(this.filtrarRedes(social, "Twitch").length > 0){
            this.urlTwitch  = this.filtrarRedes(social, "Twitch")[0].url;
          }
          if(this.filtrarRedes(social, "Twitter").length > 0){
            this.urlTwitter  = this.filtrarRedes(social, "Twitter")[0].url;
          }
          if(this.filtrarRedes(social, "WhatsApp").length > 0){
            this.urlWhatsApp  = this.filtrarRedes(social, "WhatsApp")[0].url;
          }
          if(this.filtrarRedes(social, "Youtube").length > 0){
            this.urlYoutube  = this.filtrarRedes(social, "Youtube")[0].url;
          }
        }

        if(response.address !== ''){
          this.address = response.address;
        }

        try {
          this.banner_principal = response.banner_principal;
          if(this.banner_principal.background === undefined){
            this.colorLine = this.colorTheme;
          } else if(this.banner_principal.background === ''){
            this.colorLine = this.colorTheme;
          } else {
            this.colorLine = this.banner_principal.background;
          }
        } catch(err){}

      }
    });
    // const logo = atob(localStorage.getItem('SU5GTy1MT0dP'));
    // if(logo !== ''){
    //   this.urlLogo = logo;
    // }
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.styling.setColor(`footer_bg`,'background', this.colorTheme);
    //   this.styling.setColor(`line_footer`,'background', this.colorTheme);
    //   this.logoColor = this.styling.invertColor(this.colorTheme, true);
    // }
    // const phoneNumber = atob(localStorage.getItem('SU5GTy1QSE9ORQ=='));
    // if(phoneNumber !== ''){
    //   this.phone = phoneNumber;
    //   this.urlPhone = 'tel:' + phoneNumber.replace(/\s/g, '');
    // }

    // const description = localStorage.getItem('SU5GTy1ERVNDUklQVElPTg==');
    // if(description !== ''){
    //   this.description = description;
    // }

    // const email = atob(localStorage.getItem('SU5GTy1FTUFJTA=='));
    // if(email !== ''){
    //   this.email = email;
    // }

    // const social = JSON.parse(atob(localStorage.getItem('SU5GTy1TT0NJQUw=')));
    // if(social.length > 0){
    //   if(this.filtrarRedes(social, "Email").length > 0){
    //     this.urlEmail = this.filtrarRedes(social, "Email")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Dribbble").length > 0){
    //     this.urlDribbble  = this.filtrarRedes(social, "Dribbble")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Facebook").length > 0){
    //     this.urlFacebook  = this.filtrarRedes(social, "Facebook")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Google Plus").length > 0){
    //     this.urlGooglePlus  = this.filtrarRedes(social, "Google Plus")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Instagram").length > 0){
    //     this.urlInstagram  = this.filtrarRedes(social, "Instagram")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "LinkedIn").length > 0){
    //     this.urlLinkedIn  = this.filtrarRedes(social, "LinkedIn")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Pinterest").length > 0){
    //     this.urlPinterest  = this.filtrarRedes(social, "Pinterest")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Snapchat").length > 0){
    //     this.urlSnapchat  = this.filtrarRedes(social, "Snapchat")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Tumblr").length > 0){
    //     this.urlTumblr  = this.filtrarRedes(social, "Tumblr")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Twitch").length > 0){
    //     this.urlTwitch  = this.filtrarRedes(social, "Twitch")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Twitter").length > 0){
    //     this.urlTwitter  = this.filtrarRedes(social, "Twitter")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "WhatsApp").length > 0){
    //     this.urlWhatsApp  = this.filtrarRedes(social, "WhatsApp")[0].url;
    //   }
    //   if(this.filtrarRedes(social, "Youtube").length > 0){
    //     this.urlYoutube  = this.filtrarRedes(social, "Youtube")[0].url;
    //   }
    // }

    // const address = atob(localStorage.getItem('SU5GTy1BRERSRVNT'));
    // if(address !== ''){
    //   this.address = address;
    // }

    // if(!environment.smartbCompatible){
    //   const departments = JSON.parse(localStorage.getItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ=='));
    //   if(departments !== null){
    //     if(departments.length > 0){
    //       this.categories = departments;
    //     }
    //   }
    // }
    // try {
    //   this.banner_principal = JSON.parse(atob(localStorage.getItem('TUFJTl9CQU5ORVI=')));
    //   if(this.banner_principal.background === undefined){
    //     this.colorLine = this.colorTheme;
    //   } else if(this.banner_principal.background === ''){
    //     this.colorLine = this.colorTheme;
    //   } else {
    //     this.colorLine = this.banner_principal.background;
    //   }
    // } catch(err){}
  }

  checkStatus(){
    const status = sessionStorage.getItem('U2VydmljZVN0YXR1cw==');
    if(status !== null){
      const estado = atob(status);
      if(estado!== '200'){
        if(estado === '402'){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === '403'){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === '429'){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          if(estado !== undefined && estado !== 'undefined'){
            sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
            this.toastrService.error('Error inesperado :(');
          }
        }
      }
    }
  }

  goTo(route, query){
    this.router.navigate(route);
    if(query !== undefined){
      this.router.navigate(route, { queryParams: query });
    } else {
      this.router.navigate(route);
    }
    window.scroll(0,0);
  }

  goToDepartment(department){
    localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
    this.router.navigate(['', 'tienda'], { queryParams: { collection: department } });
    window.scroll(0,0);
    // window.location.reload();
  }

  filtrarRedes(redes, nombre){
    return redes.filter((product) => {
      return product.name === nombre;
    });
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id,'color', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#687188');
    }
  }
}
