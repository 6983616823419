<!-- START MAIN CONTENT -->
<div class="main_content">

    <!-- START 404 SECTION -->
    <div class="section">
        <div class="error_wrap">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-10 order-lg-first">
                        <div class="text-center">
                            <div class="error_txt">404</div>
                            <h5 class="mb-2 mb-sm-3">¡Oops, no hemos encontrado la página que estás buscando!</h5>
                            <p>La página que estás buscando fue movida, removida, renombrada o quizá nunca existió :(</p>
                            <a [routerLink]="['', '/']" routerLinkActive="router-link-active" class="btn go_home" id="go_home" [ngStyle]="{'background': colorTheme, 'border': '1px solid'+colorTheme, 'color': 'white'}">IR A INICIO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END 404 SECTION -->
</div>
<!-- END MAIN CONTENT -->
<!-- <app-footer></app-footer> -->