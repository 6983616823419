import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import states from 'src/app/data/states.json';
import countries from 'src/app/data/countries.json';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { HttpErrorResponse } from '@angular/common/http';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { EncryptManagerService } from '@ignacioruben7/shops-encrypt-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import regimenes from 'src/app/data/regimenes.json';
import usosFacturas from 'src/app/data/usosCFDI.json';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import {getDate } from 'src/app/tools/date-utils';
import { ManageOneAddressComponent } from '../../store/manage-one-address/manage-one-address.component';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `.dashboard_menu .nav-tabs li.nav-item a.active {
      background-color: var(--colorThemePrimary);
    }`
  ]
})
export class MyaccountComponent implements OnInit {

  public folioPedido = '';
  public estadoPedido = '';
  public fechaPedido = '';
  public paqueteriaPedido = '';
  public guiaPedido = '';
  public totalPedido = 0;
  public productosEnPedido = [];
  public color = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
  public ImageSrc : string = '';
  paginaActual = 1;
  articulosPorPagina = 10;
  modalOptions: NgbModalOptions;
  closeResult: string;
  public estadosForm: FormGroup;
  public regimenForm: FormGroup;
  public usoForm: FormGroup;
  public paisesForm2: FormGroup;
  public nombres = '';
  public apellidos = '';
  public correo = '';
  public telefono = '';
  public calle = '';
  public no_exterior = '';
  public no_interior = '';
  public postal_code = '';
  public ciudad = '';
  public municipio = '';
  public colonia = '';
  public entre_calle_1 = '';
  public entre_calle_2 = '';
  public indicaciones_extra = '';
  public estados = [];
  public paises = countries.countries;
  public estadoSeleccionado = '';
  public actualizarPass = false;
  public contraseniaAnterior = '';
  public contraseniaNueva = '';
  public contraseniaConfirmacion = '';
  public pedidos = [];
  private pedidoSeleccionado;
  public mostrarInfoPersonal = true;
  public mostrarDirecciones = false;
  public mostrarPedidos = false;
  public nombreInfo;
  public apellidosInfo;
  public telefonoInfo;
  private direccionLocal;
  public paisSeleccionado2 = '+52';
  public colorTheme = 'rgb(216, 216, 216)';
  public colorTheme2 = '#333';
  private configWorker: any;
  public current_page = 1;
  public per_page = 10;
  public total_pages = 0;
  public prev_page = null;
  public next_page = null;
  private searching = false;
  public menuOptions = [
    {
      id: 'personal',
      title: 'Datos personales'
    },
    {
      id: 'direcciones',
      title: 'Mis direcciones'
    },
    {
      id: 'facturacion',
      title: 'Facturación'
    },
    {
      id: 'pedidos',
      title: 'Mis pedidos'
    },
    {
      id: 'salir',
      title: 'Salir'
    }
  ];
  public optionMenuSelected = {
    id: 'personal',
    title: 'Datos personales'
  };

  // FACTURACION
  public razon_social = '';
  public zip_code = '';
  public mostrarFacturacion = false;
  public rfc = '';
  public regimenesFiscales = regimenes;
  public regimenSeleccionado = '';
  public usosCFDI = [];
  public usoCFDISeleccionado = '';
  public myAddresses = [] as any;
  private serviceId = '';
  public change_customer_address = true;
  private infoService = null;

  constructor(private cart: CartServiceService, private service: CustomStoreService, private deviceService: DeviceDetectorService,private styling: StyleManagerService, private route: ActivatedRoute, private fb: FormBuilder, private modalService: NgbModal, private auth: ShopsAuthAngularService, private toastrService: ToastrService,  private router: Router, private encryption: EncryptManagerService, private api: RestApiServiceService) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    this.initWorkers();
    this.configWorker.onmessage = ({data}) => {
      let estado = data.code;
      let result = data.result;
      if(estado === 200){
        if(result !== undefined){
          this.searching = false;
          this.total_pages = result.total_pages;
          this.prev_page = result.prev;
          this.next_page = result.next;
          this.current_page = result.actual_page;
          this.pedidos = result.docs;
        }
      } else {
        if(estado === 402){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === 403){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === 500){
          this.toastrService.error(data.message);
        } else if(estado === 429){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.error('Error inesperado :(');
        }
      }
    };

    const token = this.auth.getToken();
    this.getAddress(token);
    // const direcciones = sessionStorage.getItem('Z2V0TXlBZGRyZXNzZXM=');
    // if(direcciones){
    //   this.cart.updateAddress(JSON.parse(direcciones));
    // } else {
    //   this.getAddress(token);
    // }
    this.cart.currentAddresses.subscribe((response) => {
      this.myAddresses = response;
    });
   }

  ngOnInit() {
    this.getDeviceInfo();
    window.scroll(0,0);
    sessionStorage.removeItem('b3JkZXJTdWNjZXNz');
    this.styling.setColor('account_detail_tab','backgroundColor', this.colorTheme2);
    if(localStorage.getItem('SU5GTy1FU1RBRE9T') !== null){
      this.estados = JSON.parse(atob(localStorage.getItem('SU5GTy1FU1RBRE9T')));
    } else {
      this.estados = states.states;
    }
    this.color = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    this.mostrarInfoPersonal = true;
    this.estadosForm = this.fb.group({
      estado: [null]
    });
    this.regimenForm = this.fb.group({
      regimen: [null]
    });

    this.usoForm = this.fb.group({
      uso: [null]
    });

    this.paisesForm2 = this.fb.group({
      pais: [null]
    });

    const token = this.auth.getToken();
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    const decodedToken = helper.decodeToken(token);

    if (token !== '' && token !== null && isExpired !== true) {
      if (sessionStorage.getItem('Tm9tYnJlIGNsaWVudGU=') !== null && sessionStorage.getItem('Tm9tYnJlIGNsaWVudGU=') !== undefined) {
        this.nombreInfo = sessionStorage.getItem('Tm9tYnJlIGNsaWVudGU=');
      }
      if (sessionStorage.getItem('QXBlbGxpZG9zIGNsaWVudGU=') !== null && sessionStorage.getItem('QXBlbGxpZG9zIGNsaWVudGU=') !== undefined) {
        this.apellidosInfo = sessionStorage.getItem('QXBlbGxpZG9zIGNsaWVudGU=');
      }
      if (sessionStorage.getItem('VGVsw6lmb25vIGNsaWVudGU=') !== null && sessionStorage.getItem('VGVsw6lmb25vIGNsaWVudGU=') !== undefined) {
        const telefono = sessionStorage.getItem('VGVsw6lmb25vIGNsaWVudGU=');
        this.telefonoInfo = `${telefono.substr(telefono.length - 10)}`.trim();
        if(telefono.length > 10){
          this.paisSeleccionado2 = telefono.substr(0, telefono.length - 10);
        }
      }
      if (decodedToken.correo !== null && decodedToken.correo !== undefined) {
        this.correo = decodedToken.correo;
      }
      if(sessionStorage.getItem('Q3VzdG9tZXIgZGlyZWN0aW9u') !== null && sessionStorage.getItem('Q3VzdG9tZXIgZGlyZWN0aW9u') !== undefined){
        this.direccionLocal = JSON.parse(sessionStorage.getItem('Q3VzdG9tZXIgZGlyZWN0aW9u'));
        this.calle = this.direccionLocal.street;
        this.no_exterior = this.direccionLocal.outdoor_number;
        this.no_interior = this.direccionLocal.interior_number;
        this.postal_code = this.direccionLocal.postal_code;
        this.estadoSeleccionado = this.direccionLocal.state_key;
        const estadosFiltrados = this.estados.filter((estado) => {
          return estado.clave === this.estadoSeleccionado;
        });
        if(estadosFiltrados.length === 0){
          this.estadoSeleccionado = '';
        }
        this.ciudad = this.direccionLocal.city;
        this.municipio = this.direccionLocal.town;
        this.colonia = this.direccionLocal.suburb;
        this.entre_calle_1 = this.direccionLocal.between_streets_1;
        this.entre_calle_2 = this.direccionLocal.between_streets_2;
        this.indicaciones_extra = this.direccionLocal.indications;
      }
      if(sessionStorage.getItem('ZGF0b3NGYWN0dXJhY2lvbg==') !== null && sessionStorage.getItem('ZGF0b3NGYWN0dXJhY2lvbg==') !== undefined){
        const infoBilling = JSON.parse(sessionStorage.getItem('ZGF0b3NGYWN0dXJhY2lvbg=='));
        this.razon_social = infoBilling.business_name;
        this.rfc = infoBilling.rfc;
        this.zip_code = infoBilling.tax_zip_code;
        this.regimenSeleccionado = infoBilling.fiscal_regimen;
        const findRegimen = this.regimenesFiscales.findIndex((regimen) => regimen.clave === infoBilling.fiscal_regimen);
        if(findRegimen !== -1){
          const persona = this.regimenesFiscales[findRegimen].persona;
          this.usosCFDI = [];
          if(persona === 'fisica'){
            this.usosCFDI = usosFacturas.filter((uso) => uso.fisica).sort((a,b)=> a.nombre.localeCompare(b.nombre));
          } else {
            this.usosCFDI = usosFacturas.filter((uso) => uso.moral).sort((a,b)=> a.nombre.localeCompare(b.nombre));
          }
        }
        setTimeout(()=> {
          this.usoCFDISeleccionado = infoBilling.cfdi_use;
        }, 100);
      }
    } else {
      this.router.navigate(['', 'ingresar']);
    }
    this.route.queryParams.subscribe(queryParams => {
      if(queryParams.menu !== undefined){
        if(queryParams.menu.trim() !== ""){
          if(queryParams.menu.trim().toLowerCase() === 'pedidos'){
            this.mostrarPedidos = true;
            this.mostrarPedidosMenu();
          } else if(queryParams.menu.trim().toLowerCase() === 'facturacion'){
            this.mostrarFacturacion = true;
            this.mostrarFacturacionMenu();
          }
        }
      }
    });

    this.checkLocalStorage();
    setTimeout(() => {
      const infoService = this.service.infoService.getValue();
      if(infoService){
        this.infoService = infoService;
        if(typeof infoService.customer_edit_address === 'boolean'){
          this.change_customer_address = infoService.customer_edit_address;
        }
      }
    }, 1000);
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }

    this.regimenesFiscales = this.regimenesFiscales.sort((a,b)=> a.nombre.localeCompare(b.nombre));
    this.regimenForm.valueChanges.subscribe(val => {
      if(val.regimen !== undefined){
        if(val.regimen !== ''){
          const findRegimen = this.regimenesFiscales.findIndex((regimen) => regimen.clave === val.regimen);
          if(findRegimen !== -1){
            const persona = this.regimenesFiscales[findRegimen].persona;
            this.usosCFDI = [];
            if(persona === 'fisica'){
              this.usosCFDI = usosFacturas.filter((uso) => uso.fisica).sort((a,b)=> a.nombre.localeCompare(b.nombre));
            } else {
              this.usosCFDI = usosFacturas.filter((uso) => uso.moral).sort((a,b)=> a.nombre.localeCompare(b.nombre));
            }
            // this.usoCFDISeleccionado = '';
          } else {
            this.toastrService.warning('No se encontraron usos CFDI para el regimen seleccionado', 'Usos CFDI');
          }
        }
      }
    });
  }

  getDateText(date){
    return getDate(date);
  }

  addNewAddress(){
    const modalRef = this.modalService.open(ManageOneAddressComponent, {size: 'xl'});
    modalRef.componentInstance.address = null;
    modalRef.componentInstance.src = 'account';
  }

  editAddress(address){
    const modalRef = this.modalService.open(ManageOneAddressComponent, {size: 'xl'});
    modalRef.componentInstance.address = address;
    modalRef.componentInstance.src = 'account';
  }

  getDeviceInfo = async () => {
    let deviceInfo = {} as any;
    deviceInfo = this.deviceService.getDeviceInfo();
    let infoIp = null as any;
    this.api.getPublicIp().subscribe(
      respuesta => {
        if(respuesta.trim() !== ''){
          infoIp = respuesta.trim().split('\n').reduce((obj, pair: any) => {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
          }, {});
        }
        // accessToken = JSON.parse(JSON.stringify(respuesta)).token;
        // estado = JSON.parse(JSON.stringify(respuesta)).code;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }

      }, () => {
        deviceInfo.ip_address = infoIp;
        const base64Code = btoa(JSON.stringify(deviceInfo));
        sessionStorage.setItem('ZGV2aWNlSW5mbw==', base64Code);
      });
  }



  selectDropdown(value, type){
    if(type === 'menu'){
      this.optionMenuSelected = value;
      this.seleccionarMenu(value.id);
    }
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id,'color', '#ffffff');
      this.styling.setColor(id,'background', this.colorTheme);
      this.styling.setColor(id,'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id,'backgroundColor', 'transparent');
      this.styling.setColor(id,'color', this.colorTheme);
      this.styling.setColor(id,'borderColor', '#ced4da');
    }
  }

  navOnPagination(type){
    if(!this.searching){
      this.searching = true;
      const token = this.auth.getToken();
      if(token !== null){
        if(type === 'next'){
          this.getOrders(token, this.current_page +1);
          // this.configWorker.postMessage({request: 'getOrdersHistory', body: {token: token, sid: decodedToken.sid, page: this.current_page + 1, per_page: this.per_page}});
        } else if(type === 'back'){
          this.getOrders(token, this.current_page -1);
          // this.configWorker.postMessage({request: 'getOrdersHistory', body: {token: token, sid: decodedToken.sid, page: this.current_page - 1, per_page: this.per_page}});
        }
      } else {
        this.router.navigate(['', 'ingresar']);
      }
    } else {
      this.toastrService.warning('Por favor espere...');
    }
  }


  async getOrders(myToken = null, currentPage= null){
    let page = 1;
    if(currentPage){
      page = currentPage;
    }
    let token = myToken;
    if(!token){
      token = this.auth.getToken();
    }
    const infoService = this.service.infoService.getValue();
    if(infoService){
      this.infoService = infoService;
    } else {
      setTimeout(()=> {
        const infoService = this.service.infoService.getValue();
        if(infoService){
          this.infoService = infoService;
        }
      }, 800);
    }
    if(this.infoService){
      const infoOrders = await this.api.getMyOrders(token, page, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
      if(infoOrders.code === 200){
        this.searching = false;
        this.total_pages = infoOrders.orders.total_pages;
        this.prev_page = infoOrders.orders.prev;
        this.next_page = infoOrders.orders.next;
        this.current_page = infoOrders.orders.actual_page;
        this.pedidos = infoOrders.orders.docs;
      } else {
        this.toastrService.error(infoOrders.message, `Error ${infoOrders.code}`);
        if(infoOrders.code === 401){
          this.auth.removeToken();
          this.router.navigate(['/ingresar']);
        }
      }
    } else {
      setTimeout(async ()=> {
        const infoOrders = await this.api.getMyOrders(token, page, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(infoOrders.code === 200){
      this.searching = false;
      this.total_pages = infoOrders.orders.total_pages;
      this.prev_page = infoOrders.orders.prev;
      this.next_page = infoOrders.orders.next;
      this.current_page = infoOrders.orders.actual_page;
      this.pedidos = infoOrders.orders.docs;
    } else {
      this.toastrService.error(infoOrders.message, `Error ${infoOrders.code}`);
      if(infoOrders.code === 401){
        this.auth.removeToken();
        this.router.navigate(['/ingresar']);
      }
    }
      }, 850);
    }

  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          if(this.mostrarInfoPersonal){
            this.styling.setColor('account_detail_tab','backgroundColor', this.colorTheme);
            this.styling.setColor('address_tab','color', '#2b2f4c');
            this.styling.setColor('orders_tab','color', '#2b2f4c');
          } else if(this.mostrarDirecciones){
            this.styling.setColor('address_tab','backgroundColor', this.colorTheme);
            this.styling.setColor('account_detail_tab','color', '#2b2f4c');
            this.styling.setColor('orders_tab','color', '#2b2f4c');
          } else if(this.mostrarPedidos){
            this.styling.setColor('orders_tab','backgroundColor', this.colorTheme);
            this.styling.setColor('account_detail_tab','color', '#2b2f4c');
            this.styling.setColor('address_tab','color', '#2b2f4c');
          }
        }
        const titleHeader = response.title ? `${response.title} | Mi cuenta`:'LocLog Shops | Mi cuenta';
        this.api.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.serviceId = response.public;
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   if(this.mostrarInfoPersonal){
    //     this.styling.setColor('account_detail_tab','backgroundColor', this.colorTheme);
    //     this.styling.setColor('address_tab','color', '#2b2f4c');
    //     this.styling.setColor('orders_tab','color', '#2b2f4c');
    //   } else if(this.mostrarDirecciones){
    //     this.styling.setColor('address_tab','backgroundColor', this.colorTheme);
    //     this.styling.setColor('account_detail_tab','color', '#2b2f4c');
    //     this.styling.setColor('orders_tab','color', '#2b2f4c');
    //   } else if(this.mostrarPedidos){
    //     this.styling.setColor('orders_tab','backgroundColor', this.colorTheme);
    //     this.styling.setColor('account_detail_tab','color', '#2b2f4c');
    //     this.styling.setColor('address_tab','color', '#2b2f4c');
    //   }
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Mi cuenta`;
    // } else {
    //   titleHeader = `LocLog Shops | Mi cuenta`;
    // }
    // this.api.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  async getAddress(token){
    try {
      const address = await this.api.getMyAddresses(token, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
        if(address.code !== 200){
          this.toastrService.warning(address.message, `Error ${address.code}`);
        } else {
          this.cart.updateAddress(address.addresses);
        }
    } catch (error) {

    }
  }


  seleccionarMenu(valor){
    if (valor === 'personal'){
      this.mostrarInfoPersonal = true;
      this.mostrarDirecciones = false;
      this.mostrarPedidos = false;
      this.mostrarFacturacion = false;
      this.styling.setColor('account_detail_tab','backgroundColor', this.colorTheme);
      this.styling.setColor('account_detail_tab','color', 'white');
      this.styling.setColor('address_tab','backgroundColor', 'transparent');
      this.styling.setColor('address_tab','color', '#2b2f4c');
      this.styling.setColor('orders_tab','backgroundColor', 'transparent');
      this.styling.setColor('orders_tab','color', '#2b2f4c');
      this.styling.setColor('facturacion_tab','backgroundColor', 'transparent');
      this.styling.setColor('facturacion_tab','color', '#2b2f4c');
      this.optionMenuSelected = {
        id: 'personal',
        title: 'Datos personales'
      }
    } else if (valor === 'direcciones'){
      this.mostrarInfoPersonal = false;
      this.mostrarDirecciones = true;
      this.mostrarPedidos = false;
      this.mostrarFacturacion = false;
      this.styling.setColor('address_tab','backgroundColor', this.colorTheme);
      this.styling.setColor('address_tab','color', 'white');
      this.styling.setColor('account_detail_tab','backgroundColor', 'transparent');
      this.styling.setColor('account_detail_tab','color', '#2b2f4c');
      this.styling.setColor('orders_tab','backgroundColor', 'transparent');
      this.styling.setColor('orders_tab','color', '#2b2f4c');
      this.styling.setColor('facturacion_tab','backgroundColor', 'transparent');
      this.styling.setColor('facturacion_tab','color', '#2b2f4c');
      this.optionMenuSelected = {
        id: 'direcciones',
        title: 'Mis direcciones'
      }
    } else if (valor === 'facturacion'){
      this.mostrarFacturacionMenu();
    } else if (valor === 'pedidos'){
      this.current_page = 1;
      this.mostrarPedidosMenu();
    } else if(valor === 'salir'){
      this.styling.setColor('salir','backgroundColor', this.colorTheme);
      this.styling.setColor('salir','color', 'white');
      this.styling.setColor('account_detail_tab','backgroundColor', 'transparent');
      this.styling.setColor('account_detail_tab','color', '#2b2f4c');
      this.styling.setColor('orders_tab','backgroundColor', 'transparent');
      this.styling.setColor('orders_tab','color', '#2b2f4c');
      this.styling.setColor('address_tab','backgroundColor', 'transparent');
      this.styling.setColor('address_tab','color', '#2b2f4c');
      this.styling.setColor('facturacion_tab','backgroundColor', 'transparent');
      this.styling.setColor('facturacion_tab','color', '#2b2f4c');
      this.router.navigate(['', 'ingresar']);
    }
  }

  mostrarPedidosMenu(){
    this.mostrarInfoPersonal = false;
      this.mostrarDirecciones = false;
      this.mostrarPedidos = true;
      this.mostrarFacturacion = false;
      const token = this.auth.getToken();
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      this.styling.setColor('orders_tab','backgroundColor', this.colorTheme);
      this.styling.setColor('orders_tab','color', 'white');
      this.styling.setColor('account_detail_tab','backgroundColor', 'transparent');
      this.styling.setColor('account_detail_tab','color', '#2b2f4c');
      this.styling.setColor('address_tab','backgroundColor', 'transparent');
      this.styling.setColor('address_tab','color', '#2b2f4c');
      this.styling.setColor('facturacion_tab','backgroundColor', 'transparent');
      this.styling.setColor('facturacion_tab','color', '#2b2f4c');

      this.getOrders(token, this.current_page);
      // this.configWorker.postMessage({request: 'getOrdersHistory', body: {token: token, sid: decodedToken.sid, page: this.current_page, per_page: this.per_page}});
      this.optionMenuSelected = {
        id: 'pedidos',
        title: 'Mis pedidos'
      }
  }

  mostrarFacturacionMenu(){
      this.mostrarFacturacion = true;
      this.mostrarInfoPersonal = false;
      this.mostrarDirecciones = false;
      this.mostrarPedidos = false;
      this.styling.setColor('facturacion_tab','backgroundColor', this.colorTheme);
      this.styling.setColor('facturacion_tab','color', 'white');
      this.styling.setColor('address_tab','backgroundColor', 'transparent');
      this.styling.setColor('address_tab','color', '#2b2f4c');
      this.styling.setColor('account_detail_tab','backgroundColor', 'transparent');
      this.styling.setColor('account_detail_tab','color', '#2b2f4c');
      this.styling.setColor('orders_tab','backgroundColor', 'transparent');
      this.styling.setColor('orders_tab','color', '#2b2f4c');
      this.optionMenuSelected = {
        id: 'facturacion',
        title: 'Facturación'
      }
      setTimeout(()=> {
        if(this.mostrarFacturacion){
          this.styling.setColor('facturacion_tab','backgroundColor', this.colorTheme);
          this.styling.setColor('facturacion_tab','color', 'white');
        }
      }, 3000);
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'nombres') {
      this.nombreInfo = valor.trim();
    } else if (idInput === 'apellidos') {
      this.apellidosInfo = valor.trim();
    } else if (idInput === 'telefono') {
      var regex = /^\d*$/;
      const validacion = regex.test(valor.trim());
      if(validacion){
        this.telefonoInfo = valor.trim();
      } else {
        event.target.value = this.telefonoInfo;
      }
    } else if (idInput === 'calle') {
      this.calle = valor.trim();
    } else if (idInput === 'no_exterior') {
      this.no_exterior = valor.trim();
    } else if (idInput === 'no_interior') {
      this.no_interior = valor.trim();
    } else if (idInput === 'postal_code') {
      var regex = /^\d*$/;
      const validacion = regex.test(valor.trim());
      if(validacion){
        this.postal_code = valor.trim();
      } else {
        event.target.value = this.postal_code;
      }
    } else if (idInput === 'ciudad') {
      this.ciudad = valor.trim();
    } else if (idInput === 'municipio') {
      this.municipio = valor.trim();
    } else if (idInput === 'colonia') {
      this.colonia = valor.trim();
    } else if (idInput === 'entre_calle_1') {
      this.entre_calle_1 = valor.trim();
    } else if (idInput === 'entre_calle_2') {
      this.entre_calle_2 = valor.trim();
    } else if (idInput === 'indicaciones_extra') {
      this.indicaciones_extra = valor.trim();
    } else if (idInput === 'contrasenia_anterior') {
      this.contraseniaAnterior = valor.trim();
    } else if (idInput === 'nueva_contrasenia') {
      this.contraseniaNueva = valor.trim();
    } else if (idInput === 'contrasenia_confirmacion') {
      this.contraseniaConfirmacion = valor.trim();
    } else if (idInput === 'razon_social') {
      this.razon_social = `${valor}`.toUpperCase();
    } else if (idInput === 'zip_code') {
      this.zip_code = valor;
    } else if (idInput === 'rfc') {
      this.rfc =  `${valor}`.toUpperCase();
    }
  }

  actualizarInformacionPersonal(){
    const token = this.auth.getToken();
    // const helper = new JwtHelperService();
    // const decodedToken = helper.decodeToken(token);

    if(this.telefonoInfo !== undefined && this.telefonoInfo !== '' &&
    this.nombreInfo !== undefined && this.nombreInfo !== '' &&
    this.apellidosInfo !== undefined && this.apellidosInfo !== ''){
      if(this.telefonoInfo.length === 10){
        const info = {
          nombres: this.nombreInfo,
          apellidos: this.apellidosInfo,
          lada: this.paisSeleccionado2,
          telefono: this.telefonoInfo,
          jwt: token,
        }
        // let privateKey = '';
        // const key = sessionStorage.getItem('a2V5');
        // if(key !== null){
        //   privateKey = key;
        // }
        this.toastrService.info('Por favor espere...', 'Actualizando información');

        const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
        const info_encriptada2 = btoa(encodeURIComponent(JSON.stringify(info)));
        let estado;
        this.api.actualizarInformacionPersonalV2(info_encriptada2, infoDevice, this.serviceId).subscribe(
          respuesta => {
            estado = JSON.parse(JSON.stringify(respuesta)).code;
          }, (err: HttpErrorResponse) => {
            this.toastrService.clear();
            if (err.error instanceof Error) {
            } else {
            }
            if (err.status === 400) {
              this.toastrService.error('Error 400');
            }
            if (err.status === 410) {
              this.toastrService.warning('Tu cuenta ha sido eliminada por el equipo de ventas');
              this.auth.removeToken();
              this.router.navigate(['/ingresar']);
            }
            if (err.status === 405) {
              window.location.reload();
            }
            if (err.status === 429) {
              this.toastrService.warning(err.error.message);
            }
            if (err.status === 500) {
              this.toastrService.error('Error 500');
            }
          }, () => {
            this.toastrService.clear();
            if (estado === 200){
              this.nombreInfo = info.nombres;
              this.apellidosInfo = info.apellidos;
              this.telefonoInfo = info.telefono;
              // Actualizar sessionStorage
              sessionStorage.setItem('Tm9tYnJlIGNsaWVudGU=', info.nombres);
              sessionStorage.setItem('QXBlbGxpZG9zIGNsaWVudGU=', info.apellidos);
              sessionStorage.setItem('VGVsw6lmb25vIGNsaWVudGU=', `${this.paisSeleccionado2}${info.telefono}`);
              this.toastrService.success('Información personal actualizada');
            } else {
              this.toastrService.error('Ocurrió un error inesperado');
            }
          });
      } else {
        this.toastrService.warning('El teléfono no es válido');
      }
    } else {
      if(this.telefonoInfo === undefined || this.telefonoInfo === ''){
        this.toastrService.warning('El teléfono es requerido');
      }
      if(this.nombreInfo === undefined || this.nombreInfo === ''){
        this.toastrService.warning('El nombre es requerido');
      }
      if(this.apellidosInfo === undefined || this.apellidosInfo === ''){
        this.toastrService.warning('Los apellidos son requeridos');
      }
    }

  }

  actualizarDireccion(){
    if(this.calle.trim() === ''){
      this.toastrService.warning('La calle es requerida');
    }
    if(this.no_exterior.trim() === ''){
      this.toastrService.warning('El número exterior es requerido');
    }
    if(this.postal_code.trim() === ''){
      this.toastrService.warning('El código postal es requerido');
    }
    if(this.estadoSeleccionado.trim() === '' || this.estadoSeleccionado === undefined){
      this.toastrService.warning('El estado es requerido');
    }
    // if(this.ciudad.trim() === ''){
    //   this.toastrService.warning('La ciudad es requerida');
    // }
    if(this.municipio.trim() === ''){
      this.toastrService.warning('El municipio es requerido');
    }
    if(this.colonia.trim() === ''){
      this.toastrService.warning('La colonia es requerida');
    }

    if (this.calle.trim() !== '' && this.no_exterior.trim() !== '' &&
    this.postal_code.trim() !== '' && this.estadoSeleccionado.trim() !== ''
    && this.estadoSeleccionado !== undefined && this.municipio.trim() !== '' && this.colonia.trim() !== '') {
      if(this.postal_code.length === 5){
        const token = this.auth.getToken();

      const direccion_rest = {
        jwt: token,
        calle: this.calle,
        no_exterior: this.no_exterior,
        no_interior: this.no_interior,
        postal_code: this.postal_code,
        clave_estado: this.estadoSeleccionado,
        estado: this.filtrarEstado(this.estadoSeleccionado)[0].nombre,
        // ciudad: this.ciudad,
        municipio: this.municipio,
        colonia: this.colonia,
        entre_calles_1: this.entre_calle_1,
        entre_calles_2: this.entre_calle_2,
        indicaciones_extra: this.indicaciones_extra
      };

      const direccion_local = {
        street: this.calle,
        outdoor_number: this.no_exterior,
        interior_number: this.no_interior,
        postal_code: this.postal_code,
        state_key: this.estadoSeleccionado,
        state_name: this.filtrarEstado(this.estadoSeleccionado)[0].nombre,
        // city: this.ciudad,
        town: this.municipio,
        suburb: this.colonia,
        between_streets_1: this.entre_calle_1,
        between_streets_2: this.entre_calle_2,
        indications: this.indicaciones_extra
      };

      // let privateKey = '';
      // const key = sessionStorage.getItem('a2V5');
      // if(key !== null){
      //   privateKey = key;
      // }
      const direccion_encriptada = btoa(encodeURIComponent(JSON.stringify(direccion_rest)));
      const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
      this.toastrService.info('Por favor espere...', 'Actualizando dirección');

      let estado;
      this.api.actualizarUnaDireccionV2(direccion_encriptada, infoDevice, this.serviceId).subscribe(
        respuesta => {
          estado = JSON.parse(JSON.stringify(respuesta)).code;
        }, (err: HttpErrorResponse) => {
          this.toastrService.clear();
          if (err.error instanceof Error) {
          } else {
          }
          if (err.status === 400) {
            this.toastrService.error('Error 400');
          }
          if (err.status === 401) {
            this.toastrService.error('No estás autorizado para realizar dicha actualización');
            this.auth.removeToken();
            this.router.navigate(['/ingresar']);
          }
          if (err.status === 405) {
            window.location.reload();
          }
          if (err.status === 409) {
            this.toastrService.warning(err.error.message);
          }
          if (err.status === 410) {
            this.toastrService.warning('Tu cuenta ha sido eliminada por el equipo de ventas');
            this.auth.removeToken();
            this.router.navigate(['/ingresar']);
          }
          if (err.status === 411) {
            this.toastrService.warning(err.error.message);
          }
          if (err.status === 429) {
            this.toastrService.warning(err.error.message);
          }
          if (err.status === 500) {
            this.toastrService.error('Error 500');
          }
        }, () => {
          this.toastrService.clear();
          if (estado === 200){
            const direccion_base64 = JSON.stringify(direccion_local);
            sessionStorage.setItem('Q3VzdG9tZXIgZGlyZWN0aW9u', direccion_base64);
            this.toastrService.success('Dirección actualizada');
          } else {
            this.toastrService.error('Ocurrió un error inesperado');
          }
        });
      } else {
        this.toastrService.warning('El código postal no es válido');
      }
    }
  }

  mostrarActualizarPass(){
    this.actualizarPass = true;
  }

  ocultarActualizarPass(){
    this.actualizarPass = false;
    this.contraseniaNueva = '';
    this.contraseniaAnterior = '';
    this.contraseniaConfirmacion = '';
  }

  async actualizarFacturacion(){
    this.toastrService.clear();
    this.toastrService.info('Actualizando informacion de facturacion');
    const token = this.auth.getToken();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const billing = {
      correo: decodedToken.correo,
      business_name: this.razon_social,
      rfc: this.rfc,
      tax_zip_code: this.zip_code,
      fiscal_regimen: this.regimenSeleccionado,
      cfdi_use: this.usoCFDISeleccionado,
      jwt: token
    }

    let estado;
    const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
    const infoService = this.service.infoService.getValue();
    const serviceId = infoService.public;
    const info_encriptada2 = btoa(encodeURIComponent(JSON.stringify(billing)));
    const infoUpdate = await this.api.actualizarDatosFacturacionV2(info_encriptada2, infoDevice, serviceId) as any;
    this.toastrService.clear();
    if(infoUpdate.code !== 200){
      this.toastrService.error(infoUpdate.message, `Error ${infoUpdate.code}`);
    } else {
      sessionStorage.setItem('ZGF0b3NGYWN0dXJhY2lvbg==', JSON.stringify(billing));
      this.toastrService.success('Datos de facturacion actualizados');
    }
    // this.api.actualizarDatosFacturacionV2(info_encriptada2, infoDevice, serviceId).subscribe(
    //   respuesta => {
    //     estado = JSON.parse(JSON.stringify(respuesta)).code;
    //   }, (err: HttpErrorResponse) => {
    //     this.toastrService.clear();
    //     if (err.error instanceof Error) {
    //     } else {
    //     }
    //     if (err.status === 400) {
    //       this.toastrService.error('Error 400');
    //     }
    //     if (err.status === 401) {
    //       this.toastrService.error('No estás autorizado para acceder');
    //       this.auth.removeToken();
    //       this.router.navigate(['/ingresar']);
    //     }
    //     if (err.status === 409) {
    //       this.toastrService.warning(err.error.message);
    //     }
    //     if (err.status === 411) {
    //       this.toastrService.warning(err.error.message);
    //     }
    //     if (err.status === 410) {
    //       this.toastrService.warning('Tu cuenta ha sido eliminada por el equipo de ventas');
    //       this.auth.removeToken();
    //       this.router.navigate(['/ingresar']);
    //     }
    //     if (err.status === 405) {
    //       window.location.reload();
    //     }
    //     if (err.status === 429) {
    //       this.toastrService.warning(err.error.message);
    //     }
    //     if (err.status === 500) {
    //       this.toastrService.error('Error 500');
    //     }
    //   }, () => {
    //     this.toastrService.clear();
    //     if (estado === 200){
    //       sessionStorage.setItem('ZGF0b3NGYWN0dXJhY2lvbg==', JSON.stringify(billing));
    //       this.toastrService.success('Datos de facturacion actualizados');
    //     } else {
    //       this.toastrService.error('Ocurrió un error inesperado');
    //     }
    //   });
  }

  actualizarContrasenia(){
    if(this.contraseniaAnterior.trim() === '') {
      this.toastrService.warning('La contraseña anterior es requerida');
    }
    if(this.contraseniaNueva.trim() === '') {
      this.toastrService.warning('La nueva contraseña es requerida');
    }
    if(this.contraseniaConfirmacion.trim() === '') {
      this.toastrService.warning('La contraseña de confirmación es requerida');
    }
    if (this.contraseniaAnterior.trim() !== '' && this.contraseniaNueva.trim() !== '' &&
    this.contraseniaConfirmacion.trim() !== '') {
      if(this.contraseniaAnterior.trim() !== this.contraseniaNueva.trim()) {
        if(this.contraseniaNueva.trim() === this.contraseniaConfirmacion.trim()) {
          // this.toastrService.info('Por favor espere...');
          // Hacer petición actualizar contrasenia
          const token = this.auth.getToken();
          const datosUsuario = {
            contrasenia_anterior: this.contraseniaAnterior,
            nueva_contrasenia: this.contraseniaNueva,
            jwt: token
          };

          // let privateKey = '';
          // const key = sessionStorage.getItem('a2V5');
          // if(key !== null){
          //   privateKey = key;
          // }
          // const info_encriptada = this.encryption.encryptBase64(JSON.stringify(datosUsuario));
          const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
          const info_encriptada2 = btoa(encodeURIComponent(JSON.stringify(datosUsuario)));
          this.toastrService.info('Por favor espere...', 'Actualizando contraseña');
          let estado;
          this.api.cambiarContraseniaV2(info_encriptada2, infoDevice, this.serviceId).subscribe(
            respuesta => {
              estado = JSON.parse(JSON.stringify(respuesta)).code;
             }, (err: HttpErrorResponse) => {
              this.toastrService.clear();
              if (err.error instanceof Error) {
              } else {
              }
              if (err.status === 400) {
                this.toastrService.error('Error 400');
              }
              if (err.status === 401) {
                // this.toastrService.warning('La contraseña actual es incorrecta');
                this.toastrService.error('Lo sentimos, por tu seguridad. Es necesario volver a ingresar');
                this.router.navigate['/ingresar'];
              }
              if (err.status === 403) {
                this.toastrService.warning(err.error.message);
              }
              // if (err.status === 404) {
              //   this.toastrService.error('No estás autorizado');
              //   this.router.navigate['/ingresar'];
              // }
              if (err.status === 405) {
                window.location.reload();
              }
              if (err.status === 410) {
                this.toastrService.warning('Tu cuenta ha sido eliminada por el equipo de ventas');
                this.auth.removeToken();
                this.router.navigate(['/ingresar']);
              }
              if (err.status === 429) {
                this.toastrService.warning(err.error.message);
              }
              if (err.status === 500) {
                this.toastrService.error('Error 500');
              }
           }, () => {
              this.toastrService.clear();
             if(estado === 200){
              this.toastrService.success('Contraseña actualizada');
              this.contraseniaNueva = '';
              this.contraseniaAnterior = '';
              this.contraseniaConfirmacion = '';
              this.actualizarPass = false;
              this.auth.removeToken();
              this.router.navigate(['/ingresar']);
             } else {
               this.toastrService.error('Ocurrió un error inesperado');
             }
           });
        } else {
          this.toastrService.warning('La nueva contraseña y la de confirmación no coinciden');
        }
      } else {
        this.toastrService.warning('La nueva contraseña debe ser diferente a la anterior');
      }
    }
  }

  // visualizarPedido(position: number, pagina: number, result) {
  //   let direccion = '';
  //   if(result.delivery_type === 'home'){
  //     direccion = result.shipping.destination;
  //   } else {
  //     direccion = result.shipping.store;
  //   }
  //   const info = {
  //     delivery_type: result.delivery_type,
  //     destination: direccion,
  //     tags: result.tags,
  //     status: result.status,
  //     order_info: {
  //       id: result.id,
  //       shipping: result.shipping,
  //       payment_ref: result.payment_ref,
  //       items: result.items,
  //       subtotal: result.amount,
  //       coupon: result.coupon,
  //       total_amount: result.total_amount,
  //       payment_limit: result.payment_limit,
  //     }
  //   }
  //   sessionStorage.setItem('UGVkaWRvIGNsaWVudGU=', JSON.stringify(info));
  //   window.open(`/pedido/${result.id}`, '_blank');
  // }

  visualizarPedido(orderInfo) {
    sessionStorage.setItem('UGVkaWRvIGNsaWVudGU=', JSON.stringify(orderInfo));
    window.open(`/pedido/${orderInfo.folio}`, '_blank');
  }

  existStatus(tags, status){
    return tags.filter((tag) => {
      return tag == status;
    })
  }


  filtrarEstado(key: string ) {
    if(this.estadoSeleccionado !== ''){
      return this.estados.filter(object => {
        return object.clave === key;
      });
    } else {
      return undefined;
    }
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }


}
