<div class="top-header header_with_topbar" id="header_bg" [ngStyle]="{'background': colorTheme}">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                    <ul class="contact_detail text-center text-lg-left" *ngIf="urlPhone !== '' && phoneNumber !== ''">
                        <li [ngStyle]="{'color': colorText}"><i class="ti-mobile" id="id_1" (mouseover)="isHover(true, 1)" (mouseleave)="isHover(false, 1)"></i><span>Llámanos <a href="{{urlPhone}}" id="id_4" (mouseover)="isHover(true, 4)" (mouseleave)="isHover(false, 4)">{{phoneNumber}}</a></span></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-center text-md-right">
                    <ul class="header_list">
                        <li *ngIf="usuarioLogeado" style="cursor: pointer;"><a (click)="goTo(['','perfil'])" id="id_2" (mouseover)="isHover(true, 2)" (mouseleave)="isHover(false, 2)"><i class="ti-user"></i><span>Mi cuenta</span></a></li>
                        <li *ngIf="!usuarioLogeado" style="cursor: pointer;"><a (click)="goTo(['','ingresar'])" id="id_3" (mouseover)="isHover(true, 3)" (mouseleave)="isHover(false, 3)"><i class="ti-user"></i><span>Iniciar sesión</span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>