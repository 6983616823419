import { Component, OnInit, HostListener } from '@angular/core';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { QuickViewComponent } from '../../widgets/quick-view/quick-view.component';
import { CartModalComponent } from '../../widgets/cart-modal/cart-modal.component';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import { Title, Meta } from '@angular/platform-browser';
import { ItemsRequest } from 'src/app/classes/items_request';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid white;
      color: white;
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
        color: white;
    }`,
    `.btn-fill-out:hover {
      background-color: white;
      color: var(--colorThemePrimary)
    }`,
    `.price {
      color: var(--colorThemePrimary);
    }`,
    `.pr_action_btn li a:hover {
      background-color: var(--colorThemePrimary);
    }`,
    `.bg_offers {
      background-color: var(--colorThemePrimary);
    }`
  ]
})
export class HomeComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth < 350){
      this.ancho = '100%';
      this.fontSize = '35px';
    } else if(event.target.innerWidth < 400){
      this.ancho = '90%';
      this.fontSize = '40px';
    } else if(event.target.innerWidth < 500){
      this.ancho = '80%';
      this.fontSize = '45px';
    } else if(event.target.innerWidth < 780){
      this.ancho = '75%';
      this.fontSize = '40px';
    } else if(event.target.innerWidth < 1000){
      this.ancho = '85%';
      this.fontSize = '45px';
    } else if(event.target.innerWidth < 1200){
      this.ancho = '70%';
      this.fontSize = '45px';
    } else {
      this.ancho = '50%';
      this.fontSize = '45px';
    }
  }

  public fontSize = '45px';
  public ancho = '50%';

  public products = [];
  feacturedProducts: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 20,
    navSpeed: 500,
    items: 4,
    responsive: {
      0: {
        items: 1
      },
      481: {
        items: 4
      },
      768: {
        items: 4
      },
      1024: {
        items: 4
      }
    },
    nav: true
  };
  public banner_principal;
  public colorLine = '#0097db';
  public ImageSrc : string = '';
  public colorTheme = 'white';
  public colorTheme2 = '#333';
  private colorDefault = '#0097db';
  public colorTxtBanner = '#333';
  private configWorker: any;
  private infoService = null;

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private modalService: NgbModal, private toastrService: ToastrService, private productService: ProductServiceService, private router: Router, private api: RestApiServiceService, private titleService: Title, private metaService: Meta, private auth: ShopsAuthAngularService) {
    this.initWorkers();
    const body: ItemsRequest = {
      category: '',
      gender: '',
      query: {type: 'featured'},
      sort: {sort_by: 'updated_at', order_by: 'desc'},
      page: 1,
      per_page: 20
    }
    this.configWorker.onmessage = ({data}) => {
      let estado = data.code;
      let result = data.result;
      if(estado === 200){
        localStorage.setItem('bGlzdE9mSXRlbXNGZWF0dXJlZA==', btoa(unescape(encodeURIComponent(JSON.stringify(result.docs)))));
        this.products = result.docs;
      } else {
        if(estado === 402){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio suspendido');
          this.router.navigate(['', '402']);
        } else if(estado === 403){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.warning('Servicio cancelado');
          this.router.navigate(['', '403']);
        } else if(estado === 429){
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.info('Demasiadas solicitudes');
        } else {
          sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
          this.toastrService.error('Error inesperado :(');
        }
      }
    };
  }

  ngOnInit() {
    window.scroll(0,0);
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    if(localStorage.getItem('bGlzdE9mSXRlbXNGZWF0dXJlZA==') !== null){
      const productosLocal = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('bGlzdE9mSXRlbXNGZWF0dXJlZA==')))));
      this.products = productosLocal;
    } else {
      this.getProducts('', '',{type: 'featured'},{sort_by: 'updated_at', order_by: 'desc'}, 1, 50);
      // this.configWorker.postMessage({request: 'getItemsV2', body: body, category: '', token: this.auth.getToken()});
     }
    this.colorTheme = this.colorDefault;
    if(window.innerWidth < 350){
      this.ancho = '100%';
      this.fontSize = '35px';
    } else if(window.innerWidth < 400){
      this.ancho = '90%';
      this.fontSize = '40px';
    } else if(window.innerWidth < 500){
      this.ancho = '80%';
      this.fontSize = '45px';
    } else if(window.innerWidth < 780){
      this.ancho = '75%';
      this.fontSize = '40px';
    } else if(window.innerWidth < 1000){
      this.ancho = '85%';
      this.fontSize = '45px';
    } else if(window.innerWidth < 1200){
      this.ancho = '70%';
      this.fontSize = '45px';
    } else {
      this.ancho = '50%';
      this.fontSize = '45px';
    }
    this.checkLocalStorage();
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  showProduct(producto){
    this.productService.actualizarDetalleProducto(producto);
    this.router.navigate(['', 'p', producto.slug], { queryParams: { uid: producto.id } });
  }

  showQuickView(product){
    const modalRef = this.modalService.open(QuickViewComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

  showCartModal(product){
    const modalRef = this.modalService.open(CartModalComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

  updateUrl(position, product) {
    if(product.attribute_types === 'attributes'){
      this.products[position].images[0].url = 'assets/images/product_img1.jpg';
    } else if(product.attribute_types === 'variations'){
      this.products[position].variations[0].images[0].url = 'assets/images/product_img1.jpg';
    } else if(product === undefined){
      this.banner_principal.img = 'assets/general/tranding_img.png';
    }
  }

  abrirBanner(banner){
    if(banner.link.trim() !== ''){
      window.open(banner.link, '_blank');
    } else {
      this.router.navigate(['', banner.page], { queryParams: { collection: banner.collection, category_id: banner.category } });
      if(banner.page === 'tienda'){
        localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
      } else if(banner.page === 'ofertas'){
        localStorage.removeItem('bGlzdE9mSXRlbXNPblNhbGU=');
      }
    }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorTheme2 = response.color;
        }
        try {
          this.banner_principal = response.banner_principal;
          if(this.banner_principal.background === undefined){
            this.colorLine = this.colorTheme;
          } else if(this.banner_principal.background === ''){
            this.colorLine = this.colorTheme;
          } else {
            this.colorLine = this.banner_principal.background;
          }

          this.colorTxtBanner = this.styling.invertColor(this.colorLine, true);
        } catch(err){}
        let titleHeader = '';
        if(response.title !== undefined && response.slogan !== undefined){
          titleHeader = `${response.title} | ${response.slogan}`;
        } else if(response.title === undefined && response.slogan !== undefined){
          titleHeader = `LocLog Shops | ${response.slogan}`;
        } else if(response.title !== undefined && response.slogan === undefined){
          titleHeader = `${response.title} | Vende en línea fácilmente`;
        } else if(response.title === undefined && response.slogan === undefined){
          titleHeader = `LocLog Shops | Vende en línea fácilmente`;
        }

        // const titleHeader = response.title ? `${response.title} | Contacto`:'LocLog Shops | Contacto';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTheme2 = colorTheme;
    // }
    // try {
    //   this.banner_principal = JSON.parse(atob(localStorage.getItem('TUFJTl9CQU5ORVI=')));
    //   if(this.banner_principal.background === undefined){
    //     this.colorLine = this.colorTheme;
    //   } else if(this.banner_principal.background === ''){
    //     this.colorLine = this.colorTheme;
    //   } else {
    //     this.colorLine = this.banner_principal.background;
    //   }

    //   this.colorTxtBanner = this.styling.invertColor(this.colorLine, true);
    // } catch(err){}
    // let titulo = '';
    // let frase = '';
    // if(localStorage.getItem('SU5GTy1USVRMRQ==') !== null){
    //   titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));
    // }
    // if(localStorage.getItem('SU5GTy1TTE9HQU4=') !== null){
    //   frase = atob(localStorage.getItem('SU5GTy1TTE9HQU4='));
    // }

    // let titleHeader = '';
    // if(titulo !== undefined && frase !== undefined){
    //   titleHeader = `${titulo} | ${frase}`;
    // } else if(titulo === undefined && frase !== undefined){
    //   titleHeader = `LocLog Shops | ${frase}`;
    // } else if(titulo !== undefined && frase === undefined){
    //   titleHeader = `${titulo} | Vende en línea fácilmente`;
    // } else if(titulo === undefined && frase === undefined){
    //   titleHeader = `LocLog Shops | Vende en línea fácilmente`;
    // }
    // this.titleService.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);
  }

  isHover2(hover){
    if(hover){
      this.styling.setColor('btn_principal','backgroundColor', '#ffffff');
      this.styling.setColor('btn_principal','color', this.colorTheme);
    } else {
      this.styling.setColor('btn_principal','backgroundColor', 'transparent');
      this.styling.setColor('btn_principal','color', '#ffffff');
    }
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id,'color', '#ffffff');
      this.styling.setColor(id,'background', this.colorTheme);
      this.styling.setColor(id,'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#6c757d');
      this.styling.setColor(id,'backgroundColor', '#ffffff');
      this.styling.setColor(id,'borderColor', '#6c757d');
    }
  }

  getProducts(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage){
    let estado2;
      let result;
      this.api.getItemsV2(categoryId, genderType, queryInfo, sortInfo, currentPage, perPage, this.auth.getToken(), this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        result = JSON.parse(JSON.stringify(respuesta)).result;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400');
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500');
        }
      }, () => {
        if(estado2 === 200){
          let producto = result;
          localStorage.setItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==', btoa(unescape(encodeURIComponent(JSON.stringify(producto)))));
          this.products = result.docs;
          const CediItemsId = this.products.filter((product) => product.warning === '').map((product) => product.id);
          if(CediItemsId.length > 0){
            this.getPrices(CediItemsId);
            this.getStocks(CediItemsId);
          }
        }
      });
  }

  getPrices(items_id){
    let estado2;
      let prices;
      this.api.getPricesItems(items_id, this.auth.getToken(), this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        prices = JSON.parse(JSON.stringify(respuesta)).prices;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            prices.forEach((price) => {
              const indexItem = this.products.findIndex((item) => item.id === price.item_id);
              if(indexItem !== -1){
                this.products[indexItem].discount = price.discount;
                this.products[indexItem].retail_price = price.retail_price;
                this.products[indexItem].currency = 'MXN';
                if(price.discount > 0){
                  this.products[indexItem].has_discount = true;
                } else {
                  this.products[indexItem].has_discount = false;
                  this.products[indexItem].minimum_purchase = 0;
                  this.products[indexItem].wholesale_price = price.retail_price;
                }
                if(price.has_wholesale_price){
                  this.products[indexItem].has_wholesale_price = true;
                } else {
                  this.products[indexItem].has_wholesale_price = false;
                }
              }
            });
          } catch (error) {

          }
        }
      });
  }

  getStocks(items_id){
    let estado2;
      let stocks;
      this.api.getStockItems(items_id, this.auth.getToken(), this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id: '').subscribe(respuesta => {
        stocks = JSON.parse(JSON.stringify(respuesta)).stocks;
        estado2 = JSON.parse(JSON.stringify(respuesta)).code;
      },  (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400', err.error.message);
        }
        if (err.status === 401) {
          this.toastrService.warning('No estás autorizado para acceder');
          this.router.navigate(['', 'ingresar']);
        }
        if (err.status === 403) {
          this.toastrService.error('El CEDI asignado no existe o no está activo');
        }
        if (err.status === 404) {
          this.toastrService.error('Error 404', err.error.message);
        }
        if (err.status === 429) {
          this.toastrService.warning(err.error.message);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500', err.error.message);
        }
      }, () => {
        if(estado2 === 200){
          try {
            stocks.forEach((stock) => {
              const indexItem = this.products.findIndex((item) => item.id === stock.item_id);
              if(indexItem !== -1){
                this.products[indexItem].stock = stock.qty;
              }
            });
          } catch (error) {

          }
        }
      });
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }
}
