<!-- START FOOTER -->
<hr *ngIf="!isBasic" class="line_footer" style="margin-top: 0px;width: 100%;" id="line_footer" [ngStyle]="{'background': colorLine}"/>
<footer class="bg_white">
    <div class="footer_top" *ngIf="!isBasic">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-6 col-sm-12">
                    <div class="widget">
                        <div class="footer_logo" *ngIf="urlLogo !== ''">
                            <a (click)="goTo(['/'], undefined)" style="cursor: pointer;"><img src="{{urlLogo}}" alt="logo" width="200px" /></a>
                        </div>
                        <p *ngIf="description !== ''" [innerHTML]="description"></p>
                    </div>
                    <div class="widget">
                        <ul class="social_icons social_style3">
                            <li *ngIf="urlEmail !== ''"  (mouseover)="isHover(true, '15')" (mouseleave)="isHover(false, '15')"><a  href="{{urlEmail}}"><i class="ion-ios-email-outline" id="15"></i></a></li>
                            <li *ngIf="urlDribbble !== ''" (mouseover)="isHover(true, '2')" (mouseleave)="isHover(false, '2')"><a href="{{urlDribbble}}"><i class="icon-social-dribbble" id="2"></i></a></li>
                            <li *ngIf="urlFacebook !== ''"  (mouseover)="isHover(true, '3')" (mouseleave)="isHover(false, '3')"><a href="{{urlFacebook}}"><i class="ion-social-facebook" id="3" ></i></a></li>
                            <li *ngIf="urlGooglePlus !== ''" (mouseover)="isHover(true, '4')" (mouseleave)="isHover(false, '4')"><a href="{{urlGooglePlus}}"><i class="ion-social-googleplus-outline" id="4"></i></a></li>
                            <li *ngIf="urlInstagram !== ''" (mouseover)="isHover(true, '5')" (mouseleave)="isHover(false, '5')"><a href="{{urlInstagram}}"><i class="ion-social-instagram-outline" id="5" ></i></a></li>
                            <li *ngIf="urlLinkedIn !== ''" (mouseover)="isHover(true, '6')" (mouseleave)="isHover(false, '6')"><a href="{{urlLinkedIn}}"><i class="ion-social-linkedin-outline" id="6"></i></a></li>
                            <li *ngIf="urlPinterest !== ''" (mouseover)="isHover(true, '7')" (mouseleave)="isHover(false, '7')"><a href="{{urlPinterest}}"><i class="ion-social-pinterest-outline" id="7"></i></a></li>
                            <li *ngIf="urlSnapchat !== ''" (mouseover)="isHover(true, '8')" (mouseleave)="isHover(false, '8')"><a href="{{urlSnapchat}}"><i class="ion-social-snapchat-outline" id="8"></i></a></li>
                            <li *ngIf="urlTumblr !== ''" (mouseover)="isHover(true, '9')" (mouseleave)="isHover(false, '9')"><a href="{{urlTumblr}}"><i class="ion-social-tumblr-outline" id="9"></i></a></li>
                            <li *ngIf="urlTwitch !== ''" (mouseover)="isHover(true, '10')" (mouseleave)="isHover(false, '10')"><a href="{{urlTwitch}}" ><i class="ion-social-twitch-outline" id="10"></i></a></li>
                            <li *ngIf="urlTwitter !== ''" (mouseover)="isHover(true, '11')" (mouseleave)="isHover(false, '11')"><a href="{{urlTwitter}}"><i class="ion-social-twitter-outline" id="11"></i></a></li>
                            <li *ngIf="urlWhatsApp !== ''" (mouseover)="isHover(true, '12')" (mouseleave)="isHover(false, '12')"><a href="{{urlWhatsApp}}"><i class="ion-social-whatsapp-outline" id="12"></i></a></li>
                            <li *ngIf="urlYoutube !== ''" (mouseover)="isHover(true, '13')" (mouseleave)="isHover(false, '13')"><a href="{{urlYoutube}}"><i class="ion-social-youtube-outline" id="13"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 offset-lg-1">
                    <div class="widget" *ngIf="categories.length > 0">
                        <!-- <h6 class="widget_title" style="text-transform: none;">Categorías principales</h6> -->
                        <h6 class="widget_title" style="text-transform: none;">Departamentos</h6>
                        <ul class="widget_links">
                            <li *ngFor="let categoria of categories; let position = index;" style="cursor: pointer;"><a (click)="goToDepartment(categoria.slug)" id="2_{{position}}"  (mouseover)="isHover(true, '2_'+position)" (mouseleave)="isHover(false, '2_'+position)">{{categoria.name | uppercase}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-3 col-sm-6" *ngIf="address !== '' || urlEmail !== '' || urlPhone !== ''" >
                    <div class="widget">
                        <h6 class="widget_title" style="text-transform: none;">Atención a clientes</h6>
                        <ul class="contact_info contact_info_light">
                            <li *ngIf="address !== ''">
                                <i class="ti-location-pin"></i>
                                <p>{{address}}</p>
                            </li>
                            <li *ngIf="urlEmail !== '' && email !== ''">
                                <i class="ti-email"></i>
                                <a href="{{urlEmail}}" style="overflow-wrap: break-word;">{{email}}</a>
                            </li>
                            <li *ngIf="urlPhone !== '' && phone !== ''">
                                <i class="ti-mobile"></i>
                                <a href="{{urlPhone}}">{{phone}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_footer border-top-tran" id="footer_bg">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="mb-md-0 text-center text-md-left" [ngStyle]="{'color': logoColor}">© {{actualYear}}. Hecho con <i class="fa fa-heart" [ngStyle]="{'color': logoColor}"></i> por <a href="https://loclog.mx/modules/shops" target="_blank" [ngStyle]="{'color': logoColor}"><b>LocLog</b></a></p>
                </div>
                <div class="col-md-6">
                    <ul class="footer_payment text-center text-lg-right">
                        <li class="mr-1">
                            <a href="#"><img alt="visa" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/visa.png'"></a>
                        </li>
                        <li class="mr-1">
                            <a href="#"><img alt="discover" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/discover.png'"></a>
                        </li>
                        <li class="mr-1">
                            <a href="#"><img alt="master_card" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/master_card.png'"></a>
                        </li>
                        <li class="mr-1">
                            <a href="#"><img alt="paypal" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/paypal.png'"></a>
                        </li>
                        <li>
                            <a href="#"><img alt="amarican_express" [lazyLoad]="ImageSrc ? ImageSrc : 'assets/images/amarican_express.png'"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- END FOOTER -->