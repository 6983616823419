<div class="modal-body rounded" style="margin-left: auto; margin-right: auto;">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="row">
        <div class="col-12 col-md-6 mb-4 mb-md-0">
            <div class="product-image pl-3 pr-3 pl-md-0 pr-md-0">
                <div class="product_img_box text-center">
                    <a (click)="showDetail()" style="cursor: pointer;"  *ngIf="product.attribute_types === 'attributes'">
                        <img *ngIf="product.images.length > 0" [src]="product.images[0].url" alt="product_img1" (error)="updateUrl(product, undefined)" width="auto" height="auto" max-width="300px">
                        <img *ngIf="product.images == undefined || product.images.length == 0" src="assets/images/product_img1.jpg" alt="product_img1" width="auto" height="auto" max-width="300px">
                    </a>
                    <a (click)="showDetail()" style="cursor: pointer;"  *ngIf="product.attribute_types === 'variations'">
                        <img *ngIf="variantSelected.images.length > 0" [src]="variantSelected.images[0].url" alt="product_img1" (error)="updateUrl(product, variantSelected)" width="auto" height="auto" max-width="300px">
                        <img *ngIf="variantSelected.images == undefined || variantSelected.images.length == 0" src="assets/images/product_img1.jpg" alt="product_img1" width="auto" height="auto" max-width="300px">
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="pr_detail">
                <div class="product_description col-12">
                    <h6 class="product_title mt-1 mt-lg-4"><a (click)="showDetail()" style="cursor: pointer;">{{product.title}}</a></h6>
                    <div *ngIf="product.has_discount" class="product_price">
                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
                        <del>{{product.retail_price | currency}} {{product.currency}}</del>
                        <div class="on_sale">
                            <span>{{product.discount}}% descuento</span>
                        </div>
                    </div>
                    <div *ngIf="!product.has_discount" class="product_price">
                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price | currency}} {{product.currency}}</span>
                    </div>
                </div>
                <div class="col-12">
                        <div *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 2">
                            <div class="pr_switch_wrap">
                                <br *ngIf="variantSelected.variant1.length === 1 && variantSelected.variant2.length >= 1"/><br *ngIf="variantSelected.variant1.length === 1 && variantSelected.variant2.length >= 1"/>
                                <span class="switch_lable" *ngIf="variantSelected.variant1.length === 1">{{variantSelected.variant1_selected.name}}: <b style="text-transform: uppercase;">{{variantSelected.variant1_selected.value_name}}</b></span><br/>
                                <div ngbDropdown class="product_color_switch" *ngIf="variantSelected.variant1.length > 1" style="margin-top: -20px;">
                                    <button class="btn dash-btn-outline-1 col-12 text-left" id="drop1" (mouseover)="isHover(true, 'drop1')" (mouseleave)="isHover(false, 'drop1')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;">{{variantSelected.variant1_selected.name}}: <b style="text-transform: uppercase;">{{variantSelected.variant1_selected.value_name}}</b><i class="fa fa-chevron-down" style="float: right; margin-top: 2%;"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="drop1" style="max-height: 150px;overflow-y: scroll;">
                                        <button class="text-left" ngbDropdownItem *ngFor="let variation of variantSelected.variant1; let position = index;" [hidden]="variation.value_name === variantSelected.variant1_selected.value_name" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;" (click)="selectVariationLevel1(variation)">{{variation.value_name}}</button>
                                    </div>
                                </div><br/>
                                <span class="switch_lable" style="margin-top: -15px;">{{variantSelected.variant2_selected.name}}: <b style="text-transform: uppercase;">{{variantSelected.variant2_selected.value_name}}</b></span><br/>
                                <div class="product_size_switch" *ngIf="variantSelected.variant2.length > 1" style="margin-top: -5px;">
                                    <span class="ml-1 mr-1 noselect" *ngFor="let variation of variantSelected.variant2; let position = index;" id="variant2_{{position}}" [ngClass]="{'active': variation.combinations[1].value_name === variantSelected.variant2_selected.value_name}" (click)="selectVariationLevel2(variation, position)" style="text-transform: uppercase;">{{variation.combinations[1].value_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 1">
                            <div class="pr_switch_wrap">
                                <br *ngIf="variantSelected.variant1.length === 1"/><br *ngIf="variantSelected.variant1.length === 1"/>
                                <span class="switch_lable" *ngIf="variantSelected.variant1.length === 1">{{variantSelected.variant1_selected.combinations[0].name}}: <b style="text-transform: uppercase;">{{variantSelected.variant1_selected.combinations[0].value_name}}</b></span><br/>
                                <div ngbDropdown class="product_color_switch" *ngIf="variantSelected.variant1.length > 1" style="margin-top: -20px;">
                                    <button class="btn dash-btn-outline-1 col-12 text-left" id="drop2"  (mouseover)="isHover(true, 'drop2')" (mouseleave)="isHover(false, 'drop2')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{variantSelected.variant1_selected.combinations[0].name}}: <b>{{variantSelected.variant1_selected.combinations[0].value_name}}</b><i class="fa fa-chevron-down" style="float: right; margin-top: 2%;"></i></button>
                                    <div ngbDropdownMenu aria-labelledby="drop2" style="max-height: 150px;overflow-y: scroll;">
                                        <button class="text-left" ngbDropdownItem *ngFor="let variation of variantSelected.variant1; let position = index;" [hidden]="variation.combinations[0].value_name === variantSelected.variant1_selected.combinations[0].value_name" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;" (click)="selectVariationLevel1(variation)">{{variation.combinations[0].value_name}}</button>
                                    </div>
                                </div><br/>
                            </div>
                        </div>
                        <br *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 2 && variantSelected.variant2.length > 1"/>
                        <div class="quantity" *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 2" style="margin-top: -10px;">
                            <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', variantSelected)" [disabled]="counter <= 1">
                            <input (keyup)="listenQuantity($event, variantSelected)" type="text" name="quantity" value="{{counter}}" title="Qty" class="qty" max="{{variantSelected.stock}}" min="1">
                            <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', variantSelected)" [disabled]="counter >= variantSelected.stock">
                        </div>
                        <div class="quantity" *ngIf="product.attribute_types === 'variations' && product.variations_type.split('&').length === 1" style="margin-top: -20px;">
                            <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', variantSelected)" [disabled]="counter <= 1">
                            <input (keyup)="listenQuantity($event, variantSelected)" type="text" name="quantity" value="{{counter}}" title="Qty" class="qty" max="{{variantSelected.stock}}" min="1">
                            <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', variantSelected)" [disabled]="counter >= variantSelected.stock">
                        </div>
                        <div *ngIf="product.attribute_types === 'variations'">
                            <span class="price" *ngIf="variantSelected.stock > 0 && !showStock" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">Disponible</span>
                            <span class="price" *ngIf="variantSelected.stock >= 1 && variantSelected.stock !== 1 && showStock" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">{{ variantSelected.stock}} disponibles</span>
                            <span class="price" *ngIf="variantSelected.stock >= 1 && variantSelected.stock === 1 && showStock" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">{{ variantSelected.stock}} disponible</span>
                            <span class="price text-danger" *ngIf="variantSelected.stock === 0" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">No disponible</span>
                        </div>
                        <br *ngIf="product.attribute_types === 'attributes' && product.stock > 0"/>
                        <br *ngIf="product.attribute_types === 'attributes' && product.stock > 0"/>
                        <!-- <div class="quantity" *ngIf="product.attribute_types === 'attributes' && product.stock >0">
                            <input type="button" value="-" class="minus" (click)="actionInCart(1, 'less', undefined)" [disabled]="counter <= 1">
                            <input (keyup)="listenQuantity($event, undefined)" type="text" name="quantity" value="{{counter}}" title="Qty" class="qty" max="{{product.stock}}" min="1">
                            <input type="button" value="+" class="plus" (click)="actionInCart(1, 'more', undefined)" [disabled]="counter >= product.stock">
                        </div> -->
                        <!-- <div *ngIf="product.attribute_types === 'attributes'">
                            <span class="price" *ngIf="product.stock > 0 && !showStock" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">Disponible</span>
                            <span class="price" *ngIf="product.stock >= 1 && product.stock !== 1 && showStock" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">{{ product.stock}} disponibles</span>
                            <span class="price" *ngIf="product.stock >= 1 && product.stock === 1 && showStock" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">{{ product.stock}} disponible</span>
                            <span class="price text-danger" *ngIf="product.stock === 0" style="font-size: 90%;" [ngStyle]="{'color': colorTheme2}">No disponible</span>
                        </div> -->
                        <div *ngIf="product.attribute_types === 'attributes' && product.stock === 0" class="mb-2 text-center">
                          <span class="price badge badge-danger text-white" style="font-size: 90%;">No disponible</span>
                        </div>
                        <app-add-cart [product]="product" [src]="'quickview'" [colorTheme]="colorTheme"></app-add-cart>
                </div>
                <!-- <div>
                    <button *ngIf="(product.attribute_types === 'attributes' && product.stock <=0) || (product.attribute_types === 'variations' && variantSelected.stock <= 0)" style="text-transform: uppercase;" class="btn col-12 mb-2 mt-3 rounded-pill" type="button" (click)="showPreorderModal(product)" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-bell"></i> ¡Avísame cuando llegue!</button>
                    <button *ngIf="(product.attribute_types === 'attributes' && product.stock >0) || (product.attribute_types === 'variations' && variantSelected.stock > 0)" style="text-transform: uppercase;" class="btn col-12 mb-1 mt-3 rounded-pill" type="button" (click)="addToCart(product)" id="add_cart" [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-basket-loaded"></i> Añadir a carrito</button><br/>
                    <button *ngIf="(product.attribute_types === 'attributes' && product.stock >0) || (product.attribute_types === 'variations' && variantSelected.stock > 0)" style="text-transform: uppercase;" class="btn col-12 rounded-pill" type="button" (click)="showDetail()" id="show_detail"  [ngStyle]="{'background': colorTheme, 'color': 'white'}"><i class="icon-eye"></i> VER MÁS</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
