<header class="header_wrap fixed-top header_with_topbar">
    <div class="bottom_header dark_skin main_menu_uppercase">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" (click)="goTo(['', '/'])" style="cursor: pointer;" *ngIf="urlLogo !== ''">
                    <img class="logo_dark" alt="logo"  width="200px" height="auto" src="{{urlLogo}}"/>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false">
                    <span class="ion-android-menu"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-start pl-5" *ngIf="!showIconSearch">
                    <form class="form-inline active-cyan-2 col-12">
                        <input [value]="querySearch" (keyup.enter)="searchQuery()" (keyup)="escucharValoresInput($event, 'searchByTitle')" class="form-control form-control-sm mr-3 col-xl-10 col-lg-9" type="text" placeholder="Buscar productos" aria-label="Buscar productos" [ngStyle]="{'border-bottom': '1px solid '+colorSearch}">
                        <a style="cursor: pointer;" (click)="searchQuery()"><i class="fa fa-search" [ngStyle]="{'background': colorSearch}" aria-hidden="true"></i> </a>
                    </form>
                </div>
                <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav" *ngIf="!isBasic">
                        <li style="cursor: pointer;" (mouseover)="isHover(true, 1)" (mouseleave)="isHover(false, 1)">
                            <a class="nav-link" (click)="goTo(['', '/'])" id="link_1">INICIO</a>
                        </li>
                        <li style="cursor: pointer;" (mouseover)="isHover(true, 2)" (mouseleave)="isHover(false, 2)">
                            <a class="nav-link" (click)="goTo(['/tienda'])" id="link_2">TIENDA</a>
                        </li>
                        <li style="cursor: pointer;" (mouseover)="isHover(true, 3)" (mouseleave)="isHover(false, 3)">
                            <a class="nav-link" (click)="goTo(['', 'ofertas'])" id="link_3">OFERTAS</a>
                        </li>
                        <li style="cursor: pointer;" (mouseover)="isHover(true, 4)" (mouseleave)="isHover(false, 4)">
                            <a class="nav-link" (click)="goTo(['', 'contacto'])" id="link_4">CONTACTO</a>
                        </li>
                    </ul>
                </div>
                <ul class="navbar-nav attr-nav align-items-center">
                    <!-- <li (click)="showSearchOption(true)" style="margin-right: -10px;margin-top: 3px;cursor: pointer;" class="d-lg-none"><a class="nav-link" id="link_5" (mouseover)="isHover(true, 5)" (mouseleave)="isHover(false, 5)"><i class="linearicons-magnifier" style="font-size: 120%;"></i></a></li> -->
                    <!-- <li *ngIf="showIconSearch" (click)="showSearchOption(true)" style="margin-right: -10px;margin-bottom: 3px;cursor: pointer;" class="d-none d-lg-block"><a class="nav-link" id="link_6" (mouseover)="isHover(true, 6)" (mouseleave)="isHover(false, 6)"><i class="linearicons-magnifier" style="font-size: 120%;"></i></a></li> -->
                    <app-settings-mobile></app-settings-mobile>
                </ul>
            </nav>
        </div>
    </div>
</header>

<div class="search_wrap" *ngIf="showSearch">
    <span class="close-search mr-3 mr-md-5 mr-xl-0" (click)="showSearchOption(false)"><i class="ion-ios-close-empty"></i></span>
    <div class="row col-12 col-md-11 offset-md-1">
        <form *ngIf="querySearch2 === '' && querySearch3 === ''" class="form-inline md-form form-sm active-cyan-2 ml-5 ml-lg-3 ml-xl-0 ml-lg-0">
            <input [value]="querySearch" (keyup.enter)="searchQuery()" (keyup)="escucharValoresInput($event, 'searchByTitle')" class="form-control form-control-sm mr-3 w-75" type="text" placeholder="Buscar productos" aria-label="Buscar productos" [ngStyle]="{'border-bottom': '1px solid '+colorSearch}">
            <a style="cursor: pointer;" (click)="searchQuery()"><i class="fa fa-search" [ngStyle]="{'background': colorSearch}" aria-hidden="true"></i> </a>
        </form>
    </div>
</div><div class="search_overlay" *ngIf="showSearch"></div>
