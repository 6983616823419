<div class="modal-body" style="margin-left: auto; margin-right: auto;">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <!-- <input [(ngModel)]="user.name" type="text">
    <input [(ngModel)]="user.age" type="number">
    <button (click)="passBack()" type="submit" class="btn btn-primary w-100">Pass back</button> -->
    <div class="row">
        <div class="col-12 col-md-3 mb-2 mb-md-0">
            <div class="product-image pl-3 pr-3 pl-md-0 pr-md-0">
                <div class="product_img_box">
                    <!-- <img id="product_img" src='assets/images/product_img1.jpg' data-zoom-image="assets/images/product_zoom_img1.jpg" alt="product_img1" /> -->
                    <a (click)="showDetail()" style="cursor: pointer;" *ngIf="product.attribute_types === 'attributes'">
                        <img *ngIf="product.images[0] !== undefined" [src]="product.images[0].url" alt="product_img1" width="100%" height="auto" max-width="300px">
                        <img *ngIf="product.images[0] === undefined" src="assets/images/product_img1.jpg" alt="product_img1" width="100%" height="auto" max-width="300px">
                    </a>
                    <a (click)="showDetail()" style="cursor: pointer;" *ngIf="product.attribute_types === 'variations'">
                        <img *ngIf="product.variation !== undefined" [src]="product.variation.images[0].url" alt="product_img1" width="100%" height="auto" max-width="300px">
                        <img *ngIf="product.variation === undefined" src="assets/images/product_img1.jpg" alt="product_img1" width="100%" height="auto" max-width="300px">
                    </a>
                </div>
            </div>
        </div>
        <div class="pr_detail col-12 col-md-9">
            <div class="product_description col-12 mt-0 mt-md-2 mb-3">
                <h6 class="product_title" *ngIf="product.attribute_types === 'attributes'"><a (click)="showDetail()" style="cursor: pointer;"><i class="fa fa-check-circle text-success mr-1"></i>{{product.title}} se agregó correctamente a su carrito</a></h6>
                <h6 class="product_title" *ngIf="product.attribute_types === 'variations'"><a (click)="showDetail()" style="cursor: pointer;"><i class="fa fa-check-circle text-success mr-1"></i>{{product.title}} se agregó correctamente a su carrito <br *ngIf="product.variation.variant1_selected.id !== undefined"/><br *ngIf="product.variation.variant1_selected.id !== undefined"/><b *ngIf="product.variation.variant1_selected.id !== undefined">{{product.variation.variant1_selected.name}}:</b> <b class="ml-1" style="font-weight: normal;" *ngIf="product.variation.variant1_selected.id !== undefined">{{product.variation.variant1_selected.value_name}}</b><b class="ml-4" *ngIf="product.variation.variant2_selected.id !== undefined">{{product.variation.variant2_selected.name}}:</b> <b class="ml-1" style="font-weight: normal;" *ngIf="product.variation.variant2_selected.id !== undefined">{{product.variation.variant2_selected.value_name}}</b></a></h6>
            </div>
            <div class="col-12 mb-2">
                <button (click)="showCart()" class="btn col-12 text-white" id="gocart"><i class="fa fa-shopping-cart mr-2"></i>VER CARRITO</button>
            </div>
            <div class="col-12 mb-2">
                <button (click)="goToConfirm()" class="btn col-12 text-white" id="gopay"><i class="fa fa-shopping-cart mr-2"></i>CONFIRMAR PEDIDO</button>
            </div>
            <!-- <div class="col-12 mb-2">
                <button (click)="activeModal.dismiss('Cross click')" class="btn btn-fill-out col-12 text-white"><i class="fa fa-shopping-basket mr-2"></i>SEGUIR COMPRANDO</button>
            </div> -->
        </div>
    </div>
</div>