import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`
  ]
})
export class CartModalComponent implements OnInit {

  @Input() public product;
  public counter = 1;
  private tieneVariantes = false;
  private varianteSeleccionada = undefined;
  public colorTheme = '';

  constructor(private service: CustomStoreService, private styling: StyleManagerService, public activeModal: NgbActiveModal, private modalService: NgbModal, private router: Router, private productService: ProductServiceService) {
  }

  ngOnInit() {
    this.checkLocalStorage();
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.styling.setColor(`gocart`, 'background', this.colorTheme);
    //   this.styling.setColor(`gocart`, 'color', 'white');
    //   this.styling.setColor(`gopay`, 'background', this.colorTheme);
    //   this.styling.setColor(`gopay`, 'color', 'white');
    // }
  }


  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.styling.setColor(`gocart`, 'background', this.colorTheme);
    //   this.styling.setColor(`gocart`, 'color', 'white');
    //   this.styling.setColor(`gopay`, 'background', this.colorTheme);
    //   this.styling.setColor(`gopay`, 'color', 'white');
    // }

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.styling.setColor(`gocart`, 'background', this.colorTheme);
          this.styling.setColor(`gocart`, 'color', 'white');
          this.styling.setColor(`gopay`, 'background', this.colorTheme);
          this.styling.setColor(`gopay`, 'color', 'white');
        }
      }
    });
  }

  showDetail(){
    this.productService.actualizarDetalleProducto(this.product);
    this.router.navigate(['', 'p', this.product.slug]);
    this.activeModal.close('Modal Closed');
  }

  showCart(){
    this.router.navigate(['/carrito']);
    this.activeModal.close('Modal Closed');
  }

  goToConfirm(){
    this.router.navigate(['', 'pagar']);
    this.activeModal.close('Modal Closed');
  }

  async addToCart(product){
    if (product.has_color === true || product.has_size === true){
      this.tieneVariantes = true;
    }
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product, this.tieneVariantes, this.varianteSeleccionada);
  }

}
