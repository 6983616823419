import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  styles: [
    `a {
      color: white
    }`,
    `a:hover {
      color: #000000;
    }`,
    `.header_list>li .dropdown-item:hover,
    .custome_dropdown .ddChild li:hover,
    .contact_detail>li a:hover {
        color: #000000;
    }`,
    `.top-header {
      background: var(--colorThemePrimary);
    }`
  ]
})
export class HeaderComponent implements OnInit {

  public usuarioLogeado = false;
  public phoneNumber = '';
  public urlPhone = '';
  public colorTheme = '';
  private colorDefault = '#0097db';
  public colorText = '#333'

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private auth: ShopsAuthAngularService, private router: Router) {
    const token = this.auth.getToken();
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    if (token !== null && isExpired !== true) {
      this.usuarioLogeado = true;
    } else {
      this.usuarioLogeado = false;
    }
  }

  ngOnInit() {
    this.styling.setColor('header_bg', 'background', this.colorDefault);
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1DT0xPUg==') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1DT0xPUg==') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.phone !== ''){
          this.phoneNumber = response.phone;
          this.urlPhone = 'tel:' + response.phone.replace(/\s/g, '');
        }
        if(response.color !== ''){
          setTimeout(()=> {
            this.styling.setColor('header_bg', 'background', response.color);
          }, 100);
          this.colorTheme = response.color;
          this.colorText = this.styling.invertColor(response.color, true);
        }
      }
    });

    // const phoneNumber = atob(localStorage.getItem('SU5GTy1QSE9ORQ=='));
    // if(phoneNumber !== ''){
    //   this.phoneNumber = phoneNumber;
    //   this.urlPhone = 'tel:' + phoneNumber.replace(/\s/g, '');
    // }
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorText = this.styling.invertColor(colorTheme, true);
    // }
  }

  goTo(route){
    this.router.navigate(route);
  }
isHover(hover, pos){
  if(hover){
    this.styling.setColor(`id_${pos}`, 'color', this.colorText);
  } else {
    this.styling.setColor(`id_${pos}`, 'color', this.colorText);
  }
}

}
