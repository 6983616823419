<div class="dontPrint">
    <app-header></app-header>
    <app-navbar></app-navbar>
</div>
<!-- START MAIN CONTENT -->
<div class="main_content" id="printableArea">
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-5 mt-5">
                    <!-- <div class="col-12 mb-5 mt-5" *ngIf="!changePaymentSource"> -->
                    <div class="row mb-5" *ngIf="order_id !== ''">
                        <div class="col-md-12 mb-0 text-center">
                            <div class="order_complete">
                                <div class="col-12 text-center mt-0">
                                  <img class="mb-4" width="200px" height="auto" src="{{urlLogo}}" />
                                </div>

                                <!-- <i class="fa fa-exclamation-circle text-danger" *ngIf="payment_ref.declined_payment"></i>
                                <div class="heading_s1">
                                    <h3 style="text-transform: uppercase;" *ngIf="!payment_ref.declined_payment && tags_order === undefined">¡Muchas gracias!</h3>
                                    <h3 style="text-transform: uppercase;" *ngIf="payment_ref.declined_payment">¡Ocurrió un problema con tu forma de pago!</h3>
                                </div> -->
                                <!-- TIPO DE ENVIO -->
                                <span *ngIf="delivery_type === 'store'"  class="badge badge-info4 mr-3">En sucursal</span>
                                <span *ngIf="delivery_type === 'home'"  class="badge badge-info5 mr-3">A domicilio</span>
                                <!-- ESTADO DE PEDIDO -->
                                <!-- <span *ngIf="existStatus(tags_order, 'pending').length > 0 && status !== 'cancelled'" class='badge mb-3 badge-warning2'><b>Sin preparar</b></span>
                                <span *ngIf="existStatus(tags_order, 'handling').length > 0 && status !== 'cancelled'" class='badge mb-3 badge-warning'><b>En preparación</b></span>
                                <span *ngIf="existStatus(tags_order, 'ready_to_ship').length > 0 && status !== 'cancelled'" class='badge mb-3 badge-warning2'><b>En recolección</b></span>
                                <span *ngIf="existStatus(tags_order, 'in_transit').length > 0 && status !== 'cancelled'" class='badge mb-3 badge-info'><b>En camino</b></span>
                                <span *ngIf="existStatus(tags_order, 'delivered').length > 0 && status !== 'cancelled'" class='badge mb-3 badge-success'><b>Entregado</b></span>
                                <span *ngIf="existStatus(tags_order, 'not_delivered').length > 0 && (status === 'partially_paid' || status === 'paid')" class='badge mb-3 badge-danger'><b>No entregado</b></span>
                                <span *ngIf="existStatus(tags_order, 'refunded').length > 0 && (status === 'partially_paid' || status === 'paid')" class='badge mb-3 badge-info2'><b>Reembolsado</b></span>
                                <span *ngIf="existStatus(tags_order, 'partially_refunded').length > 0 && (status === 'partially_paid' || status === 'paid')" class='badge mb-3 badge-info2'><b>Reembolso parcial</b></span>
                                <span *ngIf="status === 'cancelled'" class='badge mb-3 badge-danger'><b>Cancelado</b></span> -->
                                <span *ngIf="orderInfo.status === 'pending'" class='badge mb-3 badge-warning2'><b>Sin preparar</b></span>
                                <span *ngIf="orderInfo.status === 'confirmed'" class='badge mb-3 badge-warning'><b>Confirmado</b></span>
                                <span *ngIf="orderInfo.status === 'supplying'" class='badge mb-3 badge-success'><b>Surtiendo</b></span>
                                <span *ngIf="orderInfo.status === 'packing'" class='badge mb-3 badge-warning'><b>En preparación</b></span>
                                <span *ngIf="orderInfo.status === 'ready_to_ship'" class='badge mb-3 badge-info'><b>Listo para envio</b></span>
                                <span *ngIf="orderInfo.status === 'in_transit'" class='badge mb-3 badge-info'><b>En camino</b></span>
                                <span *ngIf="orderInfo.status === 'deliveried'" class='badge mb-3 badge-success'><b>Entregado</b></span>
                                <span *ngIf="orderInfo.status === 'not_deliveried'" class='badge mb-3 badge-danger'><b>No entregado</b></span>
                                <span *ngIf="orderInfo.status === 'cancelled'" class='badge mb-3 badge-danger'><b>Cancelado</b></span>
                                <!-- ETIQUETADO ANTERIOR -->
                                <!-- <p style="text-transform: none;" *ngIf="!payment_ref.declined_payment && (status === 'confirmed' || status === 'payment_in_process')">Aún no se ha registrado un pago para tu pedido</p> -->
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'pending'">El pedido se ha realizado con éxito</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'confirmed'">Hemos confirmado tu pedido</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'supplying'">Estamos surtiendo tu pedido</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'packing'">Estamos preparando tu pedido</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'ready_to_ship'">Tu pedido esta pendiente de ser enviado</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'in_transit'">Tu pedido esta en camino</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'deliveried'">Tu pedido ha sido entregado</p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'not_deliveried'">No pudimos entregar tu pedido. <b>¡Comunícate con nosotros!</b></p>
                                <p style="text-transform: none;" *ngIf="orderInfo.status === 'cancelled'">El pedido se encuentra cancelado</p>
                                <p style="text-transform: none;"><b>Folio de pedido:</b> <b [ngStyle]="{'color': colorTheme}"> {{order_id}}</b></p>
                            </div>
                            <!-- <br/>
                            <br/> -->
                        </div>
                        <div class="col-lg-10 offset-lg-1 mt-0 mb-5 text-center">
                          <div class="row order-success-sec" style="margin-top: 0px;">
                            <div class="col-12 mb-4" id="btn_payment" *ngIf="paymentSource">
                              <button (click)="open(referencias_de_pago)" *ngIf="!paymentSource.automatic" class="btn btn-success pt-2 pb-2" [ngStyle]="{'background': colorTheme, 'border-color': colorTheme}" style="text-transform: none;"><i class="fa fa-money-bill mr-2"></i>Referencia de pago</button>
                              <button *ngIf="paymentSource.automatic && (orderInfo.paidAt === undefined || orderInfo.paidAt === null)" class="btn btn-success pt-2 pb-2" [ngStyle]="{'background': colorTheme, 'border-color': colorTheme}" style="text-transform: none;"><i class="fa fa-money-bill mr-2"></i>Pagar ahora</button>
                            </div>
                              <div class="col-12" *ngIf="delivery_type === 'home' && infoAddress !== null">
                                  <h4>DIRECCIÓN DE ENVÍO</h4>
                                  <ul class="order-detail" style="list-style: none;">
                                      <li style="text-transform: uppercase;"><b style="font-weight: normal;">{{infoAddress.address_line}}</b></li>
                                  </ul>
                              </div>
                              <div class="col-12" *ngIf="delivery_type === 'store' && infoAddress !== null">
                                  <h4>DIRECCIÓN DE RECOLECCIÓN</h4>
                                  <ul class="order-detail" style="list-style: none;">
                                      <li style="text-transform: uppercase;">{{infoAddress.name}}</li>
                                      <li style="text-transform: uppercase;">{{infoAddress.address_line}}</li><br/>
                                      <!-- <li style="text-transform: uppercase;">Horario comercial <b>{{direccion.open_hours}}</b></li> -->
                                  </ul>
                              </div>
                          </div>
                      </div>
                        <div class="col-lg-10 offset-lg-1 text-center">
                            <div class="product-order ">
                                <h3>DETALLE DE PEDIDO</h3>
                                <br/>
                                <div class="row product-order-detail ">
                                    <table class="table table-bordernone mr-2 ml-3 ">
                                        <thead>
                                            <tr>
                                                <th class="text-left "><b>Cant.</b></th>
                                                <th class="text-center "><b>Producto</b></th>
                                                <th class="text-right "><b>Precio</b></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let producto of orderItems">
                                            <tr>
                                                <td class="text-left pl-4 ">{{producto.quantity}}</td>
                                                <td class="text-center ">{{producto.name}}
                                                </td>
                                                <td class="text-right ">{{producto.price | currency}} {{producto.currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="total-sec text-right" style="list-style: none;">
                                    <h4>
                                        <li>Subtotal: <span [ngStyle]="{'color': colorTheme}" style="margin-left: 5px;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;"><b>{{orderInfo.amount | currency}} {{subtotalCurrency}}</b></span></li>
                                    </h4>
                                    <h4 *ngIf="orderInfo.discount_amount > 0">
                                        <li>Descuento: <span [ngStyle]="{'color': colorTheme}" style="margin-left: 5px;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;"><b>{{orderInfo.discount_amount | currency}} {{subtotalCurrency}}</b></span></li>
                                    </h4>
                                    <h4>
                                        <li>Envío: <span [ngStyle]="{'color': colorTheme}" style="margin-left: 5px;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;"><b>{{orderInfo.shipping_amount | currency}} {{subtotalCurrency}}</b></span></li>
                                    </h4>
                                    <h4>
                                        <li>Total: <span [ngStyle]="{'background': colorBg, 'color': colorText}" style="margin-left: 5px;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;"><b>{{orderInfo.total_amount | currency}} {{subtotalCurrency}}</b></span></li>
                                    </h4>
                                </div>
                                <h3 style="margin-top: 60px;" *ngIf="itemsNotSupply.length > 0">PRODUCTOS NO SURTIDOS</h3>
                                <div *ngIf="itemsNotSupply.length > 0" class="row product-order-detail ">
                                    <table class="table table-bordernone mr-2 ml-3 ">
                                        <thead>
                                            <tr>
                                                <th class="text-left "><b>Cant.</b></th>
                                                <th class="text-center "><b>Producto</b></th>
                                                <th class="text-right "><b>Precio</b></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let producto of itemsNotSupply">
                                            <tr>
                                                <td class="text-left pl-4 ">{{producto.qty_diff}}</td>
                                                <td class="text-center ">{{producto.name}}
                                                </td>
                                                <td class="text-right ">{{producto.price | currency}} {{producto.currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br/>
                            <button (click)="printTicket()" class="btn col-12 mt-3 mt-lg-0 dontPrint" [ngStyle]="{'border-color': colorTheme, 'color': colorTheme}" style="text-transform: none;"><i class="fa fa-print mr-2 "></i> Imprimir ticket</button>
                        </div>
                    </div>
                    <div class="row mb-5 col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3" *ngIf="order_id === ''">
                        <p *ngIf="isAuth" class="mb-4 ml-2 mr-2 text-left" style="text-transform: none;"><b *ngIf="nombres !== ''">¡Hola {{nombres}}! </b>Estamos buscando su pedido, por favor espere...</p>
                        <p *ngIf="!isAuth" class="mb-4 ml-2 mr-2 text-left" style="text-transform: none;">Por motivos de seguridad, si hiciste la <b>compra como invitado</b> deberás ingresar el teléfono de contacto que colocaste al realizar tu pedido</p>
                        <div class="form-group row" *ngIf="!isAuth">
                            <label class="col-12" for="pais"><span class="text-danger" *ngIf="telefono_recibe === '' || telefono_recibe === undefined || telefono_recibe.length < 10"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="telefono_recibe !== '' && telefono_recibe !== undefined && telefono_recibe.length === 10"><i class="fa fa-check-circle"></i></span> Teléfono de contacto</label>
                            <div ngbDropdown class="product_color_switch row col-11 col-md-5 col-xl-3 mr-md-2 pr-1 ml-1">
                                <button class="btn btn-click3 col-12 text-left" id="phonenumber" (mouseover)="isHover(true, 'phonenumber')" (mouseleave)="isHover(false, 'phonenumber')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;" [disabled]="disabledBtn">{{flagPais}} {{paisSeleccionado}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 2%;"></i></button>
                                <div ngbDropdownMenu aria-labelledby="phonenumber" style="max-height: 300px;overflow-y: scroll;">
                                    <button class="text-left" ngbDropdownItem *ngFor="let pais of paises ; let positionPais=index" (click)="selectDropdown(pais, 'country')" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{pais.flag}} {{pais.dial_code}}</button>
                                </div>
                            </div>
                            <input (keyup.enter)="confirmarNumero()" type="tel" class="form-control col-11 col-md-6 col-xl-8 ml-md-1 ml-3 mt-2 mt-md-0" placeholder="10 dígitos" id="pais" value="{{telefono_recibe}}" (keyup)="escucharValoresInput($event, 'telefono_recibe')" [disabled]="disabledBtn">
                        </div>
                        <button *ngIf="!isAuth" id="confirm_order" (click)="confirmarNumero()" class="btn btn-click2 text-white btn-block" [ngStyle]="{'background': colorTheme}" [disabled]="disabledBtn">CONFIRMAR</button>
                        <div class="form-note text-center mt-3">Si hiciste la <b>compra con tu cuenta</b>, podrás consultar tu historial de pedidos <a [routerLink]="['', 'ingresar']" id="signin" (mouseover)="isHover2(true, 'signin')" (mouseleave)="isHover2(false, 'signin')">desde aquí</a></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
<!-- END MAIN CONTENT -->
<div class="dontPrint">
    <app-footer></app-footer>
</div>


<ng-template #referencias_de_pago let-modal>
    <div class="modal-header text-center bg-white" style="vertical-align: middle;">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 130%;" [ngStyle]="{'color': colorBg}"><b>REFERENCIA DE PAGO</b></h4>
        <a (click)="modal.dismiss('Cross click')"><i class="fa fa-times" style="cursor: pointer;" [ngStyle]="{'color': colorBg}"></i></a>
    </div>
    <div class="modal-body bg-white" class="col-12 mt-2">
        <div *ngIf="paymentSource.references.length > 0">
          <ul class="order-detail text-center mb-2 rounded" [ngStyle]="{'background': colorBg, 'color': colorText, 'font-size': textSize}" style="list-style: none;" [hidden]="reference.ref === ''" *ngFor="let reference of paymentSource.references;let position = index;">
              <li class="pt-3 pb-2" style="text-transform: none;" style="margin-left: 5px;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;"><b *ngIf="reference.bank !== ''">({{reference.bank}})</b> <br *ngIf="reference.bank !== ''"/><b>{{paymentSource.title}}</b><br/><i (click)="copyMessage(reference.ref, 'reference')" class="fa fa-clipboard" style="margin-right: 10px;font-size: 100%;cursor: pointer;"></i><b style="font-weight: normal;" [ngStyle]="{'font-size': textSize2}">{{reference.ref}}</b></li>
              <li style="text-transform: none;"><b>{{reference.instructions}}</b></li>
              <hr/>
          </ul>
        </div>
        <div *ngIf="paymentSource.references.length === 0" >
          <ul class="order-detail text-center mb-2 rounded" [ngStyle]="{'background': colorBg, 'color': colorText, 'font-size': textSize}">
            <li class="pt-2 pb-2">{{paymentSource.title}}</li>
          </ul>
        </div>
        <br/>
        <button type="button" class="btn btn-pedidos align-center col-12 mb-3" [ngStyle]="{'background': colorBg, 'color': colorText}" (click)="modal.close()"><b>ENTENDIDO</b></button>
    </div>
</ng-template>
