import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { OffersComponent } from './components/pages/offers/offers.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { MainStoreComponent } from './components/store/main-store/main-store.component';
import { CartComponent } from './components/store/cart/cart.component';
import { CheckoutComponent } from './components/store/checkout/checkout.component';
import { OrderSuccessComponent } from './components/store/order-success/order-success.component';
import { SigninComponent } from './components/account/signin/signin.component';
import { SignupComponent } from './components/account/signup/signup.component';
import { RecoverPasswordComponent } from './components/account/recover-password/recover-password.component';
import { MyaccountComponent } from './components/account/myaccount/myaccount.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { ProductDetailComponent } from './components/store/product-detail/product-detail.component';
import { CartModalComponent } from './components/widgets/cart-modal/cart-modal.component';
import { QuickViewComponent } from './components/widgets/quick-view/quick-view.component';
import { SettingsMobileComponent } from './components/widgets/settings-mobile/settings-mobile.component';
import { SliderComponent } from './components/widgets/slider/slider.component';
import { ServicesComponent } from './components/widgets/services/services.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPrintModule} from 'ngx-print';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';
import { Error402Component } from './components/pages/error402/error402.component';
import { Error403Component } from './components/pages/error403/error403.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PreorderModalComponent } from './components/widgets/preorder-modal/preorder-modal.component';
import { AddCartComponent } from './components/shared/cart/add-cart/add-cart.component';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { ManageOneAddressComponent } from './components/store/manage-one-address/manage-one-address.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPrintModule,
        HttpClientModule,
        CarouselModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        NgbModule,
        LazyLoadImageModule,
        ToastrModule.forRoot(),
        NgxPaginationModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ProgressButtonModule,
        IvyCarouselModule,
        NgxSkeletonLoaderModule
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        NavbarComponent,
        FooterComponent,
        HomeComponent,
        ContactUsComponent,
        OffersComponent,
        FaqComponent,
        MainStoreComponent,
        CartComponent,
        CheckoutComponent,
        OrderSuccessComponent,
        SigninComponent,
        SignupComponent,
        RecoverPasswordComponent,
        MyaccountComponent,
        Error404Component,
        ProductDetailComponent,
        CartModalComponent,
        QuickViewComponent,
        SettingsMobileComponent,
        SliderComponent,
        ServicesComponent,
        Error402Component,
        Error403Component,
        PreorderModalComponent,
        AddCartComponent,
        ManageOneAddressComponent
    ],
    bootstrap: [AppComponent],
    providers: [NgbActiveModal]
})
export class AppModule { }
