import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { EncryptManagerService } from '@ignacioruben7/shops-encrypt-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `a:hover {
      color: var(--colorThemePrimary);
    }`
  ]
})
export class RecoverPasswordComponent implements OnInit {

  public codigoRecuperacion = '';
  public emailConfirmacion = '';
  public confirmarCodigoRecuperacion = false;
  public nuevaContrasenia = '';
  public contraseniaConfirmacion = '';
  public confirmoPorCorreo = false;
  private queryParams;
  public colorTheme = 'rgb(216, 216, 216)';
  public isB2C = environment.isB2C;
  private serviceId = '';

  constructor(private service: CustomStoreService, private deviceService: DeviceDetectorService,private styling: StyleManagerService,private encryption: EncryptManagerService, private toastrService: ToastrService, private router: Router, private route: ActivatedRoute, private api: RestApiServiceService, private auth: ShopsAuthAngularService) {
    this.route.queryParamMap.subscribe(params => {
      this.queryParams = {...params.keys, ...params};
      if(this.queryParams.params.code !== undefined && this.queryParams.params.email !== undefined
        && this.queryParams.params.code.trim() !== '' && this.queryParams.params.email.trim() !== '') {
        this.emailConfirmacion = this.queryParams.params.email;
        this.codigoRecuperacion = this.queryParams.params.code;
        this.confirmarCodigoRecuperacion = true;
        this.confirmoPorCorreo = true;
      }
    });
  }

  getDeviceInfo = async () => {
    let deviceInfo = {} as any;
    deviceInfo = this.deviceService.getDeviceInfo();
    let infoIp = null as any;
    this.api.getPublicIp().subscribe(
      respuesta => {
        if(respuesta.trim() !== ''){
          infoIp = respuesta.trim().split('\n').reduce((obj, pair: any) => {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
          }, {});
        }
        // accessToken = JSON.parse(JSON.stringify(respuesta)).token;
        // estado = JSON.parse(JSON.stringify(respuesta)).code;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }

      }, () => {
        deviceInfo.ip_address = infoIp;
        const base64Code = btoa(JSON.stringify(deviceInfo));
        sessionStorage.setItem('ZGV2aWNlSW5mbw==', base64Code);
      });
  }

  olvideMiContrasenia(){
    if(this.emailConfirmacion !== undefined && this.emailConfirmacion !== null && this.emailConfirmacion.trim() !== '' && this.validateEmail(this.emailConfirmacion)){
      this.toastrService.info('Verificando correo...');
      let estado;
      const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
      this.api.recuperarContraseniaV2(this.emailConfirmacion, infoDevice, this.serviceId).subscribe(
        respuesta => {
          estado = JSON.parse(JSON.stringify(respuesta)).code;
         }, (err: HttpErrorResponse) => {
          this.toastrService.clear();
          if (err.error instanceof Error) {
          } else {
          }
          if (err.status === 403) {
            this.toastrService.warning('Cuenta bloqueada. Solicita acceso al equipo de ventas');
          }
          if (err.status === 400) {
            this.toastrService.error('Error 400');
          }
          if (err.status === 404) {
            this.toastrService.warning('La cuenta no existe');
            this.router.navigate(['/registrarme']);
          }
          if (err.status === 405) {
            window.location.reload();
          }
          if (err.status === 406) {
            this.toastrService.warning('Primero debes de confirmar tu cuenta');
          }
          if (err.status === 429) {
            this.toastrService.warning(err.error.message);
          }
          if (err.status === 500) {
            this.toastrService.error('Error 500');
          }
       }, () => {
         if(estado === 200){
          this.confirmarCodigoRecuperacion = true;
          this.toastrService.success('Te enviamos un código de recuperación');
         } else {
           this.toastrService.error('Ocurrió un error inesperado');
         }
       });
    } else {
      this.toastrService.warning('El correo electrónico no es válido');
    }
  }

  actualizarContrasenia(){
    if(this.codigoRecuperacion === undefined || this.codigoRecuperacion === null || this.codigoRecuperacion.trim() === ''){
      this.toastrService.warning('El código de recuperación es requerido');
    }
    if(this.nuevaContrasenia === undefined || this.nuevaContrasenia === null || this.nuevaContrasenia.trim() === ''){
      this.toastrService.warning('La nueva contraseña es requerida');
    }
    if(this.contraseniaConfirmacion === undefined || this.contraseniaConfirmacion === null || this.contraseniaConfirmacion.trim() === ''){
      this.toastrService.warning('La contraseña de confirmación es requerida');
    }
    if(this.codigoRecuperacion !== undefined && this.codigoRecuperacion !== null && this.codigoRecuperacion.trim() !== '' &&
    this.nuevaContrasenia !== undefined && this.nuevaContrasenia !== null && this.nuevaContrasenia.trim() !== '' &&
    this.contraseniaConfirmacion !== undefined && this.contraseniaConfirmacion !== null && this.contraseniaConfirmacion.trim() !== ''){
      if(this.nuevaContrasenia.trim() === this.contraseniaConfirmacion.trim()){
        // Hacer petición
        const datosUsuario = {
          codigo: this.codigoRecuperacion,
          nueva_contrasenia: this.nuevaContrasenia
        };

        // let privateKey = '';
        // const key = sessionStorage.getItem('a2V5');
        // if(key !== null){
        //   privateKey = key;
        // }
        const info_encriptada = this.encryption.encryptBase64(JSON.stringify(datosUsuario));
        const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
        let estado;
        this.api.actualizarContraseniaV2(info_encriptada, infoDevice, this.serviceId).subscribe(
          respuesta => {
            estado = JSON.parse(JSON.stringify(respuesta)).code;
           }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
            } else {
            }
            if (err.status === 400) {
              this.toastrService.error('Error 400');
            }
            if (err.status === 404) {
              this.toastrService.error('El código de recuperación es incorrecto');
              this.confirmoPorCorreo = false;
            }
            if (err.status === 405) {
              window.location.reload();
            }
            if (err.status === 406) {
              this.toastrService.warning('Primero debes de confirmar tu cuenta');
            }
            if (err.status === 429) {
              this.toastrService.warning(err.error.message);
            }
            if (err.status === 500) {
              this.toastrService.error('Error 500');
            }
         }, () => {
           if(estado === 200){
            this.toastrService.success('Contraseña actualizada');
            this.codigoRecuperacion = '';
            this.nuevaContrasenia = '';
            this.contraseniaConfirmacion = '';
            this.confirmarCodigoRecuperacion = false;
            this.confirmoPorCorreo = false;
            this.router.navigate(['/ingresar']);
           } else {
             this.toastrService.error('Ocurrió un error inesperado');
           }
         });
      } else {
        this.toastrService.warning('Las contraseñas no coinciden');
      }
    }
  }

  ngOnInit() {
    this.getDeviceInfo();
    window.scroll(0,0);
    this.checkLocalStorage();
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Recuperar contraseña`;
    // } else {
    //   titleHeader = `LocLog Shops | Recuperar contraseña`;
    // }
    // this.api.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
        }
        const titleHeader = response.title ? `${response.title} | Recuperar contraseña`:'LocLog Shops | Recuperar contraseña';
        this.api.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.serviceId = response.public;
      }
    });
  }

  isHover2(hover, id){
    if(hover){
      this.styling.setColor(id,'color', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#333');
    }
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'correo'){
      this.emailConfirmacion = valor.trim();
    } else if (idInput === 'codigo'){
      this.codigoRecuperacion = valor.trim();
    } else if (idInput === 'correo'){
      this.emailConfirmacion = valor.trim();
    } else if (idInput === 'nueva_contrasenia'){
      this.nuevaContrasenia = valor;
    } else if (idInput === 'contrasenia_confirmacion'){
      this.contraseniaConfirmacion = valor;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
