import { Component, OnInit } from '@angular/core';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  styles: [
    `.icon_box_style2 .icon i {
      color: var(--colorThemePrimary);
  }`,
  `.bg-service {
    background-color: var(--colorThemePrimary);
  }`
  ]
})
export class ServicesComponent implements OnInit {

  public services = [];
  public colorTheme = '';
  private colorDefault = '#0097db';
  public colorTxt = '#000000';

  constructor(private service: CustomStoreService, private styling: StyleManagerService) { }

  ngOnInit() {
    this.colorTheme = this.colorDefault;
    this.styling.setColor('bg_service', 'background', this.colorDefault);
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    // const services = JSON.parse(localStorage.getItem('bGlzdE9mU2VydmljZXNJY29u'));
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTxt = this.styling.invertColor(colorTheme, true);
    //   this.styling.setColor('bg_service', 'background', this.colorTheme);
    // } else {
    //   this.colorTheme = this.colorDefault;
    // }

    // if(services.length > 0){
    //   this.services = services;
    // } else {
    //   this.defaultService();
    // }

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorTxt = this.styling.invertColor(this.colorTheme, true);
          this.styling.setColor('bg_service', 'background', this.colorTheme);
        }
        if(response.services.length > 0){
          this.services = response.services;
        } else {
          this.defaultService();
        }
      }
    });
  }

  defaultService(){
    this.services = [
      {
        title: 'ENVÍO NACIONAL',
        subtitle: 'Enviamos por FedEx, DHL y Estafeta',
        icon: 'flaticon-shipped'
      },
      {
        title: 'OFERTAS',
        subtitle: 'Contamos con ofertas relámpago',
        icon: 'flaticon-money-back'
      },
      {
        title: 'SERVICIO 24/7',
        subtitle: 'Servicio en línea 24 X 7 por WhatsApp',
        icon: 'flaticon-support'
      }
    ];
  }
}
