<div class="modal-body rounded" *ngIf="!addressDelete" style="margin-left: auto; margin-right: auto;">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
  </button>
  <div class="col-12 row">
    <h4 class="col-12 mb-3">{{titleAction}}</h4>
    <div class="form-group col-12 col-lg-6">
      <label><span class="text-danger" *ngIf="infoAddress.reference === '' || infoAddress.reference === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="infoAddress.reference !== '' && infoAddress.reference !== undefined"><i class="fa fa-check-circle"></i></span> Nombre</label>
      <input type="text" class="form-control" name="billing_address" required placeholder="*Ejemplo: Casa, Suc. Morelia, etc" value="{{infoAddress.reference}}" (keyup)="escucharValoresInput($event, 'reference')">
  </div>
    <div class="form-group col-12 col-lg-6">
        <label><span class="text-danger" *ngIf="infoAddress.street === '' || infoAddress.street === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="infoAddress.street !== '' && infoAddress.street !== undefined"><i class="fa fa-check-circle"></i></span> Calle</label>
        <input type="text" class="form-control" name="billing_address" required placeholder="*Calle" value="{{infoAddress.street}}" (keyup)="escucharValoresInput($event, 'street')">
    </div>
    <div class="form-group col-12 col-md-6">
        <label><span class="text-danger" *ngIf="infoAddress.outdoor_number === '' || infoAddress.outdoor_number === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="infoAddress.outdoor_number !== '' && infoAddress.outdoor_number !== undefined"><i class="fa fa-check-circle"></i></span> Nº exterior</label>
        <input type="text" class="form-control" name="billing_address" required placeholder="*Nº exterior" value="{{infoAddress.outdoor_number}}" (keyup)="escucharValoresInput($event, 'outdoor_number')">
    </div>
    <div class="form-group col-12 col-md-6">
        <label>Nº interior</label>
        <input type="text" class="form-control" name="billing_address" placeholder="Nº interior" value="{{infoAddress.interior_number}}" (keyup)="escucharValoresInput($event, 'interior_number')">
    </div>
    <div class="form-group col-12 col-md-6">
        <label><span class="text-danger" *ngIf="infoAddress.neighborhood === '' || infoAddress.neighborhood === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="infoAddress.neighborhood !== '' && infoAddress.neighborhood !== undefined"><i class="fa fa-check-circle"></i></span> Colonia</label>
        <input type="text" class="form-control" name="billing_address" placeholder="Colonia" value="{{infoAddress.neighborhood}}" (keyup)="escucharValoresInput($event, 'neighborhood')">
    </div>
    <div class="form-group col-12 col-md-6">
        <label><span class="text-danger" *ngIf="infoAddress.city === '' || infoAddress.city === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="infoAddress.city !== '' && infoAddress.city !== undefined"><i class="fa fa-check-circle"></i></span> Municipio</label>
        <input type="text" class="form-control" name="billing_address" placeholder="Municipio" value="{{infoAddress.city}}" (keyup)="escucharValoresInput($event, 'city')">
    </div>
    <div class="form-group col-12 col-md-6">
        <label><span class="text-danger" *ngIf="estadoSeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="estadoSeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Estado</label>
        <div ngbDropdown class="product_color_switch">
            <button *ngIf="estadoSeleccionado === undefined" class="btn btn-click3 col-12 text-left" id="state" (mouseover)="isHover(true, 'state')" (mouseleave)="isHover(false, 'state')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">Seleccione un estado<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 1%;"></i></button>
            <button *ngIf="estadoSeleccionado !== undefined" class="btn btn-click3 col-12 text-left" id="state" (mouseover)="isHover(true, 'state')" (mouseleave)="isHover(false, 'state')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: none;white-space: normal;">{{estadoSeleccionado.nombre}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 1%;"></i></button>
            <div ngbDropdownMenu aria-labelledby="state" style="max-height: 300px;overflow-y: scroll;">
                <button class="text-left" ngbDropdownItem *ngFor="let estado of estados ; let positionEstado=index" (click)="selectDropdown(estado, 'state')" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;">{{estado.nombre}}</button>
            </div>
        </div>
    </div>
    <div class="form-group col-12 col-md-6">
        <label><span class="text-danger" *ngIf="infoAddress.postalcode === '' || infoAddress.postalcode === undefined || infoAddress.postalcode.length !== 5"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="infoAddress.postalcode !== '' && infoAddress.postalcode !== undefined && infoAddress.postalcode.length === 5"><i class="fa fa-check-circle"></i></span> Código postal</label>
        <input type="text" class="form-control" name="billing_address" placeholder="Código postal" value="{{infoAddress.postalcode}}" (keyup)="escucharValoresInput($event, 'postalcode')" maxlength="5">
    </div>
    <div class="form-group col-12">
        <input type="text" class="form-control" name="billing_address2" required="" placeholder="Indicaciones extras" value="{{infoAddress.indications}}" (keyup)="escucharValoresInput($event, 'indications')">
    </div>
    <div class="col-12" style="display: flex;">
      <button  class="col-12 mt-2 mb-2 btn btn-click2 text-white" [ngClass]="{'col-lg-6': infoAddress.id !== ''}" [ngStyle]="{'background': colorTheme}" (click)="checkAddress()" style="text-transform: none;"><b *ngIf="infoAddress.id === ''">Agregar dirección</b><b *ngIf="infoAddress.id !== ''"><i class="fa fa-save mr-2"></i>Actualizar dirección</b></button>
      <button *ngIf="infoAddress.id !== ''" class="col-12 mt-2 mb-2 btn btn-danger text-white col-lg-6" (click)="deleteAddress(infoAddress)" style="text-transform: none;"><b><i class="fa fa-trash mr-2"></i>Eliminar dirección</b></button>
    </div>
</div>
</div>

<div class="modal-body rounded" *ngIf="addressDelete" style="margin-left: auto; margin-right: auto;">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
  </button>
  <div class="row p-3">
    <h4 class="col-12 mb-2 text-danger">{{titleAction}}</h4>
    <h6 class="col-12 mb-2">{{addressLine(addressDelete)}}</h6>
    <p class="form-check-label mb-3 mt-1 col-12">Una vez eliminada la direccion ya no podrás recuperarla.</p>
    <button  class="col-12 mt-2 btn btn-danger text-white btn-block" (click)="confirmDelete()" style="text-transform: none;"><b><i class="fa fa-trash mr-2"></i>Eliminar dirección</b></button>
  </div>
</div>
