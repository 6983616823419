import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.css'],
  styles: [
    `.go_home {
      background: var(--colorThemePrimary);
      color: white;
    }`
  ]
})
export class Error403Component implements OnInit {

  public colorTheme = 'white';
  constructor(private service: CustomStoreService, private api: RestApiServiceService, private titleService: Title) { }

  ngOnInit() {
    window.scroll(0,0);
    this.checkLocalStorage();
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
        }
        const titleHeader = response.title ? `${response.title} | Servicio cancelado`:'LocLog Shops | Servicio cancelado';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Servicio cancelado`;
    // } else {
    //   titleHeader = `LocLog Shops | Servicio cancelado`;
    // }
    // this.titleService.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);
  }

}
