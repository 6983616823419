import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { ProductServiceService } from '../product/product-service.service';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebhookServiceService {

  private webSockect: WebSocket;

  constructor(private route: Router, private auth: ShopsAuthAngularService, private cartItems: ShopsCartAngularService) { }

  openWebsocket(service, userEmail, init = true){
    this.webSockect = new WebSocket(`${environment.socket_url}?service=${service}&type=cart&email=${userEmail}`);
    this.webSockect.onopen = (event) => {
      let carts = [];
      if(localStorage.getItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=") !== null){
        try {
          carts = JSON.parse(atob(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=')));
        } catch (error) {
        }
      }
      if(init){
        setTimeout(() => {
          this.sendCarts(carts);
        }, 1000);
      }
    }
    this.webSockect.onmessage = (event) => {
      this.onReceiveData(event.data);
    }

    this.webSockect.onclose = (event) => {
      if(event.code === 1000){
        // VOLVER ABRIR EL SOCKET
      } else if(event.code === 1003){
        // VOLVER ABRIR EL SOCKET
        // LOS DATOS QUE SE ENVIARON ESTAN EN OTRO FORMATO
      } else if(event.code === 1006){
        // VOLVER ABRIR EL SOCKET
        // SE REINICIO EL SERVIDOR
      } else if(event.code === 1008){
        // SACARLO AL AUTH
        // VIOLACIÓN DE PRIVACIDAD, NO ESTÁ AUTORIZADO
      } else if(event.code === 1011){
        // ERROR INTERNO
        // INTENTAR MAS TARDE
      } else if(event.code === 1013){
        // SUPERÓ EL LÍMITE DE CONEXIONES
        // INTENTAR MAS TARDE
      } else if(event.code === 1014){
        // BAD GATEWAY
        // UNA RUTA ESTÁ MAL
      }
    }
  }

  // closeWebsocket(userEmail){
  //   this.webSockect.send(JSON.stringify({
  //     type: 'close',
  //     service: environment.publicKey,
  //     email: userEmail,
  //     items: null
  //   }));
  // }

  async sendCarts(item, type = 'init_cart'){
    // let userEmail
    if(this.auth.getToken() !== null){
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(this.auth.getToken());
      if(this.webSockect !== undefined){
        if(this.webSockect.readyState === 1){
          if(type === 'init_cart'){
            this.webSockect.send(JSON.stringify({
              type: type,
              service: decodedToken.clave_publica,
              email: decodedToken.correo,
              items: item
            }));
          } else {
            this.webSockect.send(JSON.stringify({
              type: type,
              service: decodedToken.clave_publica,
              email: decodedToken.correo,
              item: item
            }));
          }
        } else {
          this.openWebsocket(decodedToken.clave_publica, decodedToken.correo, false);
          let c = this;
          this.waitForSocketConnection(this.webSockect, function(){
            if(type === 'init_cart'){
              c.webSockect.send(JSON.stringify({
                type: type,
                service: decodedToken.clave_publica,
                email: decodedToken.correo,
                items: item
              }));
            } else {
              c.webSockect.send(JSON.stringify({
                type: type,
                service: decodedToken.clave_publica,
                email: decodedToken.correo,
                item: item
              }));
            }
          });
        }
      } else {
        this.openWebsocket(decodedToken.clave_publica, decodedToken.correo, false);
        let c = this;
        this.waitForSocketConnection(this.webSockect, function(){
          c.webSockect.send(JSON.stringify({
            type: type,
            service: decodedToken.clave_publica,
            email: decodedToken.correo,
            item: item
            // items: carritos
          }));
        });
      }
    }
  }

  isOpenSocket(){
    if(this.webSockect !== undefined){
      if(this.webSockect.readyState === 1){
        return true;
      }
    }
    return false;
  }

  onReceiveData(data){
    try {
      const object = JSON.parse(data);
      if(object.items !== undefined){
        //
        localStorage.setItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=', btoa(JSON.stringify(object.items)));
        this.cartItems.updateItems(object.items);
      }
      if(object.cart_id !== undefined){
        if(object.cart_id !== ''){
          localStorage.setItem('Y2FydElk', object.cart_id);
        }
      }
    } catch (error) {
    }

  }

  waitForSocketConnection(socket, callback){
    let c = this;
    setTimeout(
        function () {
            if(socket !== undefined){
              if (socket.readyState === 1) {
                if (callback != null){
                    callback();
                }
              } else {
                  c.waitForSocketConnection(socket, callback);
              }
            } else {
              c.waitForSocketConnection(socket, callback);
            }
        }, 5); // wait 5 milisecond for the connection...
  }
}
