<div class="middle_footer bg-service" id="bg_service">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="shopping_info">
                    <div class="row justify-content-center">
                        <div class="col-md-4" *ngFor="let service of services; let position = index;">
                            <div class="icon_box icon_box_style2">
                                <div class="icon" style="top: 0px;">
                                    <i [class]="service.icon" [ngStyle]="{'color': colorTheme}"></i>
                                </div>
                                <div class="icon_box_content pl-1 pr-1">
                                    <h5 [ngStyle]="{'color': colorTxt}">{{service.title}}</h5>
                                    <p class="text-white">{{service.subtitle}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>