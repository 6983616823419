import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { EncryptManagerService } from '@ignacioruben7/shops-encrypt-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `a:hover {
      color: var(--colorThemePrimary);
    }`
  ]
})
export class SignupComponent implements OnInit {

  public nombres = '';
  public apellidos = '';
  public correo = '';
  public contrasenia = '';
  public confirmarContrasenia = '';
  public codigoConfirmacion = '';
  public confirmarCorreo = false;
  private queryParams;
  public colorTheme = 'rgb(216, 216, 216)';
  public isB2C = environment.isB2C;
  private in_process = false;
  private serviceId = '';
  private infoService = null;

  constructor(private service: CustomStoreService, private deviceService: DeviceDetectorService, private styling: StyleManagerService, private router: Router, private route: ActivatedRoute, private encryption: EncryptManagerService, private api: RestApiServiceService, private auth: ShopsAuthAngularService, private toastrService: ToastrService) {
    this.getDeviceInfo();
    this.route.queryParamMap.subscribe(params => {
      this.queryParams = {...params.keys, ...params};
      if(this.queryParams.params.code !== undefined){
        if (this.queryParams.params.code.trim() !== ''){
          this.codigoConfirmacion = this.queryParams.params.code;
          this.confirmarCorreo = true;
          const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
          if(infoDevice !== null && this.serviceId !== ''){
            this.confirmRequest(infoDevice, this.serviceId);
          } else {
            setTimeout(() => {
              const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
              this.confirmRequest(infoDevice, this.serviceId);
            }, 2000);
          }
        } else {
          this.codigoConfirmacion = this.queryParams.params.code;
          this.confirmarCorreo = true;
        }
      }
    });
   }

  ngOnInit() {
    this.getDeviceInfo();
    window.scroll(0,0);
    this.checkLocalStorage();
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  confirmRequest(infoDevice, serviceId){
    let estado;
    this.api.verificarCodigoConfirmacionV2(this.codigoConfirmacion, infoDevice, serviceId).subscribe(
      respuesta => {
        estado = JSON.parse(JSON.stringify(respuesta)).code;
       }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
        if (err.status === 400) {
          this.toastrService.error('Error 400');
        }
        if (err.status === 404) {
          this.toastrService.warning('El código ingresado es incorrecto');
        }
        if (err.status === 403) {
          this.nombres = '';
          this.apellidos = '';
          this.correo = '';
          this.contrasenia = '';
          this.confirmarContrasenia = '';
          this.confirmarCorreo = false;
          this.codigoConfirmacion = '';
          this.router.navigate(['/ingresar']);
        }
        if (err.status === 429) {
          alert(err.error);
        }
        if (err.status === 500) {
          this.toastrService.error('Error 500');
        }
     }, () => {
       if(estado === 200){
        this.nombres = '';
        this.apellidos = '';
        this.correo = '';
        this.contrasenia = '';
        this.confirmarContrasenia = '';
        this.confirmarCorreo = false;
        this.codigoConfirmacion = '';
        this.toastrService.info('Correo confirmado exitosamente');
        this.router.navigate(['/ingresar']);
       } else {
         this.toastrService.error('Ocurrió un error inesperado');
       }
     });
  }

  getDeviceInfo = async () => {
    let deviceInfo = {} as any;
    deviceInfo = this.deviceService.getDeviceInfo();
    let infoIp = null as any;
    this.api.getPublicIp().subscribe(
      respuesta => {
        if(respuesta.trim() !== ''){
          infoIp = respuesta.trim().split('\n').reduce((obj, pair: any) => {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
          }, {});
        }
        // accessToken = JSON.parse(JSON.stringify(respuesta)).token;
        // estado = JSON.parse(JSON.stringify(respuesta)).code;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
      }, () => {
        deviceInfo.ip_address = infoIp;
        const base64Code = btoa(JSON.stringify(deviceInfo));
        sessionStorage.setItem('ZGV2aWNlSW5mbw==', base64Code);
      });
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Crear una cuenta`;
    // } else {
    //   titleHeader = `LocLog Shops | Crear una cuenta`;
    // }
    // this.api.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);

    this.service.currentService.subscribe((response) => {
      if(response){
        this.infoService = response;
        if(response.color !== ''){
          this.colorTheme = response.color;
        }
        const titleHeader = response.title ? `${response.title} | Crear una cuenta`:'LocLog Shops | Crear una cuenta';
        this.api.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.serviceId = response.public;
      }
    });
  }

  isHover2(hover, id){
    if(hover){
      this.styling.setColor(id,'color', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#333');
    }
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'nombres'){
      this.nombres = valor.trim();
    } else if (idInput === 'apellidos'){
      this.apellidos = valor.trim();
    } else if (idInput === 'correo'){
      this.correo = valor.trim();
    } else if (idInput === 'contrasenia'){
      this.contrasenia = valor;
    } else if (idInput === 'confirmar_contrasenia'){
      this.confirmarContrasenia = valor;
    } else if (idInput === 'codigo'){
      this.codigoConfirmacion = valor.trim();
    }
  }

  registrarme() {
    if (this.nombres.trim() !== '' && this.nombres !== undefined &&
    this.apellidos.trim() !== '' && this.apellidos !== undefined &&
    this.correo.trim() !== '' && this.correo !== undefined &&
    this.contrasenia.trim() !== '' && this.contrasenia !== undefined &&
    this.confirmarContrasenia.trim() !== '' && this.confirmarContrasenia !== undefined){
      if(this.validateEmail(this.correo)) {
        if(this.contrasenia.trim() === this.confirmarContrasenia.trim()){
          const usuario = {
            nombres: this.nombres,
            apellidos: this.apellidos,
            correo: this.correo,
            contrasenia: this.contrasenia
          }
          // let privateKey = '';
          // const key = sessionStorage.getItem('a2V5');
          // if(key !== null){
          //   privateKey = key;
          // }
          // const info_encriptada = this.encryption.encryptBase64(JSON.stringify(usuario));
          const info_encriptada = btoa(encodeURIComponent(JSON.stringify(usuario)));

          let estado;
          if(!this.in_process){
            this.in_process = true;
            setTimeout(() => {
              if(this.in_process){
                this.in_process = false;
              }
            }, 20 * 1000);
            const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
            this.api.registroAPIv2(info_encriptada, infoDevice, this.serviceId, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '').subscribe(
              respuesta => {
                estado = JSON.parse(JSON.stringify(respuesta)).code;
               }, (err: HttpErrorResponse) => {
                this.in_process = false;
                if (err.error instanceof Error) {
                } else {
                }
                if (err.status === 400) {
                  this.toastrService.error('Error 400');
                }
                if (err.status === 401) {
                  this.toastrService.error('No estás autorizado para acceder');;
                }
                if (err.status === 427) {
                  this.toastrService.warning('No estás autorizado para acceder');;
                }
                if (err.status === 404) {
                  this.toastrService.warning('Intenta ingresar sesión');
                  this.router.navigate(['/ingresar']);
                }
                if (err.status === 403) {
                  this.toastrService.warning('Confirma tu cuenta');
                  this.confirmarCorreo = true;
                }
                if (err.status === 405) {
                  window.location.reload();
                }
                if (err.status === 429) {
                  this.toastrService.warning(err.error.message);
                }
                if (err.status === 500) {
                  this.toastrService.error('Error 500');
                }
             }, () => {
              this.in_process = false;
              this.toastrService.clear();
               if(estado === 201){
                this.nombres = '';
                this.apellidos = '';
                this.correo = '';
                this.contrasenia = '';
                this.confirmarContrasenia = '';
                this.confirmarCorreo = true;
                this.toastrService.success('Registro exitoso');
               } else {
                 this.toastrService.error('Ocurrió un error inesperado');
               }
             });
          } else {
            this.toastrService.clear();
            this.toastrService.info('Por favor espere...');
          }
        } else {
          this.toastrService.warning('Las contraseñas no coinciden');
        }
      } else {
        this.toastrService.warning('El correo no es válido');
      }
    } else {
      if(this.nombres.trim() === '' || this.nombres === undefined){
        this.toastrService.warning('El nombre es requerido');
      }
      if(this.apellidos.trim() === '' || this.apellidos === undefined){
        this.toastrService.warning('Los apellidos son requeridos');
      }
      if(this.correo.trim() === '' || this.correo === undefined){
        this.toastrService.warning('El correo es requerido');
      }
      if(this.contrasenia.trim() === '' || this.contrasenia === undefined){
        this.toastrService.warning('La contraseña es requerida');
      }
      if(this.confirmarContrasenia.trim() === '' || this.confirmarContrasenia === undefined){
        this.toastrService.warning('La contraseña de confirmación es requerida');
      }
    }
  }

  confirmar() {
    if(this.codigoConfirmacion !== undefined && this.codigoConfirmacion !== null && this.codigoConfirmacion.trim() !== ''){
      let estado;
      const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
      // const serviceId = sessionStorage.getItem('cHVibGlj');
      this.confirmRequest(infoDevice,this.serviceId);
      // this.api.verificarCodigoConfirmacionV2(this.codigoConfirmacion, infoDevice, this.serviceId).subscribe(
      //   respuesta => {
      //     estado = JSON.parse(JSON.stringify(respuesta)).code;
      //    }, (err: HttpErrorResponse) => {
      //     if (err.error instanceof Error) {
      //     } else {
      //     }
      //     if (err.status === 400) {
      //       this.toastrService.error('Error 400');
      //     }
      //     if (err.status === 404) {
      //       this.toastrService.error('El código ingresado es incorrecto');
      //     }
      //     if (err.status === 403) {
      //       this.nombres = '';
      //       this.apellidos = '';
      //       this.correo = '';
      //       this.contrasenia = '';
      //       this.confirmarContrasenia = '';
      //       this.confirmarCorreo = false;
      //       this.codigoConfirmacion = '';
      //       this.toastrService.warning('El correo ya está confirmado');
      //       this.router.navigate(['/ingresar']);
      //     }
      //     if (err.status === 429) {
      //       this.toastrService.warning(err.error.message);
      //     }
      //     if (err.status === 500) {
      //       this.toastrService.error('Error 500');
      //     }
      //  }, () => {
      //    if(estado === 200){
      //     this.nombres = '';
      //     this.apellidos = '';
      //     this.correo = '';
      //     this.contrasenia = '';
      //     this.confirmarContrasenia = '';
      //     this.confirmarCorreo = false;
      //     this.codigoConfirmacion = '';
      //     this.toastrService.success('Correo confirmado exitosamente');
      //     this.router.navigate(['/ingresar']);
      //    } else {
      //      this.toastrService.error('Ocurrió un error inesperado');
      //    }
      //  });
    } else {
      this.toastrService.warning('El código de confirmación es requerido');
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
