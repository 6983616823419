import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { environment } from 'src/environments/environment.prod';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  styles: [`.navbar .navbar-nav>li>a.active,
  .navbar .navbar-nav>li:hover>a,
  .light_skin.transparent_header.nav-fixed .navbar .navbar-nav>li>a.active,
  .light_skin.transparent_header.nav-fixed .navbar .navbar-nav>li:hover>a,
  .transparent_header.nav-fixed .light_skin .navbar .navbar-nav>li>a.active,
  .transparent_header.nav-fixed .light_skin .navbar .navbar-nav>li:hover>a {
      color: var(--colorThemePrimary);
  }`]
})
export class NavbarComponent implements OnInit {

  public urlLogo = '';
  public colorTheme = '';
  public colorSearch = '#797979';
  private colorDefault = '#687188';
  public ImageSrc : string = '';
  private url = '';
  public showSearch = false;
  public querySearch = '';
  public querySearch2 = '';
  public querySearch3 = '';
  private typeSearch = 'title';
  public showSearchGTIN = false;
  public showSearchSubstance = false;
  public showIconSearch = environment.show_icon_search_on_web;
  public isBasic = environment.isBasic;

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private router: Router, private toastrService: ToastrService, private cart: ShopsCartAngularService) {
  }

  ngOnInit() {
    this.colorTheme = '#0097db';
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
    this.cart.getQuerySearch().subscribe(response => {
      this.querySearch = response;
      if(this.querySearch.trim() !== ''){
        this.colorSearch = this.colorTheme;
      } else {
        this.colorSearch = '#797979';
      }
    });
  }

  checkLocalStorage(){
    // const logo = atob(localStorage.getItem('SU5GTy1MT0dP'));
    // if(logo !== ''){
    //   this.urlLogo = logo;
    // }
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // }
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.logo !== ''){
          this.urlLogo = response.logo;
        }
        if(response.color !== ''){
          this.colorTheme = response.color;
        }
      }
    });
  }

  goTo(route){
    const splitActual = this.router.url.split('/');
    const split2 = splitActual[1].split('?');
    if(split2[0] !== route[1]){
      this.router.navigate(route);
    }
  }

  isHover(hover, pos){
    if(hover){
      this.styling.setColor(`link_${pos}`, 'color', this.colorTheme);
    } else {
      this.styling.setColor(`link_${pos}`, 'color', this.colorDefault);
    }
  }

  showSearchOption(boolean){
    this.showSearch = boolean;
  }

  escucharValoresInput(event, idInput){
    const valor = event.target.value;
    this.querySearch = valor;
    this.typeSearch = 'title';
    if(this.querySearch.trim() !== ''){
      this.colorSearch = this.colorTheme;
    } else {
      this.colorSearch = '#797979';
    }
  }

  searchQuery(){
    if(this.querySearch.trim() !== ''){
      sessionStorage.setItem('c2VhcmNoUXVlcnk=', JSON.stringify({type: environment.searchType, query: this.querySearch}));
      this.router.navigate(['/tienda'], { queryParams: { search: this.querySearch }, queryParamsHandling: 'merge' });
      this.showSearch = false;
      this.querySearch = '';
      sessionStorage.removeItem('c2VhcmNoUXVlcnk=');
    } else {
      this.toastrService.warning('Ingrese un valor de búsqueda', 'No válido');
    }
  }

}
