import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedToPayServiceService implements CanActivate {

  constructor(private authService: ShopsAuthAngularService, private router: Router) { }
  canActivate() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(token);
    const decodedToken = helper.decodeToken(token);

    if (token !== '' && token !== null && isExpired !== true && decodedToken.correo !== null && decodedToken.correo !== undefined) {
      return true;
    } else {
      this.router.navigate(['/ingresar'], { queryParams: { pendiente_pago: true } });
      return false;
    }
  }

}
