<app-header></app-header>
<app-navbar></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content">

    <!-- START SECTION CONTACT -->
    <div class="section pb_70">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-md-6">
                    <div class="contact_wrap contact_style3" (mouseover)="isHover(true, 'direccion')" (mouseleave)="isHover(false, 'direccion')">
                        <div class="contact_icon icon_style" id="direccion2" [ngStyle]="{'border': '1px solid'+colorTheme}">
                            <i class="linearicons-map2" id="direccion" [ngStyle]="{'color': colorTheme}"></i>
                        </div>
                        <div class="contact_text">
                            <span>Dirección</span>
                            <p>{{address}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6">
                    <div class="contact_wrap contact_style3" (mouseover)="isHover(true, 'email')" (mouseleave)="isHover(false, 'email')">
                        <div class="contact_icon icon_style" id="email2" [ngStyle]="{'border': '1px solid'+colorTheme}">
                            <i class="linearicons-envelope-open" id="email" [ngStyle]="{'color': colorTheme}"></i>
                        </div>
                        <div class="contact_text">
                            <span>Correo electrónico</span>
                            <a href="{{urlEmail}}" id="email3" (mouseover)="isHover3(true, 'email3')" (mouseleave)="isHover3(false, 'email3')">{{email}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6">
                    <div class="contact_wrap contact_style3" (mouseover)="isHover(true, 'phone')" (mouseleave)="isHover(false, 'phone')">
                        <div class="contact_icon icon_style" id="phone2" [ngStyle]="{'border': '1px solid'+colorTheme}">
                            <i class="linearicons-tablet2" id="phone" [ngStyle]="{'color': colorTheme}"></i>
                        </div>
                        <div class="contact_text">
                            <span>Teléfono</span>
                            <a href="{{urlPhone}}" id="phone3" (mouseover)="isHover3(true, 'phone3')" (mouseleave)="isHover3(false, 'phone3')">{{phone}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION CONTACT -->

    <!-- START SECTION CONTACT -->
    <div class="section pt-0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="heading_s1">
                        <h2 style="text-transform: none;">Pongámonos en contacto</h2>
                    </div>
                    <div class="field_form">
                        <form method="post" name="enq">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input value="{{nombreProspecto}}" required placeholder="Nombre completo" id="first-name" class="form-control" name="name" type="text" (keyup)="escucharValoresInput($event, 'nombre')">
                                </div>
                                <div class="form-group col-md-6">
                                    <input value="{{telefonoProspecto}}" required placeholder="Teléfono de contacto" id="phone" class="form-control" name="phone" (keyup)="escucharValoresInput($event, 'telefono')">
                                </div>
                                <div class="form-group col-md-12">
                                    <input value="{{correoProspecto}}" required placeholder="Correo electrónico" id="email" class="form-control" name="email" type="email" (keyup)="escucharValoresInput($event, 'correo')">
                                </div>
                                <div class="form-group col-md-12">
                                    <textarea value="{{asuntoCorreo}}" required placeholder="¿Cómo podemos ayudarte?" id="description" class="form-control" name="message" rows="6" (keyup)="escucharValoresInput($event, 'asunto')"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <button [ngStyle]="{'background': colorTheme, 'color': 'white'}" type="submit" title="ENVIAR MENSAJE" class="btn" (click)="enviarDatos()" id="send" (mouseover)="isHover2(true, 'send')" (mouseleave)="isHover2(false, 'send')"><b>ENVIAR MENSAJE</b></button>
                                </div>
                                <div class="col-md-12">
                                    <div id="alert-msg" class="alert-msg text-center"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION CONTACT -->

</div>
<!-- END MAIN CONTENT -->
<app-footer></app-footer>