<app-header></app-header>
<app-navbar></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content" style="margin-top: -80px;">
    <!-- START SECTION SHOP -->
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="text-right">¡Hola, {{nombreInfo}}!</p>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div ngbDropdown class="product_color_switch d-md-none mb-3">
                        <button class="btn btn-click3 col-12 text-left" id="menuOption" (mouseover)="isHover(true, 'menuOption')" (mouseleave)="isHover(false, 'menuOption')" ngbDropdownToggle style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;white-space: normal;font-weight: bold;" [ngStyle]="{'color': colorTheme}">{{optionMenuSelected.title}}<i class="fa fa-chevron-down ml-3" style="float: right; margin-top: 1%;"></i></button>
                        <div ngbDropdownMenu aria-labelledby="menuOption" style="max-height: 300px;overflow-y: scroll;">
                            <button class="text-left" ngbDropdownItem *ngFor="let option of menuOptions" (click)="selectDropdown(option, 'menu')" style="padding-left: 10px;padding-right: 5px;text-transform: uppercase;" [hidden]="option.title === optionMenuSelected.title">{{option.title}}</button>
                        </div>
                    </div>
                    <div class="dashboard_menu d-none d-md-block">
                        <ul class="nav nav-tabs flex-column" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" [ngClass]="{active: mostrarInfoPersonal}" (click)="seleccionarMenu('personal')" style="cursor: pointer;" id="account_detail_tab" data-toggle="tab" role="tab" aria-controls="account-detail" aria-selected="true"><i class="ti-id-badge"></i>Datos personales</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{active: mostrarDirecciones}" (click)="seleccionarMenu('direcciones')" style="cursor: pointer;" id="address_tab" data-toggle="tab" role="tab" aria-controls="address" aria-selected="true"><i class="ti-location-pin"></i>Mis direcciones</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" [ngClass]="{active: mostrarFacturacion}" (click)="seleccionarMenu('facturacion')" style="cursor: pointer;" id="facturacion_tab" data-toggle="tab" role="tab" aria-controls="facturacion" aria-selected="true"><i class="ti-ticket"></i>Facturación</a>
                          </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{active: mostrarPedidos}" (click)="seleccionarMenu('pedidos')" style="cursor: pointer;" id="orders_tab" data-toggle="tab" role="tab" aria-controls="orders" aria-selected="false"><i class="ti-shopping-cart-full"></i>Mis pedidos</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="salir" (click)="seleccionarMenu('salir')" style="cursor: pointer;"><i class="ti-lock"></i>Salir</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="tab-content dashboard_content">
                        <div [ngClass]="{'show': mostrarInfoPersonal, 'active': mostrarInfoPersonal}" class="tab-pane fade" *ngIf="mostrarInfoPersonal" role="tabpanel" aria-labelledby="account_detail_tab">
                            <div class="card">
                                <div class="card-header text-white" [ngStyle]="{'background': colorTheme}">
                                    <h4 style="text-transform: uppercase;color: white;text-align: center;">Datos personales</h4>
                                </div>
                                <div class="card-body">
                                    <form method="post" name="enq">
                                        <div class="row">
                                            <div class="form-group col-lg-6" *ngIf="!actualizarPass">
                                                <label><span class="text-danger" *ngIf="nombreInfo === '' || nombreInfo === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="nombreInfo !== '' && nombreInfo !== undefined"><i class="fa fa-check-circle"></i></span> Nombre</label>
                                                <input required="" class="form-control" name="name" type="text" value="{{nombreInfo}}" (keyup)="escucharValoresInput($event, 'nombres')">
                                            </div>
                                            <div class="form-group col-lg-6" *ngIf="!actualizarPass">
                                                <label><span class="text-danger" *ngIf="apellidosInfo === '' || apellidosInfo === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="apellidosInfo !== '' && apellidosInfo !== undefined"><i class="fa fa-check-circle"></i></span> Apellidos</label>
                                                <input required="" class="form-control" name="phone" type="text" value="{{apellidosInfo}}" (keyup)="escucharValoresInput($event, 'apellidos')">
                                            </div>
                                            <!-- <div class="form-group col-md-6" *ngIf="!actualizarPass">
                                                <label>Teléfono</label>
                                                <input class="form-control" name="phone" type="text" value="{{telefono}}" (keyup)="escucharValoresInput($event, 'telefono')">
                                            </div> -->
                                            <div class="form-group col-lg-6 row" *ngIf="!actualizarPass">
                                                <label class="col-12" for="pais"><span class="text-danger" *ngIf="telefonoInfo === '' || telefonoInfo === undefined || telefonoInfo.length < 10"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="telefonoInfo !== '' && telefonoInfo !== undefined && telefonoInfo.length === 10"><i class="fa fa-check-circle"></i></span> Teléfono</label>
                                                <div class="form-group col-12 col-md-5" [formGroup]="paisesForm2">
                                                    <select class="custom-select" [(ngModel)]="paisSeleccionado2" formControlName="pais">
                                                        <option [ngValue]="pais.dial_code" *ngFor="let pais of paises ; let positionPais=index">{{pais.flag}} {{pais.dial_code}}</option>
                                                    </select>
                                                </div>
                                                <input type="tel" class="form-control col-12 col-md-7 ml-3 ml-md-0" placeholder="Ingrese su número a 10 dígitos" id="pais" value="{{telefonoInfo}}" (keyup)="escucharValoresInput($event, 'telefono')" maxlength="10" minlength="10">
                                            </div>
                                            <div class="form-group col-lg-6" *ngIf="!actualizarPass">
                                                <label>Correo electrónico</label>
                                                <input required="" class="form-control" name="email" type="email" value="{{correo}}" disabled>
                                            </div>
                                            <div class="col-12" *ngIf="!actualizarPass">
                                                <button type="submit" class="btn col-12" name="submit" (click)="actualizarInformacionPersonal()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">GUARDAR CAMBIOS</button>
                                            </div>
                                            <div class="form-group col-md-4 mt-5" *ngIf="!actualizarPass">
                                                <input class="form-control" name="password" type="password" value="******************" disabled>
                                            </div>
                                            <div class="col-md-8 col-12 mt-0 mt-md-5" *ngIf="!actualizarPass">
                                                <button type="submit" class="btn col-12" name="submit" (click)="mostrarActualizarPass()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">CAMBIAR CONTRASEÑA</button>
                                            </div>
                                            <div class="form-group col-md-12" *ngIf="actualizarPass">
                                                <label><span class="text-danger" *ngIf="contraseniaAnterior === '' || contraseniaAnterior === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="contraseniaAnterior !== '' && contraseniaAnterior !== undefined"><i class="fa fa-check-circle"></i></span> Contraseña actual</label>
                                                <input required="" class="form-control" name="password" type="password" value="{{contraseniaAnterior}}" (keyup)="escucharValoresInput($event, 'contrasenia_anterior')">
                                            </div>
                                            <div class="form-group col-md-6" *ngIf="actualizarPass">
                                                <label><span class="text-danger" *ngIf="contraseniaNueva === '' || contraseniaNueva === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="contraseniaNueva !== '' && contraseniaNueva !== undefined"><i class="fa fa-check-circle"></i></span> Nueva contraseña</label>
                                                <input required="" class="form-control" name="npassword" type="password" value="{{contraseniaNueva}}" (keyup)="escucharValoresInput($event, 'nueva_contrasenia')">
                                            </div>
                                            <div class="form-group col-md-6" *ngIf="actualizarPass">
                                                <label><span class="text-danger" *ngIf="contraseniaConfirmacion === '' || contraseniaConfirmacion === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="contraseniaConfirmacion !== '' && contraseniaConfirmacion !== undefined"><i class="fa fa-check-circle"></i></span> Confirmar nueva contraseña</label>
                                                <input required="" class="form-control" name="cpassword" type="password" value="{{contraseniaConfirmacion}}" (keyup)="escucharValoresInput($event, 'contrasenia_confirmacion')">
                                            </div>
                                            <div class="col-md-6 col-12" *ngIf="actualizarPass">
                                                <button type="submit" class="btn col-12" name="submit" value="Submit" (click)="ocultarActualizarPass()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">CANCELAR</button>
                                            </div>
                                            <div class="col-md-6 col-12 mt-3 mt-md-0 text-right" *ngIf="actualizarPass">
                                                <button type="submit" class="btn col-12" name="submit" value="Submit" (click)="actualizarContrasenia()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">ACTUALIZAR CONTRASEÑA</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" [ngClass]="{'show': mostrarDirecciones, 'active': mostrarDirecciones}" *ngIf="mostrarDirecciones" role="tabpanel" aria-labelledby="address_tab">
                          <div class="card">
                            <div class="card-header text-white" [ngStyle]="{'background': colorTheme}">
                              <h4 style="text-transform: uppercase;color: white;text-align: center;">Mis direcciones</h4>
                            </div>
                            <div class="card-body">
                              <div class="card col-12 mb-3" *ngFor="let address of myAddresses; let position = index;">
                                <div class="card-body" style="display: flex;vertical-align: middle;">
                                  <div class="mr-3" *ngIf="change_customer_address">
                                    <a (click)="editAddress(address)" style="cursor: pointer;"><i class="fa fa-edit"></i></a>
                                  </div>
                                  <div class="form-check-label">
                                    {{address.address_line}}
                                  </div>
                                </div>
                              </div>
                              <button *ngIf="change_customer_address" class="btn btn-click2 text-white btn-block mb-2" [ngStyle]="{'background': colorTheme}"  (click)="addNewAddress()" style="text-transform: none;"><i class="fa fa-plus mr-2"></i>Agregar dirección</button>
                              <!-- <div class="card col-12 mb-2 btn btn-click2 text-white btn-block" [ngStyle]="{'background': colorTheme}" (click)="addNewAddress()" style="cursor: pointer;">
                                <div class="card-body pt-3 pb-3">
                                  <div class="form-check">
                                    <label  class="form-check-label" style="cursor: pointer;"><i class="fa fa-plus mr-2"></i>Agregar dirección</label>
                                  </div>
                                </div>
                              </div> -->
                            </div>
                          </div>
                            <!-- <div class="row">
                                <div class="col-sm-12">
                                    <form class="theme-form">
                                        <div class="form-row">
                                            <div class="col-md-6 mb-4">
                                                <label for="name"><span class="text-danger" *ngIf="calle === '' || calle === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="calle !== '' && calle !== undefined"><i class="fa fa-check-circle"></i></span> Calle</label>
                                                <input type="text" class="form-control" id="name" value="{{calle}}" (keyup)="escucharValoresInput($event, 'calle')">
                                            </div>
                                            <div class="col-md-3 mb-4">
                                                <label for="email"><span class="text-danger" *ngIf="no_exterior === '' || no_exterior === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="no_exterior !== '' && no_exterior !== undefined"><i class="fa fa-check-circle"></i></span> N° Exterior</label>
                                                <input type="text" class="form-control" id="last-name" value="{{no_exterior}}" (keyup)="escucharValoresInput($event, 'no_exterior')">
                                            </div>
                                            <div class="col-md-3 mb-4">
                                                <label for="review">N° Interior</label>
                                                <input type="text" class="form-control" id="review" value="{{no_interior}}" (keyup)="escucharValoresInput($event, 'no_interior')">
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label for="email"><span class="text-danger" *ngIf="postal_code === '' || postal_code === undefined || postal_code.length !== 5"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="postal_code !== '' && postal_code !== undefined && postal_code.length === 5"><i class="fa fa-check-circle"></i></span> Código postal</label>
                                                <input type="text" class="form-control" id="email" value="{{postal_code}}" (keyup)="escucharValoresInput($event, 'postal_code')" maxlength="5">
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <div class="form-group">
                                                    <label><span class="text-danger" *ngIf="estadoSeleccionado === '' || estadoSeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="estadoSeleccionado !== '' && estadoSeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Estado</label>
                                                    <div class="custom_select" [formGroup]="estadosForm">
                                                        <select class="form-control" [(ngModel)]="estadoSeleccionado" formControlName="estado">
                                                            <option value="">Seleccione un estado</option>
                                                            <option [ngValue]="estado.clave" *ngFor="let estado of estados ; let positionEstado=index">{{estado.nombre}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label for="email"><span class="text-danger" *ngIf="municipio === '' || municipio === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="municipio !== '' && municipio !== undefined"><i class="fa fa-check-circle"></i></span> Municipio</label>
                                                <input type="text" class="form-control" id="email" value="{{municipio}}" (keyup)="escucharValoresInput($event, 'municipio')">
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label for="email"><span class="text-danger" *ngIf="colonia === '' || colonia === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="colonia !== '' && colonia !== undefined"><i class="fa fa-check-circle"></i></span> Colonia</label>
                                                <input type="text" class="form-control" id="email" value="{{colonia}}" (keyup)="escucharValoresInput($event, 'colonia')">
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label for="email">Entre la calle</label>
                                                <input type="text" class="form-control" id="email" value="{{entre_calle_1}}" (keyup)="escucharValoresInput($event, 'entre_calle_1')">
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label for="email">y la calle</label>
                                                <input type="text" class="form-control" id="email" value="{{entre_calle_2}}" (keyup)="escucharValoresInput($event, 'entre_calle_2')">
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label for="email">Indicaciones especiales</label>
                                                <input type="text" class="form-control" id="email" value="{{indicaciones_extra}}" (keyup)="escucharValoresInput($event, 'indicaciones_extra')">
                                            </div>
                                            <div class="col-12">
                                                <button class="btn col-12" (click)="actualizarDireccion()" style="text-transform: uppercase;" [ngStyle]="{'background': colorTheme, 'color': 'white'}">Guardar dirección</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> -->
                        </div>
                        <div class="tab-pane fade" [ngClass]="{'show': mostrarFacturacion, 'active': mostrarFacturacion}" *ngIf="mostrarFacturacion" role="tabpanel" aria-labelledby="facturacion_tab">
                          <div class="row">
                              <div class="col-sm-12">
                                  <form class="theme-form">
                                      <div class="form-row">
                                          <div class="col-md-9 mb-4">
                                              <label for="name"><span class="text-danger" *ngIf="razon_social === '' || razon_social === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="razon_social !== '' && razon_social !== undefined"><i class="fa fa-check-circle"></i></span> Nombre o Razon social</label>
                                              <input type="text" class="form-control" id="name" value="{{razon_social}}" (keyup)="escucharValoresInput($event, 'razon_social')">
                                          </div>
                                          <div class="col-md-3 mb-4">
                                              <label for="zip_code"><span class="text-danger" *ngIf="zip_code === '' || zip_code === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="zip_code !== '' && zip_code !== undefined"><i class="fa fa-check-circle"></i></span> Codigo postal</label>
                                              <input type="text" class="form-control" id="zip_code" value="{{zip_code}}" (keyup)="escucharValoresInput($event, 'zip_code')">
                                          </div>
                                          <div class="col-md-4 mb-2">
                                            <label for="rfc"><span class="text-danger" *ngIf="rfc === '' || rfc === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="rfc !== '' && rfc !== undefined"><i class="fa fa-check-circle"></i></span> RFC</label>
                                            <input type="text" class="form-control" id="rfc" value="{{rfc}}" (keyup)="escucharValoresInput($event, 'rfc')">
                                          </div>
                                          <div class="col-md-8 mb-2">
                                            <div class="form-group">
                                                <label><span class="text-danger" *ngIf="regimenSeleccionado === '' || regimenSeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="regimenSeleccionado !== '' && regimenSeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Régimen fiscal</label>
                                                <div class="custom_select" [formGroup]="regimenForm">
                                                    <select class="form-control" [(ngModel)]="regimenSeleccionado" formControlName="regimen">
                                                        <option value="">Seleccione un régimen</option>
                                                        <option [ngValue]="regimen.clave" *ngFor="let regimen of regimenesFiscales ; let positionEstado=index">{{regimen.nombre}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                          </div>
                                          <div class="col-12 mb-4" *ngIf="regimenSeleccionado !== ''">
                                            <div class="form-group">
                                                <label><span class="text-danger" *ngIf="usoCFDISeleccionado === '' || usoCFDISeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="usoCFDISeleccionado !== '' && usoCFDISeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Uso del CFDI</label>
                                                <div class="custom_select" [formGroup]="usoForm">
                                                    <select class="form-control" [(ngModel)]="usoCFDISeleccionado" formControlName="uso">
                                                        <option value="">Seleccione uso del CFDI</option>
                                                        <option [ngValue]="uso.clave" *ngFor="let uso of usosCFDI; let positionEstado=index">{{uso.nombre}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                          </div>
                                          <!-- <div class="col-md-6 mb-4">
                                              <div class="form-group">
                                                  <label><span class="text-danger" *ngIf="estadoSeleccionado === '' || estadoSeleccionado === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="estadoSeleccionado !== '' && estadoSeleccionado !== undefined"><i class="fa fa-check-circle"></i></span> Estado</label>
                                                  <div class="custom_select" [formGroup]="estadosForm">
                                                      <select class="form-control" [(ngModel)]="estadoSeleccionado" formControlName="estado">
                                                          <option value="">Seleccione un estado</option>
                                                          <option [ngValue]="estado.clave" *ngFor="let estado of estados ; let positionEstado=index">{{estado.nombre}}</option>
                                                      </select>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-md-6 mb-4">
                                            <label for="rfc"><span class="text-danger" *ngIf="rfc === '' || rfc === undefined"><i class="fa fa-exclamation-circle"></i></span><span class="text-success" *ngIf="rfc !== '' && rfc !== undefined"><i class="fa fa-check-circle"></i></span> RFC</label>
                                            <input type="text" class="form-control" id="rfc" value="{{rfc}}" (keyup)="escucharValoresInput($event, 'rfc')">
                                          </div> -->
                                          <div class="col-12">
                                              <button class="btn col-12" [disabled]="razon_social === '' || zip_code === '' || rfc === '' || regimenSeleccionado === '' || usoCFDISeleccionado === ''" (click)="actualizarFacturacion()" style="text-transform: uppercase;" [ngStyle]="{'background': colorTheme, 'color': 'white'}">Guardar datos de facturacion</button>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                        <div class="tab-pane fade" [ngClass]="{'show': mostrarPedidos, 'active': mostrarPedidos}" *ngIf="mostrarPedidos" role="tabpanel" aria-labelledby="orders_tab">
                            <div class="card">
                                <div class="card-header pt-2 pb-2" [ngStyle]="{'background': colorTheme}">
                                    <h4 class="text-white" style="text-transform: none;text-align: center;font-size: 150%;"><b>MIS PEDIDOS</b></h4>
                                </div>
                                <div class="card-body" style="margin-top: -20px;">
                                    <div class="mt-3 row text-center" style=" font-weight: bold;">
                                        <div class="col-4 text-center d-none d-lg-block"><b>FECHA PEDIDO</b></div>
                                        <div class="col-9 d-lg-none text-center"><b>FECHA PEDIDO</b></div>
                                        <div class="col-3 text-center d-none d-lg-block"><b>MONTO</b></div>
                                        <div class="col-4 text-center d-none d-lg-block"><b>ESTADO</b></div>
                                    </div>
                                    <div class="row text-center user-status">
                                        <ol class="col-12 table table-bordernone mt-2">
                                            <li class="row pt-3 pb-1" *ngFor="let pedido of pedidos | paginate: {itemsPerPage: articulosPorPagina, currentPage: paginaActual} ; let positionArticulo=index" (click)="visualizarPedido(pedido)" style="cursor: pointer;">
                                            <!-- <li class="row pt-3 pb-1" *ngFor="let pedido of pedidos | paginate: {itemsPerPage: articulosPorPagina, currentPage: paginaActual} ; let positionArticulo=index" (click)="visualizarPedido(positionArticulo,paginaActual)" (click)="open(detalle_pedido)" style="cursor: pointer;"> -->
                                                <div class="col-4 d-none d-lg-block text-center">{{getDateText(pedido.createdAt)}}</div>
                                                <div class="col-9 d-lg-none text-center">{{getDateText(pedido.createdAt)}}</div>
                                                <div class="col-3 d-none d-lg-block text-center dash-text-1">{{pedido.total_amount | currency}}</div>
                                                <div class="col-4 d-none d-lg-block text-center">
                                                    <!-- TIPO DE ENVIO -->
                                                    <span *ngIf="pedido.delivery_type === 'store'"  class="badge badge-info4 mr-3">En sucursal</span>
                                                    <span *ngIf="pedido.delivery_type === 'home'"  class="badge badge-info5 mr-3">A domicilio</span>
                                                    <!-- ESTADO DE PEDIDO -->
                                                    <span *ngIf="pedido.status === 'pending'" class='badge mb-3 badge-warning2'><b>Sin preparar</b></span>
                                                    <span *ngIf="pedido.status === 'confirmed'" class='badge mb-3 badge-warning'><b>Confirmado</b></span>
                                                    <span *ngIf="pedido.status === 'supplying'" class='badge mb-3 badge-success'><b>Surtiendo</b></span>
                                                    <span *ngIf="pedido.status === 'packing'" class='badge mb-3 badge-warning'><b>En preparación</b></span>
                                                    <span *ngIf="pedido.status === 'ready_to_ship'" class='badge mb-3 badge-info'><b>Listo para envio</b></span>
                                                    <span *ngIf="pedido.status === 'in_transit'" class='badge mb-3 badge-info'><b>En camino</b></span>
                                                    <span *ngIf="pedido.status === 'deliveried'" class='badge mb-3 badge-success'><b>Entregado</b></span>
                                                    <span *ngIf="pedido.status === 'not_deliveried'" class='badge mb-3 badge-danger'><b>No entregado</b></span>
                                                    <span *ngIf="pedido.status === 'cancelled'" class='badge mb-3 badge-danger'><b>Cancelado</b></span>
                                                </div>
                                                <div class="col-1 text-center">
                                                    <i class="fa fa-info-circle" [ngStyle]="{'color': color}" style="font-size: 120%;"></i>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <!-- <div class="col-12 text-center mt-5 mb-2">
                                    <pagination-controls (pageChange)="paginaActual = $event" directionLinks="true" autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente">
                                    </pagination-controls>
                                </div> -->
                                <div class="col-md-12 text-center mt-3 mb-5">
                                    <ul class="ui-pagination noselect" *ngIf="pedidos.length > 0 && total_pages > 1">
                                        <li class="mr-1 mr-md-4 text-on-step" *ngIf="prev_page !== null" (click)="navOnPagination('back')" style="cursor: pointer;"><i class="fa fa-chevron-left mr-1 icon-prev-next" [ngStyle]="{'color': colorTheme}"></i>Anterior</li>
                                        <li class="ml-1 mr-1"><span class="badge current-step pt-1 pb-1">{{current_page}}</span></li>
                                        <li class="text-on-step">de {{total_pages}}</li>
                                        <li class="ml-2 ml-md-4 text-on-step" *ngIf="next_page !== null" (click)="navOnPagination('next')" style="cursor: pointer;">Siguiente<i class="fa fa-chevron-right ml-1 icon-prev-next" [ngStyle]="{'color': colorTheme}"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->
</div>
<!-- END MAIN CONTENT -->
<ng-template #detalle_pedido let-modal>
    <div class="modal-header text-center" style="margin-bottom: -20px;vertical-align: middle;">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 130%;" [ngStyle]="{'color': color}"><b>DETALLE DE PEDIDO</b></h4>
        <a (click)="modal.dismiss('Cross click')"><i class="fa fa-times" style="cursor: pointer;" [ngStyle]="{'color': color}"></i></a>
    </div>
    <div class="modal-body" class="col-12 pt-4">
        <div class="col-12 text-center mt-2">
            <b style="text-transform: uppercase;">Fecha</b>
            <p style="font-size: 110%;" [ngStyle]="{'color': color}"><b>{{fechaPedido}}</b></p>
        </div>
        <div class="col-12 text-center">
            <b style="text-transform: uppercase;">Folio de pedido</b>
            <p style="font-size: 110%;" [ngStyle]="{'color': color}"><b>{{folioPedido}}</b></p>
        </div>
        <div class="col-12 text-center">
            <b style="text-transform: uppercase;">Monto</b>
            <p style="font-size: 110%;" [ngStyle]="{'color': color}"><b>{{totalPedido | currency}}</b></p>
        </div>
        <div class="col-12 text-center">
            <b style="text-transform: uppercase;">Estado</b>
            <p style="font-size: 110%;" [ngStyle]="{'color': color}"><b>{{estadoPedido}}</b></p>
        </div>
        <div class="col-12 text-center" *ngIf="paqueteriaPedido !== ''">
            <b style="text-transform: uppercase;">Paqueteria</b>
            <p style="font-size: 110%;" [ngStyle]="{'color': color}"><b>{{paqueteriaPedido}}</b></p>
        </div>
        <div class="col-12 text-center" *ngIf="guiaPedido !== ''">
            <b style="text-transform: uppercase;">Guía de rastreo</b>
            <p style="font-size: 110%;" [ngStyle]="{'color': color}"><b>{{guiaPedido}}</b></p>
        </div>
        <div class="col-12 text-center row">
            <b style="text-transform: uppercase;" class="col-12 text-white" [ngStyle]="{'background': color}">Productos</b>
            <div class="col-12 user-status table-responsive latest-order-table">
                <table class="table table-bordernone text-center">
                    <thead>
                        <tr>
                            <th class="text-center">Img</th>
                            <th class="text-center">Cant.</th>
                            <th class="text-center">Nombre</th>
                            <th class="text-center d-none d-lg-block">Marca</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let producto of productosEnPedido">
                        <tr>
                            <td *ngIf="producto.src !== undefined" class="pb-3 text-center"><img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : producto.src" class="img-fluid" width="70px" height="auto"></td>
                            <td *ngIf="producto.src === undefined" class="pb-3 text-center"><img [defaultImage]="'assets/images/product_img1.jpg'" [lazyLoad]="'assets/images/product_img1.jpg'" class="img-fluid" width="70px" height="auto"></td>
                            <td class="pb-3 text-center">{{producto.quantity}}</td>
                            <td class="pb-3 text-center">{{producto.title}}</td>
                            <td class="pb-3 text-center d-none d-lg-block">{{producto.brand}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br/>
    </div>
    <div class="modal-footer col-12">
        <button type="button" class="btn btn-pedidos align-center col-12" [ngStyle]="{'background': color}" (click)="modal.close()"><b>ENTENDIDO</b></button>
    </div>
</ng-template>

<app-footer></app-footer>
