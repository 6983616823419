import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestApiServiceService } from 'src/app/services/api/rest-api-service.service';
import countries from 'src/app/data/countries.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css'],
  styles: [
    `.order_complete i {
      color: var(--colorThemePrimary);
  }`
  ]
})
export class OrderSuccessComponent implements OnInit, OnDestroy {

  public order_id = '';
  public pedido = [];
  public subtotal = 0;
  public descuento = 0;
  public envio = 0;
  public total = 0;
  public delivery_type = '';
  public direccion;
  public payment_ref;
  public fechaString = '';
  public colorTheme = '#333';
  public colorText = 'white';
  public colorBg = '#333';
  public textSize = '16px';
  public textSize2 = '16px';
  public paises = countries.countries;
  public paisSeleccionado = '+52';
  public countryCode = 'MX';
  public flagPais = '🇲🇽';
  public telefono_recibe = '';
  private folioOrden = '';
  public disabledBtn = false;
  private configWorker: any;
  public isAuth = false;
  public nombres = '';
  public isNew = true;
  public tags_order = undefined;
  public status = '';
  public carrier = '';
  public tracking = '';
  public urlLogo = '';
  public urlWhatsApp = '';
  modalOptions: NgbModalOptions;
  closeResult: string;
  public paymentReferences = [];

  public subtotalCurrency = '';

  public orderInfo = null;
  public orderItems = [];
  public itemsNotSupply = [];
  public infoAddress = null;
  private serviceId = '';

  public paymentSource = null;
  private infoService = null;


  constructor(private service: CustomStoreService, private styling: StyleManagerService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private router: Router, private api: RestApiServiceService, private toastrService: ToastrService, private auth: ShopsAuthAngularService) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    this.initWorkers();
    this.activatedRoute.params.subscribe(params => {
      this.folioOrden = params.order;
    });
    // this.configWorker.onmessage = ({data}) => {
    //   let estado = data.code;
    //   let result = data.result;
    //   let payments = data.payments;
    //   if(estado === 200){
    //     if(result !== undefined){
    //       this.toastrService.clear();
    //       this.disabledBtn = false;
    //       this.order_id = result.id;
    //       this.delivery_type = result.delivery_type;
    //       if(result.delivery_type === 'home'){
    //         this.direccion = result.shipping.destination;
    //       } else {
    //         this.direccion = result.shipping.store;
    //       }
    //       this.payment_ref = result.payment_ref;
    //       this.pedido = result.items;
    //       this.subtotal = result.subtotal;
    //       this.descuento = result.coupon.amount;
    //       this.envio = result.shipping.quotation.amount;
    //       this.total = result.total_amount;
    //       this.fechaString = result.payment_limit;
    //       this.tags_order = result.tags;
    //       this.status = result.status;
    //       this.carrier = result.shipping.provider;
    //       this.tracking = result.shipping.tracking_number;

    //       const info = {
    //         delivery_type: this.delivery_type,
    //         destination: this.direccion,
    //         tags: result.tags,
    //         status: result.status,
    //         order_info: {
    //           id: this.order_id,
    //           shipping: result.shipping,
    //           payment_ref: this.payment_ref,
    //           items: this.pedido,
    //           subtotal: this.subtotal,
    //           coupon: result.coupon,
    //           total_amount: this.total,
    //           payment_limit: this.fechaString,
    //         }
    //       }
    //       sessionStorage.setItem('UGVkaWRvIGNsaWVudGU=', JSON.stringify(info));
    //       if(this.status !== "cancelled" && this.status !== "paid"){
    //         setTimeout(() => {
    //           this.viewPaymentDetail(this.payment_ref.id);
    //         }, 2000);
    //       }
    //     }
    //   } else {
    //     if(estado === 402){
    //       sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
    //       this.toastrService.warning('Servicio suspendido');
    //       this.router.navigate(['', '402']);
    //     } else if(estado === 403){
    //       sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
    //       this.toastrService.warning('Servicio cancelado');
    //       this.router.navigate(['', '403']);
    //     } else if(estado === 404){
    //       this.toastrService.clear();
    //       if(this.isAuth){
    //         this.toastrService.warning('Pedido no encontrado');
    //         this.router.navigate(['', 'perfil']);
    //       } else {
    //         this.toastrService.warning(data.message);
    //       }
    //       this.disabledBtn = false;
    //       this.telefono_recibe = '';
    //     } else if(estado === 500){
    //       this.toastrService.error(data.message);
    //     } else if(estado === 429){
    //       sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
    //       this.toastrService.info('Demasiadas solicitudes');
    //     } else {
    //       sessionStorage.removeItem('U2VydmljZVN0YXR1cw==');
    //       this.toastrService.error('Error inesperado :(');
    //     }
    //   }
    // };
    if (sessionStorage.getItem('UGVkaWRvIGNsaWVudGU=') !== null && sessionStorage.getItem('UGVkaWRvIGNsaWVudGU=') !== undefined) {
      const info = JSON.parse(sessionStorage.getItem('UGVkaWRvIGNsaWVudGU='));
      this.orderInfo = info;
      this.order_id = this.orderInfo.folio;
      this.delivery_type = this.orderInfo.delivery_type;
      this.status = this.orderInfo.status;
      const token = this.auth.getToken();
      this.getOrderItems(token,this.orderInfo.sale_id);
      if(this.delivery_type === 'home'){
        this.getShippingAddress(token, this.orderInfo.address_id);
      } else {
        this.getPickupAddress(token, this.orderInfo.pickup_id);
      }
      if(this.orderInfo.payment_info && this.orderInfo.payment_info.source_id){
        this.getPaymentSourceInfo(token, this.orderInfo.payment_info.source_id);
      }
      // if(info.delivery_type === 'home'){
      //   this.direccion = info.destination;
      // } else {
      //   this.direccion = info.order_info.shipping.store;
      // }
      // this.tags_order = info.tags;
      // this.payment_ref = info.order_info.payment_ref;
      // this.pedido = info.order_info.items;
      // this.subtotal = info.order_info.subtotal;
      // this.descuento = info.order_info.coupon.amount;
      // this.envio = info.order_info.shipping.quotation.amount;
      // this.total = info.order_info.total_amount;
      // this.fechaString = info.order_info.payment_limit;
      // this.carrier = info.order_info.shipping.provider;
      // this.tracking = info.order_info.shipping.tracking_number;
      // if(this.status !== "cancelled" && this.status !== "paid"){
      //   setTimeout(() => {
      //     this.viewPaymentDetail(this.payment_ref.id);
      //   }, 2000);
      // }
    } else {
      if(this.folioOrden === ''){
        this.router.navigate(['', '404']);
      } else {
        this.router.navigate(['', 'perfil'], { queryParams: { menu: 'pedidos' }, queryParamsHandling: 'merge' });
      }
    }
   }

   existStatus(tags, status){
     if(tags !== undefined){
      return tags.filter((tag) => {
        return tag == status;
      })
     } else {
       return [];
     }
  }

  async getPaymentSourceInfo(token, source_id){
    if(this.infoService){
      const infoSource = await this.api.getPaymentSourceInfo(token, source_id, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(infoSource){
      if(infoSource.code !== 200){
        this.toastrService.error(infoSource.message, `Error ${infoSource.code}`);
      } else {
        this.paymentSource = infoSource.payment_source;
      }
    } else {
      this.toastrService.error('Ocurrió un error inesperado');
    }
    } else {
      setTimeout(async ()=> {
        const infoSource = await this.api.getPaymentSourceInfo(token, source_id, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(infoSource){
      if(infoSource.code !== 200){
        this.toastrService.error(infoSource.message, `Error ${infoSource.code}`);
      } else {
        this.paymentSource = infoSource.payment_source;
      }
    } else {
      this.toastrService.error('Ocurrió un error inesperado');
    }
      }, 550);
    }
  }

  copyMessage(val: string, type = 'reference'){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastrService.clear();
    if(type === 'reference'){
      this.toastrService.info('La referencia se copió correctamente');
    }
  }

  async getShippingAddress(token, addressId){
    const address = await this.api.getMyAddresses(token, addressId, this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(address.code === 200){
      if(address.addresses && address.addresses.length > 0){
        this.infoAddress = {
          name: address.addresses[0].reference,
          address_line: address.addresses[0].address_line,
          business_hours: []
        };
      } else {
        this.toastrService.error('No se encontró la dirección de entrega');
      }
    } else {
      this.toastrService.error(address.message, `Error ${address.code}`);
    }
  }

  async getPickupAddress(token, pickupId){
    const pickup = await this.api.getPickupPoints(token, pickupId) as any;
    if(pickup.code === 200){
      if(pickup.pickups && pickup.pickups.length > 0){
        this.infoAddress = {
          name: pickup.pickups[0].name,
          address_line: pickup.pickups[0].address_line,
          business_hours: pickup.pickups[0].business_hours
        };
      } else {
        this.toastrService.error('No se encontró la dirección de recolección');
      }
    } else {
      this.toastrService.error(pickup.message, `Error ${pickup.code}`);
    }
  }

  async getOrderItems(token, orderId){
    if(this.infoService){
      const infoItems = await this.api.getOrderItems(token, orderId, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(infoItems.code === 200){
      const itemsId = infoItems.items.map((item) => item.item_id);
      const itemsInfo = await this.api.getInfoItems(itemsId, token) as any;
      if(itemsInfo.code === 200){
        for(const item of infoItems.items){
          const findInfo = itemsInfo.items.find((item2) => item2.item_id === item.item_id);
          if(findInfo){
            // las quantities dependen del status del order
            let quantity = 0;
            if(this.orderInfo.status !== 'cancelled'){
              quantity = item.qty_delivered;
            }
            this.orderItems.push({
              item_id: findInfo.item_id,
              ean: findInfo.ean,
              name: findInfo.name,
              quantity,
              qty_diff: item.qty_requested - item.qty_delivered,
              price: item.price,
              currency: environment.currencyCode
            });
          }
        };
        this.itemsNotSupply = [];
        this.itemsNotSupply = [...this.orderItems.filter((item) => item.qty_diff > 0)];
      } else {
        this.toastrService.error(itemsInfo.message, `Error ${itemsInfo.code}`);
      }
    } else {
      this.toastrService.error(infoItems.message, `Error ${infoItems.code}`);
    }
    } else {
      setTimeout(async ()=> {
        const infoItems = await this.api.getOrderItems(token, orderId, this.infoService && this.infoService.pickerpro_id ? this.infoService.pickerpro_id : '') as any;
    if(infoItems.code === 200){
      const itemsId = infoItems.items.map((item) => item.item_id);
      const itemsInfo = await this.api.getInfoItems(itemsId, token) as any;
      if(itemsInfo.code === 200){
        for(const item of infoItems.items){
          const findInfo = itemsInfo.items.find((item2) => item2.item_id === item.item_id);
          if(findInfo){
            // las quantities dependen del status del order
            let quantity = 0;
            if(this.orderInfo.status !== 'cancelled'){
              quantity = item.qty_delivered;
            }
            this.orderItems.push({
              item_id: findInfo.item_id,
              ean: findInfo.ean,
              name: findInfo.name,
              quantity,
              qty_diff: item.qty_requested - item.qty_delivered,
              price: item.price,
              currency: environment.currencyCode
            });
          }
        };
        this.itemsNotSupply = [];
        this.itemsNotSupply = [...this.orderItems.filter((item) => item.qty_diff > 0)];
      } else {
        this.toastrService.error(itemsInfo.message, `Error ${itemsInfo.code}`);
      }
    } else {
      this.toastrService.error(infoItems.message, `Error ${infoItems.code}`);
    }
      }, 550);
    }
  }

  ngOnInit() {
    window.scroll(0,0);
    this.checkLocalStorage();
    const infoService = this.service.infoService.getValue();
      if(infoService){
        this.infoService = infoService;
      }
    setTimeout(() => {
      const infoService = this.service.infoService.getValue();
      if(infoService){
        this.infoService = infoService;
      }
    }, 1000);
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
    this.paises.sort((a, b) => {
      if (a.dial_code < b.dial_code) {
        return -1;
      } else if (a.dial_code > b.dial_code) {
        return 1;
      }
      return 0;
    });
    for(let i = 0; i < this.pedido.length; i++){
      if(this.pedido[i].currency !== undefined){
        if(i === 0){
          this.subtotalCurrency = this.pedido[i].currency;
        }
        if(this.pedido[i].currency !== this.subtotalCurrency){
          this.subtotalCurrency = '';
        }
      }
    }
  }

  isHover2(hover, id){
    if(hover){
      this.styling.setColor(id, 'color', this.colorTheme);
    } else {
      this.styling.setColor(id, 'color', '#333');
    }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorBg = response.color;
          // this.styling.setColor('go_back', 'background', this.colorTheme);
        }
        if(response.logo !== ''){
          this.urlLogo = response.logo;
        }

        const social = response.social;
        if(social.length > 0){
          if(this.filtrarRedes(social, "WhatsApp").length > 0){
            this.urlWhatsApp  = this.filtrarRedes(social, "WhatsApp")[0].url;
          }
        }
        const titleHeader = response.title ? `${response.title} | Ticket de pedido`:'LocLog Shops | Ticket de pedido';
        this.api.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.serviceId = response.public;
      }
    });
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorBg = colorTheme;
    // }

    // const logo = atob(localStorage.getItem('SU5GTy1MT0dP'));
    // if(logo !== ''){
    //   this.urlLogo = logo;
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Ticket de pedido`;
    // } else {
    //   titleHeader = `LocLog Shops | Ticket de pedido`;
    // }
    // this.api.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);

    // const social = JSON.parse(atob(localStorage.getItem('SU5GTy1TT0NJQUw=')));
    // if(social.length > 0){
    //   if(this.filtrarRedes(social, "WhatsApp").length > 0){
    //     this.urlWhatsApp  = this.filtrarRedes(social, "WhatsApp")[0].url;
    //   }
    // }
  }

  goToWhatsapp(){
    if(this.urlWhatsApp !== ''){
      window.open(this.urlWhatsApp, '_blank');
    }
  }

  filtrarRedes(redes, nombre){
    return redes.filter((product) => {
      return product.name === nombre;
    });
  }

  ngOnDestroy() {
    sessionStorage.removeItem('b3BlblJlZg==');
  }

  printTicket(){
    this.onBeforePrint();
    window.onafterprint = () => {
      this.onAfterPrint();
    };

    setTimeout(() => {
      window.print();
    },1);
  }

  onBeforePrint(){
    this.colorText = this.colorTheme;
    this.colorBg = 'transparent';
    this.textSize = '20px';
    this.textSize2 = '30px';
  }

  onAfterPrint(){
    this.colorText = 'white';
    this.colorBg = this.colorTheme;
    this.textSize = '16px';
    this.textSize2 = '16px';
  }

  confirmarNumero(){
    this.toastrService.clear();
    if(this.telefono_recibe.trim().length !== 10){
      this.toastrService.warning('Teléfono de contacto requerido');
    } else {
      this.toastrService.info('Buscando pedido...');
      this.disabledBtn = true;
      this.configWorker.postMessage({request: 'getOrderDetail', body: {order: this.folioOrden, sid: '', email: '', lada: this.paisSeleccionado, number: this.telefono_recibe}});
    }
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'telefono_recibe') {
      var regex = /^\d*$/;
      const validacion = regex.test(valor.trim());
      if(validacion){
        if(valor.trim().length <= 10){
          this.telefono_recibe = valor.trim();
        } else {
          event.target.value = this.telefono_recibe;
        }
      } else {
        event.target.value = this.telefono_recibe;
      }
    }
  }

  selectDropdown(country, type){
    if(type === 'country'){
      this.paisSeleccionado = country.dial_code;
      this.flagPais = country.flag;
      this.countryCode = country.code;
    }
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id, 'color',  '#ffffff');
      this.styling.setColor(id, 'background', this.colorTheme);
      this.styling.setColor(id, 'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id, 'backgroundColor',  'transparent');
      this.styling.setColor(id, 'color',  '#495057');
      this.styling.setColor(id, 'borderColor',  '#ced4da');
    }
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('../../../workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }

  viewPaymentDetail(source){
    let publicKey = this.serviceId;
    // const key = sessionStorage.getItem('cHVibGlj');
    // if(key !== null){
    //   publicKey = key;
    // }
    if(source === ''){
      const openWindow = window.open(`${environment.app_checkout}/${this.order_id}/init?service=${publicKey}`, '_blank');
      if(openWindow === null){
        this.toastrService.warning('Activa los permisos para abrir ventanas emergentes y vuelve cargar la página', 'Permisos requeridos');
        setTimeout(() => {
          window.location.href = `${environment.app_checkout}/${this.order_id}/init?service=${publicKey}&source=${source}`;
        }, 2 * 60 * 1000)
      }
    } else {
      const openWindow = window.open(`${environment.app_checkout}/${this.order_id}/init?service=${publicKey}&source=${source}`, '_blank');
      if(openWindow === null){
        this.toastrService.warning('Activa los permisos para abrir ventanas emergentes y vuelve cargar la página', 'Permisos requeridos');
        setTimeout(() => {
          window.location.href = `${environment.app_checkout}/${this.order_id}/init?service=${publicKey}&source=${source}`;
        }, 2 * 60 * 1000)
      }
    }
  }

  pop = (url, w, h) => {
    const popup = window.open(url, '_blank', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=500,height=500');
    return popup !== null && typeof popup !== 'undefined'
  }



  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
