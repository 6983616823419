import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Product } from '../../../classes/product';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { Title } from '@angular/platform-browser';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { WebhookServiceService } from '../../../services/webhook/webhook-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { environment } from 'src/environments/environment.prod';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Json2excelService } from 'src/app/services/excel/json2excel.service';
import * as XLSX from 'xlsx';

interface Producto {
  item_id: number;
  title: string;
  quantity: number | null;
}
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  styles: [
    `.price {
      color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary);
    }`,
    `.go_back {
      background: var(--colorThemePrimary);
      color: white
    }`,
    `.subtotal {
      background: var(--colorThemePrimary);
      color: white
    }`,
    `.subtotal-price {
      color: var(--colorThemePrimary);
    }`,
  ]
})
export class CartComponent implements OnInit {

  public products: Product[] = [];
  public ImageSrc : string = '';
  public colorTheme = 'white';
  public showStock = environment.showStock;
  public subtotalCurrency = '';
  modalOptions: NgbModalOptions;
  closeResult: string;
  @ViewChild('confirmar_pedido') confirmar_pedido : TemplateRef<any>;

  public warning = '';
  private productoSubjects: { [key: string]: Subject<any> } = {};
  private infoService = null;

  // EXCEL
  private excelData: any;
  private rowHeaders = [];
  public in_process = false;
  private cart_items = [];

  constructor(private json2excel: Json2excelService, private service: CustomStoreService, private modalService: NgbModal, private styling: StyleManagerService, private cart: CartServiceService, private websocket: WebhookServiceService, private auth: ShopsAuthAngularService, public productService: ProductServiceService, private router: Router, private titleService: Title, private api: RestApiServiceService, private toastr: ToastrService) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }
    window.scroll(0,0);
    // if(localStorage.getItem('c2hvd1N0b2Nr') !== null){
    //   try {
    //     const info = JSON.parse(atob(localStorage.getItem('c2hvd1N0b2Nr')));
    //     this.showStock = info.show;
    //   } catch (error) {

    //   }
    // }
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1DT0xPUg==') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1DT0xPUg==') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
    if(this.auth.getToken() !== null){
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(this.auth.getToken());
      if(!this.websocket.isOpenSocket()){
        this.websocket.openWebsocket(decodedToken.clave_publica, decodedToken.correo);
      }
    }

    this.cart.currentCart.subscribe((response) => {
      if(response !== undefined){
        this.products = response;
      }
      for(let i = 0; i < this.products.length; i++){
        if(this.products[i].currency !== undefined){
          if(i === 0){
            this.subtotalCurrency = this.products[i].currency;
          }
          if(this.products[i].currency !== this.subtotalCurrency){
            this.subtotalCurrency = '';
          }
        }
      }
    })

    const token = this.auth.getToken();
    if(token){
      if(this.infoService){
        this.getPaymentSources(token);
      } else {
        setTimeout(() => {
          this.infoService = this.service.infoService.getValue();
          if(this.infoService){
            this.getPaymentSources(token);
          } else {
            setTimeout(() => {
              this.infoService = this.service.infoService.getValue();
              if(this.infoService){
                this.getPaymentSources(token);
              } else {
                setTimeout(() => {
                  this.infoService = this.service.infoService.getValue();
                  if(this.infoService){
                    this.getPaymentSources(token);
                  } else {
                    setTimeout(() => {
                      this.infoService = this.service.infoService.getValue();
                      if(this.infoService){
                        this.getPaymentSources(token);
                      }
                    }, 3000);
                  }
                }, 2000);
              }
            }, 1000);
          }
        }, 500);
      }
    }

    for(let i = 0; i < this.products.length; i++){
      this.productoSubjects[this.products[i].item_id] = new Subject<number>();

      this.productoSubjects[this.products[i].item_id].pipe(debounceTime(500)).pipe(distinctUntilChanged()).subscribe(async(value) => {
        if(value.diff !== 0){
          const status = await this.cart.addItem({...this.products[i], ...{
            id: this.products[i].item_id,
            gtin: this.products[i].sku,
            retail_price: this.products[i].price,
          }}, value.diff, token, this.infoService ? this.infoService.pickerpro_id: '', '');
        } else {
          if(this.products[i].quantity === 1){
            value.event.target.value = '1';
          }
        }
      });
    }
  }

  async getPaymentSources(token){
    const infoSources = await this.api.getPaymentSources(token, this.service.infoService.getValue() ? this.service.infoService.getValue().pickerpro_id : '') as any;
    if(infoSources){
      if(infoSources.code !== 200){
        this.toastr.error(infoSources.message, `Error ${infoSources.code}`);
      } else {
        sessionStorage.setItem('cGF5bWVudFNvdXJjZXM=', JSON.stringify(infoSources.payment_sources));
      }
    } else {
      this.toastr.error('Ocurrió un error al consultar formas de pago');
    }
  }

  downloadFormat(){
    this.json2excel.exportAsExcelFile([{codigo_barras: 'ABC', cantidad: 3}, {codigo_barras: 'DEF', cantidad: 10},{codigo_barras: 'HIJ', cantidad: 7}], 'pedido_rapido_');
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.styling.setColor('go_back', 'background', this.colorTheme);
        }
        const titleHeader = response.title ? `${response.title} | Carrito`:'LocLog Shops | Carrito';
        this.titleService.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);
        this.showStock = response.show_stock;
      }
    });
  }

  pagarPedido(){
    let showModal = false;
    for(let i = 0; i < this.products.length;i++){
      if(this.products[i].warning && this.products[i].warning !== ''){
        showModal = true;
      }
    }

    if(showModal){
      this.modalService.open(this.confirmar_pedido);
    } else {
      this.router.navigate(['', 'pagar']);
    }
  }

  aceptarPedido(aceptado){
    if(aceptado){
      this.modalService.dismissAll();
      this.router.navigate(['', 'pagar']);
    } else {
      for(let i = 0; i < this.products.length;i++){
        if(this.products[i].warning !== undefined){
          this.removeItem(this.products[i]);
        }
      }
      this.modalService.dismissAll();
      this.router.navigate(['', 'pagar']);
    }
  }

  public get getTotal(): Observable<number> {
    return this.cart.cartTotalAmount();
  }

  async increment(product, qty = 1) {
    // this.productService.updateCartQuantity(product, qty, product.variation);
    const token = this.auth.getToken();
    // this.cart.addItem(product,qty, token);
    const status = await this.cart.addItem({...product, ...{
      id: product.item_id,
      gtin: product.sku,
      retail_price: product.price,
    }}, qty, token, this.infoService ? this.infoService.pickerpro_id: '', '');
  }

  async decrement(product, qty = -1) {
    // this.productService.updateCartQuantity(product, qty, product.variation);
    const token = this.auth.getToken();
    // this.cart.addItem(product,qty, token);
    const status = await this.cart.addItem({...product, ...{
      id: product.item_id,
      gtin: product.sku,
      retail_price: product.price,
    }}, qty, token, this.infoService ? this.infoService.pickerpro_id: '', '');
  }

  // listenQuantity(event, product){
  //   const value = event.target.value;
  //   const reg = /^\d+$/;
  //   const quantityBefore = product.quantity;
  //   let newQty = quantityBefore;
  //   if(reg.test(value)){
  //     const quantity = Number(value);
  //     let stock = product.stock;
  //     // if(product.attribute_types === "variations"){
  //     //   stock = product.variation.stock;
  //     // }

  //     const min = Math.min(quantity, stock);
  //     if(min >= 1){
  //       newQty = min;
  //       // event.target.value = `${newQty}`;
  //     } else {
  //       newQty = 1
  //     }

  //     // if(quantity > 0 && quantity <= stock){
  //     //   newQty = quantity;
  //     //   // this.productService.updateCartQuantity(product, quantity, product.variation);
  //     // } else if(quantity > stock){
  //     //   newQty = stock;
  //     //   // this.productService.updateCartQuantity(product, stock, product.variation);
  //     //   event.target.value = `${stock}`;
  //     // } else {
  //     //   newQty = 1;
  //     //   // this.productService.updateCartQuantity(product, 1, product.variation);
  //     //   event.target.value = '1';
  //     // }
  //   } else {
  //     newQty = 1;
  //     // this.productService.updateCartQuantity(product, 1, product.variation);
  //   }
  //   const diff = newQty - quantityBefore;

  //   if (!isNaN(diff)) {
  //     this.productoSubjects[product.item_id].next({diff, event});
  //   }

  listenQuantity(event, product){
    const value = event.target.value;
    const reg = /^\d+$/;
    const quantityBefore = product.quantity;
    let newQty = quantityBefore;
    if(reg.test(value)){
      const quantity = Number(value);
      let stock = product.stock;
      const min = Math.min(quantity, stock);
      if(min >= 1){
        newQty = min;
      } else {
        newQty = 1
      }
    } else {
      newQty = 1;
    }
    const diff = newQty - quantityBefore;

    if (!isNaN(diff)) {
      this.productoSubjects[product.item_id].next({diff, event});
    }
  }

  public removeItem(product: any) {
    const token = this.auth.getToken();
    this.cart.removeItem(product, token, this.infoService ? this.infoService.pickerpro_id: '');
    // this.productService.removeCartItem(product);
  }

  showDetail(product){
    sessionStorage.setItem('c291cmNlQmFjaw==', 'cart');
    if(product.attribute_types === 'attributes'){
      this.router.navigate(['', 'p', product.slug], { queryParams: { uid: product.item_id } });
    }
  }

  openFileInput(selectorId) {
    const input = document.getElementById(selectorId);
    if(input){
      input.click();
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if ((file.type.includes('excel') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls'))) {
        const reader = new FileReader();
        this.in_process = true;

        reader.onload = (e: ProgressEvent<FileReader>) => {
          const binaryString = e.target?.result as string;
          const workbook = XLSX.read(binaryString, { type: 'binary' });

          // Convert first sheet to JSON
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          this.excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          try {
            this.excelData.forEach((row, index) => {
              let info = {
                qty: 0,
                ean: ''
              };
              if(index === 0){
                const findQty = row.findIndex((value) => value === 'cantidad');
                const findEan = row.findIndex((value) => value === 'codigo_barras');
                if(findQty !== -1 && findEan !== -1){
                  this.rowHeaders = row;
                } else {
                  this.toastr.clear();
                  this.toastr.warning('Formato no compatible...');
                }
              }
              if(this.rowHeaders.length > 0 && index > 0){
                const qtyPos = this.rowHeaders.findIndex((value) => value === 'cantidad');
                const eanPos = this.rowHeaders.findIndex((value) => value === 'codigo_barras');
                if(qtyPos !== -1){
                  if(!isNaN(Number(row[qtyPos]))){
                    info.qty = Number(row[qtyPos]);
                  }
                }
                if(eanPos !== -1){
                  if(row[eanPos] !== ''){
                    info.ean = `${row[eanPos]}`.trim();
                  }
                }
                if(qtyPos !== -1){
                  if(!isNaN(Number(row[qtyPos]))){
                    this.cart_items.push(info);
                  }
                }
              }
            });
            if(this.cart_items.length > 0){
              this.importCartItems()
            } else {
              this.in_process = false;
            }
          } catch (error) {
            this.toastr.error('Ocurrió un error inesperado');
          }
        };

        reader.readAsBinaryString(file);
      } else {
        this.toastr.warning('Selecciona un archivo Excel válido');
      }
    }
  }

  async importCartItems(){
    this.toastr.clear();
    this.toastr.info('Por favor espere...', 'Validando existencias');
    const info = await this.api.validateCartItem(this.auth.getToken(), this.cart_items.map((item) => {
      return {
        ean: item.ean
      }
    })) as any;
    if(info){
      this.toastr.clear();
      if(info.code !== 200){
        this.toastr.error(info.message, `Error ${info.code}`);
      } else {
        this.in_process = false;
        const items = info.items;
        for(const item of items){
          const findQty = this.cart_items.find((cart_item) => cart_item.ean === item.ean);
          const quantity = findQty ? findQty.qty : 1;
          item.quantity = quantity;
          await this.cart.addItem(item, quantity, this.auth.getToken(), this.infoService ? this.infoService.pickerpro_id: '', '');
        }
        this.cart.updateCartAnimation(true);
      }
      this.in_process = false;
    } else {
      this.toastr.error('Ocurrió un error inesperado al validar existencias');
    }
  }

}
