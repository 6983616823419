import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { Product } from '../../classes/product';
import { environment } from 'src/environments/environment.prod';
import { WebhookServiceService } from '../webhook/webhook-service.service';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
let items = [];
if(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=') !== null){
  try {
    items = JSON.parse(atob(localStorage.getItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=')))
  } catch(err) {
    localStorage.removeItem('UHJvZHVjdG9zIGVuIGNhcnJpdG8=');
  }
}
let state = {
  products: JSON.parse(localStorage['UHJvZHVjdG9z'] || '[]'),
  cart: items
}

@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {

  public Currency = { name: 'Peso mexicano', currency: 'MXN', price: 1 } // Default Currency
  // public OpenCart: boolean = false;
  // public Products;
  private headers: HttpHeaders;
  public productos = [];
  private counter = 0;
  private counter2 = 0;
  private timeoutHandle;

  // DETALLE DEL PRODUCTO
  private productSource = new BehaviorSubject({});
  currentProduct = this.productSource.asObservable();

  private productList = new BehaviorSubject([]);
  currentProducts = this.productList.asObservable();


  // constructor(private toastrService: ToastrService, private http: HttpClient) { }
  constructor(private http: HttpClient, private websocket: WebhookServiceService, private cartItem: ShopsCartAngularService) {
    /* HEADER PUBLICO*/
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `LocLog ${environment.appKey}`
    });
    this.cartItem.currentCart.subscribe(response => {
      state.cart = response;
    });
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

    public actualizarListado(productos) {
      this.productList.next(productos);
    }

  public actualizarDetalleProducto(producto) {
    this.productSource.next(producto);
  }
    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Add to Cart
    public addToCart(product, tieneVariante, infoVariante): any {
      let varianteSeleccionada;
      let hasColor = false;
      let hasSize = false;
      let cartItem;
      if(!tieneVariante) {
        if(state.cart === undefined){
          state.cart = [];
        }
        cartItem = state.cart.find(item => {
          if(item.id === product.id) {
            return item;
          }
        });
      } else {
        varianteSeleccionada = infoVariante;
        // hasColor = true;
        // hasSize = true;
        const variant_length = product.variations_type.split('&').length;
        cartItem = state.cart.find(item => {
          if(item.id === product.id) {
            if(variant_length === 1){
              if(item.variation.variant1.id.trim().toUpperCase() === varianteSeleccionada.variant1_selected.id.trim().toUpperCase() &&
              item.variation.variant1.value_name.trim().toUpperCase() === varianteSeleccionada.variant1_selected.value_name.trim().toUpperCase()){
                return item;
              }
            } else if(variant_length === 2){
              if(item.variation.variant1.id.trim().toUpperCase() === varianteSeleccionada.variant1_selected.id.trim().toUpperCase() &&
              item.variation.variant1.value_name.trim().toUpperCase() === varianteSeleccionada.variant1_selected.value_name.trim().toUpperCase() &&
              item.variation.variant2.id.trim().toUpperCase() === varianteSeleccionada.variant2_selected.id.trim().toUpperCase() &&
              item.variation.variant2.value_name.trim().toUpperCase() === varianteSeleccionada.variant2_selected.value_name.trim().toUpperCase()){
                return item;
              }
            }
          }
        });
      }

      const qty = product.quantity ? product.quantity : 1;
      const items = cartItem ? cartItem : product;
      const stock = this.calculateStockCounts(items, qty, varianteSeleccionada);

      const wid = product.wid ? product.wid : '';

      if(!stock) return false

      let item = {} as any;
      if (cartItem) {
          cartItem.stock = product.stock;
          cartItem.quantity += qty;
          if(cartItem.attribute_types === 'attributes'){
            if(cartItem.quantity > product.stock){
              cartItem.quantity = product.stock;
            }
          } else if(cartItem.attribute_types === 'variations'){
            cartItem.variation.stock = varianteSeleccionada.stock;
            if(cartItem.quantity > varianteSeleccionada.stock){
              cartItem.quantity = varianteSeleccionada.stock;
            }
          }
          if(cartItem.has_wholesale_price === true && cartItem.quantity >= cartItem.minimum_purchase){
            cartItem.price = cartItem.wholesale_price;
          } else {
            cartItem.price = cartItem.normal_price;
          }
          item = cartItem;
      } else {
        let precioFinal;
        if(product.has_wholesale_price === true && qty >= product.minimum_purchase) {
          precioFinal = product.wholesale_price;
        } else {
          precioFinal = product.retail_price;
        }
        let pesoProducto = 1;
        const atributoPeso = product.attributes.filter((attribute) => {
          return attribute.id === 'PACKAGE_WEIGHT';
        });
        if(atributoPeso.length > 0){
          pesoProducto = atributoPeso[0].value_struct;
        }
        if(tieneVariante) {
          item = {
            // CARACTERÍSTICAS PARA DESCUENTO
            id: product.id,
            wid,
            created_at: product.created_at,
            category_id: product.category_id,
            gender_id: product.gender_id,
            is_featured: product.is_featured,
            // OTRAS CARACTERÍSTICAS
            attribute_types: product.attribute_types,
            variations_type: product.variations_type,
            has_discount: product.has_discount,
            discount: product.discount,
            has_wholesale_price: product.has_wholesale_price,
            minimum_purchase: product.minimum_purchase,
            price: precioFinal,
            slug: product.slug,
            title: product.title,
            wholesale_price: product.wholesale_price,
            weight_unit: pesoProducto,
            variation: {
              id: varianteSeleccionada.id,
              variant1: varianteSeleccionada.variant1_selected,
              variant2: varianteSeleccionada.variant2_selected,
              stock: varianteSeleccionada.stock
            },
            normal_price: product.retail_price,
            currency: product.currency,
            images: varianteSeleccionada.images,
            quantity: qty,
            warning: product.warning
          }
        } else {
          item = {
            // CARACTERÍSTICAS PARA DESCUENTO
            id: product.id,
            wid,
            created_at: product.created_at,
            category_id: product.category_id,
            gender_id: product.gender_id,
            is_featured: product.is_featured,
            // OTRAS CARACTERÍSTICAS
            attribute_types: product.attribute_types,
            variations_type: product.variations_type,
            has_discount: product.has_discount,
            discount: product.discount,
            has_wholesale_price: product.has_wholesale_price,
            images: product.images,
            minimum_purchase: product.minimum_purchase,
            price: precioFinal,
            slug: product.slug,
            title: product.title,
            wholesale_price: product.wholesale_price,
            stock: product.stock,
            weight_unit: pesoProducto,
            normal_price: product.retail_price,
            currency: product.currency,
            quantity: qty,
            warning: product.warning
          }
        }
        state.cart.push(item);
      }
      this.shareCarts(state.cart, item, 'carts_add');
      localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
      return true;
    }


    // Update Cart Quantity
    public updateCartQuantity(product, quantity: number, variation): Product | boolean {
      return state.cart.find((items, index) => {
        if(product.attribute_types === 'attributes'){
          if (items.id === product.id) {
            this.updateItemCart(quantity, variation, index);
            return true
          }
        } else if(product.attribute_types === 'variations'){
          const variant_length = product.variations_type.split('&').length;
          if(variant_length === 1 && items.id === product.id &&
            items.variation.variant1.id === product.variation.variant1.id &&
            items.variation.variant1.value_name === product.variation.variant1.value_name){
              this.updateItemCart(quantity, variation, index);
              return true
          } else if(variant_length === 2 && items.id === product.id &&
            items.variation.variant1.id === product.variation.variant1.id &&
            items.variation.variant1.value_name === product.variation.variant1.value_name &&
            items.variation.variant2.id === product.variation.variant2.id &&
            items.variation.variant2.value_name === product.variation.variant2.value_name){
              this.updateItemCart(quantity, variation, index);
              return true
          }
        }
      });
    }

    async updateItemCart(quantity: number, variation, index){
      const qty = state.cart[index].quantity + quantity
      const stock = this.calculateStockCounts(state.cart[index], qty, variation);
      if (qty > 0 && stock) {
        state.cart[index].quantity = qty;
        if(state.cart[index].has_wholesale_price === true && state.cart[index].quantity >= state.cart[index].minimum_purchase){
          state.cart[index].price = state.cart[index].wholesale_price;
        } else {
          state.cart[index].price = state.cart[index].normal_price;
        }
      }
      localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
      clearTimeout(this.timeoutHandle);
      this.sendData(state.cart, state.cart[index]);
    }

    sendData(cart, item) {
      this.timeoutHandle = setTimeout(() => {
          this.shareCarts(cart, item, 'carts_update');
      }, 1000);
    }

    // Calculate Stock Counts
    public calculateStockCounts(product, quantity, varianteSeleccionada) {
      const variant_length = product.variations_type.split('&').length;
      let stock = 0;
      if(product.attribute_types === 'attributes'){
        stock = product.stock;
      } else if(product.attribute_types === 'variations'){
        stock = varianteSeleccionada.stock;
      }
      if(stock <= quantity){
        let existeEnCarrito = false;
        let contadorItems = 0;
        for(let i = 0; i < state.cart.length; i++){
          contadorItems = contadorItems + 1;
          if (state.cart[i].id === product.id) {
            if(product.attribute_types === 'attributes'){
              state.cart[i].stock = product.stock;
              state.cart[i].quantity = product.stock;
            } else if(product.attribute_types === 'variations'){
              if(variant_length === 1
                && state.cart[i].variation.variant1.id === varianteSeleccionada.variant1.id &&
                state.cart[i].variation.variant1.value_name === varianteSeleccionada.variant1.value_name){
                  state.cart[i].variation.stock = varianteSeleccionada.stock;
                  state.cart[i].quantity = varianteSeleccionada.stock;
                  existeEnCarrito = true;
              } else if(variant_length === 2
                && state.cart[i].variation.variant1.id === varianteSeleccionada.variant1.id &&
                state.cart[i].variation.variant1.value_name === varianteSeleccionada.variant1.value_name &&
                state.cart[i].variation.variant2.id === varianteSeleccionada.variant2.id &&
                state.cart[i].variation.variant2.value_name === varianteSeleccionada.variant2.value_name){
                  state.cart[i].variation.stock = varianteSeleccionada.stock;
                  state.cart[i].quantity = varianteSeleccionada.stock;
                  existeEnCarrito = true;
              }
            }
            localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
          }
        }
        if(contadorItems === state.cart.length && existeEnCarrito){
          return false;
        } else {
          return true;
        }
      } else if (stock === 0) {
        return false;
      }
      return true;
    }

    public calculateStockCountsV2(product, quantity) {
      let stock = product.stock;
      if(stock <= quantity){
        let existeEnCarrito = false;
        let contadorItems = 0;
        for(let i = 0; i < state.cart.length; i++){
          contadorItems = contadorItems + 1;
          if (state.cart[i].id === product.id) {
            state.cart[i].stock = product.stock;
            state.cart[i].quantity = product.stock;
            localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
          }
        }
        if(contadorItems === state.cart.length && existeEnCarrito){
          return false;
        } else {
          return true;
        }
      } else if (stock === 0) {
        return false;
      }
      return true;
    }

  //   public calculateStockCountsV2(product, quantity) {
  //     const total = Math.min(quantity, product.stock);
  //     if(product.stock >=0){
  //       const existeEnCarrito = state.cart.some(item => {
  //         if (item.id === product.id) {
  //             item.stock = total;
  //             item.quantity = total;
  //             localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
  //             return true;
  //         }
  //         return false;
  //       });

  //       return !existeEnCarrito && state.cart.length > 0;
  //     }

  //     return false;
  // }

    // Remove Cart items
    public removeCartItem(product): any {
      state.cart.find((items, index) => {
        if(product.attribute_types === 'attributes'){
          if (items.id === product.id) {
            const item = state.cart[index];
            state.cart.splice(index, 1);
            localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
            this.shareCarts(state.cart, item, 'carts_remove');
            return true
          }
        } else if(product.attribute_types === 'variations'){
          const variant_length = product.variations_type.split('&').length;
          if(variant_length === 1 && items.id === product.id &&
            items.variation.variant1.id === product.variation.variant1.id &&
            items.variation.variant1.value_name === product.variation.variant1.value_name){
              const item = state.cart[index];
              state.cart.splice(index, 1);
              localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
              this.shareCarts(state.cart, item, 'carts_remove');
              return true
          } else if(variant_length === 2 && items.id === product.id &&
            items.variation.variant1.id === product.variation.variant1.id &&
            items.variation.variant1.value_name === product.variation.variant1.value_name &&
            items.variation.variant2.id === product.variation.variant2.id &&
            items.variation.variant2.value_name === product.variation.variant2.value_name){
              const item = state.cart[index];
              state.cart.splice(index, 1);
              localStorage.setItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=", btoa(JSON.stringify(state.cart)));
              this.shareCarts(state.cart, item, 'carts_remove');
              return true
          }
        }
      });
    }

    shareCarts(carts, item, type){
      this.cartItem.updateItems(carts);
      this.websocket.sendCarts(item, type);
    }

    public cleanCart() {
      localStorage.removeItem("UHJvZHVjdG9zIGVuIGNhcnJpdG8=");
      localStorage.removeItem('Y2FydElk');
      state.cart = [];
      this.cartItem.updateItems(state.cart);
      this.websocket.sendCarts([], 'clean_cart');
      return true;
    }

    // Total amount
    public cartTotalAmount(): Observable<number> {
      return this.cartItem.currentCart.pipe(map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if(curr.has_discount) {
            price = curr.price - (curr.price * curr.discount / 100);
          }
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      }));
    }
}
