import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';
import { RestApiServiceService } from '../../../services/api/rest-api-service.service';
import { EncryptManagerService } from '@ignacioruben7/shops-encrypt-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `a:hover {
      color: var(--colorThemePrimary);
    }`
  ]
})
export class SigninComponent implements OnInit {

  public correo;
  public correoValido = false;
  public contrasenia;
  private queryParams;
  private pendiente_pago = false;
  public colorTheme = 'rgb(216, 216, 216)';
  public isB2C = environment.isB2C;
  private hasToken = false;
  private myPublicIp = '';

  public serviceId = '';
  private storeKey = '';
  public currentVersion = environment.app_version;

  constructor(private service: CustomStoreService, private cart: CartServiceService, private deviceService: DeviceDetectorService, private styling: StyleManagerService, private toastrService: ToastrService, private route: ActivatedRoute,private router: Router, private encryption: EncryptManagerService, private api: RestApiServiceService, private auth: ShopsAuthAngularService) {
    const pendientePago = sessionStorage.getItem('cGVuZGllbnRlX3BhZ28=');
    if(pendientePago !== null){
      this.pendiente_pago = true;
    }
    this.auth.removeToken();
    if(!this.pendiente_pago){
      // sessionStorage.clear();
      sessionStorage.removeItem('Tm9tYnJlIGNsaWVudGU=');
      sessionStorage.removeItem('Q3VzdG9tZXIgZGlyZWN0aW9u');
      sessionStorage.removeItem('QXBlbGxpZG9zIGNsaWVudGU=');
      sessionStorage.removeItem('VGVsw6lmb25vIGNsaWVudGU=');
      sessionStorage.removeItem('ZGF0b3NGYWN0dXJhY2lvbg==');
      sessionStorage.removeItem('cGVuZGllbnRlX3BhZ28=');
      sessionStorage.removeItem('c2VhcmNoUXVlcnk=');
      sessionStorage.removeItem('UGVkaWRvIGNsaWVudGU=');
    }
    this.route.queryParamMap.subscribe(params => {
      this.queryParams = {...params.keys, ...params};
      if(this.queryParams.params.pendiente_pago !== undefined){
        this.pendiente_pago = this.queryParams.params.pendiente_pago;
        sessionStorage.setItem('cGVuZGllbnRlX3BhZ28=', 'c2k=');
      }
      if(this.queryParams.params.token !== undefined){
          this.hasToken = true;
          const info_desencriptada = this.encryption.decryptBase64(this.queryParams.params.token);
          this.auth.removeToken();
          // sessionStorage.setItem('YWNjZXNzIHRva2Vu', info_desencriptada);
          this.auth.setToken(info_desencriptada);
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(info_desencriptada);
          if (decodedToken.direccion !== null && decodedToken.direccion !== undefined) {
            const direccion_base64 = JSON.stringify(decodedToken.direccion);
            sessionStorage.setItem('Q3VzdG9tZXIgZGlyZWN0aW9u', direccion_base64);
          }
          if (decodedToken.nombres !== null && decodedToken.nombres !== undefined) {
            const nombres_base64 = decodedToken.nombres;
            sessionStorage.setItem('Tm9tYnJlIGNsaWVudGU=', nombres_base64);
          }
          if (decodedToken.apellidos !== null && decodedToken.apellidos !== undefined) {
            const apellidos_base64 = decodedToken.apellidos;
            sessionStorage.setItem('QXBlbGxpZG9zIGNsaWVudGU=', apellidos_base64);
          }
          if (decodedToken.telefono !== null && decodedToken.telefono !== undefined) {
            const telefono_base64 = decodedToken.telefono;
            sessionStorage.setItem('VGVsw6lmb25vIGNsaWVudGU=', telefono_base64);
          }
          this.toastrService.success('Bienvenido ' + decodedToken.nombres + ' :)');
          // const pendientePago = sessionStorage.getItem('cGVuZGllbnRlX3BhZ28=');
          this.cart.getCartItems();
          if(this.pendiente_pago){
            sessionStorage.removeItem('cGVuZGllbnRlX3BhZ28=');
            this.router.navigate(['/pagar']);
          } else {
            this.router.navigate(['/tienda']);
          }
      }
    });
  }

  ngOnInit() {
    this.getDeviceInfo();
    window.scroll(0,0);
    // const infoService = sessionStorage.getItem('cHVibGlj');
    // if(infoService === null){
    //   // window.location.reload();
    // }
    sessionStorage.removeItem('cGF5bWVudFNvdXJjZXM=');
    localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
    localStorage.removeItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==');
    if(!this.hasToken){
      this.auth.removeToken();
      if(!this.pendiente_pago){
        // sessionStorage.clear();
        sessionStorage.removeItem('Tm9tYnJlIGNsaWVudGU=');
        sessionStorage.removeItem('Q3VzdG9tZXIgZGlyZWN0aW9u');
        sessionStorage.removeItem('QXBlbGxpZG9zIGNsaWVudGU=');
        sessionStorage.removeItem('VGVsw6lmb25vIGNsaWVudGU=');
        sessionStorage.removeItem('ZGF0b3NGYWN0dXJhY2lvbg==');
        sessionStorage.removeItem('cGVuZGllbnRlX3BhZ28=');
        sessionStorage.removeItem('c2VhcmNoUXVlcnk=');
        sessionStorage.removeItem('UGVkaWRvIGNsaWVudGU=');
      }
    }
    this.checkLocalStorage();
    // if(localStorage.getItem('TUFJTl9CQU5ORVI=') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('TUFJTl9CQU5ORVI=') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }

    // BUSCAR VERSION
    const currentVersion = localStorage.getItem('Y3VycmVudFZlcnNpb24=');
    if(currentVersion !== null){
      if(currentVersion !== environment.app_version){
        localStorage.setItem('Y3VycmVudFZlcnNpb24=', environment.app_version);
        window.location.reload();
      }
    } else {
      localStorage.setItem('Y3VycmVudFZlcnNpb24=', environment.app_version);
      window.location.reload();
    }


  }

  getDeviceInfo = async () => {
    let deviceInfo = {} as any;
    deviceInfo = this.deviceService.getDeviceInfo();
    let infoIp = null as any;
    this.api.getPublicIp().subscribe(
      respuesta => {
        if(respuesta.trim() !== ''){
          infoIp = respuesta.trim().split('\n').reduce((obj, pair: any) => {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
          }, {});
        }
        // accessToken = JSON.parse(JSON.stringify(respuesta)).token;
        // estado = JSON.parse(JSON.stringify(respuesta)).code;
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
        } else {
        }
      }, () => {
        deviceInfo.ip_address = infoIp;
        const base64Code = btoa(JSON.stringify(deviceInfo));
        sessionStorage.setItem('ZGV2aWNlSW5mbw==', base64Code);
      });
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // }

    // let titulo = atob(localStorage.getItem('SU5GTy1USVRMRQ=='));;
    // let titleHeader = '';
    // if(titulo !== ''){
    //   titleHeader = `${titulo} | Ingresar`;
    // } else {
    //   titleHeader = `LocLog Shops | Ingresar`;
    // }
    // this.api.setTitle(titleHeader);
    // this.api.updateTags([
    //   {name: 'og:title', content: titleHeader}
    // ]);

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
        }
        const titleHeader = response.title ? `${response.title} | Ingresar`:'LocLog Shops | Ingresar';
        this.api.setTitle(titleHeader);
        this.api.updateTags([
          {name: 'og:title', content: titleHeader}
        ]);

        this.serviceId = response.public;
        this.storeKey = response.key;
      }
    });
  }

  isHover2(hover, id){
    if(hover){
      this.styling.setColor(id,'color', this.colorTheme);
    } else {
      this.styling.setColor(id,'color', '#333');
    }
  }

  escucharValoresInput(event, idInput) {
    const valor = event.target.value;

    if (idInput === 'correo'){
      this.correo = valor.trim();
    } else if (idInput === 'contrasenia'){
      this.contrasenia = valor.trim();
    }
  }

  iniciarSesion() {
    if (this.correo === undefined || this.correo.trim() === ''){
      this.toastrService.warning('El correo es requerido');
    }
    if (this.contrasenia === undefined || this.contrasenia.trim() === ''){
      this.toastrService.warning('La contraseña es requerida');
    }
    if (this.correo !== undefined && this.correo.trim() !== '' && this.contrasenia !== undefined && this.contrasenia.trim() !== ''){
      this.toastrService.info('Verificando identidad...');
      const usuario = {
        correo: this.correo,
        contrasenia: this.contrasenia
      }
      if(this.serviceId !== ''){
        const info_encriptada = this.encryption.encryptBase64(JSON.stringify(usuario));
        const infoDevice = sessionStorage.getItem('ZGV2aWNlSW5mbw==');
        let accessToken;
        let estado;
        this.api.authAPIv2(info_encriptada, infoDevice, this.serviceId).subscribe(
          respuesta => {
            accessToken = JSON.parse(JSON.stringify(respuesta)).token;
            estado = JSON.parse(JSON.stringify(respuesta)).code;
          }, (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
            } else {
            }
            this.toastrService.clear();
            if (err.status === 400) {
              this.toastrService.error('Error 400');
            }
            if (err.status === 403) {
              this.toastrService.warning('Confirma tu cuenta');
              this.router.navigate(['/registrarme'], { queryParams: { code: '' } });
            }
            if (err.status === 405) {
              window.location.reload();
            }
            // if (err.status === 401 || err.status === 404) {
            //   this.toastrService.error('No estás autorizado para acceder');
            // }
            if (err.status === 401) {
              this.toastrService.error('No estás autorizado para acceder');
            }
            if (err.status === 409) {
              this.toastrService.warning('Solicita asignación de un CEDI al equipo de ventas');
            }
            if (err.status === 410) {
              this.toastrService.warning('Tu usuario fue eliminado por el equipo de ventas');
              this.router.navigate(['/registrarme']);
            }
            if (err.status === 411) {
              this.toastrService.warning('Espera 5 segundos despues de que vuelva a cargar la pagina y vuelve a intentarlo', 'Verificando servicio');
              setTimeout(()=> {
                window.location.reload();
              }, 3000);
            }
            if (err.status === 405) {
              this.toastrService.warning('Confirma tu cuenta');
            }
            if (err.status === 427) {
              this.toastrService.warning('Cuenta bloqueada. Solicita acceso al equipo de ventas');
            }
            if (err.status === 429) {
              this.toastrService.warning(err.error.message);
            }
            if (err.status === 500) {
              this.toastrService.error('Error 500');
            }
          }, () => {
            if (estado === 200){
              // let privateKey = '';
              // const key = sessionStorage.getItem('a2V5');
              // if(key !== null){
              //   privateKey = key;
              // }
              const info_desencriptada = this.encryption.decryptBase64(accessToken);
              this.auth.removeToken();
              this.auth.setToken(info_desencriptada);
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(info_desencriptada);
              if (decodedToken.direccion !== null && decodedToken.direccion !== undefined) {
                const direccion_base64 = JSON.stringify(decodedToken.direccion);
                sessionStorage.setItem('Q3VzdG9tZXIgZGlyZWN0aW9u', direccion_base64);
              }
              // obtener info de direcciones en Tienda o Checkout depende el ingreso
              if (decodedToken.facturacion !== null && decodedToken.facturacion !== undefined) {
                const facturacion_base64 = JSON.stringify(decodedToken.facturacion);
                sessionStorage.setItem('ZGF0b3NGYWN0dXJhY2lvbg==', facturacion_base64);
              }
              if (decodedToken.nombres !== null && decodedToken.nombres !== undefined) {
                const nombres_base64 = decodedToken.nombres;
                sessionStorage.setItem('Tm9tYnJlIGNsaWVudGU=', nombres_base64);
              }
              if (decodedToken.apellidos !== null && decodedToken.apellidos !== undefined) {
                const apellidos_base64 = decodedToken.apellidos;
                sessionStorage.setItem('QXBlbGxpZG9zIGNsaWVudGU=', apellidos_base64);
              }
              if (decodedToken.telefono !== null && decodedToken.telefono !== undefined) {
                const telefono_base64 = decodedToken.telefono;
                sessionStorage.setItem('VGVsw6lmb25vIGNsaWVudGU=', telefono_base64);
              }
              if(decodedToken.preorders){
                sessionStorage.setItem('cHJlb3JkZXJz', JSON.stringify(decodedToken.preorders));
              }
              this.toastrService.clear();
              this.toastrService.success('Bienvenido ' + decodedToken.nombres + ' :)');
              this.cart.getCartItems();
              if(this.pendiente_pago){
                this.router.navigate(['/pagar']);
              } else {
                this.router.navigate(['/tienda']);
              }
            } else {
              this.toastrService.error('Ocurrió un error inesperado');
            }
          });
      } else {
        this.toastrService.clear();
        this.toastrService.warning('Por favor espere unos segundos y vuelva hacer clic en INGRESAR');
      }
    }
  }

  goToFacebookAuth(){
    // window.location.href = `http://localhost:3222/facebook/init?store=${window.location.hostname}`;
    window.location.href = `https://auth.loclog.mx/facebook/init?store=${window.location.hostname}`;
  }


  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
