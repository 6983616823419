<app-header *ngIf="isB2C"></app-header>
<app-navbar *ngIf="isB2C"></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content">

    <!-- START LOGIN SECTION -->
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-md-10">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div class="heading_s1">
                                <h3 style="text-transform: none;" *ngIf="!confirmarCorreo">Crea una cuenta</h3>
                                <h3 style="text-transform: none;" *ngIf="confirmarCorreo">Confirma tu cuenta</h3>
                            </div>
                            <div *ngIf="!confirmarCorreo">
                                <div class="form-group">
                                    <input type="text" value="{{nombres}}" required="" class="form-control" name="firstname" placeholder="Nombre" (keyup)="escucharValoresInput($event, 'nombres')">
                                </div>
                                <div class="form-group">
                                    <input type="text" value="{{apellidos}}" required="" class="form-control" name="lastname" placeholder="Apellidos" (keyup)="escucharValoresInput($event, 'apellidos')">
                                </div>
                                <div class="form-group">
                                    <input type="text" value="{{correo}}" required="" class="form-control" name="email" placeholder="Correo electrónico" (keyup)="escucharValoresInput($event, 'correo')">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" value="{{contrasenia}}" required="" type="password" name="password" placeholder="Contraseña" (keyup)="escucharValoresInput($event, 'contrasenia')">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" value="{{confirmarContrasenia}}" required="" type="password" name="password" placeholder="Confirmar contraseña" (keyup)="escucharValoresInput($event, 'confirmar_contrasenia')">
                                </div>
                                <!-- <div class="login_footer form-group">
                                    <div class="chek-form">
                                        <div class="custome-checkbox">
                                            <input class="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox2" value="">
                                            <label class="form-check-label" for="exampleCheckbox2"><span>Estoy de acuerdo con los tér &amp; Policy.</span></label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <button class="btn btn-block" (click)="registrarme()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">REGISTRARME</button>
                                </div>
                            </div>
                            <div *ngIf="confirmarCorreo">
                                <label for="email" style="text-transform: none;"><span class="text-danger"><b>*</b></span> Ingresa tu código de confirmación</label>
                                <input type="text" value="{{codigoConfirmacion}}" class="form-control" id="codigo" placeholder="Código de confirmación" (keyup)="escucharValoresInput($event, 'codigo')">
                                <div class="form-group">
                                    <button class="btn btn-block mt-4" (click)="confirmar()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">CONFIRMAR</button>
                                </div>
                            </div>
                            <div class="form-note text-center" *ngIf="!confirmarCorreo">¿Ya tienes cuenta? <a [routerLink]="['', 'ingresar']" id="signin" (mouseover)="isHover2(true, 'signin')" (mouseleave)="isHover2(false, 'signin')">Ingresa aquí</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END LOGIN SECTION -->
</div>
<!-- END MAIN CONTENT -->
<app-footer *ngIf="isB2C"></app-footer>