import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { CartModalComponent } from '../../widgets/cart-modal/cart-modal.component';
import { environment } from 'src/environments/environment.prod';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { ToastrService } from 'ngx-toastr';
import { PreorderModalComponent } from '../preorder-modal/preorder-modal.component';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.css'],
  styles: [
    `.price {
      color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `.product_size_switch span.active {
      border-color: var(--colorThemePrimary);
      background-color: var(--colorThemePrimary);
      color: #fff;
    }`,
    `.dash-btn-outline-1:hover {
      background: var(--colorThemePrimary);
      border: 1px solid var(--colorThemePrimary);
      color: white;
    }`
  ]
})
export class QuickViewComponent implements OnInit {

  @Input() public product;
  public counter = 1;
  public variantsLevel1 = [];
  public variantsLevel2 = [];
  public variantSelected;
  public colorTheme = 'white';
  public colorTheme2 = '#333';
  public showStock = environment.showStock;

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private cart:ShopsCartAngularService, private modalService: NgbModal, public activeModal: NgbActiveModal, private router: Router, private productService: ProductServiceService) { }

  ngOnInit() {
    const variations_length = this.product.variations_type.split('&').length;
    if(this.product.attribute_types === "variations" && variations_length === 2){
      this.variantsLevel2 = [...this.product.variations];
      this.product.variations.forEach((variation) => {
        const filterVariation = this.variantsLevel1.filter((variant) => {
          return variant.id.trim().toUpperCase() === variation.combinations[0].id.trim().toUpperCase() && variant.value_name.trim().toUpperCase() === variation.combinations[0].value_name.trim().toUpperCase();
        });

        if(filterVariation.length === 0){
          this.variantsLevel1.push(variation.combinations[0]);
        }
      });
      const filterVariante2 = this.variantsLevel2.filter((variant2) => {
        return variant2.combinations[0].id.trim().toUpperCase() === this.variantsLevel1[0].id.trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === this.variantsLevel1[0].value_name.trim().toUpperCase();
      });
      if(filterVariante2.length > 0){
        this.variantSelected = {
          variant1: [...this.variantsLevel1],
          images: [...filterVariante2[0].images],
          variant2: filterVariante2,
          variant1_selected: this.variantsLevel1[0],
          variant2_selected: filterVariante2[0].combinations[1],
          stock: filterVariante2[0].stock,
          selected: 1
        }
        if(filterVariante2.length > 0){
          setTimeout(() => {
            this.styling.setColor(`variant2_0`, 'backgroundColor', this.colorTheme);
            this.styling.setColor(`variant2_0`, 'borderColor', this.colorTheme);
            this.styling.setColor(`variant2_0`, 'color', 'white');
          }, 1);
        }
      }
    } else if(this.product.attribute_types === "variations" && variations_length === 1){
      this.variantsLevel1 = [...this.product.variations];
      this.variantSelected = {
        variant1: [...this.variantsLevel1],
        images: [...this.variantsLevel1[0].images],
        variant2: [],
        variant1_selected: this.variantsLevel1[0],
        variant2_selected: '',
        stock: this.variantsLevel1[0].stock,
        selected: 1
      };
    } else if(this.product.attribute_types === "attributes"){
      this.variantSelected = {
        variant1: [],
        images: [...this.product.images],
        variant2: [],
        variant1_selected: '',
        variant2_selected: '',
        stock: this.product.stock,
        selected: 1
      };
    }
    // if(localStorage.getItem('c2hvd1N0b2Nr') !== null){
    //   try {
    //     const info = JSON.parse(atob(localStorage.getItem('c2hvd1N0b2Nr')));
    //     this.showStock = info.show;
    //   } catch (error) {

    //   }
    // }
    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorTheme2 = colorTheme;
    // }
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorTheme2 = response.color;
        }
        this.showStock = response.show_stock;
      }
    });
  }

  isHover(hover, id){
    if(hover){
      this.styling.setColor(id, 'color', '#ffffff');
      this.styling.setColor(id, 'background', this.colorTheme);
      this.styling.setColor(id, 'borderColor', this.colorTheme);
    } else {
      this.styling.setColor(id, 'backgroundColor', 'transparent');
      this.styling.setColor(id, 'color', '#6c757d');
      this.styling.setColor(id, 'borderColor', '#6c757d');
    }
  }

  updateUrl(product, variantSelected) {
    try {
      if(product.attribute_types === 'attributes'){
        product.images[0].url = 'assets/images/product_img1.jpg';
      } else if(product.attribute_types === 'variations'){
        variantSelected.images[0].url = 'assets/images/product_img1.jpg';
      }
    } catch(err){}
  }

  showDetail(){
    this.productService.actualizarDetalleProducto(this.product);
    this.router.navigate(['', 'p', this.product.slug], { queryParams: { uid: this.product.id } });
    this.activeModal.close('Modal Closed');
  }

  showPreorderModal(product){
    this.activeModal.close('Modal Closed');
    const modalRef = this.modalService.open(PreorderModalComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.product = product;
  }

  actionInCart(quantity, type, variation){
    if(variation === undefined){
      if(type === 'less' && (this.counter - quantity) >= 1){
        this.counter = this.counter - quantity;
      }
      if(type === 'more' && (this.counter + quantity) <= this.product.stock){
        this.counter = this.counter + quantity;
      }
    }
    if(variation !== undefined){
      if(this.product.attribute_types === 'variations'){
        if(type === 'less' && (this.counter - quantity) >= 1){
          this.counter = this.counter - quantity;
          this.variantSelected.selected = this.counter;
        }
        if(type === 'more' && (this.counter + quantity) <= this.variantSelected.stock){
          this.counter = this.counter + quantity;
          this.variantSelected.selected = this.counter;
        }
      }
    }
  }

  listenQuantity(event, variation){
    const value = event.target.value;
    const reg = /^\d+$/;
    if(reg.test(value)){
      const quantity = Number(value);
      let stock = this.product.stock;
      if(variation !== undefined){
        stock = variation.stock;
      }

      if(quantity > 0 && quantity <= stock){
        this.counter = quantity;
      } else if(quantity > stock){
        this.counter = stock;
        event.target.value = `${stock}`;
      } else {
        this.counter = 1;
        event.target.value = '';
      }
      if(variation !== undefined){
        this.variantSelected.selected = this.counter;
      }
    } else {
      this.counter = 1;
      event.target.value = '';
    }
  }

  async addToCart(product){
    let tieneVariantes = false;
    let infoVariation;
    if (this.product.attribute_types === 'variations'){
      tieneVariantes = true;
      const variant_length = product.variations_type.split('&').length;
      if(variant_length === 2){
        infoVariation = this.variantSelected;
        const filtered = product.variations.findIndex((variation) => {
          return variation.combinations[0].value_name.trim().toLowerCase() === this.variantSelected.variant1_selected.value_name.trim().toLowerCase() && variation.combinations[1].value_name.trim().toLowerCase() === this.variantSelected.variant2_selected.value_name.trim().toLowerCase();
        });
        if(filtered !== -1){
          infoVariation.id = product.variations[filtered].id;
        }
      } else if(variant_length === 1){
        infoVariation = {
          images: this.variantSelected.images,
          selected: this.variantSelected.selected,
          stock: this.variantSelected.stock,
          variant1: this.variantSelected.variant1.map((variant) => {
            return variant.combinations[0]
          }),
          variant1_selected: this.variantSelected.variant1_selected.combinations[0],
          variant2: [],
          variant2_selected: ""
        };
        infoVariation.id = this.variantSelected.variant1_selected.id;
      }
    }
    product.quantity = this.counter || 1;
    const status = await this.productService.addToCart(product, tieneVariantes, infoVariation);
    if(status){
      sessionStorage.setItem('aXNBbmltYXRlQ2FydA==', 'true');
      this.cart.updateCartAnimation(true);
      if(environment.show_confirm_cart_modal){
        const modalRef = this.modalService.open(CartModalComponent, {size: 'lg', centered: true});
        if(!tieneVariantes){
          modalRef.componentInstance.product = product;
        } else {
          let producto = product;
          producto.variation = infoVariation;
          modalRef.componentInstance.product = producto;
        }
        this.activeModal.close('Modal Closed');
      } else {
        this.activeModal.close('Modal Closed');
      }
    }
  }

  selectVariationLevel1(variation){
    const variation_length = this.product.variations_type.split('&').length;
    if(this.product.attribute_types === "variations" && variation_length === 2){
      const filterVariante2 = this.variantsLevel2.filter((variant2) => {
        return variant2.combinations[0].id.trim().toUpperCase() === variation.id.trim().toUpperCase() && variant2.combinations[0].value_name.trim().toUpperCase() === variation.value_name.trim().toUpperCase();
      });
      if(filterVariante2.length > 0){
        this.variantSelected.images = [];
        this.variantSelected.variant2 = [];
        this.variantSelected.images = [...filterVariante2[0].images];
        this.variantSelected.variant2 = [...filterVariante2];
        this.variantSelected.variant1_selected = variation;
        this.variantSelected.variant2_selected = filterVariante2[0].combinations[1];
        this.variantSelected.stock = filterVariante2[0].stock;
        this.variantSelected.selected = 1;
        setTimeout(() => {
          this.styling.setColor(`variant2_0`, 'backgroundColor', this.colorTheme);
          this.styling.setColor(`variant2_0`, 'borderColor', this.colorTheme);
          this.styling.setColor(`variant2_0`, 'color', 'white');
        }, 1);
      }
    } else if(this.product.attribute_types === "variations" && variation_length === 1){
        this.variantSelected.images = [];
        this.variantSelected.variant2 = [];
        this.variantSelected.images = [...variation.images];
        this.variantSelected.variant1_selected = variation;
        this.variantSelected.variant2_selected = '';
        this.variantSelected.stock = variation.stock;
        this.variantSelected.selected = 1;
    }
    this.counter = 1;
  }

  selectVariationLevel2(variation, position){
    this.variantSelected.variant2_selected = variation.combinations[1];
    this.variantSelected.stock = variation.stock;
    this.variantSelected.selected = 1;
    this.counter = 1;
    setTimeout(() => {
      this.variantSelected.variant2.forEach((variant, index) => {
        if(index !== position){
          this.styling.setColor(`variant2_${index}`, 'backgroundColor', 'transparent');
          this.styling.setColor(`variant2_${index}`, 'borderColor', '#ddd');
          this.styling.setColor(`variant2_${index}`, 'color', '#687188');
        } else {
          this.styling.setColor(`variant2_${index}`, 'backgroundColor', this.colorTheme);
          this.styling.setColor(`variant2_${index}`, 'borderColor', this.colorTheme);
          this.styling.setColor(`variant2_${index}`, 'color', 'white');
        }
      });
    }, 1);
  }

}
