import { Component, AfterContentChecked, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopsCartAngularService } from '@ignacioruben7/shops-cart-angular';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { Product } from '../../../classes/product';
import { ProductServiceService } from '../../../services/product/product-service.service';
import { CartServiceService } from 'src/app/services/cart/cart-service.service';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';
import { ShopsAuthAngularService } from '@ignacioruben7/shops-auth-angular';

@Component({
  selector: 'app-settings-mobile',
  templateUrl: './settings-mobile.component.html',
  styleUrls: ['./settings-mobile.component.css'],
  styles: [
    `.cart_count,
    .wishlist_count {
        background-color: var(--colorThemePrimary);
    }`,
    `.cart_trigger:hover {
      color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
      color: white;
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `.cart_list a:hover {
      color: var(--colorThemePrimary)
    }`,
    `.cart_list a {
      color: var(--colorThemePrimary)
    }`,
    `.linearicons-cart {
      color: var(--colorThemePrimary)
    }`
  ]
})
export class SettingsMobileComponent implements OnInit {

  public products: Product[] = [];
  public ImageSrc : string = '';
  public subtotal = 0;
  public colorIcon = '#333';
  public colorTheme = 'white';
  public showCartIcon = true;
  private animationCart = false;
  public subtotalCurrency = '';
  private infoService = null;

  constructor(private service: CustomStoreService, private cart: CartServiceService, private activatedRoute : ActivatedRoute, public productService: ProductServiceService, private router: Router, private auth: ShopsAuthAngularService) {
    this.activatedRoute.url.subscribe(url =>{
      const ruta = activatedRoute.snapshot.url;
      if(ruta[0].path === 'pagar'){
        this.showCartIcon = false;
      } else {
        this.showCartIcon = true;
      }
    });
    this.cart.currentCartAnimation.subscribe((state) => {
      this.animationCart = state;
      if(this.animationCart){
        sessionStorage.setItem('aXNBbmltYXRlQ2FydA==', 'true');
        const iconCart = document.getElementById('icon_add_to_cart');
        let c = this;
        if(iconCart !== null){
          iconCart.classList.add("pulseAnimation");
        }
        setTimeout(function () {
          if(iconCart !== null){
            iconCart.classList.remove("pulseAnimation");
            sessionStorage.removeItem('aXNBbmltYXRlQ2FydA==');
            c.animationCart = false;
            c.cart.updateCartAnimation(false);
          }
        }, 2000);
      }
    })
    // this.cart.getCurrentCartAnimation().subscribe(state => {
    //   if(this.animationCart){
    //     sessionStorage.setItem('aXNBbmltYXRlQ2FydA==', 'true');
    //     const iconCart = document.getElementById('icon_add_to_cart');
    //     let c = this;
    //     if(iconCart !== null){
    //       iconCart.classList.add("pulseAnimation");
    //     }
    //     setTimeout(function () {
    //       if(iconCart !== null){
    //         iconCart.classList.remove("pulseAnimation");
    //         sessionStorage.removeItem('aXNBbmltYXRlQ2FydA==');
    //         c.animationCart = false;
    //         c.cart.updateCartAnimation(false);
    //       }
    //     }, 2000);
    //   } else {
    //     this.animationCart = state;
    //   }
    // });
  }

  ngOnInit() {
    this.getCarts();
    this.checkLocalStorage();
    this.infoService = this.service.infoService.getValue();
    if(!this.infoService){
      setTimeout(()=> {
        this.infoService = this.service.infoService.getValue();
      }, 500);
    }

    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  getCarts(){
    this.cart.currentCart.subscribe((response) => {
      if(response !== undefined){
        this.products = response;
      }
      this.subtotal = 0;
      for(let i = 0; i < this.products.length; i++){
        if(this.products[i].has_wholesale_price === false && this.products[i].quantity >= this.products[i].minimum_purchase){
          if(this.products[i].has_discount){
            this.subtotal =  this.subtotal + (this.products[i].quantity * (this.products[i].price * ((100 - this.products[i].discount)/100)));
          } else {
            this.subtotal =  this.subtotal + (this.products[i].quantity * this.products[i].price);
          }
        } else if(this.products[i].has_wholesale_price === false && this.products[i].quantity < this.products[i].minimum_purchase){
          if(this.products[i].has_discount){
            this.subtotal = this.subtotal + (this.products[i].quantity * (this.products[i].price * ((100 - this.products[i].discount)/100)));
          } else {
            this.subtotal = this.subtotal + (this.products[i].quantity * this.products[i].price);
          }
        } else if(this.products[i].has_wholesale_price && this.products[i].quantity >= this.products[i].minimum_purchase){
          if(this.products[i].has_discount){
            this.subtotal = this.subtotal + (this.products[i].quantity * (this.products[i].wholesale_price * ((100 - this.products[i].discount)/100)));
          } else {
            this.subtotal = this.subtotal + (this.products[i].quantity * this.products[i].wholesale_price);
          }
        } else if(this.products[i].has_wholesale_price && this.products[i].quantity < this.products[i].minimum_purchase){
          if(this.products[i].has_discount){
            this.subtotal = this.subtotal + (this.products[i].quantity * (this.products[i].price * ((100 - this.products[i].discount)/100)));
          } else {
            this.subtotal = this.subtotal + (this.products[i].quantity * this.products[i].price);
          }
        }

        if(this.products[i].currency !== undefined){
          if(i === 0){
            this.subtotalCurrency = this.products[i].currency;
          }
          if(this.products[i].currency !== this.subtotalCurrency){
            this.subtotalCurrency = '';
          }
        }
      }
    });
    // this.cart.getItems().subscribe(response => {
    //   if(response !== undefined){
    //     this.products = response;
    //   }
    //   this.subtotal = 0;
    //   for(let i = 0; i < this.products.length; i++){
    //     if(this.products[i].has_wholesale_price === false && this.products[i].quantity >= this.products[i].minimum_purchase){
    //       if(this.products[i].has_discount){
    //         this.subtotal =  this.subtotal + (this.products[i].quantity * (this.products[i].price * ((100 - this.products[i].discount)/100)));
    //       } else {
    //         this.subtotal =  this.subtotal + (this.products[i].quantity * this.products[i].price);
    //       }
    //     } else if(this.products[i].has_wholesale_price === false && this.products[i].quantity < this.products[i].minimum_purchase){
    //       if(this.products[i].has_discount){
    //         this.subtotal = this.subtotal + (this.products[i].quantity * (this.products[i].price * ((100 - this.products[i].discount)/100)));
    //       } else {
    //         this.subtotal = this.subtotal + (this.products[i].quantity * this.products[i].price);
    //       }
    //     } else if(this.products[i].has_wholesale_price && this.products[i].quantity >= this.products[i].minimum_purchase){
    //       if(this.products[i].has_discount){
    //         this.subtotal = this.subtotal + (this.products[i].quantity * (this.products[i].wholesale_price * ((100 - this.products[i].discount)/100)));
    //       } else {
    //         this.subtotal = this.subtotal + (this.products[i].quantity * this.products[i].wholesale_price);
    //       }
    //     } else if(this.products[i].has_wholesale_price && this.products[i].quantity < this.products[i].minimum_purchase){
    //       if(this.products[i].has_discount){
    //         this.subtotal = this.subtotal + (this.products[i].quantity * (this.products[i].price * ((100 - this.products[i].discount)/100)));
    //       } else {
    //         this.subtotal = this.subtotal + (this.products[i].quantity * this.products[i].price);
    //       }
    //     }

    //     if(this.products[i].currency !== undefined){
    //       if(i === 0){
    //         this.subtotalCurrency = this.products[i].currency;
    //       }
    //       if(this.products[i].currency !== this.subtotalCurrency){
    //         this.subtotalCurrency = '';
    //       }
    //     }
    //   }
    // });
  }

  checkLocalStorage(){
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    //   this.colorIcon = colorTheme;
    // }

    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          this.colorIcon = response.color;
        }
      }
    });


  }

  goTo(route){
    this.router.navigate(route);
  }


  public removeItem(product: any) {
    // this.productService.removeCartItem(product);
    const token = this.auth.getToken();
    this.cart.removeItem(product, token, this.infoService ? this.infoService.pickerpro_id: '');
  }

  showDetail(product){
    // if(product.attribute_types === 'attributes'){
    //   this.router.navigate(['', 'p', product.slug], { queryParams: { uid: product.id } });
    // } else if(product.attribute_types === 'variations'){
    //   const variant_length = product.variations_type.split('&').length;
    //   if(variant_length === 1){
    //     this.router.navigate(['', 'p', product.slug], { queryParams: { uid: product.id, variant1: `${product.variation.variant1.id}_${product.variation.variant1.value_name}` } });
    //   } else if(variant_length === 2){
    //     this.router.navigate(['', 'p', product.slug], { queryParams: { uid: product.id, variant1: `${product.variation.variant1.id}_${product.variation.variant1.value_name}`, variant2: `${product.variation.variant2.id}_${product.variation.variant2.value_name}` } });
    //   }
    // }

    sessionStorage.setItem('c291cmNlQmFjaw==', 'cart');
    if(product.attribute_types === 'attributes'){
      this.router.navigate(['', 'p', product.slug], { queryParams: { uid: product.item_id } });
    }
  }

}
