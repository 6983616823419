<app-header></app-header>
<app-navbar></app-navbar>
<app-slider></app-slider>


<!-- END MAIN CONTENT -->
<div class="main_content">

    <div class="mt-0">
        <app-services></app-services>
    </div>

    <!-- START SECTION SHOP -->
    <div class="section pt-5 pb-0 mb-0" *ngIf="products.length > 0">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="heading_s1 text-center">
                        <h2>PRODUCTOS DESTACADOS</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-5">
                    <carousel [cellWidth]="300" [height]="300" [autoplay]="true" [arrows]="false">
                        <div class="carousel-cell" *ngFor="let product of products; let position = index;">
                            <div class="product">
                                <span [hidden]="!product.is_featured" class="pr_flash">TOP</span>
                                <span [hidden]="!product.sale" class="pr_flash bg-danger" [ngClass]="{'mt-4': product.is_featured}">LIQUIDACIÓN</span><br/>
                                <div class="product_img" (click)="showProduct(product)" style="cursor: pointer;" [hidden]="product.attribute_types !== 'attributes'">
                                    <a>
                                        <img *ngIf="product.images.length > 0" alt="product_img1" (error)="updateUrl(position, product)" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].url"
                                        width="100%" height="auto" max-width="300px" style="max-height: 180px;">
                                        <img *ngIf="product.images == undefined || product.images.length == 0" src="assets/images/product_img1.jpg" alt="product_img1" width="100%" height="auto" max-width="300px" style="max-height: 180px;">
                                    </a>
                                </div>
                                <div *ngIf="product.variations" class="product_img" (click)="showProduct(product)" style="cursor: pointer;" [hidden]="product.attribute_types !== 'variations'">
                                    <a>
                                        <img *ngIf="product.variations.length > 0" (error)="updateUrl(position, product)" alt="product_img1" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.variations[0].images[0].url" width="100%" height="auto" max-width="300px" style="max-height: 180px;">
                                        <img *ngIf="product.variations == undefined || product.variations.length == 0" src="assets/images/product_img1.jpg" alt="product_img1" width="100%" height="auto" max-width="300px" style="max-height: 180px;">
                                    </a>
                                </div>
                                <!-- <div class="product_action_box">
                                    <ul class="list_none pr_action_btn">
                                        <li><a (click)="showQuickView(product)" class="popup-ajax" style="cursor: pointer;" [ngStyle]="{'background': colorTheme, 'border-color': 'colorTheme', 'color': 'white'}"><i class="icon-magnifier-add"></i></a></li>
                                    </ul>
                                </div> -->
                                <div class="product_info">
                                    <h6 class="product_title"><a (click)="showProduct(product)">{{product.title}}</a></h6>
                                    <div *ngIf="product.has_discount" class="product_price">
                                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price * ((100 - product.discount)/100) | currency}} {{product.currency}}</span>
                                        <del>{{product.retail_price | currency}} {{product.currency}}</del>
                                        <div class="on_sale">
                                            <span>{{product.discount}}% descuento</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!product.has_discount" class="product_price">
                                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price | currency}} {{product.currency}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </carousel>
                    <!-- <owl-carousel-o [options]="feacturedProducts" class="product_slider owl-theme nav_style1" *ngIf="products.length > 0">
                        <ng-template *ngFor="let product of products; let position = index;" class="item" carouselSlide >
                            <div class="product">
                                <span [hidden]="!product.is_featured" class="pr_flash">TOP</span>
                                <span [hidden]="!product.sale" class="pr_flash bg-danger" [ngClass]="{'mt-4': product.is_featured}">LIQUIDACIÓN</span><br/>
                                <div class="product_img" (click)="showProduct(product)" style="cursor: pointer;" [hidden]="product.attribute_types !== 'attributes'">
                                    <a>
                                        <img *ngIf="product.images.length > 0" alt="product_img1" (error)="updateUrl(position, product)" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].url"
                                        width="100%" height="auto" max-width="300px">
                                        <img *ngIf="product.images == undefined || product.images.length == 0" src="assets/images/product_img1.jpg" alt="product_img1" width="100%" height="auto" max-width="300px">
                                    </a>
                                </div>
                                <div class="product_img" (click)="showProduct(product)" style="cursor: pointer;" [hidden]="product.attribute_types !== 'variations'">
                                    <a>
                                        <img *ngIf="product.variations.length > 0" (error)="updateUrl(position, product)" alt="product_img1" [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product_img1.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.variations[0].images[0].url" width="100%" height="auto" max-width="300px">
                                        <img *ngIf="product.variations == undefined || product.variations.length == 0" src="assets/images/product_img1.jpg" alt="product_img1" width="100%" height="auto" max-width="300px">
                                    </a>
                                </div>
                                <div class="product_action_box">
                                    <ul class="list_none pr_action_btn">
                                        <li><a (click)="showQuickView(product)" class="popup-ajax" style="cursor: pointer;" [ngStyle]="{'background': colorTheme, 'border-color': 'colorTheme', 'color': 'white'}"><i class="icon-magnifier-add"></i></a></li>
                                    </ul>
                                </div>
                                <div class="product_info">
                                    <h6 class="product_title"><a (click)="showProduct(product)">{{product.title}}</a></h6>
                                    <div *ngIf="product.has_discount" class="product_price">
                                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price * ((100 - product.discount)/100) | currency}}</span>
                                        <del>{{product.retail_price | currency}}</del>
                                        <div class="on_sale">
                                            <span>{{product.discount}}% descuento</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!product.has_discount" class="product_price">
                                        <span class="price" [ngStyle]="{'color': colorTheme2}">{{product.retail_price | currency}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o> -->
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->

    <!-- START SECTION SINGLE BANNER -->
    <div class="bg_offers pb-0 mt-0" *ngIf="banner_principal !== undefined" id="bg_offers" [ngStyle]="{'background': colorLine}">
        <div class="container paddings" [hidden]="!banner_principal.show">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-md-6 offset-md-1">
                    <div class="medium_divider d-none d-md-block clearfix"></div>
                    <div class="trand_banner_text text-center text-md-left">
                        <div class="heading_s1 mb-3">
                            <span class="sub_heading" [ngStyle]="{'color': colorTxtBanner}" style="text-transform: none;"><b>{{banner_principal.title || ''}}</b></span>
                            <!-- <h2>{{temporada}} {{anio}}</h2> -->
                            <h2 [ngStyle]="{'color': colorTxtBanner}" style="text-transform: none;">{{banner_principal.title2 || ''}}</h2>
                        </div>
                        <a (click)="abrirBanner(banner_principal)" class="btn rounded-0" *ngIf="banner_principal.text_btn !== ''" id="btn_principal" [ngStyle]="{'border': '1px solid white', 'color': 'white'}" (mouseover)="isHover2(true)" (mouseleave)="isHover2(false)"><b>{{banner_principal.text_btn || ''}}</b></a>
                    </div>
                    <div class="medium_divider clearfix"></div>
                </div>
                <div class="col-md-5">
                    <div style="margin-top: 0px;" class="text-center trading_img mt-0" *ngIf="banner_principal.img !== ''">
                        <img (error)="updateUrl(0, undefined)" [lazyLoad]="ImageSrc ? ImageSrc : banner_principal.img" class="mt-0 mt-md-0 mb-0" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END SECTION SINGLE BANNER -->

</div>
<!-- END MAIN CONTENT -->
<app-footer></app-footer>
