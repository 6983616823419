<app-header *ngIf="isB2C"></app-header>
<app-navbar *ngIf="isB2C"></app-navbar>
<!-- START MAIN CONTENT -->
<div class="main_content">

    <!-- START LOGIN SECTION -->
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-md-10">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div class="heading_s1">
                                <h3 style="text-transform: none;">Recuperar contraseña</h3>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <input type="text" required="" class="form-control" name="email" placeholder="Correo electrónico" value="{{emailConfirmacion}}" (keyup)="escucharValoresInput($event, 'correo')" [disabled]="confirmarCodigoRecuperacion">
                                </div>
                                <div class="form-group col-md-12" *ngIf="confirmarCodigoRecuperacion">
                                    <input type="text" required="" class="form-control" name="email" placeholder="Código de confirmación" value="{{codigoRecuperacion}}" (keyup)="escucharValoresInput($event, 'codigo')">
                                </div>
                                <div class="form-group col-md-12" *ngIf="confirmarCodigoRecuperacion">
                                    <input type="password" required="" class="form-control" name="email" placeholder="Nueva contraseña" value="{{nuevaContrasenia}}" (keyup)="escucharValoresInput($event, 'nueva_contrasenia')">
                                </div>
                                <div class="form-group col-md-12" *ngIf="confirmarCodigoRecuperacion">
                                    <input type="password" required="" class="form-control" name="email" placeholder="Confirma tu nueva contraseña" value="{{contraseniaConfirmacion}}" (keyup)="escucharValoresInput($event, 'contrasenia_confirmacion')">
                                </div>
                                <div class="form-group col-12">
                                    <button class="btn btn-block col-12" *ngIf="!confirmarCodigoRecuperacion" (click)="olvideMiContrasenia()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">RECUPERAR</button>
                                    <button class="btn btn-block col-12" *ngIf="confirmarCodigoRecuperacion" (click)="actualizarContrasenia()" [ngStyle]="{'background': colorTheme, 'color': 'white'}">ACTUALIZAR CONTRASEÑA</button>
                                </div>
                            </div>
                            <div class="form-note text-center" *ngIf="!confirmarCodigoRecuperacion">¿Recordaste tu contraseña? <a [routerLink]="['', 'ingresar']" id="signin" (mouseover)="isHover2(true, 'signin')" (mouseleave)="isHover2(false, 'signin')">Ingresa aquí</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END LOGIN SECTION -->

</div>
<!-- END MAIN CONTENT -->
<app-footer *ngIf="isB2C"></app-footer>
