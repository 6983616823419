import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { StyleManagerService } from '@ignacioruben7/shops-styling-angular';
import { CustomStoreService } from 'src/app/services/store/custom-store.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  styles: [
    `.btn-fill-out {
      border: 1px solid var(--colorThemePrimary);
    }`,
    `.btn-fill-out::before,
      .btn-fill-out::after {
        background-color: var(--colorThemePrimary);
    }`,
    `.btn-fill-out:hover {
      color: var(--colorThemePrimary)
    }`,
    `.carousel-control-next:hover,
    .carousel-control-prev:hover,
    .light_arrow .carousel-control-next:hover,
    .light_arrow .carousel-control-prev:hover {
        background-color: var(--colorThemePrimary);
    }`,
    `.banner_content h2 {
      font-size: 48px;
    }`
  ]
})
export class SliderComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth < 350){
      this.fontSize = '35px';
    } else if(event.target.innerWidth < 400){
      this.fontSize = '40px';
    } else if(event.target.innerWidth < 500){
      this.fontSize = '45px';
    } else if(event.target.innerWidth < 770){
      this.fontSize = '45px';
    } else if(event.target.innerWidth < 1000){
      this.fontSize = '45px';
    } else if(event.target.innerWidth < 1200){
      this.fontSize = '45px';
    } else {
      this.fontSize = '45px';
    }
  }

  public fontSize = '45px';
  public banners = [];
  public colorTheme = 'white';
  private colorDefault = '#0097db';
  public widthImg = '';
  public heightImg = '';

  constructor(private service: CustomStoreService, private styling: StyleManagerService, private router: Router) {

  }

  ngOnInit() {
    this.widthImg = `${window.innerWidth}px`;
    this.heightImg = `${(window.innerWidth)* (500/1920)}px`;
    if(window.innerWidth < 350){
      this.fontSize = '35px';
    } else if(window.innerWidth < 400){
      this.fontSize = '40px';
    } else if(window.innerWidth < 500){
      this.fontSize = '45px';
    } else if(window.innerWidth < 770){
      this.fontSize = '45px';
    } else if(window.innerWidth < 1000){
      this.fontSize = '45px';
    } else if(window.innerWidth < 1200){
      this.fontSize = '45px';
    } else {
      this.fontSize = '45px';
    }

    this.checkLocalStorage();
    // if(localStorage.getItem('SU5GTy1MT0dP') === null){
    //   const interval = setInterval(() => {
    //     if(localStorage.getItem('SU5GTy1MT0dP') !== null){
    //       this.checkLocalStorage();
    //       clearInterval(interval);
    //     }
    //   }, 100);
    // } else {
    //   this.checkLocalStorage();
    // }
  }

  checkLocalStorage(){
    this.service.currentService.subscribe((response) => {
      if(response){
        if(response.color !== ''){
          this.colorTheme = response.color;
          // this.colorIcon = response.color;
        }
        if(response.banners){
          if(response.banners.length > 0){
            this.banners = response.banners;
            this.banners.forEach((banner, index) => {
              if(banner.img !== ''){
                if(banner.local){
                  this.setImage(`banner_${index}`, `../../../../assets/general/${banner.img}`);
                } else {
                  this.setImage(`banner_${index}`, banner.img);
                }
              }
            });
          } else {
            this.bannerByDefault();
          }
        }
      }
    });
    // let banners = null;
    // try {
    //   banners = JSON.parse(atob(localStorage.getItem('SU5GTy1CQU5ORVJT')));
    // } catch (error) {

    // }
    // const colorTheme = atob(localStorage.getItem('SU5GTy1DT0xPUg=='));
    // if(colorTheme !== ''){
    //   this.colorTheme = colorTheme;
    // } else {
    //   this.colorTheme = this.colorDefault;
    // }
    // if(banners !== null){
    //   if(banners.length > 0){
    //     this.banners = banners;
    //     this.banners.forEach((banner, index) => {
    //       if(banner.img !== ''){
    //         if(banner.local){
    //           this.setImage(`banner_${index}`, `../../../../assets/general/${banner.img}`);
    //         } else {
    //           this.setImage(`banner_${index}`, banner.img);
    //         }
    //       }
    //     });
    //   } else {
    //     this.bannerByDefault();
    //   }
    // }
  }

  visualizarProductos(banner){
    if(banner.link.trim() !== ''){
      window.open(banner.link, '_blank');
    } else {
      this.router.navigate(['', banner.page], { queryParams: { collection: banner.collection, category_id: banner.category } });
      if(banner.page === 'tienda'){
        localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
      } else if(banner.page === 'ofertas'){
        localStorage.removeItem('bGlzdE9mSXRlbXNPblNhbGU=');
      }
    }
  }

  setImage(id, img){
    setTimeout(() => {
      try {
        this.styling.setEffect(id, 'backgroundImage', `url(${img})`);
      } catch (error) {

      }
    }, 50);
  }

  bannerByDefault(){
    this.banners = [
      {
        local: true,
        show_btn: true,
        title: 'MODA PARA',
        title2: 'DAMA',
        text_btn: 'VER PRODUCTOS',
        img: 'banner1.webp',
        link: '',
        page: 'tienda',
        collection: 'dama',
        category: undefined
    }, {
        local: true,
        show_btn: true,
        title: 'MODA PARA',
        title2: 'CABALLERO',
        text_btn: 'VER PRODUCTOS',
        img: 'banner2.webp',
        link: '',
        page: 'tienda',
        collection: 'caballero',
        category: ''
    }
    ];


    this.banners.forEach((banner, index) => {
      if(banner.img.trim() !== ''){
        if(banner.local){
          this.setImage(`banner_${index}`, `../../../../assets/general/${banner.img}`);
        } else {
          this.setImage(`banner_${index}`, banner.img);
        }
      }
    });
  }

  isHover(hover, index){
    if(hover){
      this.styling.setColor(`btn_link_${index}`, 'backgroundColor', 'transparent');
      this.styling.setColor(`btn_link_${index}`, 'color', this.colorTheme);
    } else {
      this.styling.setColor(`btn_link_${index}`, 'backgroundColor', this.colorTheme);
      this.styling.setColor(`btn_link_${index}`, 'color', '#fff');
    }
  }

  isHover2(hover, id){
    if(hover){
      this.styling.setColor(id, 'backgroundColor', this.colorTheme);
      this.styling.setColor(id, 'color', '#ffffff');
    } else {
      this.styling.setColor(id, 'backgroundColor', '#ffffff');
      this.styling.setColor(id, 'color', '#000000');
    }
  }

}
