import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { RestApiServiceService } from './services/api/rest-api-service.service';
import { CartServiceService } from './services/cart/cart-service.service';
import { CustomStoreService } from './services/store/custom-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  private configWorker: any;
  public finish = false;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  favIcon2: HTMLLinkElement = document.querySelector('#appIcon2');
  favIcon3: HTMLLinkElement = document.querySelector('#appIcon3');
  favIcon4: HTMLLinkElement = document.querySelector('#appIcon4');
  favIcon5: HTMLLinkElement = document.querySelector('#appIcon5');

  constructor(private api: RestApiServiceService, private cart: CartServiceService, private service: CustomStoreService) {
    this.initWorkers();
    this.configWorker.onmessage = ({data}) => {
      let estado = data.code;
      let info = data.general;
      let stores = data.stores;
      let payments = data.payments;
      sessionStorage.setItem('U2VydmljZVN0YXR1cw==', btoa(''+estado));
      if(estado === 200){
        if(info !== undefined){
          // 1. CLEAN
        localStorage.removeItem('SU5GTy1BRERSRVNT');
        localStorage.removeItem('SU5GTy1QSE9ORQ==');
        localStorage.removeItem('SU5GTy1FTUFJTA==');
        localStorage.removeItem('SU5GTy1TT0NJQUw=');
        localStorage.removeItem('SU5GTy1ERVNDUklQVElPTg==');
        localStorage.removeItem('SU5GTy1MT0dP');
        localStorage.removeItem('SU5GTy1USVRMRQ==');
        localStorage.removeItem('SU5GTy1DT0xPUg==');
        localStorage.removeItem('SU5GTy1FU1RBRE9T');
        localStorage.removeItem('SU5GTy1CQU5ORVJT');
        localStorage.removeItem('SU5GTy1CQU5ORVJTLUNBQkFMTEVSTw==');
        localStorage.removeItem('SU5GTy1CQU5ORVJTLVVOSVNFWA==');
        localStorage.removeItem('SU5GTy1CQU5ORVJTLU5JTk8=');
        localStorage.removeItem('SU5GTy1CQU5ORVJTLU5JTkE=');
        localStorage.removeItem('SU5GTy1CQU5ORVJTLUJFQkVT');
        // localStorage.removeItem('aW5mb0NhdGFsb2dTZXJ2aWNl');
        localStorage.removeItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==');
        localStorage.removeItem('TUFJTl9CQU5ORVI=');
        localStorage.removeItem('bGlzdE9mU2VydmljZXNJY29u');

        /* VARIABLES */
        // FAVICON
        let service = {
          pickerpro_id: '',
          customer_edit_address: true,
          phone: '',
          color: '#0097db',
          logo: '',
          description: '',
          email: '',
          social: [],
          show_stock: true,
          address: '',
          title: '',
          slogan: '',
          banners: [],
          states: [],
          collections: [],
          keywords: '',
          key: '',
          public: '',
          domain: '',
          banner_principal: {
            show: true,
            local: true,
            title: '',
            title2: '',
            text_btn: '',
            img: '',
            link: '',
            page: '',
            collection: undefined,
            category: undefined,
            background: info.color
          },
          services: [
            {
              title: 'ENVÍO NACIONAL',
              subtitle: 'Enviamos por FedEx, DHL y Estafeta',
              icon: 'flaticon-shipped'
            },
            {
              title: 'OFERTAS',
              subtitle: 'Contamos con ofertas relámpago',
              icon: 'flaticon-money-back'
            },
            {
              title: 'SERVICIO 24/7',
              subtitle: 'Servicio en línea 24 X 7 por WhatsApp',
              icon: 'flaticon-support'
            }
          ]
        }
        if(info.favicon !== undefined){
          if(info.favicon.ico !== ''){
            this.favIcon.href = info.favicon.ico;
          }
          if(info.favicon.png !== ''){
            this.favIcon2.href = info.favicon.png;
            this.favIcon3.href = info.favicon.png;
            this.favIcon4.href = info.favicon.png;
            this.favIcon5.href = info.favicon.png;
          }
        }

        // PHONE NUMBER
        if(info.phone){
          service.phone = info.phone;
        }

        if(info.pickerpro_id){
          service.pickerpro_id = info.pickerpro_id;
        }

        // if(info.phone !== undefined){
        //   service.phone = info.phone;
        //   localStorage.setItem('SU5GTy1QSE9ORQ==', btoa(info.phone));
        // } else {
        //   localStorage.setItem('SU5GTy1QSE9ORQ==', btoa(''));
        // }
        // COLOR
        if(info.color){
          service.color = info.color;
        }
        // if(info.color !== undefined){
        //   service.color = info.color;
        //   localStorage.setItem('SU5GTy1DT0xPUg==', btoa(info.color));
        // } else {
        //   localStorage.setItem('SU5GTy1DT0xPUg==', btoa("#0097db"));
        // }
        // customer_edit_address
        if(typeof info.customer_edit_address === 'boolean'){
          if(!info.customer_edit_address){
            service.customer_edit_address = false;
          }
        }
        // LOGO
        if(info.logo){
          service.logo = info.logo;
        }
        // let logo = '';
        // if(info.logo !== undefined){
        //   logo = info.logo;
        //   service.logo = info.logo;
        //   localStorage.setItem('SU5GTy1MT0dP', btoa(info.logo));
        // } else {
        //   localStorage.setItem('SU5GTy1MT0dP', btoa(''));
        // }
        // descripcion
        if(info.description){
          service.description = info.description;
        }
        // let descripcion = '';
        // if(info.description !== undefined){
        //   descripcion = info.description;
        //   service.description = info.description;
        //   localStorage.setItem('SU5GTy1ERVNDUklQVElPTg==', info.description);
        // } else {
        //   localStorage.setItem('SU5GTy1ERVNDUklQVElPTg==', '');
        // }
        // EMAIL
        if(info.email){
          service.email = info.email;
        }
        // if(info.email !== undefined){
        //   service.email = info.email;
        //   localStorage.setItem('SU5GTy1FTUFJTA==', btoa(info.email));
        // } else {
        //   localStorage.setItem('SU5GTy1FTUFJTA==', btoa(''));
        // }
        // SOCIAL
        if(info.social){
          service.social = info.social;
        }
        // if(info.social !== undefined){
        //   service.social = info.social;
        //   localStorage.setItem('SU5GTy1TT0NJQUw=', btoa(JSON.stringify(info.social)));
        // } else {
        //   localStorage.setItem('SU5GTy1TT0NJQUw=', btoa(JSON.stringify([])));
        // }
        // SHOW STOCK
        if(info.show_stock !== undefined){
          service.show_stock = info.show_stock;
        }
        // if(info.show_stock !== undefined){
        //   service.show = info.show_stock;
        //   localStorage.setItem('c2hvd1N0b2Nr', btoa(JSON.stringify({show: info.show_stock})));
        // } else {
        //   localStorage.setItem('c2hvd1N0b2Nr', btoa(JSON.stringify({show: true})));
        // }
        // DIRECCIÓN
        if(info.address){
          service.address = info.address;
        }
        // if(info.address !== undefined){
        //   service.address = info.address;
        //   localStorage.setItem('SU5GTy1BRERSRVNT', btoa(info.address));
        // } else {
        //   localStorage.setItem('SU5GTy1BRERSRVNT', btoa(''));
        // }
        // CATALOGO
        // if(info.catalog !== undefined){
        //   const catalog = {
        //     categories: info.catalog,
        //     version: info.catalog_version
        //   }
        //   localStorage.setItem('aW5mb0NhdGFsb2dTZXJ2aWNl', btoa(JSON.stringify(catalog)));
        // } else {
        //   const catalog = {
        //     categories: [],
        //     version: 0
        //   }
        //   localStorage.setItem('aW5mb0NhdGFsb2dTZXJ2aWNl', btoa(JSON.stringify(catalog)))
        // }

        // SERVICIOS
        if(info.services && info.services?.length > 0){
          service.services = info.services;
        }
        // const services = [
        //   {
        //     title: 'ENVÍO NACIONAL',
        //     subtitle: 'Enviamos por FedEx, DHL y Estafeta',
        //     icon: 'flaticon-shipped'
        //   },
        //   {
        //     title: 'OFERTAS',
        //     subtitle: 'Contamos con ofertas relámpago',
        //     icon: 'flaticon-money-back'
        //   },
        //   {
        //     title: 'SERVICIO 24/7',
        //     subtitle: 'Servicio en línea 24 X 7 por WhatsApp',
        //     icon: 'flaticon-support'
        //   }
        // ];
        // if(info.services !== undefined){
        //   if(info.services.length > 0){
        //     service.services = info.services;
        //     localStorage.setItem('bGlzdE9mU2VydmljZXNJY29u', JSON.stringify(info.services));
        //   } else {
        //     localStorage.setItem('bGlzdE9mU2VydmljZXNJY29u', JSON.stringify(services));
        //   }
        // } else {
        //   localStorage.setItem('bGlzdE9mU2VydmljZXNJY29u', JSON.stringify(services));
        // }
        // TITULO
        if(info.title){
          service.title = info.title;
        }
        // let titulo = '';
        // if(info.title !== undefined){
        //   localStorage.setItem('SU5GTy1USVRMRQ==', btoa(info.title));
        //   titulo = info.title;
        //   service.title = info.title;
        // } else {
        //   localStorage.setItem('SU5GTy1USVRMRQ==', btoa(''));
        // }
        // SLOGAN
        if(info.slogan){
          service.slogan = info.slogan;
        }
        // let slogan = '';
        // if(info.slogan !== undefined){
        //   service.slogan = info.slogan;
        //   localStorage.setItem('SU5GTy1TTE9HQU4=', btoa(info.slogan));
        //   slogan = info.slogan;
        // } else {
        //   localStorage.setItem('SU5GTy1TTE9HQU4=', btoa(''));
        // }
        // BANNERS
        if(info.banners){
          service.banners = info.banners;
        }
        // if(info.banners !== undefined){
        //   service.banners = info.banners;
        //   localStorage.setItem('SU5GTy1CQU5ORVJT', btoa(JSON.stringify(info.banners)));
        // } else {
        //   localStorage.setItem('SU5GTy1CQU5ORVJT', btoa(JSON.stringify([])));
        // }
        // BANNER PRINCIPAL
        if(info.banner_principal){
          service.banner_principal = info.banner_principal;
        }
        // if(info.banner_principal !== undefined){
        //   service.banner_principal = info.banner_principal;
        //   localStorage.setItem('TUFJTl9CQU5ORVI=', btoa(JSON.stringify(info.banner_principal)));
        // } else {
        //   localStorage.setItem('TUFJTl9CQU5ORVI=', btoa(JSON.stringify({
        //     show: true,
        //     local: true,
        //     title: '',
        //     title2: '',
        //     text_btn: '',
        //     img: '',
        //     link: '',
        //     page: '',
        //     collection: undefined,
        //     category: undefined,
        //     background: info.color
        //   })));
        // }

        if(info.states){
          service.states = info.states;
        }
        // if(info.states !== undefined){
        //   service.states = info.states;
        //   localStorage.setItem('SU5GTy1FU1RBRE9T', btoa(JSON.stringify(info.states)));
        // }
        if(info.keywords){
          service.keywords = info.keywords;
        }
        // let keywords = '';
        // if(info.keywords !== undefined){
        //   keywords = info.keywords;
        //   service.keywords = info.keywords;
        // }

        if(info.key){
          service.key = info.key;
        }
        // if(info.key !== undefined){
        //   service.key = info.key;
        //   sessionStorage.setItem('a2V5', info.key);
        // }

        if(info.public){
          service.public = info.public;
        }

        // if(info.public !== undefined){
        //   service.public = info.public;
        //   sessionStorage.setItem('cHVibGlj', info.public);
        // }

        if(info.domain){
          service.domain = info.domain;
        }
        // let dominio = '';
        // if(info.domain !== undefined){
        //   dominio = info.domain;
        //   service.domain = info.domain;
        //   localStorage.setItem('SU5GTy1ET01BSU4=', btoa(info.domain));
        // }

        let titleHeader = '';
        if (service.title !== '' && service.slogan !== '') {
          titleHeader = `${service.title} | ${service.slogan}`
        } else if (service.title === '' && service.slogan !== '') {
          titleHeader = `LocLog Shops | ${service.slogan}`
        } else if (service.title !== '' && service.slogan === '') {
          titleHeader = `${service.title} | Vende en línea fácilmente`
        } else if (service.title === '' && service.slogan === '') {
          titleHeader = `LocLog Shops | Vende en línea fácilmente`
        }

        const tags = [
          {name: 'keywords', content: service.keywords},
          {name: 'author', content: 'LocLog'},
          {name: 'copyright', content: `${new Date().getFullYear()}`},
          {name: 'description', content: service.description},
          {name: 'robots', content: 'index, follow'},
          {name: 'twitter:image', content: service.logo},
          {name: 'twitter:card', content: 'summary_large_image'},
          {name: 'twitter:url', content: `https://${service.domain}`},
          {name: 'twitter:description', content: service.description},
          {name: 'og:locale', content: 'es_MX'},
          {name: 'og:type', content: 'website'},
          {name: 'og:image', content: service.logo},
          {name: 'og:title', content: titleHeader},
          {name: 'og:description', content: service.description},
          {name: 'og:url', content: `https://${service.domain}`},
          {name: 'og:site_name', content: titleHeader}
        ];
        this.api.setTitle(titleHeader);
        this.api.updateTags(tags);

        // if(!environment.smartbCompatible){
        //   if(info.collections !== undefined){
        //     localStorage.setItem('aW5mb0NvbGxlY3Rpb25zU2VydmljZQ==', JSON.stringify(info.collections));
        //   }
        // }
        localStorage.removeItem('bGlzdE9mSXRlbXNGZWF0dXJlZA==');
        localStorage.removeItem('ZGV0YWlsT2ZJdGVt');
        localStorage.removeItem('bGlzdE9mSXRlbXNPbk1haW5TdG9yZQ==');
        localStorage.removeItem('bGlzdE9mSXRlbXNPblNhbGU=');
        this.service.updateService(service);
        this.cart.getCartItems();
        } else if(stores !== undefined){
          // this.service.updateStores(stores);
          // sessionStorage.setItem('bGlzdE9mU3RvcmVz', btoa(JSON.stringify(stores)));
        } else if(payments !== undefined){
          this.service.updatePayments(payments);
          // sessionStorage.setItem('bGlzdE9mUGF5bWVudHM=', btoa(JSON.stringify(payments)));
        }
      } else if(estado === 401){
        window.location.href = environment.public_loclogshop;
      } else if(estado === 500){

      }
    };
    this.configWorker.postMessage({request: 'getInfoService'});
    // this.configWorker.postMessage({request: 'getListOfStores'});
    // this.configWorker.postMessage({request: 'getListOfPayments'});
    setTimeout(() => {
      this.initCSS();
      setTimeout(() => {
        this.finish = true;
      }, 100)
    }, 100);
  }

  ngOnInit(){
  }

  initWorkers(){
    if(typeof Worker !== undefined){
      // CREAR WORKER
      if(!this.configWorker){
        this.configWorker = new Worker(new URL('./workers/config-worker.worker', import.meta.url), { type: 'module'});
      }
    } else {
      // WEBWORKER NOT SUPORT
    }
  }

  initCSS(){
    var bootstrap = document.createElement( "link" );
    bootstrap.rel = "stylesheet";
    bootstrap.href = "assets/bootstrap/css/bootstrap.min.css";
    document.head.insertBefore( bootstrap, document.head.childNodes[0].nextSibling );

    var font1 = document.createElement( "link" );
    font1.rel = "stylesheet";
    font1.href = "assets/css/font1.css";
    document.head.insertBefore( font1, document.head.childNodes[0].nextSibling );

    var font2 = document.createElement( "link" );
    font2.rel = "stylesheet";
    font2.href = "assets/css/font2.css";
    document.head.insertBefore( font2, document.head.childNodes[0].nextSibling );

    var all = document.createElement( "link" );
    all.rel = "stylesheet";
    all.href = "assets/css/all.min.css";
    document.head.insertBefore( all, document.head.childNodes[0].nextSibling );

    var simple_line_icons = document.createElement( "link" );
    simple_line_icons.rel = "stylesheet";
    simple_line_icons.href = "assets/css/simple-line-icons.css";
    document.head.insertBefore( simple_line_icons, document.head.childNodes[0].nextSibling );

    var ionicons = document.createElement( "link" );
    ionicons.rel = "stylesheet";
    ionicons.href = "assets/css/ionicons.min.css";
    document.head.insertBefore( ionicons, document.head.childNodes[0].nextSibling );

    var flaticon = document.createElement( "link" );
    flaticon.rel = "stylesheet";
    flaticon.href = "assets/css/flaticon.css";
    document.head.insertBefore( flaticon, document.head.childNodes[0].nextSibling );

    var linearicons = document.createElement( "link" );
    linearicons.rel = "stylesheet";
    linearicons.href = "assets/css/linearicons.css";
    document.head.insertBefore( linearicons, document.head.childNodes[0].nextSibling );

    var themify_icons = document.createElement( "link" );
    themify_icons.rel = "stylesheet";
    themify_icons.href = "assets/css/themify-icons.css";
    document.head.insertBefore( themify_icons, document.head.childNodes[0].nextSibling );

    var style_css = document.createElement( "link" );
    style_css.rel = "stylesheet";
    style_css.href = "assets/css/style.css";
    document.head.insertBefore( style_css, document.head.childNodes[0].nextSibling );

    var responsive_css = document.createElement( "link" );
    responsive_css.rel = "stylesheet";
    responsive_css.href = "assets/css/responsive.css";
    document.head.insertBefore( responsive_css, document.head.childNodes[0].nextSibling );
  }

  initJS(){
  }
}
